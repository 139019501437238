import { Injectable } from '@angular/core';
import { Observable, skipWhile } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IMunicipality } from '../common/models/muni.model';

const API = environment.api;
const APIFORMS = environment.apiForms;

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(private http: HttpClient) {}
  /**
   * get anything
   */
  public get(
    url: string,
    customParams?: { [key: string]: string }
  ): Observable<any> {
    let params = new HttpParams();
    if (customParams) {
      Object.keys(customParams).forEach((key) => {
        if (customParams[key]) {
          params = params.append(key, customParams[key]);
        }
      });
    }
    return this.http.get(API + url, { params });
  }

  /**
   * post anything
   */
  public post(
    url: string,
    body?: any,
    customParams?: { [key: string]: string }
  ): Observable<any> {
    let params = new HttpParams();
    if (customParams) {
      Object.keys(customParams).forEach((key) => {
        if (customParams[key]) {
          params = params.append(key, customParams[key]);
        }
      });
    }
    return this.http.post(API + url, body, { params, observe: 'response' });
  }

  public getF(
    url: string,
    customParams?: { [key: string]: string }
  ): Observable<any> {
    let params = new HttpParams();
    if (customParams) {
      Object.keys(customParams).forEach((key) => {
        if (customParams[key]) {
          params = params.append(key, customParams[key]);
        }
      });
    }
    return this.http.get(APIFORMS + url, { params });
  }

  /**
   * post anything
   */
  public postF(
    url: string,
    body?: any,
    customParams?: { [key: string]: string }
  ): Observable<any> {
    let params = new HttpParams();
    if (customParams) {
      Object.keys(customParams).forEach((key) => {
        if (customParams[key]) {
          params = params.append(key, customParams[key]);
        }
      });
    }
    return this.http.post(APIFORMS + url, body, {
      params,
      observe: 'response',
    });
  }
  /**
   * delete anything
   */
  public deleteF(url: string, body?: any): Observable<any> {
    return this.http.delete(APIFORMS + url, {body: body});
  }
}
