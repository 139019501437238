import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PayVoucherComponent } from './forms/allForms/pay-voucher/pay-voucher.component';
import { AuthGuard } from './gurad/auth.guard';
import { SucssesPaymentComponent } from './forms/elementForm/payment-page/sucsses-payment/sucsses-payment.component';
import { FaildPaymentComponent } from './forms/elementForm/payment-page/faild-payment/faild-payment.component';
import { ChangePaymentsComponent } from './forms/allForms/change-payments/change-payments.component';
import { NumberPersonsComponent } from './forms/allForms/number-persons/number-persons.component';
import { OpenBugComponent } from './forms/allForms/open-bug/open-bug.component';
import { DeductibleComponent } from './forms/allForms/deductible/deductible.component';
import { ConfidentialityWaiverComponent } from './forms/allForms/confidentiality-waiver/confidentiality-waiver.component';
import { ResidentConfirmSimpleComponent } from './forms/allForms/resident-confirm-simple/resident-confirm-simple.component';
import { DebtBalanceComponent } from './debt-balance/debt-balance.component';
import { PaymentPageComponent } from './forms/elementForm/payment-page/payment-page.component';
import { RandomPaymentsComponent } from './random-payments/random-payments.component';
import { ResidentMasComponent } from './forms/allForms/resident-mas/resident-mas.component';
import { SettingComponent } from './setting/setting.component';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { MyAssetComponent } from './my-asset/my-asset.component';
import { MyPaymentsComponent } from './my-payments/my-payments.component';
import { DiscountWelcomeComponent } from './dicsountProject/discount-welcome/discount-welcome.component';
import { DiscountNewReqComponent } from './dicsountProject/discount-new-req/discount-new-req.component';
import { AfterBitPaymentComponent } from './forms/elementForm/payment-page/after-bit-payment/after-bit-payment.component';
import { CheckAccountStatusComponent } from './forms/allForms/check-account-status/check-account-status.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: false }],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: false }],
  },
  {
    path: 'payVoucher',
    component: PayVoucherComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: false }],
  },
  {
    path: 'successLandingUrl',
    component: SucssesPaymentComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: false }],
  },
  {
    path: 'failureLandingUrl',
    component: FaildPaymentComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: false }],
  },
  {
    path: 'changePayments',
    component: ChangePaymentsComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: false }],
  },
  {
    path: 'numberPersons',
    component: NumberPersonsComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: false }],
  },
  {
    path: 'fileOpening',
    component: OpenBugComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: false }],
  },
  {
    path: 'deductible',
    component: DeductibleComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: false }],
  },
  {
    path: 'ConfidentialityWaiver',
    component: ConfidentialityWaiverComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: false }],
  },
  {
    path: 'residentConfirmSimple',
    component: ResidentConfirmSimpleComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: false }],
  },
  {
    path: 'debtBalance',
    component: DebtBalanceComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: true }],
  },
  {
    path: 'payment',
    component: PaymentPageComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: false }],
  },
  {
    path: 'randomPayments',
    component: RandomPaymentsComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: false }],
  },
  {
    path: 'randomPaymetns',
    component: RandomPaymentsComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: false }],
  },
  {
    path: 'residentMas',
    component: ResidentMasComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: false }],
  },
  {
    path: 'setting',
    component: SettingComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: true }],
  },
  {
    path: 'personal',
    component: PersonalDetailsComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: true }],
  },
  {
    path: 'my-asset',
    component: MyAssetComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: true }],
  },
  {
    path: 'my-payments',
    component: MyPaymentsComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: true }],
  },
  {
    path: 'discount-welcome',
    component: DiscountWelcomeComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: false }],
  },
  {
    path: 'newDiscount',
    component: DiscountNewReqComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: false }],
  },
  {
    path: 'BackFromBit',
    component: AfterBitPaymentComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: false }],
  },
  {
    path: 'checkAccountStatus',
    component: CheckAccountStatusComponent,
    canActivate: [AuthGuard],
    data: [{ needLogin: false }],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
