import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EMPTY, Subject, of, skipWhile, take, takeUntil } from 'rxjs';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { MessageDialogComponent } from 'src/app/forms/popups/message-dialog/message-dialog.component';
import { AccountService } from 'src/app/services/account.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  text = {
    sisma: 'שכחת סיסמא?',
    place1: 'נייד / אימייל',
    place2: 'אימות סיסמא',
    pass: 'תעודת זהות',
    password2: 'אימות חדשה',
    clalim1: 'נשלח לך קוד זמני חדש, ותוכל לבחור סיסמא חדשה',
    after: 'לאחר האישור תועברו לאתר בית לביצוע כניסה מחדש למערכת',
    btn: 'שלח קוד',
    btn2: 'בחר סיסמא חדשה',
    save: 'שמור סיסמא חדשה',
    inputcode: 'הזן את קוד האימות שקיבלת לכתובת המייל או הטלפון',
  };
  hide: boolean = true;
  hideNew: boolean = true;
  hideConfirm: boolean = true;
  form: FormGroup;
  errorPass = '';
  errprNew = '';
  errorSecond = '';
  note = '';
  isCorrectCurrent = false;
  isCorrectNew = false;
  isCorrectSecond = false;
  loginID = 0;
  codeInput = false;
  ChooseAnotherPassword = false;

  constructor(
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private dialog: MatDialog,
    private router: Router,
    private loading: LoadingService,
    private authService: AuthService
  ) {}
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  ngOnInit(): void {
    this.form = this.fb.group({
      id: ['', Validators.required],
      device: ['', Validators.required],
      password: ['', Validators.required],
      newPassword: ['', Validators.required],
    });
  }
  checkCurrentPassword(Password: string) {
    if (Password != '') {
      this.accountService
        .checkCurrentPassword(Password)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            if (res.body.rc != 100) {
              this.errorPass = 'סיסמא נוכחית לא תקינה';
              this.isCorrectCurrent = false;
            } else {
              this.errorPass = 'סיסמא תקינה :)';
              this.isCorrectCurrent = true;
            }
          },
          (error) => {
            this.errorPass = 'שגאה בבדיקת הסיסמא';
            this.isCorrectCurrent = false;
          }
        );
    }
    return false;
  }

  disabled(): boolean {
    if (this.form.controls['id'].value && this.form.controls['device'].value)
      return false;
    else return true;
  }
  approval() {
    if (this.form.valid) {
      const newPass = this.form.get('newPass').value;
      const currentPassword = this.form.get('pass').value;
      this.accountService
        .newPassword(currentPassword, newPass)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.body.rc == 0) {
            // !
            // מעכשיו תוכל להתחבר לאתר עם הסיסמא שבחרת
            let ref = this.dialog.open(MessageDialogComponent, {
              data: {
                title: 'הסיסמא שונתה בהצלחה!',
                body: ['מעכשיו תוכל להתחבר לאתר עם הסיסמא שבחרת'],
                btn: 'תודה!',
              },
            });
            ref
              .afterClosed()
              .pipe(takeUntil(this.destroy$))
              .subscribe((res) => {
                this.router.navigate(['']);
              });
          } else {
            //באג
            this.note = 'אירעה שגיאה. נסה שוב';
          }
        });
    } else {
      this.note = 'יש למלא ערכים בכל השדות';
    }
  }
  sendCode() {
    let id = this.form.controls['id'].value;
    let device = this.form.controls['device'].value;
    if (id != '' && device != '') {
      this.loading.show('');
      this.authService
        .forgetPassword({id: id, phone : device})
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            this.loading.hide();
            if (res?.body && res.body.loginID  > 0) {
              this.codeInput = true;
              this.loginID = res.body.loginID;
            } else if (res && res.body.rc == -1) {
              this.codeInput = false;
              this.note = 'ת.ז. לא תואמת למספר הטלפון / אימייל';
            } else if (res && res.body.rc == 1) {
              this.codeInput = false;
              this.note = 'זוהתה שגיאה. יש לנסות לבצע רישום לאתר';
            } else  {
              this.codeInput = false;
              this.note = 'לא ניתן לבצע שינוי סיסמא עם פרטים אלו';
            }
          },
          (error) => {
            this.loading.hide();
          }
        );
    }
  }
  onCodeCompleted(event: any) {
    this.loading.show('כמה רגעים ואתם בתוך הבית הדיגיטלי שלכם');
    this.authService
      .CheckQuickLoginPass(this.loginID, event)
      .pipe(
        takeUntil(this.destroy$),
        skipWhile((x) => !x),
        take(1)
      )
      .subscribe(
        (user) => {
          if (user.body.token) {
            this.authService.setSession(user.body);
            this.note = '';
            this.loading.hide();
            this.ChooseAnotherPassword = true;
            this.codeInput = false;
            return of(user.body);
          } else {
            this.note = 'סיסמה לא נכונה';
            return EMPTY;
          }
        },
        (e) => {
          this.loading.hide();
          if (e.status !== 401) this.note = 'קרתה שגיאה, נא נסו שנית';
          else this.note = 'קוד לא תקין. נסו שנית';
        }
      );

    this.loading.hide();
  }

}
