import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNo'
})
export class YesNoPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value == false || value =='false') return 'לא';
    else if (value == true || value =='true') return 'כן';
    else return 'לא'
  }

}
