import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { stringDate } from '../../../functions.model';
import { fullControl } from 'src/app/forms/elementForm/control.model';
import {
  addEmergencyControl,
  firstControlsList,
  secondControlsList,
} from './editPerson.model';
import { MyDateService } from 'src/app/forms/elementForm/my-date/my-date.service';
import { FormGroup } from '@angular/forms';
import {
  InputConfig,
  addControlToForm,
  generateForm,
} from 'src/app/forms/elementForm/form-input/form-input.model';
import { FileOpeningService } from '../../file-opening.service';
import { Subject, takeUntil } from 'rxjs';
import { FormsDataService } from 'src/app/forms/forms-data.service';
import { deleteMemberBody, familyMember } from '../../openBug.model';
import * as moment from 'moment';
import { MuniService } from 'src/app/services/muni.service';
import { FormsFunctionsService } from '../../../forms-functions.service';

@Component({
  selector: 'app-edit-person',
  templateUrl: './edit-person.component.html',
  styleUrls: ['./edit-person.component.scss'],
})
export class EditPersonComponent implements OnInit {
  @Input() person: familyMember;
  @Output() saveChanges = new EventEmitter<any>();
  destroy$ = new Subject();
  text = {
    title: 'נצטרך כמה שיותר פרטים כדי להכיר אתכם יותר טוב...',
  };
  tabs = [
    {
      label: 'פרטים אישיים',
    },
    {
      label: 'פרטים נוספים',
    },
  ];
  firstControls: fullControl[] = firstControlsList;
  secondControls: fullControl[] = secondControlsList;
  addEmergency: fullControl = addEmergencyControl;
  form: FormGroup;
  form2: FormGroup;
  CountryCombo = [];
  constructor(
    private loading: LoadingService,
    private dateService: MyDateService,
    private fileOpeningService: FileOpeningService,
    private formsData: FormsDataService,
    private muni: MuniService,
    private formsFunctions: FormsFunctionsService
  ) {}
  ngOnInit(): void {
    this.formsData.currentFormType = 40;
    this.generateFormFull();
    this.getControlsList();
    if (this.person) {
      this.setPersonData();
    }
  }
  getControlsList() {
    this.fileOpeningService
      .GetRelationshipCombo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        let i = this.firstControls.findIndex(
          (c) => c.inputControl.config.formControlName == 'relationship'
        );
        if (i != -1) {
          this.firstControls[i].inputControl.selectconfig.list =
            this.fileOpeningService.convertResListTiItem(res);
          this.firstControls[i].inputControl.selectconfig.copyList =
            this.fileOpeningService.convertResListTiItem(res);
        }
      });
    this.fileOpeningService
      .GetPopulationGroupCombo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        let i = this.secondControls.findIndex(
          (c) => c.inputControl.config.formControlName == 'populationGroup'
        );
        if (i != -1) {
          this.secondControls[i].inputControl.selectconfig.list =
            this.fileOpeningService.convertResListTiItem(res);
          this.secondControls[i].inputControl.selectconfig.copyList =
            this.fileOpeningService.convertResListTiItem(res);
        }
      });
    this.fileOpeningService
      .GetSocialSecurityCombo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        let i = this.secondControls.findIndex(
          (c) => c.inputControl.config.formControlName == 'socialSecurity'
        );
        if (i != -1) {
          this.secondControls[i].inputControl.selectconfig.list =
            this.fileOpeningService.convertResListTiItem(res);
          this.secondControls[i].inputControl.selectconfig.copyList =
            this.fileOpeningService.convertResListTiItem(res);
        }
      });
    this.fileOpeningService
      .GetEducationCombo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        let i = this.secondControls.findIndex(
          (c) => c.inputControl.config.formControlName == 'education'
        );
        if (i != -1) {
          this.secondControls[i].inputControl.selectconfig.list =
            this.fileOpeningService.convertResListTiItem(res);
          this.secondControls[i].inputControl.selectconfig.copyList =
            this.fileOpeningService.convertResListTiItem(res);
        }
      });
    this.fileOpeningService
      .GetLanguageCombo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        let i = this.secondControls.findIndex(
          (c) => c.inputControl.config.formControlName == 'language'
        );
        if (i != -1) {
          this.secondControls[i].inputControl.selectconfig.list =
            this.fileOpeningService.convertResListTiItem(res);
          this.secondControls[i].inputControl.selectconfig.copyList =
            this.fileOpeningService.convertResListTiItem(res);
        }
        let j = this.secondControls.findIndex(
          (c) => c.inputControl.config.formControlName == 'anotherLanguage'
        );
        if (j != -1) {
          this.secondControls[j].inputControl.selectconfig.list =
            this.fileOpeningService.convertResListTiItem(res);
          this.secondControls[j].inputControl.selectconfig.copyList =
            this.fileOpeningService.convertResListTiItem(res);
        }
      });
    this.fileOpeningService
      .GetCountryCombo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        let i = this.firstControls.findIndex(
          (c) => c.inputControl.config.formControlName == 'birthLand'
        );
        if (i != -1) {
          this.firstControls[i].inputControl.selectconfig.list =
            this.fileOpeningService.convertResListTiItem(res);
          this.firstControls[i].inputControl.selectconfig.copyList =
            this.fileOpeningService.convertResListTiItem(res);
        }
        this.CountryCombo = this.fileOpeningService.convertResListTiItem(res);
      });
  }
  setSecondData() {
    if (this.person?.populationGroup)
      this.secondControls[0].inputControl.selectconfig.selected =
        this.person?.populationGroup;
    if (this.person?.workStatus)
      this.secondControls[1].inputControl.selectconfig.selected =
        this.person?.workStatus;
    if (this.person?.existAllowance)
      this.secondControls[2].inputControl.selectconfig.selected =
        this.person?.existAllowance;
    if (this.person?.colloquialLanguage)
      this.secondControls[3].inputControl.selectconfig.selected =
        this.person?.colloquialLanguage;
    if (this.person?.additionalLanguage)
      this.person[4].inputControl.selectconfig.selected =
        this.person?.additionalLanguage;
    if (this.person?.speakAbility)
      this.secondControls[5].inputControl.selectconfig.selected =
        this.person?.speakAbility;
    if (this.person?.readAbility)
      this.secondControls[6].inputControl.selectconfig.selected =
        this.person?.readAbility;
    if (this.person.isEmergency) this.addEmergency.buttonControl.value = 'true';
  }
  setPersonData() {
    this.firstControls[0].inputControl.selectconfig.selected =
      this.person.relationship;
    this.firstControls[1].inputControl.config.value = this.person.pasport;
    this.firstControls[2].inputControl.config.value = this.person.lastName;
    this.firstControls[3].inputControl.config.value = this.person.firstName;
    if (this.person.dateOfBirth) {
      let birth = this.setDateData(this.person.dateOfBirth);
      this.firstControls[4].inputControl.config.day = birth.day;
      this.firstControls[4].inputControl.config.month = birth.month;
      this.firstControls[4].inputControl.config.year = birth.year;
    }
    this.firstControls[5].inputControl.selectconfig.selected =
      this.person.birthCountry;
    this.firstControls[6].inputControl.selectconfig.selected =
      this.person.gender;
    this.firstControls[7].inputControl.selectconfig.selected =
      this.person.familyStatus;
    this.firstControls[8].inputControl.config.value = this.person.fatherName;
    this.firstControls[9].inputControl.config.value = this.person.phone;
    this.firstControls[10].inputControl.config.value = this.person.email;
    if (this.person.aliaCountry)
      this.firstControls[12].inputControl.selectconfig.selected =
        this.person.aliaCountry;
    if (this.person?.aliaDate) {
      let comeDate = this.setDateData(this.person.aliaDate);
      this.firstControls[13].inputControl.config.day = comeDate.day;
      this.firstControls[13].inputControl.config.month = comeDate.month;
      this.firstControls[13].inputControl.config.year = comeDate.year;
    }
    this.setSecondData();
  }
  disabledfunc(): boolean {
    let count = 0;
    if (this.person)
      Object.keys(this.person).forEach((key) => {
        if (
          ((key != '' && this.person[key] == null) || this.person[key] == '') &&
          this.person[key] !== 0
        ) {
          let ctrl = this.firstControls.find(
            (f) => f.inputControl.config.formControlName == key
          );
          if (ctrl && ctrl.inputControl.config.required) count++;
          let ctrls = this.secondControls.find(
            (f) => f.inputControl.config.formControlName == key
          );
          if (ctrls && ctrls.inputControl.config.required) count++;
        }
      });
    else {
      return true;
    }
    if (count == 0) return false;
    else return true;
  }
  getDataInput(event) {
    if (event.name == 'birthLand') {
      if (event.value != 900) {
        if (
          this.firstControls.findIndex(
            (c) => c.inputControl.config.formControlName == 'comeDate'
          ) == -1
        ) {
          this.firstControls.push({
            controlType: 'input',
            inputControl: {
              config: {
                formControlName: 'comeLand',
                inputType: 'select',
                label: 'ארץ עליה',
                name: 'comeLand',
                required: true,
                type: 'select',
              },
              selectconfig: {
                bigSelect: false,
                copyList: this.CountryCombo,
                list: this.CountryCombo,
                lable: 'ארץ עליה',
              },
            },
          });
          this.firstControls.push({
            controlType: 'input',
            inputControl: {
              config: {
                formControlName: 'comeDate',
                inputType: 'date',
                label: 'תאריך עליה',
                name: 'comeDate',
                required: true,
                type: 'date',
              },
            },
          });
          addControlToForm(this.form, {
            formControlName: 'comeLand',
            inputType: 'select',
            label: 'ארץ עליה',
            name: 'comeLand',
            required: true,
            type: 'select',
          });
          addControlToForm(this.form,  {
            formControlName: 'comeDate',
            inputType: 'date',
            label: 'תאריך עליה',
            name: 'comeDate',
            required: true,
            type: 'date',
          });
        }
      } else if (event.value == 900) {
        if (
          this.firstControls.findIndex(
            (c) => c.inputControl.config.formControlName == 'comeDate'
          ) != -1
        ) {
          this.firstControls.pop();
          this.firstControls.pop();
        }
      }
    } else if (event.name == 'relationship') {
      let i = this.firstControls.findIndex(
        (c) => c.inputControl.config.formControlName == 'fatherName'
      );
      if (i != -1) {
        if (event.value == 1) {
          this.firstControls[i].inputControl.config.required = true;
        } else {
          this.firstControls[i].inputControl.config.required = false;
        }
      }
    }
  }
  save(event, i) {
    if (i == 0) {
      if (event.value.birthDate) {
        this.person.dateOfBirth = moment
          .utc(
            this.dateService.getDefultStrinsOfDate(event.value.birthDate),
            'MM/DD/YYYY'
          )
          .toDate();
      }
      this.person.birthCountry =
        event.value.birthLand !== null && event.value.birthLand != ''
          ? event.value.birthLand
          : null;
      this.person.email = event.value.email != '' ? event.value.email : null;
      this.person.fatherName =
        event.value.fatherName != '' ? event.value.fatherName : null;
      this.person.firstName =
        event.value.firstName != '' ? event.value.firstName : null;
      this.person.gender = event.value.gender != '' ? event.value.gender : null;
      this.person.pasport = event.value.id != '' ? event.value.id : null;
      this.person.lastName =
        event.value.lastName != '' ? event.value.lastName : null;
      this.person.familyStatus =
        event.value.maritalStatus !== null && event.value.maritalStatus != ''
          ? event.value.maritalStatus
          : null;
      this.person.phone = event.value.phone != '' ? event.value.phone : null;
      this.person.relationship =
        event.value.relationship !== null && event.value.relationship !== ''
          ? event.value.relationship
          : null;
    } else if (i == 1) {
      this.person.additionalLanguage =
        event.value.anotherLanguage !== null &&
        event.value.anotherLanguage != ''
          ? event.value.anotherLanguage
          : null;
      this.person.education =
        event.value.education !== null && event.value.education != ''
          ? event.value.education
          : null;
      this.person.colloquialLanguage =
        event.value.language !== null && event.value.language != ''
          ? event.value.language
          : null;
      this.person.populationGroup =
        event.value.populationGroup !== null &&
        event.value.populationGroup != ''
          ? event.value.populationGroup
          : null;
      this.person.readAbility =
        event.value.readAbility !== null && event.value.readAbility != ''
          ? event.value.readAbility
          : null;
      this.person.existAllowance =
        event.value.socialSecurity !== null && event.value.socialSecurity != ''
          ? event.value.socialSecurity
          : null;
      this.person.speakAbility =
        event.value.speechAbility !== null && event.value.speechAbility != ''
          ? event.value.speechAbility
          : null;
      this.person.workStatus =
        event.value.work !== null && event.value.work != ''
          ? event.value.work
          : null;
    }
  }
  deletePerson() {
    this.loading.show();
    if (this.person?.pasport && this.person.pasport != '') {
      let body: deleteMemberBody = {
        familyMemberID: this.person.pasport,
        mainRequestId:
          this.formsFunctions.existRequestData$.value.requestData.lineID,
        muniCode: this.muni.getMuniCode,
      };
      this.fileOpeningService
        .deleteFamilyMembers(body)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            this.loading.hide();
            this.person = {
              additionalLanguage: null,
              address: null,
              aliaCountry: null,
              aliaDate: null,
              birthCountry: null,
              colloquialLanguage: null,
              dateOfBirth: null,
              education: null,
              email: null,
              existAllowance: null,
              familyStatus: null,
              fatherName: null,
              firstName: null,
              formType: null,
              gender: null,
              isEmergency: null,
              landlinePhone: null,
              lastName: null,
              muniCode: null,
              pasport: null,
              pasportFileID: null,
              phone: null,
              populationGroup: null,
              readAbility: null,
              relationship: null,
              speakAbility: null,
              workStatus: null,
              panelOpen: true,
            }
            this.saveChanges.emit({familyMember: this.person, isDelete: true});
          },
          (error) => {
            this.loading.hide();
          }
        );
    }
  }
  saveData() {
    this.saveChanges.emit({familyMember: this.person});
  }
  buttonSelected(event) {
    this.addEmergency.buttonControl.value = event.value;
    this.person.isEmergency = event.value == 'true' ? true : false;
  }
  private generateFormFull() {
    let arr: InputConfig[] = [];
    this.firstControls.map((c) => {
      arr.push(c.inputControl.config);
    });
    this.form = generateForm(arr);
    let arr2: InputConfig[] = [];
    this.secondControls.map((d) => {
      arr2.push(d.inputControl.config);
    });
    this.form2 = generateForm(arr2);
  }
  private setDateData(date: Date): stringDate {
    let stringDate: stringDate = this.dateService.getStrinsOfDate(
      date.toString()
    );
    return stringDate;
  }
}
