<div class="filter">
  <mat-checkbox #field id="field" (change)="checkAll($event)"></mat-checkbox>
  <img
    class="filterImg"
    (click)="filterOpen()"
    src="../../../assets/svg/filter.svg"
  />
</div>
<div class="bottom">
  <mat-expansion-panel
    #panel
    hideToggle
    class="margin-b"
    disabled
    [expanded]="line.opened"
    *ngFor="let line of objects; let i = index"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="titleDiv">
          <div class="flexColumn">
            <div class="checkboxdiv">
              <mat-checkbox
                #field
                [checked]="line.isToPay"
                id="field"
                (change)="checkAsset($event, i)"
              ></mat-checkbox>
              <div class="flexColumn">
                <span class="title"
                  >{{ text.asset }} {{ line.assetNumber }}</span
                >
                <span class="mini"> {{ line.assetAddress }}</span>
              </div>
            </div>
          </div>
          <span class="title bold ltr" *ngIf="!line.opened"
            >₪{{ line.sum | number : "1.2-2" }}</span
          >
        </div>

        <img (click)="close(i)" src="../../../assets/svg/randomArrow.svg" />
      </mat-panel-title>
    </mat-expansion-panel-header>
    <!-- <span class="date">{{ text.date }} {{ line.period }}</span> -->
    <div
      class="content"
      *ngFor="let mini of line.charge; let lineIndex = index"
    >
      <div class="flex">
        <div class="checkboxdiv">
          <mat-checkbox
            #field
            [checked]="line.isToPay"
            id="field"
            (change)="check($event, i, lineIndex)"
          ></mat-checkbox>
          <span class="bold">{{ mini.chargeType }}</span>
        </div>
        <span class="bold ltr"> ₪ {{ mini.sum | number : "1.2-2" }} </span>
      </div>
      <!-- <span class="mini">{{ mini.note }}</span> -->
      <div class="row">
        <span class="mini paddingMini"
          >{{ text.ribit }}{{ mini.interest | yesNo }}</span
        >
        <span class="mini paddingMini">{{ text.year }}{{ mini.year }}</span>
      </div>
    </div>
    <div class="footer">
      <span>{{ text.topay }}</span>
      <span>₪{{ line.sum | number : "1.2-2" }}</span>
    </div>
  </mat-expansion-panel>
  <div class="nextDiv">
    <button class="btn" mat-button (click)="next()">
      לתשלום {{ sum | number : "1.2-2" }} ₪
    </button>
  </div>
</div>
<div id="filterPopup" class="filterPopup">
  <span class="title">{{ text.titleFilter }}</span>
  <div class="filters">
    <my-select
      [config]="assetSelectConfig"
      [selected]="assetSelected"
      (selectionChange)="select($event, 'asset')"
    ></my-select>
    <my-select
      [config]="typeSelectConfig"
      [selected]="typeSelected"
      (selectionChange)="select($event, 'type')"
    ></my-select>
    <my-select
      [config]="yearSelectConfig"
      [selected]="yearSelected"
      (selectionChange)="select($event, 'year')"
    ></my-select>
  </div>
  <button class="btn" (click)="filterEmit()">{{ text.update }}</button>
</div>
