<div class="wrapper">
  <div class="item">
    <span class="title">{{ text.title }}</span>
    <div class="list" *ngFor="let line of listAssetAddress">
      <span class="label">{{ line.label }}</span>
      <span>{{ line.value | noneValue}}</span>
    </div>
  </div>
  <div class="item">
    <span class="title">{{ text.title2 }}</span>
    <div class="list" *ngFor="let line of listSendAddress">
      <span class="label">{{ line.label }}</span>
      <span>{{ line.value | noneValue}}</span>
    </div>
  </div>
  <div class="item">
    <span class="title">{{ text.title3 }}</span>
    <div class="list" *ngFor="let line of listPayment">
      <span class="label">{{ line.label }}</span>
      <span>{{ line.value | noneValue    }}</span>
    </div>
  </div>
</div>
