import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { inputConfig } from '../common/elements/my-big-input/my-big-input.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { DetailsRegisterComponent } from './details-register/details-register.component';
import { generateForm } from '../common/elements/my-input/my-input.functions';
import { AuthService } from '../services/auth.service';
import { CheckVoucher, IRegisterDetails } from '../common/models/auth.model';
import { MuniService } from '../services/muni.service';
import { VoucherService } from '../services/voucher.service';
import { Subject, skipWhile, takeUntil } from 'rxjs';
import { voucherResponse } from '../common/models/voucher.model';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
  @Output() login = new EventEmitter<any>();
  destroy$ = new Subject();
  text = {
    title1: '?עם מי יש לנו הכבוד',
    title2: 'כדי שנוכל לזהות אתכם ',
    title3: ' יש למלא את הפרטים הבאים',
    btn: 'המשך',
    registerQ: 'כבר רשומים?',
    registerLink: 'הכנסו עכשיו',
  };
  paymentNumberConfig: inputConfig = {
    icon: 'man.svg',
    lable: 'מספר משלם',
    type: 'input',
    required: true,
    formControlName: 'paymentNumber',
  };
  voucherConfig: inputConfig = {
    icon: 'voucher.svg',
    lable: 'מספר שובר',
    type: 'input',
    required: true,
    formControlName: 'voucher',
  };
  voucherForm = generateForm([this.paymentNumberConfig, this.voucherConfig]);
  error = '';

  constructor(
    private dialogRef: MatDialogRef<LoginComponent>,
    private dialog: MatDialog,
    private authService: AuthService,
    private voucherService: VoucherService
  ) {}
  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  goLogin() {
    this.login.emit();
  }
  async checkIfUserExist(Maintz: string, VoucherGouestReq: any) {
    await this.authService.checkIfUserExist(Maintz).then((res) => {
      if (res.body.rc == 100) {
        this.voucherService
          .getOneVoucher(VoucherGouestReq, +Maintz)
          .pipe(
            skipWhile((x) => !x),
            takeUntil(this.destroy$)
          )
          .subscribe(
            (res) => {
              if (!res || res.body.rc == 99) {
                // Bug
                this.error = 'אירעה שגיאה. נסה שנית';
              } else if (res.body.rc == 2) {
                this.dialog;
                this.error = 'לא ניתן ליצור משתמש עם פרטים אלו';
              } else if (res.body.rc == 1) {
                this.error = 'לא ניתן ליצור משתמש עם פרטים אלו';
              } else {
                this.dialogRef.close();
                this.dialog.open(DetailsRegisterComponent, {
                  data: { mainTz: Maintz },
                  width: '730px',
                  height: '550px',
                });
              }
            },
            (error) => {
              //  bug
              this.error = 'אירעה שגיאה. נסה שנית';
            }
          );
        return false;
      } else {
        this.error = 'קיים כבר משתמש למשלם זה';
        return true;
      }
    });
    return false;
  }

  async continueRegister() {
    if (this.voucherForm.valid) {
      await this.checkIfUserExist(
        this.voucherForm.controls.paymentNumber.value,
        this.voucherForm.controls.voucher.value
      );
    } else {
      if (!this.voucherForm.controls.paymentNumber.valid) {
        this.error = 'יש למלא מספר משלם';
      } else if (!this.voucherForm.controls.voucher.value) {
        this.error = 'יש למלא מספר שובר';
      }
    }
  }
  fillForm(event: { name: string; value: any }) {
    this.voucherForm.controls[event.name]?.setValue(event.value);
  }
}
