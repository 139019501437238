import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { inputConfig } from 'src/app/common/elements/my-big-input/my-big-input.model';
import { card, cardsActions, cardsForms } from '../cards-data/cardsData.model';
import { MuniServiceCard, MuniTitle } from 'src/app/common/models/muni.model';
import { MuniService } from 'src/app/services/muni.service';
import { BehaviorSubject, Subject, combineLatest, takeUntil } from 'rxjs';
import { IListItem } from 'src/app/forms/elementForm/form-input/form-input.model';
import { Router } from '@angular/router';
@Component({
  selector: 'app-how-can-help',
  templateUrl: './how-can-help.component.html',
  styleUrls: ['./how-can-help.component.scss'],
})
export class HowCanHelpComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  muniServices$ = new BehaviorSubject<MuniServiceCard[]>([]);
  titles$ = new BehaviorSubject<MuniTitle[]>([]);

  services = this.muniServices$.value;
  choose = 1;
  searchMenuList = [];
  searchConfig: inputConfig = {
    lable: 'תשלום יתרה , הפקת אישור תושב...',
    type: 'input',
    icon: 'search.png',
    required: false,
    formControlName: 'search',
  };
  text = {
    title: 'איך נוכל לעזור?',
    forms: 'טפסים מקוונים',
    actions: 'פעולות דיגיטליות',
  };
  constructor(private muniService: MuniService, private router: Router) {}
  ngOnInit(): void {
    combineLatest([
      this.muniService.muniServices$,
      this.muniService.muniTitles$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([muniServices, titles]: [MuniServiceCard[], MuniTitle[]]) => {
        if (muniServices?.length > 0 && titles?.length > 0) {
          this.titles$.next(titles);
          this.muniServices$.next(muniServices);
          if (this.muniService.changeToggleFromOut$.value < 0)
            this.toggleChange(1);
        }
      });

    this.muniService.changeToggleFromOut$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res > 0) {
          this.toggleChange(res);
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  toggleChange(value: number) {
    this.choose = value;
    this.services = this.muniServices$.value;
    if (this.muniServices$.value && this.choose == 1) {
      let el = document.getElementById('right');
      if (el) {
        el.style.transition = 'all 0.5s ease';
        el.style.transform = 'translate(0px, 0)';
      }
      // this.services = this.services.filter((f) => f.serviceType == 2);
      this.services = this.convertTitleToService(this.titles$.value, 2);
    } else if (this.muniServices$.value && this.choose == 2) {
      let el = document.getElementById('right');
      if (el) {
        el.style.transition = 'all 0.5s ease';
        el.style.transform = 'translate(-170px, 0)';
      }
      this.services = this.convertTitleToService(this.titles$.value, 1);
    }
  }
  convertTitleToService(titles: MuniTitle[], type: 1 | 2): MuniServiceCard[] {
    let list: MuniServiceCard[] = [];
    let services = this.services.filter((f) => f.serviceType == type);
    if (services.length > 0) {
      services.map((ser) => {
        if (!ser.titleID) {
          list.push(ser);
        } else {
          let find = titles.find((t) => t.titleID == ser.titleID);
          if (find) {
            if (!list.find((l) => l.titleID == find.titleID)) {
              list.push({
                icon: '',
                link: '',
                serviceID: 0,
                titleID: find.titleID,
                serviceName: find.title,
                serviceType: type == 1 ? 2 : 1,
                visibility: 0,
                subType: ['בסיסי', 'כללי'],
              });
            }
          }
        }
      });
    }
    return list;
  }
  search(text: any) {
    if (text.value == '') {
      this.searchMenuList = [];
    } else {
      this.muniService
        .GetWords(text.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            this.searchMenuList = [];
            res.map((r) => {
              let find = this.muniServices$.value.find(
                (s) => s.serviceID == r.serviceID
              );
              if (find) {
                this.searchMenuList.push({
                  label: r.serviceName,
                  value: r.serviceID,
                });
              } 
            });
            if(this.searchMenuList.length == 0){
              this.searchMenuList.push({
                label: 'לא נמצאו תוצאות',
                value: -1,
              });
            }
          },
          (error) => {}
        );
    }
  }
  chooseSearchOne(value: IListItem) {
    let find = this.muniServices$.value.find((s) => s.serviceID == value.value);
    if (find) {
      this.router.navigate([find.link]);
    }
  }
}
