import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { MuniService } from 'src/app/services/muni.service';
import { FormsFunctionsService } from '../forms-functions.service';
import {
  Person,
  fileOpeningSaveDetails,
  myFile,
} from '../functions.model';
import { IListItem } from '../../elementForm/form-input/form-input.model';
import { RequestCauseDetails, SaveFilesDetailsBody, deleteMemberBody, familyMember, saveFamilyDetailsBody } from './openBug.model';
import { FileConfig } from '../../elementForm/my-file/file.model';

@Injectable({
  providedIn: 'root',
})
export class FileOpeningService {
  public fileOpeningFiles$ = new BehaviorSubject<myFile[]>([]);
  public fileOpeningFamilyMembers$ = new BehaviorSubject<{
    persons: familyMember[];
    needSet: boolean;
  }>({
    persons: [{
      additionalLanguage: null,
      address: null,
      aliaCountry: null,
      aliaDate: null,
      birthCountry: null,
      colloquialLanguage: null,
      dateOfBirth: null,
      education: null,
      email: null,
      existAllowance: null,
      familyStatus: null,
      fatherName: null,
      firstName: null,
      formType: null,
      gender: null,
      isEmergency: null,
      landlinePhone: null,
      lastName: null,
      muniCode: null,
      pasport: null,
      pasportFileID: null,
      phone: null,
      populationGroup: null,
      readAbility: null,
      relationship: null,
      speakAbility: null,
      workStatus: null,
      panelOpen: true,
    }
    ],
    needSet: true,
  });
  constructor(
    private app: AppService,
    private muni: MuniService,
    private formsService: FormsFunctionsService
  ) {}
  public fileOpeningGetExist(): Observable<any> {
    let body = {
      muniCode: this.muni.getMuniCode,
      mainRequestID:
        this.formsService.existRequestData$.value.requestData.lineID,
    };
    return this.app.postF('Forms/FileOpening/GetExistRequest', body);
  }
  public fileOpeningSaveDetails(body: fileOpeningSaveDetails): Observable<any> {
    return this.app.postF('Forms/FileOpening/SaveDetails', body);
  }
  public SaveRequestCauseDetails(body: RequestCauseDetails): Observable<any> {
    return this.app.postF('Forms/FileOpening/SaveRequestCauseDetails', body);
  }
  public deleteFamilyMembers(body: deleteMemberBody): Observable<any> {
    return this.app.deleteF('Forms/FileOpening/DeleteFamilyMembers',body );
  }
  // {
  //   muniCode: body.muniCode,
  //   mainRequestId: body.mainRequestId,
  //   familyMemberID: body.familyMemberID
  // }
  public fileOpeningSaveFamily(body: saveFamilyDetailsBody): Observable<any> {
    return this.app.postF('Forms/FileOpening/SaveFamilyDetails', body);
  }
  public SaveFilesDetails(body: SaveFilesDetailsBody): Observable<any> {
    return this.app.postF('Forms/FileOpening/SaveFilesDetails', body);
  }
  public GetFileOpeningCauseList(): Observable<any> {
    return this.app.getF('Forms/FileOpening/GetFileOpeningCauseList', {
      municode: this.muni.getMuniCode.toString(),
    });
  }
  public GetRelationshipCombo(): Observable<any> {
    return this.app.getF('Forms/FileOpening/GetRelationshipCombo', {
      municode: this.muni.getMuniCode.toString(),
    });
  }
  public GetPopulationGroupCombo(): Observable<any> {
    return this.app.getF('Forms/FileOpening/GetPopulationGroupCombo', {
      municode: this.muni.getMuniCode.toString(),
    });
  }
  public GetSocialSecurityCombo(): Observable<any> {
    return this.app.getF('Forms/FileOpening/GetSocialSecurityCombo', {
      municode: this.muni.getMuniCode.toString(),
    });
  }
  public GetEducationCombo(): Observable<any> {
    return this.app.getF('Forms/FileOpening/GetEducationCombo', {
      municode: this.muni.getMuniCode.toString(),
    });
  }
  public GetLanguageCombo(): Observable<any> {
    return this.app.getF('Forms/FileOpening/GetLanguageCombo', {
      municode: this.muni.getMuniCode.toString(),
    });
  }
  public GetCountryCombo(): Observable<any> {
    return this.app.getF('Forms/FileOpening/GetCountryCombo', {
      municode: this.muni.getMuniCode.toString(),
    });
  }
  public convertResListTiItem(res): IListItem[]{
    let newlist: IListItem[] = [];
    res.map(r=>{
      newlist.push({
        label : r.desc,
        value: r.lineID
      })
    })
    return newlist;
  }
}
