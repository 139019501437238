import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { checkSimpleResident, getSimple, getSimpleMail } from './resident.model';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResisentConfirmService {
  public TempTableLineID = 0;
  public fileFromRes: any;
  public residentHaveEntitlement$ = new BehaviorSubject<boolean>(false);
  constructor(private app: AppService, private http: HttpClient) {}
  public SimpleResidentConfirmCheck(body: checkSimpleResident) {
    const API = environment.api;
    return this.http.post(API + 'reports/SimpleResidentConfirmCheck', body);
  }
  public  GetSimpleResidentConfirm(body: getSimple){
    const API = environment.api;
    return this.http.post(API + 'reports/GetSimpleResidentConfirm', body);
  }
  public SendPdfInMail(body: getSimpleMail){
    const API = environment.api;
    return this.http.post(API + 'reports/SendPdfInMail', body);
  }
}
