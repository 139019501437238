import { Injectable, OnDestroy } from '@angular/core';
import { AppService } from '../services/app.service';
import { MuniService } from '../services/muni.service';
import { AuthService } from '../services/auth.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { FormPersonReq, Person, city } from './allForms/functions.model';
import { IListItem } from './elementForm/form-input/form-input.model';
import { FormGroup } from '@angular/forms';
import { fullControl } from './elementForm/control.model';
import { FormType } from './allForms/functionEnum.model';
import { signText } from './models/form.model';

@Injectable({
  providedIn: 'root',
})
export class FormsDataService implements OnDestroy {
  destroy$ = new Subject();
  public needSubmitInput$ = new BehaviorSubject<{
    need: boolean;
    name: string;
  }>({ name: '', need: false });
  public haveChildControls$ = new BehaviorSubject<{ need: boolean }>({
    need: false,
  });


  public citiesListSelect$ = new BehaviorSubject<IListItem[]>([]);
  public streetsListSelect$ = new BehaviorSubject<IListItem[]>([]);
  public signText$ = new BehaviorSubject<signText[]>([]);
  public currentFormType: FormType;
  constructor(
    private app: AppService,
    private muni: MuniService,
    private authService: AuthService
  ) {}
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  public getCities() {
    this.app
      .get('App/getCities')
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.citiesListSelect$.next(
            this.getListSelectFromCityList(res, true)
          );
        }
      });
  }
  public getStreets(cityID: number) {
    let body = {
      cityID: cityID,
    };
    this.app
      .post('App/getStreets', body)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && res.body) {
          this.streetsListSelect$.next(
            this.getListSelectFromCityList(res.body, false)
          );
        }
      });
  }
  public getDeclerationText() {
    this.app
      .postF('Forms/MainForms/GetDeclerationText', {
        muniCode: this.muni.getMuniCode,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res && res.body) {
            this.signText$.next(res.body);
          }
        },
        (error) => {}
      );
  }
  private getListSelectFromCityList(list: any[], isCity: boolean): IListItem[] {
    let newList: IListItem[] = [];
    if (isCity) {
      list.map((l) => {
        newList.push({ label: l.cityName, value: l.cityID });
      });
    } else {
      list.map((l) => {
        newList.push({ label: l.streetName, value: l.streetID });
      });
    }
    return newList;
  }
}
