import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { LoginComponent } from 'src/app/login/login.component';
import { AuthService } from 'src/app/services/auth.service';
import { MuniService } from 'src/app/services/muni.service';
import { UserMenuComponent } from './user-menu/user-menu.component';
import {
  menuConfirms,
  menuForms,
  menuPayments,
  menuPersonal,
} from './digital-menu/menu.model';
import { PopupGetMuniComponent } from 'src/app/common/popup-get-muni/popup-get-muni.component';
import { LayoutService } from 'src/app/layout/layout.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isMenuOpen = false;
    }
  }
  destroy$ = new Subject();
  logo = '';
  name = '';
  text = {
    digitalActions: 'פעולות דיגיטליות',
    aboutUs: 'קצת עלינו ',
    enter: 'כניסה / הרשמה',
    title1: 'ארנונה וגביה',
    title2: 'שירותים חברתיים',
    title3: 'תשלומים',
    title5: 'הנפקת אישורים',
    title6: 'טפסים',
    title4: 'האיזור האישי שלי',
  };
  // menuArnona = menuArnona;
  // menuhevra = menuhevra;
  menuPayments = menuPayments;
  menuPersonal = menuPersonal;
  menuConfirms = menuConfirms;
  menuForms = menuForms;
  mobile = false;
  isLoggedIn = false;
  constructor(
    public dialog: MatDialog,
    private muniService: MuniService,
    private authService: AuthService,
    private router: Router,
    private muni: MuniService,
    private elementRef: ElementRef,
    private layoutService: LayoutService
  ) {}
  ngOnInit(): void {
    if (window.screen.width <= 550) {
      this.mobile = true;
    }
    this.muniService.muniLogo$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res != '') {
          this.logo = res;
          let el = document.getElementById('logoDiv');
          if (el) {
            el.style.transition = 'all 1s ease';
            el.style.transform = 'translate(0, 110px)';
          }
        }
      });
    this.authService
      .getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && res.maintz && res.maintz != '') {
          this.isLoggedIn = true;
          this.name = 'שלום, ' + res.firstName + ' ' + res.lastName;
        } else {
          this.isLoggedIn = false;
          this.name = 'כניסה/הרשמה';
        }
      });
  }
  nav(url) {
    this.toggleMenu();
    if (this.muni.getMuniCode == 0) {
      this.dialog.open(PopupGetMuniComponent);
    } else {
      if (url.includes('http')) window.open(url);
      else this.router.navigate([url]);
    }
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  openDialogLogin(
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ): void {
    if (!this.authService.isLoggedIn())
      this.dialog.open(LoginComponent, {
        width: '490px',
        height: '580px',
        enterAnimationDuration,
        exitAnimationDuration,
      });
    else if (this.mobile)
      this.dialog.open(UserMenuComponent, {
        position: {
          top: '90px', // Adjust the desired top position
          left: '15%', // Adjust the desired left position
        },
        autoFocus: false,
      });
    else
      this.dialog.open(UserMenuComponent, {
        position: {
          top: '90px', // Adjust the desired top position
          left: '250px', // Adjust the desired left position
        },
        autoFocus: false,
      });
  }
  scroll() {
    let e = document.getElementById('aboutUsWrapper');
    if (e)
      e.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
  }
  main() {
    this.router.navigate(['dashboard']);
  }
  isMenuOpen = false;

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
  openMobileSidebar(){
    this.layoutService.openMobileSideBar$.next(true);
  }
 
}
