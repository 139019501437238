import { selectConfig } from "src/app/common/elements/my-select/my-select.model";
import { fullControl } from "../control.model";
import { IListItem, InputConfig } from "../form-input/form-input.model";
import { IResponse } from "src/app/common/models/auth.model";

export const payList : IListItem[] = [
  {
    label: 'רגיל',
    value: 1,
  }
];
export const typeConfig: InputConfig = {
  formControlName: 'typePay',
  icon: null,
  label: 'יש לבחור צורת תשלום',
  required: false,
  type: 'select',
  list: payList,
  inputType: 'select',
  name: 'typePay',
};
export const typePayControl: fullControl = {
  controlType: 'input',
  inputControl: {
    config: typeConfig,
  },
};
export const emailConfig: InputConfig = {
  formControlName: 'email',
  icon: null,
  label: 'כתובת מייל לקבלת אישור',
  required: false,
  type: 'input',
  inputType: 'text',
  name: 'email',
};
export const emailPayControl: fullControl = {
  controlType: 'input',
  inputControl: {
    config: emailConfig,
  },
};
export const typeSelectConfig: selectConfig = {
  bigSelect: false,
  copyList:payList,
  lable: 'יש לבחור צורת תשלום',
  list:payList
}
export interface SavedCreditCard {
  cardToken?: string;
  cardBrand?: string;
  cardImage?: string;
  cardID?: number;
  cardExpiration?: string;
}
export interface PaymentsLogRequest {
  PaymentLog?: paymentsLog[];
  guest?: boolean;
  ID?: number;
  clientDomain?: string;
  muniCode?: number;
  paymentProcessCategory?: number;
  creditType?: number;
  numberOfPayments?: number;
  actType?: number;
  cardID?: number;
  cardToken?: string;
  personID?: string;
  mspkod?: number;
  phone?: string;
  email?: string;
  fullname?: string;
  generalNote?: string;
}
export interface paymentsLog {
  admkod?: number;
  assetId?: string;
  yearOfProduct?: number;
  voucherSerialNumber?: string;
  casualPaymentID?: number;
  quantity?: number;
  usury?: number;
  paymentAmount?: number;
  note?: string;
  answers?: casualPaymentAnswer[];
}
export interface casualPaymentAnswer {
  questionID: number;
  answerValue: string;
}
export interface PaymentRes extends IResponse {
  generalUrl?: string;
  androidUrl?: string;
  iosUrl?: string;
  status?: number;
  paymentIdentifiers?: paymentIdentifiers;
  err?: any;
}
export interface paymentIdentifiers {
  actType: number;
  uniqueID: string;
  paymentInitiationId?: string;
  transactionID?: string;
  muniCode: number;
  email?: string;
}
export interface basket {
  //שם התשלום - בשוברים זה "שובר"
  //ביתרת חוב זה השם של החוב ששולם - לדוג "אגרת רישום כיתה א
  //בתשלום מזדמן זה השם של התשלום - לדוג "אגרת ביוב
  title?: string;
  //מספר זיהוי
  //בשובר זה מספר השובר
  //ביתרת חוב זה מספר הנכס
  //בתשלום מזדמן זה הקוד שלו
  id: string;
  assetID?: string;
  //כמות פריטים בתשלום מזדמן רק
  NumOfOrderedItems?: number;
  // אם זה ריבית או לא
  isInterest?: number;
  //השנה של היתרת חוב
  yearOfProduct?: string;
  //מחיר ליחידה בתשלום מזדמן
  //בשובר זה הסכום שלו
  //ביתרת חוב זה הסכום שלו
  price?: number;
  DynamicField?: casualPaymentAnswer[];
  //כמה תשלומים אפשר לשלם - בתשלום מזדמן ספציפי
  numOfPayments?: number;
}
export interface muniParams {
  paramID: number;
  paramContent: string;
  active: boolean;
}
export interface dataForm {
  haveBit: boolean;
  havePayments: boolean;
  numOfPayments: number;
  haveCredit: boolean;
  numCredit: number;
}