<div class="bottom" #voucher>
  <mat-expansion-panel
    #panel
    hideToggle
    class="row margin-b"
    disabled
    [expanded]="line.opened"
    *ngFor="let line of objects; let i = index"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="titleDiv">
          <div class="flexColumn">
            <span class="title">{{ text.asset }} {{ line.assetNumber }}</span>
            <span class="mini">{{line.assetAddress}}</span>
          </div>
          <span class="title bold ltr" *ngIf="!line.opened"
            >₪{{ line.sum | number : "1.2-2" }}</span
          >
        </div>

        <img (click)="close(i)" src="../../../assets/svg/randomArrow.svg" />
      </mat-panel-title>
    </mat-expansion-panel-header>
    <span class="date">{{ text.date }} {{ line.period }}</span>
    <div class="content" *ngFor="let mini of line.charge">
      <div class="flex">
        <span class="bold">{{ mini.chargeType }}</span>
        <span class="bold ltr"> ₪ {{ mini.sum | number : "1.2-2" }} </span>
      </div>
      <span class="mini">{{ mini.note }}</span>
      <span class="mini"> {{ text.ribit }}{{ mini.interest | yesNo }} </span>
    </div>
    <div class="footer">
      <span>{{ text.topay }}</span>
      <span>₪{{ line.sum | number : "1.2-2" }}</span>
    </div>
  </mat-expansion-panel>
</div>
