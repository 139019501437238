import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  addressAssetConfig,
  assetNumberConfig,
  assetNumberInputConfig,
  emailconfig,
  fileSefachconfig,
  firstNameconfig,
  formDeme,
  idconfig,
  lastNameconfig,
  level,
  numLiversLetterSelectConfig,
  numLiversNowSelectConfig,
  paymentNumberconfig,
  phoneconfig,
  signBody,
} from '../../elementForm/controlsDeme';
import { saveDataLevel } from '../../elementForm/control.model';
import { FormsFunctionsService } from '../forms-functions.service';
import { MuniService } from 'src/app/services/muni.service';
import {
  CollectionAsset,
  FormPersonReq,
  FormPropertyReq,
  FormsExistData,
  GetAssetsByFamilyMainIDReq,
  existReqPersonNum,
  myFile,
  updateNumPersons,
} from '../functions.model';
import { MatStepper } from '@angular/material/stepper';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { Subject, takeUntil } from 'rxjs';
import { FormsDataService } from '../../forms-data.service';

@Component({
  selector: 'app-number-persons',
  templateUrl: './number-persons.component.html',
  styleUrls: ['./number-persons.component.scss'],
})
export class NumberPersonsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  signBody: signBody = {
    buttons: [
      {
        btnName: 'check1',
        config: {
          name: 'check1',
          text: 'בשליחת טופס זה הריני מצהיר/ה בזה כי כל הפרטים הרשומים בבקשתי זו הם נכונים, מדויקים ומלאים .וכי לא העלמתי פרט כלשהו מהפרטים שנתבקשתי למלא וכי אני מסכים לתנאי השימוש באתר והצהרת הפרטיות כפי שמופעים באתר .משלוח טופס זה מהווה הסכמה לכך שהרשות המקומית תיצור איתי קשר באמצעות הטלפון בשיחה ו/או מסרון ובאמצעות הדוא"ל לצורך טיפול בפנייה ובנושאים אחרים בכל עת .ידוע לי כי דיווח חלקי ו/או כוזב בבקשתי זו הינו עבירה על החוק, וכי לרשות המקומית יש זכות לבדוק את נכונות הפרטים שמסרתי בבקשתי זו בכל האמצעים העומדים לרשותה בחוק.זהו שמי וזו תעודת הזהות שלי, ותוכן הצהרה זו - אמת',
          type: 'checkbox',
        },
      },
      {
        btnName: 'check2',
        config: {
          name: 'check2',
          text: 'אני מסכים לתנאי השימוש ולמדיניות הפרטיות של האתר',
          type: 'checkbox',
        },
      },
    ],
    sign: [
      {
        name: 'חתימת הפונה',
      },
    ],
    needEnd: true,
  };
  levels: level[] = [
    {
      id: 0,
      lable: '',
      name: 'הסבר',
      type: 'popup',
      descPopupBody: {
        headText: 'לפניך טופס עבור עידכון מספר נפשות',
        innerText:
          'סכום החיוב החודשי נקבע בהתאם לצריכת המים בפועל, לתעריפי רשות המים ולכמות הנפשות המתגוררות בנכס. בכדי שהחיוב יהיה נכון חשוב שנדע כמה נפשות מתגוררות בנכס',
        docsList: [
          {
            label: 'צילום ספח תעודת זהות של הרשום בנכס',
            value: 0,
          },
          {
            label: 'צילומי ספח תעודת זהות של מתגוררים מעל גיל 18',
            value: 1,
          },
        ],
      },
    },
    {
      id: 1,
      lable: '',
      name: 'פרטי מגיש הבקשה',
      type: 'form',
      controls: [
        idconfig,

        firstNameconfig,
        lastNameconfig,
        emailconfig,
        phoneconfig,
        fileSefachconfig,
      ],
    },
    {
      id: 2,
      lable: '',
      name: 'פרטי הבקשה',
      type: 'form',
      controls: [
        paymentNumberconfig,
        // assetNumberConfig,
        assetNumberInputConfig,
        // cityConfig,
        // streetConfig,
        // houseNumberApartmentConfig,
        // floorHouseEnterConfig,
        // addressAssetConfig,
        // assetTypeTaboConfig,
        {
          controlType: 'input',
          inputControl: {
            config: {
              formControlName: '',
              type: 'anyThing',
              inputType: '',
              label: '',
              name: '',
              required: false,
            },
          },
        },
        numLiversNowSelectConfig,
        numLiversLetterSelectConfig,
        {
          controlType: 'input',
          inputControl: {
            config: {
              formControlName: '',
              type: 'anyThing',
              inputType: '',
              label: '',
              name: '',
              required: false,
            },
          },
        },
        {
          controlType: 'button',
          buttonControl: {
            btnName: 'addPersons',
            config: {
              name: 'addPersons',
              text: 'במידה וקיימים מתגוררים נוספים מעל גיל 18 בנכס יש לצרף צילום תעודת זהות + ספח עבור כל אחד',
              type: 'addPersons',
            },
            childControls: [],
          },
        },
      ],
    },
    {
      id: 3,
      lable: '',
      name: 'אישור הבקשה וסיום ',
      type: 'sign',
      signBody: this.signBody,
    },
    // {
    //   id: 4,
    //   lable: '',
    //   name: 'סיום',
    //   type: 'sendSucsses',
    // },
  ];
  numberPersonsForm: formDeme = {
    id: 20,
    levels: this.levels,
    name: 'טופס הצהרת מספר נפשות',
  };
  haveAssetData = false;
  constructor(
    private formsFunctions: FormsFunctionsService,
    private muni: MuniService,
    private authService: AuthService,
    private loading: LoadingService,
    private formsData: FormsDataService
  ) {}
  ngOnInit(): void {
    this.formsFunctions.existRequestData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: FormsExistData) => {
        if (res && res?.requestData?.id != '') {
          this.setLevel1Data(res);
        }
      });
    this.formsFunctions.collectionAsset$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: CollectionAsset[]) => {
        if (res && res.length > 0) {
          this.haveAssetData = true;
          this.setLevel2Data(res);
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  setLevel2Data(res: CollectionAsset[]) {
    this.levels[2].controls[0].inputControl.config.value =
      this.formsFunctions.existRequestData$.value.requestData.familyMainID;
    this.levels[2].controls[1].inputControl.config.value = res[0].assetNum;
    // this.levels[2].controls[1].inputControl.selectconfig.list =
    // this.formsFunctions.assetNumberList$.value;
    // this.levels[2].controls[1].inputControl.selectconfig.copyList =
    // this.formsFunctions.assetNumberList$.value;
    // this.levels[2].controls[1].inputControl.selectconfig.selected =
    // this.formsFunctions.assetNumberList$.value[0].value;
    // this.levels[2].controls[2].inputControl.config.value = res[0].address;
    // this.levels[2].controls[3].inputControl.config.value = res[0].assetType;
  }
  setLevel2DataSecond(res: existReqPersonNum) {
    if (res.tenantsNumber > 0)
      this.levels[2].controls[3].inputControl.config.value = res.tenantsNumber;
    // this.levels[0].controls[5].inputControl.config.file = {
    //   fileID: res.idFiles[0].fileID,
    //   fileName: res.idFiles[0].fileName,
    // };
    if (res.idFiles.length > 1) {
      this.addControlToFileID(res);
    }
  }
  addControlToFileID(res: existReqPersonNum) {
    res.idFiles.forEach((file: myFile, i) => {
      this.levels[2].controls[6].buttonControl.childControls.push({
        controlType: 'input',
        inputControl: {
          config: {
            formControlName: 'fileSefach' + i,
            inputType: 'file',
            label: 'צילום תעודת זהות + ספח',
            name: 'fileSefach' + i,
            required: true,
            type: 'file',
            file: {
              fileID: file.fileID,
              fileName: file.fileName,
            },
          },
        },
      });
    });
    this.formsData.haveChildControls$.next({ need: true });
  }
  setLevel1Data(res: FormsExistData) {
    if (res.requestData && res.requestData.applicantDetails) {
      this.levels[1].controls[1].inputControl.config.value =
        res.requestData.applicantDetails.lastName;
      this.levels[1].controls[2].inputControl.config.value =
        res.requestData.applicantDetails.firstName;
      this.levels[1].controls[3].inputControl.config.value =
        res.requestData.applicantDetails.email;
      this.levels[1].controls[4].inputControl.config.value =
        res.requestData.applicantDetails.phone;
      if (res.requestData.familyMainID) {
        this.levels[2].controls[0].inputControl.config.value =
          res.requestData.familyMainID;
        // this.getAssetByMaintz(res.requestData.familyMainID.toString());
      }
    }
  }
  getAssetByMaintz(maintz: string) {
    let body: GetAssetsByFamilyMainIDReq = {
      id: maintz,
      muniCode: this.muni.getMuniCode,
    };
    this.formsFunctions.GetAssetsByFamilyMainID(body);
  }
  saveDataLevel(event: saveDataLevel) {
    let level = this.numberPersonsForm.levels.find(
      (l) => l.id == event.levelID
    );

    if (level) {
      switch (level.id) {
        case 1:
          const body1: FormPersonReq = {
            email: event.data.email,
            firstName: event.data.firstName,
            lastName: event.data.lastName,
            muniCode: this.muni.getMuniCode,
            pasport: event.data.id,
            phone: event.data.phone,
            formType: this.numberPersonsForm.id,
            requestId: this.formsFunctions.existRequestData$.value.requestData.lineID
          };
          this.formsFunctions
            .SaveApplicantRequestDetails(body1)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res) => {
                if (this.authService.isLoggedIn() && !this.haveAssetData) {
                  this.authService
                    .getUser()
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((res) => {
                      this.getAssetByMaintz(res.maintz.toString());
                    });
                }
                this.formsFunctions
                  .PersonsNumGetExist({
                    muniCode: this.muni.getMuniCode,
                    mainRequestID:
                      this.formsFunctions.existRequestData$.value.requestData
                        .lineID,
                  })
                  .pipe(takeUntil(this.destroy$))
                  .subscribe(
                    (res) => {
                      this.setLevel2DataSecond(res.body);
                    },
                    (error) => {}
                  );
                this.next(event.stepper);
              },
              (error) => {}
            );
          break;
        case 2:
          let add = [];
          Object.keys(event.data).forEach((key) => {
            if (key.includes('fileSefach')) {
              add.push(event.data[key]);
            }
          });
          let deleted = [];
          Object.keys(event.data).forEach((key) => {
            if (key.includes('deleteFile')) {
              deleted.push(event.data[key]);
            }
          });
          const body2: updateNumPersons = {
            assetID: event.data.assetNumber,
            muniCode: this.muni.getMuniCode,
            familyMainID: event.data.paymentNumber,
            newFileIds: add,
            deleteFileIds: deleted,
            requestID:
              this.formsFunctions.existRequestData$.value.requestData.lineID,
            tenantsNumber: +event.data.numLivers,
          };
          this.formsFunctions
            .PersonsNumSaveDetails(body2)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res) => {
                this.next(event.stepper);
              },
              (error) => {}
            );
          break;
        default:
          break;
      }
    }
  }
  next(mystepper: MatStepper) {
    this.loading.hide();
    setTimeout(() => {
      mystepper.selected.completed = true;
      mystepper.next();
    }, 1);
  }
}
