import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { Charge } from 'src/app/common/models/voucher.model';
import { Voucher, voucherResponse } from 'src/app/common/models/voucher.model';
import { VoucherService } from 'src/app/services/voucher.service';

@Component({
  selector: 'app-form-table',
  templateUrl: './form-table.component.html',
  styleUrls: ['./form-table.component.scss'],
})
export class FormTableComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  data: Charge[];
  voucher: Voucher;
  displayedColumns = [];
  error = '';
  width = window.screen.width;
  constructor(
    private voucherService: VoucherService,
    private loadingService: LoadingService
  ) {}
  ngOnInit(): void {
    this.voucherService.columnsVoucher$.pipe(takeUntil(this.destroy$)).subscribe(res=>{
      this.displayedColumns = res;
    })
    this.voucherService.oneVocher$.pipe(takeUntil(this.destroy$)).subscribe((res: Voucher) => {
      if (res) {
        this.loadingService.hide();
        this.data = res.charges;
        this.voucher = res;
      } else {
        this.error = 'לא קיים שובר';
      }
    });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
   }
}
