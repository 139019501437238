import { Component, Input } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { discount } from 'src/app/personal-details/asset/asset.model';
import { AssetService } from 'src/app/services/asset.service';

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss'],
})
export class DiscountComponent {
  destroy$ = new Subject();
  displayedColumns = ['תאור', 'תאריך', 'עד תאריך', 'הערה'];
  propertyTaxDiscounts: discount[] = [];
  constructor(private assetService: AssetService) {}
  ngOnInit(): void {
    this.assetService.PropertyTaxDataObject$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((res) => {
      if (res) {
        this.propertyTaxDiscounts = res.propertyTaxDiscounts;
      }
    });
  }
}
