import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaymentService } from '../payment.service';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sucsses-payment',
  templateUrl: './sucsses-payment.component.html',
  styleUrls: ['./sucsses-payment.component.scss'],
})
export class SucssesPaymentComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  text = {
    title: 'התשלום עבר בהצלחה!',
    title2: 'אישור תשלום נשלח אליך למייל',
    btn: 'חזרה למסך הבית',
    link: 'לאיזור האישי שלך',
  };
  email;
  constructor(private paymentService: PaymentService, private router: Router) {}
  ngOnInit(): void {
    this.paymentService.EmailForSend$.pipe(takeUntil(this.destroy$)).subscribe(
      (res) => {
        this.email = res;
      }
    );
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  navigat(index: number) {
    if (index == 1) {
      this.router.navigate(['']);
    } else if (index == 2) {
      this.router.navigate(['']);
    }
  }
}
