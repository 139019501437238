import {  Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { emitObj } from '../control.model';
import { IButton, IButtonToggleConfig } from './my-button.model';
import { FormsDataService } from '../../forms-data.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'my-button',
  templateUrl: './my-button.component.html',
  styleUrls: ['./my-button.component.scss'],
})
export class MyButtonComponent implements OnInit {
  @Input() config: IButton;
  @Input() toggleConfig?: IButtonToggleConfig[];
  @Input() value: string;
  @Output() buttonSelected = new EventEmitter<emitObj>();
  destroy$ = new Subject();
  color = '#484848';
  constructor(private formsData: FormsDataService) {}

  ngOnInit(): void {
    this.formsData.needSubmitInput$.pipe(takeUntil(this.destroy$)).subscribe(res=>{
      if(res.need==true){
        this.onChange('true', res.name)
      }
    })
  }
  changeStyle($event) {
    this.color =
      $event.type == 'mouseover'
        ? this.config.color.replace('#', 'color')
        : 'btn';
  }
  onChange(value, name) {
    let obj: emitObj = {
      name: name,
      value: value,
      errors: null
    }
    this.buttonSelected.emit(obj);
  }
}
