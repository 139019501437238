<div class="wrapper">
  <div class="route">
    <span (click)="nav(1)">{{ route1 }}</span> >
    <span *ngIf="typeCards == 1" (click)="nav(2)">{{ route2 }}</span> 
    <span *ngIf="typeCards == 2" (click)="nav(2)">{{ route22 }}</span> >
    <span class="choose">{{ route3 }}</span>
  </div>
  <div class="content">
    <div id="title" class="title">
      <div class="muniLineText">
        <span>{{ route3 }}</span>
        <div class="muniLine"></div>
      </div>
    </div>
    <span class="mini">{{ text.title }}</span>
    <div class="filter" *ngIf="card?.subType">
      <span
        class="color{{ i }}"
        *ngFor="let subject of card.subType; let i = index"
        >{{ subject }}</span
      >
      <img src="../../../assets/svg/graySearch.svg" />
    </div>
    <div class="forms" *ngIf="newForms?.length > 0">
      <app-form-type-card
        *ngFor="let form of newForms"
        [form]="form"
      ></app-form-type-card>
    </div>
    <div class="divMore">
      <button [disabled]="newForms?.length == filterd?.length" (click)="add()" class="more">
        {{ text.more }}
        <img src="../../../assets/svg/arrowDown.svg" />
      </button>
    </div>
    <div>
      <span class="sum">נמצאו {{sum}} טפסים בנושא</span>
    </div>
  </div>
</div>
