<div class="wrapper">
  <div class="titleDiv">
    <span class="spantitle title">{{ text.title }}</span>
    <span class="spantitle">{{ text.title2 }}</span>
  </div>
  <div class="muniSelect">
    <div class="select">
      <span class="spantitle mini">{{ text.title3 }}</span>
      <my-input
        [config]="muniConfig"
        [selectconfig]="muniselectConfig"
        [selected]="muniSelected"
        (blur)="fillForm($event)"
      ></my-input>
    </div>
    <span class="spantitle font16"
      >{{ text.conect }}
      <span class="link" (click)="open()">
        {{ text.here }}
      </span>
    </span>
  </div>
</div>
