<div id="bigInput">
  <mat-form-field appearance="outline">
    <mat-label class="inputLable" *ngIf="!field.value && !focusInput">{{
      config.lable
    }}</mat-label>
    <input
      #field
      matInput
      type="{{ config.type }}"
      (focus)="focusInput = true"
      (blur)="focusInput = false"
      (keyup)="search($event.target.value)"
    />
    <img
      *ngIf="config.icon"
      class="icon"
      src="../../../../assets/png/{{ config.icon }}"
    />
  </mat-form-field>
</div>
<div class="menu" *ngIf="menuList.length > 0">
  <span
    class="value"
    [ngClass]="{
      noLink: service.value == -1
    }"
    *ngFor="let service of menuList"
    (click)="choose(service)"
    >{{ service.label }}</span
  >
</div>
