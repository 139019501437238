import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { inputConfig } from '../my-big-input/my-big-input.model';
import { selectConfig } from '../my-select/my-select.model';
import { FormsDataService } from 'src/app/forms/forms-data.service';

@Component({
  selector: 'my-input',
  templateUrl: './my-input.component.html',
  styleUrls: ['./my-input.component.scss'],
})
export class MyInputComponent implements OnInit {
  @Input() config: inputConfig;
  @Input() selectconfig: selectConfig;
  @Input() selected: any;
  @Output() blur = new EventEmitter<{ name: string; value: any }>();
  hide = true;
  timeout: any = null;
  constructor(private formsData: FormsDataService){}
  ngOnInit(): void {}
  onBlur(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        if ($this.config.type == 'select') {
          if ($this.config.formControlName == 'city') {
            $this.formsData.getStreets(event);
          }
          $this.blur.emit({
            value: event,
            name: $this.config.formControlName,
          });
        } else {
          $this.blur.emit({
            value: event.target.value,
            name: $this.config.formControlName,
          });
        }
      }
    }, 250);
  }
  getType(): string {
    return this.hide ? 'password' : 'text';
  }
}
