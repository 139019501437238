import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsDashbordComponent } from './forms-dashbord/forms-dashbord.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormInputComponent } from './elementForm/form-input/form-input.component';
import { BasicFormComponent } from './basic-form/basic-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormHeaderComponent } from './form-header/form-header.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MyDateComponent } from './elementForm/my-date/my-date.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MyFileComponent } from './elementForm/my-file/my-file.component';
import { MyButtonComponent } from './elementForm/my-button/my-button.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { SignComponent } from './elementForm/sign/sign.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { PopupModule } from './popups/popup.module';
import { PayVoucherComponent } from './allForms/pay-voucher/pay-voucher.component';
import { FormTableComponent } from './allForms/pay-voucher/form-table/form-table.component';
import { ElementsModule } from '../common/elements/elements.module';
import { PaymentPageComponent } from './elementForm/payment-page/payment-page.component';
import { PaymentIFrameComponent } from './elementForm/payment-page/payment-iframe/payment-iframe.component';
import { SafePipe } from '../common/pipes/safe.pipe';
import { SucssesPaymentComponent } from './elementForm/payment-page/sucsses-payment/sucsses-payment.component';
import { FaildPaymentComponent } from './elementForm/payment-page/faild-payment/faild-payment.component';
import { ChangePaymentsComponent } from './allForms/change-payments/change-payments.component';
import { SucssesSendComponent } from './elementForm/sucsses-send/sucsses-send.component';
import { NumberPersonsComponent } from './allForms/number-persons/number-persons.component';
import { OpenBugComponent } from './allForms/open-bug/open-bug.component';
import { MatRadioModule } from '@angular/material/radio';
import { TableOpenBugComponent } from './allForms/open-bug/table-open-bug/table-open-bug.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { DeductibleComponent } from './allForms/deductible/deductible.component';
import { ConfidentialityWaiverComponent } from './allForms/confidentiality-waiver/confidentiality-waiver.component';
import { NgxCaptureModule } from 'ngx-capture';
import { ResidentConfirmSimpleComponent } from './allForms/resident-confirm-simple/resident-confirm-simple.component';
import { ResidentPopupComponent } from './allForms/resident-confirm-simple/resident-popup/resident-popup.component';
import { CodeInputModule } from 'angular-code-input';
import { DateTableComponent } from './allForms/open-bug/table-open-bug/date-table/date-table.component';
import { ResidentMasComponent } from './allForms/resident-mas/resident-mas.component';
import { MobileDetailsComponent } from './allForms/pay-voucher/mobile-details/mobile-details.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MobileMembersComponent } from './allForms/open-bug/mobile-members/mobile-members.component';
import { MemeberMobileEditComponent } from './allForms/open-bug/mobile-members/memeber-mobile-edit/memeber-mobile-edit.component';
import { MultyFilesComponent } from './elementForm/multy-files/multy-files.component';
import { AfterBitPaymentComponent } from './elementForm/payment-page/after-bit-payment/after-bit-payment.component';
import { EditPersonComponent } from './allForms/open-bug/table-open-bug/edit-person/edit-person.component';
import { MatTabsModule } from '@angular/material/tabs';
import { TableLivesFileOpenComponent } from './allForms/open-bug/table-open-bug/table-lives-file-open/table-lives-file-open.component';
import { GenderPipe } from '../common/pipes/gender.pipe';
import { FamilyStatusPipe } from '../common/pipes/family-status.pipe';
import { ProximityPipe } from '../common/pipes/proximity.pipe';
import { OpenBugDocumentsComponent } from './allForms/open-bug/open-bug-documents/open-bug-documents.component';
import { DescFormPopupComponent } from './popups/desc-form-popup/desc-form-popup.component';
import { CheckAccountStatusComponent } from './allForms/check-account-status/check-account-status.component';

@NgModule({
  declarations: [
    FormsDashbordComponent,
    FormInputComponent,
    BasicFormComponent,
    FormHeaderComponent,
    MyDateComponent,
    MyFileComponent,
    MyButtonComponent,
    SignComponent,
    PayVoucherComponent,
    FormTableComponent,
    PaymentPageComponent,
    PaymentIFrameComponent,
    SafePipe,
    SucssesPaymentComponent,
    FaildPaymentComponent,
    ChangePaymentsComponent,
    SucssesSendComponent,
    NumberPersonsComponent,
    OpenBugComponent,
    TableOpenBugComponent,
    DeductibleComponent,
    ConfidentialityWaiverComponent,
    ResidentConfirmSimpleComponent,
    ResidentPopupComponent,
    DateTableComponent,
    ResidentMasComponent,
    MobileDetailsComponent,
    MobileMembersComponent,
    MemeberMobileEditComponent,
    MultyFilesComponent,
    AfterBitPaymentComponent,
    EditPersonComponent,
    TableLivesFileOpenComponent,
    GenderPipe,
    FamilyStatusPipe,
    ProximityPipe,
    OpenBugDocumentsComponent,
    DescFormPopupComponent,
    CheckAccountStatusComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatCheckboxModule,
    MatDialogModule,
    PopupModule,
    ElementsModule,
    MatRadioModule,
    MatTooltipModule,
    NgxCaptureModule,
    CodeInputModule,
    MatExpansionModule,
    MatTabsModule,

  ],
  exports: [
    PaymentPageComponent,
    PaymentIFrameComponent,
    BasicFormComponent,
    MyButtonComponent
  ]
})
export class FormsServiceModule {}
