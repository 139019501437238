<div class="wrapper">
  <div class="title">
    <div id="allTitles" class="allTitles">
      <span id="title1">{{ text.allActions1 }}</span>
      <span id="title2">{{ text.allActions2 }}</span>
      <span id="title3">{{ text.allActions3 }}</span>
      <span id="title4">{{ text.allActions4 }}</span>
    </div>
    <div id="muniLineText" class="muniLineText">
      <b>{{ text.mhirut }}</b>
      <div class="muniLine"></div>
    </div>
  </div>
  <div class="welcome">
    <span>{{ text.welcome }}</span>
    <span>{{ text.here }}</span>
  </div>
    <div class="chooseMuni">
    <my-select
      class="selectWidth"
      [config]="muniSelectConfig"
      [selected]="muniSelected"
      (selectionChange)="getMuniData($event)"
    ></my-select>
  </div>
</div>
