<img class="arrow" src="../../../assets/svg/arrowbigHeader.svg" alt="" />
<div class="wrapper">
  <span class="title">
    {{ text.title }}
  </span>
  <span class="title1">
    {{ text1.title1 }}
    <span>
      {{ text2.title2 }}
    </span>
  </span>
  <div class="title3">
    <span >
      {{ text3.title3 }}
    </span>
  </div>
</div>
