import { Component, OnDestroy, OnInit } from '@angular/core';
import { RandomService } from '../random.service';
import { selectedService } from '../random.model';

@Component({
  selector: 'app-random-sum',
  templateUrl: './random-sum.component.html',
  styleUrls: ['./random-sum.component.scss'],
})
export class RandomSumComponent implements OnInit, OnDestroy {
  text = { text: 'סיכום השירותים שנבחרו', sum: 'סה"כ' };
  sum = 0;
  amount = 0;
  services: selectedService[] = [];
  constructor(private randomService: RandomService) {}
  ngOnDestroy(): void {
    this.amount = 0;
    this.services = [];
    this.sum = 0;
  }
  ngOnInit(): void {
    this.randomService.selectedServices$.subscribe((res) => {
      if (res && res.length > 0) {
        this.services = res;
        this.amount = res.length;
        this.sum = 0;
        res.map((r) => {
          this.sum += r.service.price * r.amount;
        });
      }
    });
  }
}
