<div *ngIf="!isRegister" class="wrapper">
  <div class="titleDiv">
    <span class="spantitle">{{ text.title }}</span>
    <span class="spantitle title">{{ text.title2 }}</span>
  </div>
  <div class="typeLogin" *ngIf="!isForgetPassword">
    <div class="muniLineText">
      <span
        class="text"
        [ngClass]="{
          bold: quickLogin
        }"
        (click)="havePassword()"
        >{{ text.password }}</span
      >
      <div *ngIf="quickLogin" class="muniLine"></div>
      <div *ngIf="!quickLogin" class="muniLineGray"></div>
    </div>

    <div class="muniLineText">
      <span
        class="text"
        [ngClass]="{
          bold: !quickLogin
        }"
        (click)="quickLogin = !quickLogin"
        >{{ text.code }}</span
      >
      <div *ngIf="!quickLogin" class="muniLine"></div>
      <div *ngIf="quickLogin" class="muniLineGray"></div>
    </div>
  </div>
  <div class="typeLogin" *ngIf="isForgetPassword">
    <div class="muniLineText">
      <span class="text">{{ text.forgetTitle }}</span>
      <div *ngIf="isForgetPassword" class="muniLine"></div>
    </div>
  </div>
  <div *ngIf="!codeInput" class="typeEnterContent">
    <form [formGroup]="loginForm" *ngIf="!isForgetPassword">
      <div class="inputs">
        <my-input
          [config]="muniConfig"
          [selectconfig]="muniselectConfig"
          [selected]="muniSelected"
          (blur)="fillForm($event)"
        ></my-input>
        <my-input
          *ngIf="quickLogin"
          [config]="paymentNumberConfig"
          (blur)="fillForm($event)"
        ></my-input>
        <my-input
          *ngIf="!quickLogin"
          [config]="idConfig"
          (blur)="fillForm($event)"
        ></my-input>
        <my-input
          *ngIf="!quickLogin"
          [config]="deviceConfig"
          (blur)="fillForm($event)"
        ></my-input>
        <my-input
          *ngIf="quickLogin"
          [config]="passwordConfig"
          (blur)="fillForm($event)"
        ></my-input>
      </div>
    </form>
    <form [formGroup]="loginForm" *ngIf="isForgetPassword">
      <div class="inputs">
        <my-input
          [config]="muniConfig"
          [selectconfig]="muniselectConfig"
          [selected]="muniSelected"
          (blur)="fillForm($event)"
        ></my-input>
        <my-input [config]="idConfig" (blur)="fillForm($event)"></my-input>
        <my-input [config]="deviceConfig" (blur)="fillForm($event)"></my-input>
      </div>
    </form>
    <div class="password">
      <span *ngIf="quickLogin && !isForgetPassword">
        שכחת סיסמא?
        <span class="link" (click)="forgetPassword()">{{ text.click }}</span>
      </span>
    </div>
    <div class="addnote">
      <span *ngIf="!quickLogin || isForgetPassword  ">
        {{ text.addnote }}
      </span>
    </div>
    <span class="error">{{ error }}</span>
  </div>
  <div *ngIf="!codeInput" class="btnLogin">
    <button [disabled]="disabledLogin()" (click)="sendCodeOrLogin(!quickLogin)">
      {{ !quickLogin || isForgetPassword ? text.btn1 : text.btn2 }}
    </button>
    <span class="graytext" *ngIf="!isForgetPassword"
      >{{ text.registerQ }}
      <span class="link" (click)="register()">{{ text.registerLink }}</span>
    </span>
  </div>
  <div *ngIf="codeInput && !chooseNewPassword" class="codeInput">
    <span class="inputcodetext">{{ text.inputcode }}</span>
    <code-input
      class="codeComp"
      [initialFocusField]="0"
      [codeLength]="6"
      (codeCompleted)="onCodeCompleted($event)"
    >
    </code-input>
  </div>
  <div *ngIf="chooseNewPassword">
    <app-choose-new-password [isLogin]="true"></app-choose-new-password>
  </div>
</div>
<app-register *ngIf="isRegister" (login)="register()"></app-register>
