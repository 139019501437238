<div id="asset">
  <mat-expansion-panel
  *ngIf="!mobile"
    [expanded]="panelOpen"
    #panel
    hideToggle
    class="row margin-b"
    disabled
  >
    <mat-expansion-panel-header>
      <div class="wrapper">
        <div class="muniLineText">
          <span class="title">{{ text.title }}</span>
          <div *ngIf="panelOpen" class="muniLine"></div>
        </div>
        <div class="details">
          <div class="item" *ngFor="let item of details">
            <div class="label">{{ item.label }}:</div>
            <span  class="value"> {{ item.value }} </span>
          </div>
        </div>
        <button class="btn" (click)="nav()">
          {{ text.btn }}
        </button>
      </div>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
  <div *ngIf="mobile" class="list">
    <div class="itemMobile" *ngFor="let item of details">
      <span class="label">{{ item.label }}:</span>
      <span class="value"> {{ item.value }} </span>
    </div>
    <button class="btnborder" (click)="nav()">עוד פרטים על הנכס שלי</button>
  </div>
</div>
