<ng-container [ngSwitch]="config.type">
  <ng-container *ngSwitchCase="'action'">
    <button mat-button class="action" (click)="onChange(null, config.name)">
      {{ config.text }}
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'checkbox'">
    <div class="selectContainer">
      <span
        class="mat-form-field mat-form-field-appearance-outline textLable fix"
        [ngClass]="{ 'mat-focused': config.upLable || selectElement }"
      >
        <div class="checkboxdiv">
          <mat-checkbox
            #field
            id="field"
            [value]="selected"
            class="example-margin"
            (change)="onChange($event.checked, config.name)"
          ></mat-checkbox>
          <div class="textFlex">
            <p
              class="text"
              [ngClass]="{
                bold: config?.textMini?.length > 1
              }"
            >
              {{ config.text }}
            </p>
            <div *ngIf="config?.textMini">
              <p *ngFor="let mini of config.textMini" class="text">
                {{ mini }}
              </p>
            </div>
          </div>
        </div>
      </span>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'option'">
    <button
      mat-button
      class="btn"
      [ngClass]="color"
      (mouseover)="changeStyle($event)"
      (mouseout)="changeStyle($event)"
    >
      {{ config.text }}
      <div
        *ngIf="config.haveCircle"
        class="circle"
        [style.background-color]="config.color"
      >
        <img class="img" src="{{ config.image }}" />
      </div>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'toggle-group'">
    <div
      class="toggleWrapper"
      [ngClass]="{ miniDiv: toggleConfig[0].miniBtn === true }"
    >
      <mat-button-toggle-group
        name="{{ config.toggleGroupName }}"
        [value]="value"
      >
        <div class="lableDiv" *ngIf="config.needTitle">
          <!-- <div class="asterisk box" *ngIf="config.required"><span>*</span></div> -->
          <label class="label" for="config.name" *ngIf="config.text">{{
            config.text
          }}</label>
        </div>
        <div class="toggleDiv">
          <mat-button-toggle
            (click)="onChange(t.value, config.name)"
            class="action align"
            [ngClass]="{ miniBtn: t.miniBtn === true }"
            *ngFor="let t of toggleConfig"
            value="{{ t.value }}"
            >{{ t.name }}</mat-button-toggle
          >
        </div>
      </mat-button-toggle-group>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'addPersons'"> 
    <div class="addPersonsDiv">
      <span>{{config.text}}</span>
      <button class="plus" (click)="onChange(true, config.name)">+</button>
    </div>
  </ng-container>
</ng-container>
