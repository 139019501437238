import { Component, Input } from '@angular/core';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { Charge } from '../debt.model';
import { Router } from '@angular/router';
import { DebtsBalanceService } from '../debts-balance.service';

@Component({
  selector: 'app-debt-table',
  templateUrl: './debt-table.component.html',
  styleUrls: ['./debt-table.component.scss'],
})
export class DebtTableComponent {
  @Input() data: Charge[];
  displayedColumns = [
    'checkAll',
    'מספר נכס',
    'סוג חיוב',
    'שנה',
    'ריבית / הצמדה',
    'יתרת חוב',
    'לתשלום',
  ];
  error = '';
  sum = 0;
  count = 0;
  checkAllVal = false;
  text = {
    sumTitle: 'סך החיובים שנבחרו',
    pay: 'לתשלום',
  };
  constructor(
    private loadingService: LoadingService,
    private router: Router,
    private debtsService: DebtsBalanceService
  ) {}
  ngOnInit(): void {
    if (this.data && this.data.length > 0) {
      this.data.map((d) => {
        if (d.isToPay) {
          this.sum += d.sumToPay;
          this.count++;
        }
      });
    }
  }
  check(value, index: number) {
    if (value.checked) {
      this.data[index].sumToPay = this.data[index].sum;
      this.data[index].isToPay = true;
      this.sum += this.data[index].sum;
      this.count++;
    } else {
      this.data[index].sumToPay = 0;
      this.data[index].isToPay = false;
      this.sum -= this.data[index].sum;
      if (this.count > 0) this.count--;
    }
  }
  checkAll(event) {
    if (event.checked) {
      this.data.map((d, i) => {
        d.sumToPay = d.sum;
        d.isToPay = true;
        this.sum += d.sum;
      });
      this.count = this.data.length;
    } else {
      this.data.map((d) => {
        d.sumToPay = 0;
        d.isToPay = false;
      });
      this.sum = 0;
      this.count = 0;
    }
  }
  pay() {
    if (this.sum > 0) {
      let dataForBasket = [];
      this.data.map((element) => {
        if (element.sumToPay)
          dataForBasket.push({
            chargeType: element.chargeType,
            sum: element.sumToPay ? element.sumToPay : element.sum,
            assetId: element.assetId,
            ChargeID: element.chargeID,
            isInterest: element.interest,
            yearOfProduct: element.year.toString(),
          });
      });
      this.debtsService.setBasketItemsDebts(dataForBasket);
      this.debtsService.level$.next(2);
    }
  }
}
