import { Component, OnDestroy, OnInit } from '@angular/core';
import { IListItem } from 'src/app/forms/elementForm/form-input/form-input.model';
import { selectConfig } from '../elements/my-select/my-select.model';
import { inputConfig } from '../elements/my-big-input/my-big-input.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoginComponent } from 'src/app/login/login.component';
import { MuniService } from 'src/app/services/muni.service';
import { Subject, takeUntil } from 'rxjs';
import { LayoutService } from 'src/app/layout/layout.service';

@Component({
  selector: 'app-popup-get-muni',
  templateUrl: './popup-get-muni.component.html',
  styleUrls: ['./popup-get-muni.component.scss'],
})
export class PopupGetMuniComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  text = {
    title: '!היי',
    title2: 'אנחנו עדיין לא מכירים',
    title3: 'כדאי לבחור רשות מהרשימה',
    conect: 'או להתחבר לאזור האישי שלך',
    here: 'כאן',
  };
  muncipalities: IListItem[] = [];
  muniselectConfig: selectConfig = {
    icon: 'home.svg',
    lable: 'בחר רשות',
    list: this.muncipalities,
    bigSelect: false,
    copyList: this.muncipalities,
  };
  muniSelected: any;

  muniConfig: inputConfig = {
    icon: 'home.svg',
    lable: 'בחר רשות',
    type: 'select',
    list: this.muncipalities,
    required: true,
    formControlName: 'muni',
  };
  mobile = window.screen.width <= 850 ? true : false;

  constructor(
    private muniService: MuniService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<PopupGetMuniComponent>,
    private layoutService: LayoutService
  ) 
  {}
  ngOnInit(): void {
    this.muniService
      .getMunicipalities()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          res.map((muni) => {
            this.muncipalities.push({
              label: muni.muniName,
              value: muni.muniCode,
            });
          });
        },
        (error) => {}
      );
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  fillForm(event: { name: string; value: any }) {
    this.muniSelected = event.value;
    this.muniService.getMuniData(
      this.muniSelected,
      this.muncipalities.find((m) => m.value == event.value).label
    );
    this.dialogRef.close();
  }
  open() {
    this.dialogRef.close();
    if (!this.mobile) this.dialog.open(LoginComponent);
    else this.layoutService.openMobileLogin$.next(true);
  }
}
