<!-- *ngIf="!payIframe" -->
<form  [formGroup]="form" *ngIf="!payIframe">
  <div class="wrapper">
    <span class="title">{{ text.title }}</span>
    <img
      (click)="prevNormal()"
      class="arrowPrevMobile"
      *ngIf="mobile"
      src="../../../../assets/svg/arrowPrevMobile.svg"
    />
    <div class="types">
      <div
        (click)="chooseCard(card, true)"
        class="btn"
        *ngFor="let card of creditCards$ | async"
        [ngClass]="{
          choose: iChooseSaved == card.cardID
        }"
      >
        <img class="iconcard" src="{{ card.cardImage }}" />
        <span *ngIf="!mobile">
          {{
            card.cardToken
              | slice : card.cardToken.length - 4 : card.cardToken.length
          }}**** **** ****
        </span>
        <span *ngIf="mobile">
          {{
            card.cardToken
              | slice : card.cardToken.length - 4 : card.cardToken.length
          }}****
        </span>
      </div>
      <div
        (click)="chooseCard(save, false)"
        class="btn"
        *ngFor="let save of cards"
        [ngClass]="{
          choose: iChooseCards == save.value
        }"
      >
        <img src="../../../../assets/png/{{ save.icon }}" />
        <span>
          {{ save.label }}
        </span>
      </div>
    </div>

    <div class="method" *ngIf="payQuestions && !isBit">
      <form-input
        [fullControl]="typePayControl.inputControl"
        [selectconfig]="typeSelectConfig"
        (submitFunction)="submitInput($event, true)"
      ></form-input>
      <form-input
        *ngIf="needNumOfPay"
        [fullControl]="payNumberControl.inputControl"
        [selectconfig]="payNumberSelectConfig"
        (submitFunction)="submitInput($event, true)"
      ></form-input>
    </div>
    <div class="method" *ngIf="payQuestions || isBit">
      <form-input
        [fullControl]="emailPayControl.inputControl"
        (submitFunction)="submitInput($event, false)"
      ></form-input>
    </div>
    <span class="note">{{ note }}</span>
  </div>
  <div class="footer" *ngIf="!mobile">
    <span
      >{{ text.topay }} <b>{{ sum | currency : "ILS" }} </b>
    </span>
    <button *ngIf="haveStepper" class="prev" mat-button matStepperPrevious>
      הקודם
    </button>
    <button *ngIf="!haveStepper" class="prev" mat-button (click)="prevNormal()">
      הקודם
    </button>
    <button
      [disabled]="disabled()"
      class="next"
      mat-button
      (click)="createPayment()"
    >
      אישור
      <img src="../../../../assets/png/leftPay.png" />
    </button>
  </div>
  <div *ngIf="mobile" class="mobileFooter">
    <button
      *ngIf="haveStepper && !mobile"
      class="prev"
      mat-button
      matStepperPrevious
    >
      הקודם
    </button>
    <button
      [disabled]="disabled()"
      class="next"
      mat-button
      (click)="createPayment()"
    >
      לתשלום {{ sum | currency : "ILS" }}
    </button>
  </div>
</form>
<app-payment-iframe *ngIf="payIframe"></app-payment-iframe>
