import { Component, Input } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { inputConfig } from 'src/app/common/elements/my-big-input/my-big-input.model';
import { MessageDialogComponent } from 'src/app/forms/popups/message-dialog/message-dialog.component';
import { AccountService } from 'src/app/services/account.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoginComponent } from '../login.component';

@Component({
  selector: 'app-choose-new-password',
  templateUrl: './choose-new-password.component.html',
  styleUrls: ['./choose-new-password.component.scss'],
})
export class ChooseNewPasswordComponent {
  @Input() isLogin: boolean;
  destroy$ = new Subject();
  text = {
    save: 'שמור סיסמא חדשה',
    password2: 'אימות סיסמא',
    btn2: 'בחר סיסמא חדשה',
  };
  hide: boolean = true;
  hideNew: boolean = true;
  hideConfirm: boolean = true;
  form: FormGroup;
  errprNew = '';
  errorSecond = '';
  note = '';
  isCorrectCurrent = false;
  isCorrectNew = false;
  isCorrectSecond = false;
  ChooseAnotherPassword = false;
  newPasswordConfig: inputConfig = {
    icon: 'password.svg',
    lable: 'בחר סיסמא חדשה',
    type: 'password',
    required: false,
    formControlName: 'newPassword',
  };
  secondPasswordConfig: inputConfig = {
    icon: 'password.svg',
    lable: 'אימות סיסמא',
    type: 'password',
    required: false,
    formControlName: 'secondPassword',
  };
  constructor(
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private dialog: MatDialog,
    private router: Router,
    private loading: LoadingService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<LoginComponent>

  ) {}
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  ngOnInit(): void {
    this.form = this.fb.group({
      newPassword: ['', Validators.required],
      secondPassword: ['', Validators.required],
    });
  }
  fillForm(event: { name: string; value: any }) {
    this.form.controls[event.name]?.setValue(event.value);
  }
  checkNewPass(newPassword: string): boolean {
    if (newPassword != '') {
      const regex =
        /^(?=[^A-Z\n]*[A-Z])(?=[^a-z\n]*[a-z])(?=[^0-9\n]*[0-9])\S{7,}[A-Za-z0-9-]$/;
      const hebrew = /[\u0590-\u05FF]/;
      if (!regex.test(newPassword) || hebrew.test(newPassword)) {
        this.errprNew = 'סיסמא לא תקינה';
        this.isCorrectNew = false;
        return false;
      } else {
        this.errprNew = 'הסיסמא תקינה';
        this.isCorrectNew = true;
        return true;
      }
    }
    return false;
  }
  checkSecondPass(SecondPass: string, firstPassword: any): boolean {
    if (SecondPass != '' && firstPassword != '') {
      if (SecondPass != firstPassword) {
        this.errorSecond = 'הסיסמאות אינן תואמות';
        this.isCorrectSecond = false;
        return false;
      } else {
        this.errorSecond = '';
        this.isCorrectSecond = true;
        return true;
      }
    }
    return false;
  }
  disabledSave(): boolean {
    if (
      this.checkNewPass(this.form.get('newPassword').value) &&
      this.checkSecondPass(
        this.form.get('newPassword').value,
        this.form.get('secondPassword').value
      )
    )
      return false;
    else return true;
  }
  saveNewPass() {
    const newPass = this.form.get('newPassword').value;
    const secondPassword = this.form.get('secondPassword').value;
    if (newPass == secondPassword) {
      this.loading.show();
      this.authService
        .newPassword('', newPass)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            if (res.body.rc == 0) {
              this.loading.hide();
              //good
              let ref = this.dialog.open(MessageDialogComponent, {
                data: {
                  title: 'הסיסמא שונתה בהצלחה!',
                  body: ['מעכשיו תוכל להתחבר לאתר עם הסיסמא שבחרת'],
                  btn: 'תודה!',
                },
              });
              ref
                .afterClosed()
                .pipe(takeUntil(this.destroy$))
                .subscribe((res) => {
                  this.router.navigate(['']);
                  this.dialogRef.close();
                });
            } else {
              this.loading.hide();
              //bad
              this.note = 'אירעה שגיאה. ניתן לנסות שוב';
            }
          },
          (error) => {
            this.loading.hide();
            //bad
            this.note = 'אירעה שגיאה. ניתן לנסות שוב';
          }
        );
    } else {
      this.loading.hide();
      this.note = 'הסיסמאות לא תואמות';
    }
  }
}
