import { Component, OnInit } from '@angular/core';
import {
  emailconfig,
  firstNameconfig,
  formDeme,
  idconfig,
  lastNameconfig,
  phoneconfig,
} from '../../elementForm/controlsDeme';
import { Subject, takeUntil } from 'rxjs';
import { saveDataLevel } from '../../elementForm/control.model';
import { FormsFunctionsService } from '../forms-functions.service';
import { FormPersonReq, FormsExistData, generalSave } from '../functions.model';
import { MuniService } from 'src/app/services/muni.service';
import { MatStepper } from '@angular/material/stepper';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';

@Component({
  selector: 'app-check-account-status',
  templateUrl: './check-account-status.component.html',
  styleUrls: ['./check-account-status.component.scss'],
})
export class CheckAccountStatusComponent implements OnInit {
  destroy$ = new Subject();
  CheckAccountStatusForm: formDeme = {
    id: 70,
    levels: [
      {
        id: 1,
        // lable: 'כאן ניתן להגיש בקשה למחלקת הגבייה עבור בירור מצב חשבון, ולקבל שיחת חזרה של נציג המחלקה באמצעות טלפון/ מייל למגיש הבקשה',
        lable: '',
        name: 'פרטי מגיש הבקשה',
        type: 'form',
        controls: [
          idconfig,
          firstNameconfig,
          lastNameconfig,
          emailconfig,
          phoneconfig,
        ],
      },
      {
        id: 2,
        lable: '',
        name: 'פרטי הבקשה',
        type: 'form',
        controls: [
          {
            controlType: 'input',
            inputControl: {
              config: {
                formControlName: 'cause',
                inputType: 'text',
                label: 'יש לפרט את סיבת הפניה',
                name: 'cause',
                required: true,
                type: 'textarea',
                titleTextarea: 'פירוט הפניה',
              },
            },
          },
          {
            controlType: 'input',
            inputControl: {
              config: {
                formControlName: '',
                type: 'anyThing',
                inputType: '',
                label: '',
                name: '',
                required: false,
              },
            },
          },
          {
            controlType: 'input',
            inputControl: {
              config: {
                formControlName: '',
                type: 'anyThing',
                inputType: '',
                label: '',
                name: '',
                required: false,
              },
            },
          },
          {
            controlType: 'input',
            inputControl: {
              config: {
                formControlName: 'file',
                inputType: 'file',
                label: 'צירוף קובץ (לא חובה)',
                name: 'file',
                required: false,
                type: 'file',
              },
            },
          },
        ],
      },
      {
        id: 3,
        lable: '',
        name: 'הצהרה',
        type: 'sign',
        signBody: {
          buttons: [
            {
              btnName: 'check1',
              config: {
                name: 'check1',
                text: 'הנני מצהיר כי כל הפרטים דלעיל נכונים',
                type: 'checkbox',
              },
            },
          ],
          sign: [
            {
              name: 'חתימת הפונה',
            },
          ],
          needEnd: true,
        },
      },
    ],
    name: 'טופס בירור מצב חשבון',
  };
  constructor(
    private formsFunctions: FormsFunctionsService,
    private muni: MuniService,
    private loading: LoadingService
  ) {}
  ngOnInit(): void {
    this.formsFunctions.existRequestData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: FormsExistData) => {
        if (res && res?.requestData?.id != '') {
          this.setLevel1Data(res);
        }
      });
  }
  setLevel1Data(res: FormsExistData) {
    if (res.requestData && res.requestData.applicantDetails) {
      this.CheckAccountStatusForm.levels[0].controls[1].inputControl.config.value =
        res.requestData.applicantDetails.firstName;
      this.CheckAccountStatusForm.levels[0].controls[2].inputControl.config.value =
        res.requestData.applicantDetails.lastName;
      this.CheckAccountStatusForm.levels[0].controls[3].inputControl.config.value =
        res.requestData.applicantDetails.email;
      this.CheckAccountStatusForm.levels[0].controls[4].inputControl.config.value =
        res.requestData.applicantDetails.phone;
    }
  }
  saveDataLevel(event: saveDataLevel) {
    let level = this.CheckAccountStatusForm.levels.find(
      (l) => l.id == event.levelID
    );
    if (level) {
      switch (level.id) {
        case 1:
          const body1: FormPersonReq = {
            email: event.data.email,
            firstName: event.data.firstName,
            lastName: event.data.lastName,
            muniCode: this.muni.getMuniCode,
            pasport: event.data.id,
            phone: event.data.phone,
            formType: this.CheckAccountStatusForm.id,
            requestId: this.formsFunctions.existRequestData$.value.requestData.lineID
          };
          this.formsFunctions
            .SaveApplicantRequestDetails(body1)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res) => {
                this.loading.hide();
                this.next(event.stepper);
              },
              (error) => {
                this.loading.hide();
              }
            );
          break;
        case 2:
          let body: generalSave = {
            content: event.data.cause,
            deleteFiles: [],
            muniCode: this.muni.getMuniCode,
            newFiles: event.data.file? [event.data.file]: [],
            requestID:
              this.formsFunctions.existRequestData$.value.requestData.lineID,
          };
          this.formsFunctions
            .generalRequestSaveDetails(body)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res) => {
                this.loading.hide();
                this.next(event.stepper);
              },
              (error) => {
                this.loading.hide();
              }
            );
          break;
      }
    }
  }
  next(mystepper: MatStepper) {
    this.loading.hide();
    setTimeout(() => {
      mystepper.selected.completed = true;
      mystepper.next();
    }, 1);
  }
}
