import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from 'src/app/forms/popups/message-dialog/message-dialog.component';
import { FileOpeningService } from '../../file-opening.service';
import { familyMember, saveFamilyDetailsBody } from '../../openBug.model';
import { MuniService } from 'src/app/services/muni.service';
import { FormsFunctionsService } from '../../../forms-functions.service';

@Component({
  selector: 'app-table-lives-file-open',
  templateUrl: './table-lives-file-open.component.html',
  styleUrls: ['./table-lives-file-open.component.scss'],
})
export class TableLivesFileOpenComponent {
  @Input() formType: number;
  @Output() next = new EventEmitter();
  destroy$ = new Subject();
  destroyThis$ = new Subject();
  displayedColumns = [
    'תעודת זהות',
    'שם משפחה',
    'שם פרטי',
    'תאריך לידה',
    'גיל',
    'מגדר',
    'קרבה',
    'מצב משפחתי',
    '',
  ];
  data: familyMember[] = [];
  elementPerson: familyMember;
  mobile = window.screen.width <= 850 ? true : false;
  constructor(
    private loadingService: LoadingService,
    private dialog: MatDialog,
    private fileOpeningService: FileOpeningService,
    private muni: MuniService,
    private formsFunctions: FormsFunctionsService
  ) {}
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  ngOnInit(): void {
    this.fileOpeningService.fileOpeningFamilyMembers$
      .pipe(takeUntil(this.destroyThis$))
      .subscribe((res) => {
        if (res && res.persons.length > 0) {
          this.data = [];
          res.persons.map((p) => {
            this.data.push(p);
          });
          if (
            this.data.length == 1 &&
            (!this.data[0].pasport || this.data[0].pasport == '')
          )
            this.data[0].panelOpen = true;
        }
      });
  }
  addLine() {
    this.data.push({
      additionalLanguage: null,
      address: null,
      aliaCountry: null,
      aliaDate: null,
      birthCountry: null,
      colloquialLanguage: null,
      dateOfBirth: null,
      education: null,
      email: null,
      existAllowance: null,
      familyStatus: null,
      fatherName: null,
      firstName: null,
      formType: null,
      gender: null,
      isEmergency: null,
      landlinePhone: null,
      lastName: null,
      muniCode: null,
      pasport: null,
      pasportFileID: null,
      phone: null,
      populationGroup: null,
      readAbility: null,
      relationship: null,
      speakAbility: null,
      workStatus: null,
      panelOpen: true,
    });
  }
  saveChanges(
    event: { familyMember: familyMember; isDelete?: boolean },
    i: number
  ) {
    if (event.isDelete) {
      this.data.splice(i, 1);
      if (this.data.length == 0) {
        this.data.push({
          additionalLanguage: null,
          address: null,
          aliaCountry: null,
          aliaDate: null,
          birthCountry: null,
          colloquialLanguage: null,
          dateOfBirth: null,
          education: null,
          email: null,
          existAllowance: null,
          familyStatus: null,
          fatherName: null,
          firstName: null,
          formType: null,
          gender: null,
          isEmergency: null,
          landlinePhone: null,
          lastName: null,
          muniCode: null,
          pasport: null,
          pasportFileID: null,
          phone: null,
          populationGroup: null,
          readAbility: null,
          relationship: null,
          speakAbility: null,
          workStatus: null,
          panelOpen: true,
        });
      }
    } else {
      this.data[i] = event.familyMember;
      this.data[i].panelOpen = false;
    }
  }
  calcAge(date: Date) {
    if (date) {
      let n = new Date(date);
      let timeDiff = Math.abs(Date.now() - n.getTime());
      return Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    }
    return null;
  }
  nextEmit() {
    if (this.data.length > 0) {
      this.loadingService.show();
      this.data.map((d) => {
        d.muniCode = this.muni.getMuniCode;
        d.formType = this.formType;
        d.panelOpen = null;
      });
      let body: saveFamilyDetailsBody = {
        familyMembers: this.data,
        mainRequestId:
          this.formsFunctions.existRequestData$.value.requestData.lineID,
        muniCode: this.muni.getMuniCode,
      };
      this.fileOpeningService
        .fileOpeningSaveFamily(body)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            this.loadingService.hide();
            this.next.emit();
          },
          (error) => {
            this.loadingService.hide();
          }
        );
    } else {
      let ref = this.dialog.open(MessageDialogComponent, {
        data: {
          title: 'יש למלא נתוני בני משפחה',
          body: [],
          btn: 'חזור למילוי',
        },
      });
      ref.afterClosed().subscribe((r) => {});
    }
  }
  openLine(i: number) {
    this.elementPerson = this.data[i];
  }
}
