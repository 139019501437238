import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, skipWhile, takeUntil } from 'rxjs';
import { fullControl } from '../../elementForm/control.model';
import { idconfig } from '../../elementForm/controlsDeme';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { MuniService } from 'src/app/services/muni.service';
import { ResidentPopupComponent } from '../resident-confirm-simple/resident-popup/resident-popup.component';
import {
  IListItem,
  InputConfig,
  generateForm,
} from '../../elementForm/form-input/form-input.model';
import { muniParams } from '../../elementForm/payment-page/paymentPage.model';
import {
  ResidentConfirmLoginUser,
  TableResidentRes,
  residentPdf,
  tableResidentDetailsreq,
} from './mas.model';
import { ResidentMasService } from './resident-mas.service';
import { MessageDialogComponent } from '../../popups/message-dialog/message-dialog.component';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { AccountService } from 'src/app/services/account.service';
import { FormsDataService } from '../../forms-data.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-resident-mas',
  templateUrl: './resident-mas.component.html',
  styleUrls: ['./resident-mas.component.scss'],
})
export class ResidentMasComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  focusControl = new BehaviorSubject<string>('');
  controls: fullControl[] = [
    idconfig,
    {
      controlType: 'input',
      inputControl: {
        config: {
          label: 'כתובת אימייל / נייד לאימות',
          type: 'input',
          required: true,
          formControlName: 'email',
          inputType: 'text',
          name: 'email',
        },
      },
    },
  ];
  public controlsHaveEntitlement: fullControl[] = [
    {
      controlType: 'input',
      inputControl: {
        config: {
          formControlName: 'id',
          inputType: 'select',
          label: 'תעודת זהות',
          name: 'id',
          required: true,
          type: 'select',
        },
        selectconfig: {
          bigSelect: false,
          copyList: [],
          lable: 'תעודת זהות',
          list: [],
        },
      },
    },
    {
      controlType: 'input',
      inputControl: {
        config: {
          formControlName: 'years',
          inputType: 'select',
          label: 'בחר שנה',
          name: 'years',
          required: true,
          type: 'select',
        },
        selectconfig: {
          bigSelect: false,
          copyList: [
            {
              label: new Date().getFullYear().toString(),
              value: new Date().getFullYear().toString(),
            },
            {
              label: (new Date().getFullYear() - 1).toString(),
              value: (new Date().getFullYear() - 1).toString(),
            },
            {
              label: (new Date().getFullYear() - 2).toString(),
              value: (new Date().getFullYear() - 2).toString(),
            },
          ],
          lable: 'בחר שנה',
          list: [
            {
              label: new Date().getFullYear().toString(),
              value: new Date().getFullYear().toString(),
            },
            {
              label: (new Date().getFullYear() - 1).toString(),
              value: (new Date().getFullYear() - 1).toString(),
            },
            {
              label: (new Date().getFullYear() - 2).toString(),
              value: (new Date().getFullYear() - 2).toString(),
            },
          ],
        },
        selected: new Date().getFullYear(),
      },
    },
    {
      controlType: 'input',
      inputControl: {
        config: {
          formControlName: 'lastName',
          inputType: 'text',
          label: 'שם משפחה',
          name: 'lastName',
          required: false,
          type: 'input',
          disabled: true,
        },
      },
    },
    {
      controlType: 'input',
      inputControl: {
        config: {
          formControlName: 'firstName',
          inputType: 'text',
          label: 'שם פרטי',
          name: 'firstName',
          required: false,
          type: 'input',
          disabled: true,
        },
      },
    },
    {
      controlType: 'input',
      inputControl: {
        config: {
          formControlName: 'phone',
          inputType: 'text',
          label: 'נייד',
          name: 'phone',
          required: false,
          type: 'input',
          disabled: true,
        },
      },
    },
    {
      controlType: 'input',
      inputControl: {
        config: {
          formControlName: 'email',
          inputType: 'text',
          label: 'אימייל',
          name: 'email',
          required: false,
          type: 'input',
          disabled: true,
        },
      },
    },
  ];
  text = {
    title: 'אישור תושב לרשות המיסים',
    your: 'האישור שלך מוכן!',
    what: 'מה תבחרו לעשות עכשיו?',
    needDetails: 'בחר פרטים להפקת האישור',
    soon: 'האישור שלך בהפקה...',
    print: 'הדפסת האישור',
    email: 'שליחת האישור למייל',
    send: 'האישור שלך אצלך במייל',
    sendto: 'האישור הופק ונשלח למייל',
    addnote:
      'תהליך ההזדהות נעשה על פי הנתונים המעודכנים במערכת הגביה של הרשות המקומית',
  };
  needDetails = false;
  error = '';
  form: FormGroup;
  formHaveEntitlement: FormGroup;
  haveEntitlement = false;
  disabled = false;
  emailNote = '';
  isSending = false;
  email = '';
  workWithTableParam: muniParams;
  valuesFromLoginData: Array<ResidentConfirmLoginUser> = [];
  data: residentPdf;
  mobile = false;
  constructor(
    private dialog: MatDialog,
    private loading: LoadingService,
    private muni: MuniService,
    private residendMasService: ResidentMasService,
    private acountService: AccountService,
    private router: Router,
    private authService: AuthService,
    private formsDataService: FormsDataService
  ) {}
  ngOnInit(): void {
    if (window.screen.width <= 850) {
      this.mobile = true;
      this.text.email = 'שליחה במייל';
    }
    this.muni.GetMuniParams();
    this.muni.MuniParams$.pipe(takeUntil(this.destroy$)).subscribe(
      (res: Array<muniParams>) => {
        if (res) this.workWithTableParam = res.find((x) => x.paramID == 16);
        this.residendMasService.workWithTableParam$.next(
          this.workWithTableParam
        );
        // this.tableOrRegularPopup(this.workWithTableParam);
      }
    );
    if (this.authService.isLoggedIn()) {
      this.getDataForFormLogin(this.workWithTableParam.active);
    } else {
    }
    this.generateForm();
    this.formsDataService.currentFormType = 70;
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  public getDataInput(event) {
    if (event.type == 'resident') {
      this.setValuesFromLoginData(event.value);
    }
  }

  public saveDataLevel(event: any) {
    this.form = event;
  }
  public saveDataLevelHaveEntitlement(event: any) {
    this.needDetails = false;
    this.formHaveEntitlement = event;
  }
  public disabledfunc(): boolean {
    return false;
  }
  public checkFormValidation() {
    let controls = this.form.controls;
    const validator = [];
    let note = '';
    for (const name in controls) {
      if (controls[name].invalid) {
        validator.push(name);
      }
    }
    if (validator.length > 0) {
      if (validator.length > 1) {
        note = 'יש להזין ערכים תקינים בכל השדות';
      }
      validator.map((vald) => {
        let find = this.controls.findIndex(
          (c) => c.inputControl.config.formControlName == vald
        );
        switch (vald) {
          case 'id':
            this.controls[find].inputControl.config.errors = 'id';
            break;
          case 'firstName':
            this.controls[find].inputControl.config.errors = 'required';
            break;
          case 'lastName':
            this.controls[find].inputControl.config.errors = 'required';
            break;
          case 'device':
            this.controls[find].inputControl.config.errors = 'emailPhone';
            break;
          case 'birthDate':
            this.controls[find].inputControl.config.errors = 'dateAfterToday';
            break;
          default:
            break;
        }
      });
    }
    return note;
  }
  public getCode() {
    if (this.checkFormValidation() == '') {
      this.loading.show();
      let body: tableResidentDetailsreq = {
        id: this.form.value.id,
        email: this.form.value.email.includes('@') ? this.form.value.email : '',
        phone: this.form.value.email.includes('@') ? '' : this.form.value.email,
        muniCode: this.muni.getMuniCode,
      };
      this.residendMasService
        .checkDetailsTableResident(body)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            this.loading.hide();
            if (res && res.body && res.body.tempId > 0) {
              this.residendMasService.idTemp = res.body.tempId;
              this.error = '';
              const dialogRef = this.dialog.open(ResidentPopupComponent, {
                data: {},
                disableClose: true,
              });
              dialogRef
                .afterClosed()
                .pipe(takeUntil(this.destroy$))
                .subscribe((result) => {
                  this.haveEntitlement = true;
                  if (this.mobile) {
                    let doc = document.getElementById('formID');
                    if (doc) {
                      doc.style.paddingBottom = '300px';
                      doc.scrollIntoView({ block: 'end' });
                    }
                  }
                  this.initTableForm();
                  this.dialog.closeAll();
                });
            } else {
              if (res.body.tz > 0) {
                this.dialog.closeAll();
                this.noteligible(res.body);
              } else {
                this.dialog.closeAll();
                this.error = 'ת.ז. לא תואמת למספר הטלפון / אימייל';
              }
            }
          },
          (error) => {
            this.loading.hide();
            this.error = ' אירעה שגיאה. נסה שנית';
          }
        );
    }
  }
  public print() {
    this.loading.show();
    const data = {
      id: this.formHaveEntitlement
        ? this.formHaveEntitlement.value.id.toString()
        : this.residendMasService.residentDetails.id.toString(),
      phone: this.data.phone ? this.data.phone?.toString() : '',
      name: this.data.name,
      email: this.data.email,
      isSimpleResident: false,
      muniCode: this.muni.getMuniCode,
      year: this.formHaveEntitlement
        ? this.formHaveEntitlement.value.years
        : this.residendMasService.residentDetails.years[0],
      sendEmail: false,
    };
    this.getPdfOpen(data);
  }
  private getPdfOpen(data: any): void {
    this.residendMasService
      .GetResidentConfirmPDF(data, this.authService.isLoggedIn())
      .pipe(
        takeUntil(this.destroy$),
        skipWhile((x) => !x)
      )
      .subscribe(
        (response: HttpResponse<Blob>) => {
          this.loading.hide();
          if (response?.body?.size > 0) {
            let binaryData = [];
            binaryData.push(response.body);
            let downloadLink = document.createElement('a');
            const filename =
              new Date()
                .toLocaleString()
                .substr(0, 11)
                .replace(',', '')
                .replace(' ', '') + '.pdf';
            downloadLink.href = window.URL.createObjectURL(
              new Blob(binaryData, { type: 'application/pdf' })
            );
            if (filename) {
              this.loading.hide();
              downloadLink.setAttribute('download', filename);
            }
            document.body.appendChild(downloadLink);
            window.open(downloadLink.href);
          } else {
            this.loading.hide();
            let ref = this.dialog.open(MessageDialogComponent, {
              data: {
                title: ' אירעה שגיאה בהפקת האישור',
                body: [],
                btn: 'מעבר לעמוד הראשי',
              },
            });
            ref.afterClosed().subscribe((res) => {
              this.router.navigate(['']);
            });
          }
        },
        async (err) => {
          this.loading.hide();
          if (this.workWithTableParam.active) {
            const errorText = this.blobToString(err.error);
            let ref = this.dialog.open(MessageDialogComponent, {
              data: {
                title: ' אירעה שגיאה בהפקת האישור',
                body: [errorText],
                btn: 'מעבר לעמוד הראשי',
              },
            });
            ref.afterClosed().subscribe((res) => {
              this.router.navigate(['']);
            });
          } else {
            let ref = this.dialog.open(MessageDialogComponent, {
              data: {
                title: ' אירעה שגיאה בהפקת האישור',
                body: ['יש לפנות למחלקת הגביה ברשות המקומית'],
                btn: 'מעבר לעמוד הראשי',
              },
            });
            ref.afterClosed().subscribe((res) => {
              this.router.navigate(['']);
            });
          }
        }
      );
  }
  private blobToString(blob): string {
    const url = URL.createObjectURL(blob);
    let xmlRequest = new XMLHttpRequest();
    xmlRequest.open('GET', url, false);
    xmlRequest.send();
    URL.revokeObjectURL(url);
    return xmlRequest.responseText;
  }
  public openFile(fileContent, fileType: string) {
    const byteArray = new Uint8Array(
      atob(fileContent)
        .split('')
        .map((char) => char.charCodeAt(0))
    );
    var typeToConvert: string;
    typeToConvert = fileType;
    const file = new Blob([byteArray], { type: typeToConvert });
    var url = window.URL.createObjectURL(file);
    let anchor = document.createElement('a');
    anchor.href = url;
    anchor.target = '_blank';
    anchor.click();
    this.loading.hide();
  }
  public sendEmail() {
    this.loading.show();
    const data = {
      id: this.formHaveEntitlement.value.id.toString(),
      phone: this.data.phone ? this.data.phone?.toString() : '',
      name: this.data.name,
      email: this.data.email,
      isSimpleResident: false,
      muniCode: this.muni.getMuniCode,
      year: this.formHaveEntitlement
        ? this.formHaveEntitlement.value.years
        : this.residendMasService.residentDetails.years[0],
      sendEmail: true,
    };
    this.residendMasService
      .GetResidentConfirm(data, this.authService.isLoggedIn())
      .pipe(
        takeUntil(this.destroy$),
        skipWhile((x) => !x)
      )
      .subscribe(
        (response: any) => {
          this.loading.hide();
          if (response.body.rc == 0) {
            // נשלח
            let ref = this.dialog.open(MessageDialogComponent, {
              data: {
                title: 'האישור אצלך במייל :)',
                body: [],
                btn: 'תודה!',
              },
            });
            ref.afterClosed().subscribe((res) => {
              this.router.navigate(['']);
            });
          } else {
            //לא נשלח
            let ref = this.dialog.open(MessageDialogComponent, {
              data: {
                title: 'אירעה שגיאה :(',
                body: [],
                btn: 'חזרה לעמוד הראשי',
              },
            });
            ref.afterClosed().subscribe((res) => {
              this.router.navigate(['']);
            });
          }
        },
        (err) => {
          this.loading.hide();
          // const dialogRef = this.dialog.open(PopupComponent, {
          //   data: { id: 'residentWithDesc', data: [err.error] },
          //   disableClose: true,
          // });
          // dialogRef.afterClosed().subscribe((result) => {
          //   this.router.navigate(['/']);
          // });
        }
      );
  }
  private setValuesFromLoginData(id: number) {
    const details = this.valuesFromLoginData.find((d) => d.tz == id);
    this.controlsHaveEntitlement[2].inputControl.config.value = details.name;
    this.controlsHaveEntitlement[3].inputControl.config.value = details.name;
    this.controlsHaveEntitlement[4].inputControl.config.value = details.phone;
    this.controlsHaveEntitlement[5].inputControl.config.value = details.email;
    this.controlsHaveEntitlement[1].inputControl.selectconfig.list =
      this.convertYearToItemList(details.years);
    this.controlsHaveEntitlement[1].inputControl.selectconfig.copyList =
      this.convertYearToItemList(details.years);
    this.data = {
      email: details.email,
      id: details.tz.toString(),
      name: details.name,
      phone: details.phone,
    };
  }
  private noteligible(res: TableResidentRes): void {
    let title: Array<string> = [
      'לא נמצאה זכאות לאישור תושב במהלך המרוכז מהסיבות הבאות:',
    ];
    if (!res.arnona) title.push('לא נמצא תיעוד לתשלום ארנונה למועצה. ');
    if (!res.hin)
      title.push('לפי רישומנו, ילדכם אינם מתחנכים במוסדות המועצה. ');
    if (!res.pnim)
      title.push('בתעודת הזהות לא קיים הרישום הנדרש על פי הנחיות מס הכנסה. ');
    if (!res.water) title.push('לא נמצא תיעוד לחשבון מים / ביוב. ');
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: {
        title: 'לא נמצאה זכאות',
        body: title,
        btn: 'תודה!',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.router.navigate(['/main']);
    });
  }
  private getDataForFormLogin(workWithTable: boolean): void {
    this.loading.show();
    if (workWithTable === true) {
      this.initTableFormLogin();
    } else {
      this.initFormNotTableLogin();
    }
    this.loading.hide();
  }
  private initTableFormLogin() {
    this.residendMasService
      .GetResidentConfirmLoginUser()
      .subscribe((res: Array<ResidentConfirmLoginUser>) => {
        if (res) {
          this.valuesFromLoginData = res;
          this.haveEntitlement = true;
          this.needDetails = true;
          this.controlsHaveEntitlement[0].inputControl.selectconfig.list = [];
          res.map((element) => {
            this.controlsHaveEntitlement[0].inputControl.selectconfig.list.push(
              {
                label: element.tz.toString(),
                value: element.tz,
              }
            );
            this.controlsHaveEntitlement[0].inputControl.selectconfig.copyList =
              this.controlsHaveEntitlement[0].inputControl.selectconfig.list;
          });
        }
        if (res.length == 0) {
          this.dialog.open(MessageDialogComponent, {
            data: {
              title: 'לא נמצאה זכאות',
              body: ['למשתמש זה לא קיימים זכאים לאישור תושב לרשות המיסים'],
              btn: 'תודה!',
            },
          });
        }
      });
  }
  private initFormNotTableLogin() {
    this.acountService.FamilyDetails$.pipe(takeUntil(this.destroy$)).subscribe(
      (res) => {
        if (!res || res.paymentId == '') {
          this.acountService.GetFamilyDetailsSub();
        } else {
          // this.familyMembers = res.familyMembers;
          this.controlsHaveEntitlement[0].inputControl.selectconfig.list = [];
          res.familyMembers.map((element) => {
            this.controlsHaveEntitlement[0].inputControl.selectconfig.list.push(
              {
                label: element.id.toString(),
                value: element.id,
              }
            );
            this.controlsHaveEntitlement[0].inputControl.selectconfig.copyList =
              this.controlsHaveEntitlement[0].inputControl.selectconfig.list;
          });
          // if (this.authService.authDetails) {
          let user;
          this.authService
            .getUser()
            .pipe(takeUntil(this.destroy$))
            .subscribe((_user) => (user = _user));
          this.form.markAsDirty();
          this.form.controls.name.setValue(
            user.firstName + ' ' + user.lastName
          );
          this.form.controls.id.setValue(user.maintz);
          // this.authService.authDetails = false;
          // }
          this.controlsHaveEntitlement[1].inputControl.selectconfig.list =
            this.convertYearToItemList([new Date().getFullYear().toString()]);
          this.controlsHaveEntitlement[1].inputControl.selectconfig.copyList =
            this.controlsHaveEntitlement[1].inputControl.selectconfig.list;
          this.controlsHaveEntitlement[1].inputControl.selectconfig.selected =
            new Date().getFullYear().toString();
          this.data = {
            email: user.email,
            id: user.maintz.toString(),
            name: user.firstName + ' ' + user.lastName,
            phone: user.phone,
          };
          this.loading.hide();
        }
      }
    );
  }
  private initTableForm(): void {
    const details = this.residendMasService.residentDetails;
    this.controlsHaveEntitlement[0].inputControl.selectconfig.list.push({
      label: details.id.toString(),
      value: details.id,
    });
    this.controlsHaveEntitlement[0].inputControl.selectconfig.copyList.push({
      label: details.id.toString(),
      value: details.id,
    });
    this.controlsHaveEntitlement[0].inputControl.selectconfig.selected =
      details.id;
    this.controlsHaveEntitlement[1].inputControl.selectconfig.list =
      this.convertYearToItemList(details.years);
    this.controlsHaveEntitlement[1].inputControl.selectconfig.copyList =
      this.convertYearToItemList(details.years);
    this.controlsHaveEntitlement[1].inputControl.selectconfig.selected =
      details.years[0];
    this.controlsHaveEntitlement[2].inputControl.config.value = details.name;
    this.controlsHaveEntitlement[3].inputControl.config.value = details.name;
    this.controlsHaveEntitlement[4].inputControl.config.value = details.phone;
    this.controlsHaveEntitlement[5].inputControl.config.value = details.email;
    this.data = {
      email: details.email,
      id: details.id.toString(),
      name: details.name,
      phone: details.phone,
    };
  }
  private convertYearToItemList(years: string[]): IListItem[] {
    let newList: IListItem[] = [];
    years.map((y) => {
      newList.push({
        label: y,
        value: y,
      });
    });
    return newList;
  }
  private generateForm() {
    if (this.controls?.length > 0) {
      let configs: InputConfig[] = [];
      this.controls.map((ctrl) => {
        if (!ctrl.inputControl.doubleConfig)
          configs.push(ctrl.inputControl?.config);
        else {
          ctrl.inputControl.doubleConfig.map((c) => {
            configs.push(c);
          });
        }
      });
      this.form = generateForm(configs);
    }
  }
}
