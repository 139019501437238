import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MySelectComponent } from './my-select/my-select.component';
import {MatSelectModule} from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MyBigInputComponent } from './my-big-input/my-big-input.component';
import { MyInputComponent } from './my-input/my-input.component';
import { LoaderComponent } from './loader/loader.component';
import { YesNoPipe } from '../pipes/yes-no.pipe';


@NgModule({
  declarations: [
    MySelectComponent,
    MyBigInputComponent,
    MyInputComponent,
    LoaderComponent,
    YesNoPipe
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule
  ],
  exports: [
    MySelectComponent,
    MyBigInputComponent,
    MyInputComponent,
    YesNoPipe
  ]
})
export class ElementsModule { }
