import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AppService } from '../services/app.service';
import { MuniService } from '../services/muni.service';
import { MuniCodeReq, MuniServiceCard } from '../common/models/muni.model';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  destroy$ = new Subject();
  formsTypes: string = 'false';
  mobile = false;
  constructor(
    private muniService: MuniService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  ngAfterViewInit(): void {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        if (params.formsTypes) {
          this.formsTypes = encodeURI(params.formsTypes);
        }
        if (params.div && params.type) {
          let e = document.getElementById('toggle');
          if (e)
            e.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            });
          this.muniService.changeToggleFromOut$.next(params.type);
        }
      });
  }
  ngOnInit(): void {
    if (window.screen.width <= 550) {
      this.mobile = true;
    }
    if (this.muniService.getMuniCode != 0) {
      this.muniService.getMuniData(this.muniService.getMuniCode, '');
    }
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  scroll() {
    let e = document.getElementById('backDown');
    if (e)
      e.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
  }
}
