<div class="wrapper">
  <div class="img tooltip" (click)="nav('home')">
    <span class="tooltiptext">דף הבית</span>
    <img class="home" src="../../../assets/svg/sidebar/dashboard.svg" />
  </div>
  <div class="icons">
    <div class="img tooltip" (click)="nav('personal')">
      <span class="tooltiptext">אזור אישי</span>
      <img class="home" src="../../../assets/svg/sidebar/men.svg" />
    </div>
    <!-- <div class="img tooltip" (click)="nav('forms')">
      <span class="tooltiptext">טפסים מקוונים</span>
      <img class="home" src="../../../assets/svg/sidebar/forms.svg" />
    </div> -->
    <div class="img tooltip" (click)="nav('asset')">
      <span class="tooltiptext">הנכס שלי</span>
      <img class="home" src="../../../assets/svg/sidebar/asset.svg" />
    </div>
    <div class="img tooltip" (click)="nav('payments')">
      <span class="tooltiptext">התשלומים שלי</span>
      <img class="home" src="../../../assets/svg/payWhite.svg" />
    </div>
  </div>
  <div class="bottom">
    <div class="mini">
      <div class="img tooltip" (click)="nav('setting')">
        <span class="tooltiptext">הגדרות</span>
        <img class="home" src="../../../assets/svg/sidebar/setting.svg" />
      </div>
      <div *ngIf="user" class="img tooltip" (click)="nav('logout')">
        <span class="tooltiptext">התנתקות</span>
        <img class="home" src="../../../assets/svg/sidebar/logout.svg" />
      </div>
    </div>
  </div>
</div>
