import { Component, Input, OnInit } from '@angular/core';
import { MuniService } from 'src/app/services/muni.service';
import { MuniServiceCard } from 'src/app/common/models/muni.model';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PopupGetMuniComponent } from 'src/app/common/popup-get-muni/popup-get-muni.component';
import { FormsTypesService } from '../forms-type/forms-types.service';

@Component({
  selector: 'app-cards-data',
  templateUrl: './cards-data.component.html',
  styleUrls: ['./cards-data.component.scss'],
})
export class cardsDataComponent implements OnInit {
  @Input() cards: MuniServiceCard[];
  @Input() type: number;
  constructor(
    private muniService: MuniService,
    private router: Router,
    private dialog: MatDialog,
    private formsTypeService: FormsTypesService
  ) {}
  ngOnInit(): void {}

  hover(i: number) {
    let headEdit = document.getElementById('headEdit' + i);
    if (headEdit) {
      headEdit.style.width = '65px';
      headEdit.style.height = '65px';
      headEdit.style.transition = 'all 0.5s ease-in';
    }
    let img = document.getElementById('img' + i);
    if (img) {
      img.style.width = '35px';
      img.style.height = '35px';
      img.style.transition = 'all 0.5s ease-in';
    }
  }
  out(i: number) {
    let headEdit = document.getElementById('headEdit' + i);
    if (headEdit) {
      headEdit.style.width = '60px';
      headEdit.style.height = '60px';
      headEdit.style.transition = 'all 0.5s ease-in';
    }
    let img = document.getElementById('img' + i);
    if (img) {
      img.style.width = '30px';
      img.style.height = '30px';
      img.style.transition = 'all 0.5s ease-in';
    }
  }
  choose(index: number) {
    if (this.muniService.getMuniCode == 0) {
      this.dialog.open(PopupGetMuniComponent);
    }
    if (this.cards[index].titleID) {
      let e = document.getElementById('dashboardContent');
      if (e)
        e.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      this.router.navigate([''], {
        queryParams: {
          formsTypes: '1',
          index: this.cards[index].titleID.toString(),
          typeCards:  this.cards[index].serviceType
        },
      });
    } else if (this.cards[index].link.includes('/')) {
      window.open(this.cards[index].link, '_blank');
    } else {
      this.router.navigate([this.cards[index].link]);
    }
  }
}
