import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Subject, takeUntil } from 'rxjs';
import { AssetService } from 'src/app/services/asset.service';
import { propertyTax } from './asset.model';
import { Router } from '@angular/router';
import { IListItem } from 'src/app/forms/elementForm/form-input/form-input.model';

@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss'],
})
export class AssetComponent implements OnInit, OnDestroy {
  @ViewChild(MatExpansionPanel) panel?: MatExpansionPanel;
  @Output() closeParent = new EventEmitter();
  destroy$ = new Subject();
  text = {
    title: 'הנכס שלי',
    btn: 'עוד פרטים על הנכס שלי',
  };
  resPrepertyData: propertyTax[] = [];
  assetObj: propertyTax;
  details = [];
  panelOpen = false;
  mobile = false;
  numbers: Array<IListItem> = [];
  constructor(private assetService: AssetService, private router: Router) {}
  ngOnInit(): void {
    if (window.screen.width <= 850) {
      this.mobile = true;
    }
    this.assetService.GetPropertyTaxData();
    this.assetService.PropertyTaxData$.pipe(takeUntil(this.destroy$)).subscribe(
      (res: propertyTax[]) => {
        if (res && res.length > 0) {
          this.resPrepertyData = res;
          this.assetObj = this.resPrepertyData[0];
          this.generateList(this.assetObj);
        }
      }
    );
    this.assetService.assetsNumbers$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.numbers = res;
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  generateList(res: propertyTax) {
    this.details = [];
    this.details.push({ label: 'מספר נכס', value: res.assetNum });
    this.details.push({ label: ' שם משלם', value: res.assetDetails.fullName });
    this.details.push({ label: 'סוג נכס', value: res.assetDetails.assetType });
    this.details.push({ label: 'אזור', value: res.assetDetails.area });
    this.details.push({
      label: 'כתובת נכס',
      value: res.assetDetails.assetAddress,
    });
    this.details.push({
      label: 'שובר לדואר',
      value: res.assetDetails.printEnvelope ? 'כן' : 'לא',
    });
  }
  close() {
    this.closeParent.emit();
  }
  nav() {
    this.router.navigate(['my-asset']);
  }
}
