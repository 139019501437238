import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { PaymentService } from '../forms/elementForm/payment-page/payment.service';
import { LoadingService } from '../common/elements/loader/loading.service';
import { myPayment } from './paymentSite.model';

@Component({
  selector: 'app-my-payments',
  templateUrl: './my-payments.component.html',
  styleUrls: ['./my-payments.component.scss'],
})
export class MyPaymentsComponent implements OnInit {
  destroy$ = new Subject();
  text = {
    title: 'התשלומים שלי',
  };
  tabs = [
    {
      label: 'תשלומים באתר',
    },
    {
      label:'תשלומים במערכת'
    }
  ];
  mobile = window.screen.width <= 850 ? true : false;
  paymentSite: myPayment[] = [];
  constructor(private paymentService: PaymentService,
    private loading: LoadingService) {}
  ngOnInit(): void {
    this.paymentService.getMyPayments().subscribe(
      (res) => {
        this.loading.hide();
        this.paymentSite = res.body.paymens;
      },
      (error) => {
        this.loading.hide();
      }
    );
  }
  
}
