import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { action } from './action.model';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { MuniService } from 'src/app/services/muni.service';
import {
  MuniCodeReq,
  MuniServiceCard,
  MuniServiceCommonAction,
} from 'src/app/common/models/muni.model';
import { MatDialog } from '@angular/material/dialog';
import { PopupGetMuniComponent } from 'src/app/common/popup-get-muni/popup-get-muni.component';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-actions-line',
  templateUrl: './actions-line.component.html',
  styleUrls: ['./actions-line.component.scss'],
})
export class ActionsLineComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  actions: MuniServiceCommonAction[] = [
    {
      commonActionImage: 'digital',
      serviceName: 'פעולות דיגיטליות',
      commonActionImportance: 1,
      serviceID: 1,
    },
    {
      commonActionImage: 'arnona',
      serviceName: 'הגשת הנחה לארנונה',
      commonActionImportance: 2,
      serviceID: 2,
    },
    {
      commonActionImage: 'hinuh',
      serviceName: 'תיק חינוך',
      commonActionImportance: 3,
      serviceID: 3,
    },
    {
      commonActionImage: 'ishi',
      serviceName: 'איזור אישי לתושב',
      commonActionImportance: 4,
      serviceID: 4,
    },
    {
      commonActionImage: 'moked',
      serviceName: 'פנייה למוקד 106',
      commonActionImportance: 5,
      serviceID: 5,
    },
  ];
  mobileIndex = 0;
  muniServices: MuniServiceCard[] = [];
  mobile = window.screen.width <= 850 ? true : false;
  index = 1;
  constructor(
    private muniService: MuniService,
    private dialog: MatDialog,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.muniService.commonActions$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && res.length > 0) {
          this.actions = res;
          if (window.screen.width <= 920) {
            this.actions.splice(2, this.actions.length - 3);
          } else if (window.screen.width <= 1090) {
            this.actions.splice(3, this.actions.length - 4);
          }
        }
      });
    this.muniService.muniServices$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.muniServices = res;
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  prev() {
    if (this.mobileIndex > 0) this.mobileIndex--;
    else if (this.mobileIndex == 0) this.mobileIndex = this.actions.length - 1;
  }
  next() {
    if (this.mobileIndex < this.actions.length - 1) this.mobileIndex++;
    else if (this.mobileIndex == this.actions.length - 1) this.mobileIndex = 0;
  }
  open(action: MuniServiceCommonAction) {
    if (this.muniService.getMuniCode == 0) {
      this.dialog.open(PopupGetMuniComponent);
    } else {
      let service = this.muniServices.find(
        (s) => s.serviceID == action.serviceID
      );
      if (service) {
        if (service.link.includes('/')) {
          window.open(service.link, '_blank');
        } else {
          this.router.navigate([service.link]);
        }
      }
    }
  }
  onSwipe(evt) {
    const x =
      Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
    if (x == 'right' && this.index < this.actions.length-1) {
      this.index = this.index + 1;
    } else if (x == 'left' && this.index > 0) {
      this.index = this.index - 1;
    }
  }
}
