import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { MessageDialogComponent } from 'src/app/forms/popups/message-dialog/message-dialog.component';
import { propertyTax } from 'src/app/personal-details/asset/asset.model';
import { IsendType } from 'src/app/personal-details/personal.model';
import { AccountService } from 'src/app/services/account.service';
import { AssetService } from 'src/app/services/asset.service';

@Component({
  selector: 'app-voucher-to-mail',
  templateUrl: './voucher-to-mail.component.html',
  styleUrls: ['./voucher-to-mail.component.scss'],
})
export class VoucherToMailComponent implements OnInit {
  destroy$ = new Subject();
  text = {
    email: 'user@email.co.il',
    title:
      'מקבלים את שוברי הארנונה למייל, חוסכים נייר, מפחיתים פסולת, ונהנים מחשבון נוח ומסודר שגם ידידותי לסביבה! מלאו את כתובת המייל שלכם פה למטה',
    label: 'עבור נכס זה שוברים ישלחו לכתובת המייל:',
    save: 'שמור שינויים',
  };
  form: FormGroup;
  email = '';
  sendtype: IsendType;
  assetNumber = ''
  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private dialog: MatDialog,
    private assetService: AssetService
  ) {
    this.form = this.fb.group({
      email: [],
    });
  }
  ngOnInit(): void {
    this.accountService.GetAssetSendType();
    combineLatest([
      this.assetService.PropertyTaxDataObject$,
      this.accountService.sendVochersType$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ([propertyTax, sendVochersType]: [propertyTax, IsendType[]]) => {
          if (propertyTax && sendVochersType && sendVochersType.length > 0) {
            this.sendtype = sendVochersType.find(
              (t) => t.assetNumber == propertyTax.assetNum
            );
            this.assetNumber = propertyTax.assetNum;
            if (this.sendtype.email.includes('@'))
              this.form.controls['email'].setValue(this.sendtype.email);
          }
        }
      );
  }
  focus() {
    document.getElementById('input')?.focus();
  }
  save() {
    if (this.email != '') {
      const bill2mailReq = {
        AssetId: this.assetNumber,
        Email: this.email,
      };
      this.accountService
        .ChangeBill2Mail(bill2mailReq)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            //success
            if (res == null || (res && res.status == 204)) {
              const dialogRef = this.dialog.open(MessageDialogComponent, {
                data: {
                  title: 'כתובת האימייל עודכנה בהצלחה :)',
                  body: 'מעכשיו חשבוניות ישלחו ל' + this.email,
                  btn: 'תודה',
                },
                autoFocus: false,
              });
              dialogRef.afterClosed().subscribe((result) => {
                window.location.reload();
              });
            } else {
              this.dialog.open(MessageDialogComponent, {
                data: {
                  title: 'לצערנו אירעה שגיאה בשמירת הנתונים :(',
                  body: 'ניתן לנסות שוב..',
                  btn: 'תודה',
                },
                autoFocus: false,
              });
            }
          },
          (error: any) => {
            this.dialog.open(MessageDialogComponent, {
              data: {
                title: 'לצערנו אירעה שגיאה בשמירת הנתונים :(',
                body: 'ניתן לנסות שוב..',
                btn: 'תודה',
              },
              autoFocus: false,
            });
          }
        );
    }
  }
}
