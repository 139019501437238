<div class="wrapper">
  <table class="main" *ngIf="paymentSite?.length > 0">
    <table class="widthTable">
      <tr>
        <th *ngFor="let title of displayedColumns">
          <span> {{ title }}</span>
        </th>
      </tr>
    </table>
    <tbody class="widthTable">
      <tr>
        <td class="body">
          <table class="widthTable">
            <tr class="row" *ngFor="let element of paymentSite; let i = index">
              <td class="td" width="20%">
                {{ element.payDate | date : "dd/MM/yyyy" }}
              </td>
              <td class="td" width="30%">
                {{ element.paymentProcessCategoryName }}
              </td>
              <td class="td" width="36%">
                <span *ngFor="let line of element.casualPayments; let i = index"
                  >{{ line.casualPaymentName }} <span *ngIf="i<element.casualPayments.length-1">, </span>
                </span>
              </td>
              <td class="td" width="13%">
                {{ element.amount | currency : "ILS" }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr></tr>
    </tbody>
  </table>
  <span class="no" *ngIf="paymentSite?.length == 0">לא נמצאו תשלומים באתר למשתמש זה :(</span>
</div>
