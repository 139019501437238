<div class="spinner-wrapper">
  <div class="container">
    <div class="row">
      <div class="div-container">
        <img class="loud" src="../../../../assets/png/loading.gif" />
      </div>

      <span class="spinner-message" *ngIf="message">
       {{ message  }}
      </span>
    </div>
  </div>
</div>
