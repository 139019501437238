import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { inputConfig } from 'src/app/common/elements/my-big-input/my-big-input.model';
import { generateForm } from 'src/app/common/elements/my-input/my-input.functions';
import { chackFormValid } from './formValid';
import { IRegisterDetails, User } from 'src/app/common/models/auth.model';
import { AuthService } from 'src/app/services/auth.service';
import { EMPTY, Subject, of, skipWhile, takeUntil } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-details-register',
  templateUrl: './details-register.component.html',
  styleUrls: ['./details-register.component.scss'],
})
export class DetailsRegisterComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  text = {
    title1: 'יצירת חשבון חדש',
    title2: 'בקרוב תוכלו לנהל את הבית הדיגיטלי שלכם ',
    btn: 'הצטרפות',
    registerQ: 'כבר רשומים?',
    registerLink: 'הכנסו עכשיו',
  };
  controls: inputConfig[] = [
    {
      icon: '',
      lable: 'שם משפחה',
      type: 'input',
      required: true,
      formControlName: 'lastName',
    },
    {
      icon: '',
      lable: 'שם פרטי',
      type: 'input',
      required: true,
      formControlName: 'firstName',
    },
    {
      icon: '',
      lable: 'מספר נייד',
      type: 'input',
      required: true,
      formControlName: 'phone',
    },
    {
      icon: '',
      lable: 'כתובת מייל',
      type: 'input',
      required: true,
      formControlName: 'email',
    },
    {
      icon: 'lock.svg',
      lable: 'אימות סיסמא',
      type: 'password',
      required: true,
      formControlName: 'password',
    },
    {
      icon: 'lock.svg',
      lable: 'סיסמא',
      type: 'password',
      required: true,
      formControlName: 'password2',
    },
  ];
  registerForm = generateForm(this.controls);
  id: any;
  error = '';
  registerDetails: IRegisterDetails;
  constructor(
    private loading: LoadingService,
    private authService: AuthService,
    private router: Router,
    public dialogRef: MatDialogRef<DetailsRegisterComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.id = data.mainTz;
  }
  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  regiter() {
    this.loading.show();
    const controls = this.registerForm.controls;
    const validator = chackFormValid(controls);
    if (validator != '') {
      this.loading.hide();
      this.error = validator;
      return;
    }
    if (
      this.checkPasswords(controls.password.value, controls.password2.value)
    ) {
      this.addNewUser();
    } else {
      this.loading.hide();
      this.error =
        'יש למלא סיסמא בארוך 8 תווים המכילה אותיות גדולות וקטנות באנגלית ומספרים';
    }
  }
  addNewUser() {
    const controls = this.registerForm.controls;
    this.registerDetails = {
      FirstName: controls.firstName.value,
      LastName: controls.lastName.value,
      Email: controls.email.value,
      Cell: controls.phone.value,
      Password: controls.password.value,
      Maintz: this.id.toString(),
    };
    this.authService
      .register(this.registerDetails)
      .pipe(
        skipWhile((x) => !x),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (res) => {
          if (res.body.rc == 0) {
            this.dialogRef.close();
            this.authService
              .login(this.id, controls.password.value)
              .pipe(
                skipWhile((x) => !x),
                takeUntil(this.destroy$)
              )
              .subscribe(
                (user) => {
                  this.loading.hide();
                  if (user.body.token) {
                    this.authService.setSession(user.body);
                    this.error = '';
                    this.loading.hide();
                    this.dialogRef.close();
                    if (this.authService.prevUrl != '') {
                      this.router.navigate([this.authService.prevUrl]);
                    }
                    return of(user.body);
                  } else {
                    this.error = 'קוד משלם או סיסמא לא נכונים';
                    return EMPTY;
                  }
                },
                (e) => {
                  this.loading.hide();
                  if (e.status !== 401) this.error = 'קרתה שגיאה, נא נסו שנית';
                  else this.error = 'קוד משלם או סיסמא לא נכונים';
                }
              );
            this.dialogRef.close();
          } else if (res.body.rc == 99) {
            this.loading.hide();
            this.error = 'קיים משתמש למשלם זה';
          }
        },
        (error) => {
          this.loading.hide();
          if (error.status == 401) {
            this.error = 'לא ניתן לפתוח משתמש עם פרטים אלו';
          }
        }
      );
  }

  goLogin() {}
  checkPasswords(newPassword: string, second: string): boolean {
    if (newPassword == second) {
      const regex =
        /^(?=[^A-Z\n]*[A-Z])(?=[^a-z\n]*[a-z])(?=[^0-9\n]*[0-9])\S{7,}[A-Za-z0-9-]$/;
      const hebrew = /[\u0590-\u05FF]/;
      if (!regex.test(newPassword) || hebrew.test(newPassword)) {
        this.error =
          'על הסיסמא להכיל אותיות קטנות וגדולות, מספרים ולהיות באורך 8 תווים לפחות';
        return false;
      } else {
        // this.error = 'הסיסמא תקינה';
        return true;
      }
    } else {
      this.error = 'הסיסמאות לא תואמות';
      return false;
    }
  }
  fillForm(event: { name: string; value: any }) {
    this.registerForm.controls[event.name]?.setValue(event.value);
  }
}
