import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiscountWelcomeComponent } from './discount-welcome/discount-welcome.component';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsServiceModule } from '../forms/FormsService.module';
import { DiscountNewReqComponent } from './discount-new-req/discount-new-req.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { DiscountAssetComponent } from './discount-new-req/discount-asset/discount-asset.component';
import { ElementsModule } from '../common/elements/elements.module';



@NgModule({
  declarations: [
    DiscountWelcomeComponent,
    DiscountNewReqComponent,
    DiscountAssetComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    MatStepperModule,
    FormsServiceModule,
    HttpClientModule,
    ReactiveFormsModule,
    ElementsModule
  ]
})
export class DiscountsModule { }
