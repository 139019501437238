import { Component, Input, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { addresses } from 'src/app/personal-details/asset/asset.model';
import { AssetService } from 'src/app/services/asset.service';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss'],
})
export class AddressesComponent implements OnInit {
  @Input() assetNumber: string;
  destroy$ = new Subject();
    text = {
    title: 'כתובת בעל הנכס',
    title2: 'כתובת למשלוח',
    title3: 'כתובת משלם',
  };
  listAssetAddress = [
    {
      label: 'שם',
      value: '',
    },
    {
      label: 'ת.ז',
      value: '',
    },
    {
      label: 'כתובת',
      value: '',
    },
    {
      label: 'יישוב',
      value: '',
    },
    {
      label: 'מיקוד',
      value: '',
    },
  ];
  listSendAddress = [
    {
      label: 'כתובת',
      value: '',
    },
    {
      label: 'עיר',
      value: '',
    },
    {
      label: 'מיקוד',
      value: '',
    },
  ];
  listPayment = [
    {
      label: 'כתובת',
      value: ''
    },
    {
      label: 'עיר',
      value: ''
    },
    {
      label: 'מיקוד',
      value: ''
    },
  ];
  constructor(private assetService: AssetService){}
  ngOnInit(): void {
    this.assetService.PropertyTaxDataObject$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((res) => {
      if (res) {
        this.convertToList(res.address);
      }
    });
  }
  convertToList(addresses: addresses){
    this.listAssetAddress[0].value = addresses.ownerAddress.name;
    this.listAssetAddress[1].value = addresses.ownerAddress.id.toString();
    this.listAssetAddress[2].value = addresses.ownerAddress.address;
    this.listAssetAddress[3].value = addresses.ownerAddress.city;
    this.listSendAddress[0].value = addresses.shippingAddress.address;
    this.listSendAddress[1].value = addresses.shippingAddress.city;
    this.listSendAddress[2].value = addresses.shippingAddress.postalCode;
    this.listPayment[0].value = addresses.paymentAddress.address;
    this.listPayment[1].value = addresses.paymentAddress.city;
    this.listPayment[2].value = addresses.paymentAddress.postalCode;
  }
}
