<div class="wrapper">
  <span class="title">{{ text.title }}</span>
  <div class="content">
    <div class="container firstPanel">
      <div class="header">
        <span class="name">{{ user.firstName }} {{ user.lastName }}</span>
        <span>{{ user.email }}</span>
        <span>{{ user.phone }}</span>
      </div>
      <div class="btn">
        <button (click)="nav()">{{ text.goPersonal }}</button>
      </div>
    </div>
   <app-passwords class="app"  *ngIf="isHaveUser"></app-passwords>
   <app-forget-password class="app" *ngIf="isHaveUser"></app-forget-password>
   <app-contact class="app" [user]="user" *ngIf="isHaveUser"></app-contact>
   <app-add-new-user class="app" *ngIf="!isHaveUser"></app-add-new-user>
   <!-- <app-more-setting class="app"></app-more-setting> -->
  </div>
</div>
