import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { emitObj } from '../control.model';
import { InputConfig } from '../form-input/form-input.model';
import { FileConfig, UpdateFile, UploadFile, fileDownload } from './file.model';
import { FilesService } from './files.service';
import { MuniService } from 'src/app/services/muni.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { FormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { FormsFunctionsService } from '../../allForms/forms-functions.service';

@Component({
  selector: 'my-file',
  templateUrl: './my-file.component.html',
  styleUrls: ['./my-file.component.scss'],
})
export class MyFileComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() config: InputConfig;
  @Input() file: FileConfig;
  @Input() errorRes: string;
  @Input() errorAnother: string;
  @Input() withoutLine: boolean;
  @Output() submitFunction = new EventEmitter();
  @Output() deletedFile = new EventEmitter();
  destroy$ = new Subject();
  text = {
    file: 'בחר קובץ',
  };
  error = {
    error: '',
    is: 'הוא',
    must: 'חובה',
  };
  base64Output: any;
  control = new FormControl();
  personID: string = '';
  constructor(
    private filesService: FilesService,
    private muni: MuniService,
    private authService: AuthService,
    private loading: LoadingService,
    private formsFunctions: FormsFunctionsService
  ) {}
  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.formsFunctions.personID$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res != '') this.personID = res;
      });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  deleteFile() {
    this.deletedFile.emit();
    this.file = {
      fileID: 0,
      fileName: '',
    };
  }
  disabledFiles() {
    if (this.file && this.file.fileID > 0) return true;
    else return false;
  }
  onFileSelected(event) {
    if (!this.filesService.validFileSize(event)) {
      return;
    }
    for (let i = 0; i < event.target.files.length; i++) {
      this.filesService
        .convertFile(event.target.files[i])
        .pipe(takeUntil(this.destroy$))
        .subscribe((base64) => {
          this.loading.show('');
          this.base64Output = base64;
          let fileType = 0;
          this.errorRes = '';
          switch (this.config.formControlName) {
            case 'proofFile':
              fileType = 30;
              break;
            case 'fileWaterMone':
              fileType = 40;
              break;
            case 'fileBank':
              fileType = 50;
              break;
            case 'fileIncome':
              fileType = 60;
              break;
            case 'personalFiles':
              fileType = 70;
              break;
            default:
              fileType = 10;
              break;
          }
          var doc: UploadFile = {
            content: this.base64Output,
            fileName: event.target.files[i].name,
            fileType: fileType,
            description: '',
            muniCode: this.muni.getMuniCode,
            path: '',
            personID: this.personID,
          };
          this.filesService
            .UploadSimpleFile(doc)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res) => {
                this.file = {
                  fileID: res.body.fileID,
                  fileName: event.target.files[i].name,
                };
                let obj: emitObj = {
                  name: this.config.formControlName,
                  value: this.file.fileID,
                  errors: null,
                };
                this.loading.hide();
                this.submitFunction.emit(obj);
              },
              (error) => {
                this.loading.hide();
                this.errorRes = 'another';
                this.errorAnother = 'שגיאה בהעלאת הקובץ';
              }
            );

          // let body: UpdateFile = {
          //   content: this.base64Output,
          //   fileID: this.file.fileID,
          //   fileName:event.target.files[0].name,
          //   muniCode: this.muni.getMuniCode
          // }
          // this.filesService.UpdateFile(body).pipe(takeUntil(this.destroy$)).subscribe(res=>{
          //   this.file = {
          //     fileID: res.body.fileID,
          //     fileName: event.target.files[0].name,
          //   };
          //     let obj: emitObj = {
          //       name: this.config.formControlName,
          //       value: this.file.fileID,
          //       errors: null,
          //     };
          //     this.loading.hide();
          //     this.submitFunction.emit(obj);
          //   }, error=>{
          //   })
          // }
        });
    }
  }
  downloadFile() {
    let body: fileDownload = {
      fileID: this.file.fileID,
      muniCode: this.muni.getMuniCode,
    };
    this.filesService
      .DownloadFile(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.filesService.openFile(res.body, this.file.fileName);
        },
        (error) => {
          if (error.status == 200) {
            this.filesService.openFile(error.error.text, this.file.fileName);
          }
        }
      );
  }
}
