<div id="voucher" #voucher>
  <img
    (click)="prev(stepper)"
    class="arrowPrevMobile"
    *ngIf="!isLevel1 && mobile"
    src="../../../../assets/svg/arrowPrevMobile.svg"
  />

  <div class="container">
    <span class="title">{{ formdeme.name }}</span>
    <div class="name" *ngIf="stepper.selectedIndex == 1">
      <span> שם משלם: </span>
      <span>
        {{ paymentName }}
      </span>
    </div>
    <div class="main">
      <mat-stepper
        labelPosition="bottom"
        [linear]="false"
        #stepper
        class="steper"
      >
        <ng-template matStepperIcon="edit">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 96 960 960"
            width="24"
          >
            <path
              fill="#ffffff"
              d="M382 802.153 168.616 588.769l42.769-42.768L382 716.616l366.615-366.615 42.769 42.768L382 802.153Z"
            />
          </svg>
        </ng-template>

        <ng-template matStepperIcon="done">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 96 960 960"
            width="24"
          >
            <path
              fill="#ffffff"
              d="M382 802.153 168.616 588.769l42.769-42.768L382 716.616l366.615-366.615 42.769 42.768L382 802.153Z"
            />
          </svg>
        </ng-template>

        <mat-step [stepControl]="formdeme">
          <ng-template matStepLabel>{{ formdeme.levels[0].name }}</ng-template>
          <ng-template class="upLable">{{
            formdeme.levels[0].name
          }}</ng-template>
          <app-basic-form
            [stepper]="stepper"
            [controlsList]="formdeme.levels[0].controls"
            [hideNext]="2"
            [hidePrev]="true"
            [miniForm]="true"
            [btnText]="formdeme.levels[0].btnText"
            [autoSave]="true"
            [enterNext]="true"
            [clearControl]="clearControl"
            (submitFunction)="saveDataLevel($event)"
            (enterNextGo)="searchVoucher()"
          ></app-basic-form>

          <div *ngIf="isLevel1" class="voucher">
            <span id="openSpan" (click)="openVoucher()">{{ text.where }}</span>
            <img
              id="openImg"
              (click)="openVoucher()"
              src="../../../assets/gif/voucher.gif"
            />
          </div>
          <div id="voucherImg" #voucherImg class="bottomVouvher">
            <img
              class="theImg"
              [ngClass]="{
                mobileImg: mobile
              }"
              *ngIf="haveVoucher"
              src="{{ voucherSrc }}"
            />
            <div class="closeArrow" (click)="close()">
              <img
                class="arrowClose"
                *ngIf="haveVoucher"
                src="../../../../assets/svg/selectArrow.svg"
              />
            </div>
          </div>

          <div class="nextdiv">
            <button
              [disabled]="paymentNumber == 0 || voucherNumber == ''"
              class="next"
              mat-button
              (click)="searchVoucher()"
            >
              המשך לתשלום
            </button>
          </div>
        </mat-step>
        <mat-step [stepControl]="formdeme">
          <ng-template matStepLabel>{{ formdeme.levels[1].name }}</ng-template>
          <ng-template class="upLable">{{
            formdeme.levels[1].name
          }}</ng-template>
          <app-form-table *ngIf="!mobile"></app-form-table>
          <div>
            <app-mobile-details *ngIf="mobile"></app-mobile-details>
          </div>
          <div
            class="nextdiv"
            [ngClass]="{
              level2Next: mobile
            }"
          >
            <button
              *ngIf="!mobile"
              class="prev"
              mat-button
              (click)="prev(stepper)"
            >
              <img src="../../../../assets/svg/arrow-right.svg" />
              הקודם
            </button>
            <button
              *ngIf="!mobile"
              class="next"
              mat-button
              (click)="next(stepper)"
            >
              לתשלום
              <img src="../../../../assets/svg/arrowLeft.svg  " />
            </button>
            <button
              *ngIf="mobile"
              class="next"
              mat-button
              (click)="next(stepper)"
            >
              לתשלום {{ sum | number : "1.2-2" }} ₪
            </button>
          </div>
        </mat-step>
        <mat-step [stepControl]="formdeme">
          <ng-template matStepLabel>{{ formdeme.levels[2].name }}</ng-template>
          <ng-template class="upLable">{{
            formdeme.levels[2].name
          }}</ng-template>
          <app-payment-page
            [haveStepper]="true"
            (goPrev)="prev(stepper)"
          ></app-payment-page>
        </mat-step>
      </mat-stepper>
    </div>
  </div>
</div>
