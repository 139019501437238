import { Pipe, PipeTransform } from '@angular/core';
import { isDate } from 'moment';
import { stringDate } from 'src/app/forms/allForms/functions.model';
import { MyDateService } from 'src/app/forms/elementForm/my-date/my-date.service';

@Pipe({
  name: 'noneValue',
})
export class NoneValuePipe implements PipeTransform {
  constructor(private date: MyDateService) {}
  transform(value: unknown, ...args: unknown[]): unknown {
    if (!value) return '---';
    if (
      value.toString().includes('T') &&
      value.toString().includes(':') &&
      (value.toString().includes('/') || value.toString().includes('-'))
    ) {
      let date: stringDate = this.date.getStrinsOfDate(value.toString());
      if (date) {
        return date.day + '/' + date.month + '/' + date.year;
      } else return '---';
    } else return value;
  }
}
