import { Component, OnDestroy, OnInit } from '@angular/core';
import { MuniService } from 'src/app/services/muni.service';
import { FormsFunctionsService } from '../forms-functions.service';
import { saveDataLevel } from '../../elementForm/control.model';
import {
  citySelectConfig,
  emailconfig,
  firstNameconfig,
  formDeme,
  houseNumberApartmentConfig,
  idconfig,
  lastNameconfig,
  maritalStatusConfig,
  phoneconfig,
  signBody,
  streetSelectConfig,
} from '../../elementForm/controlsDeme';
import {
  FormPersonReq,
  FormsExistData,
  SelfParticipationSpouseDetailsReq,
  saveDeductible,
  saveDeductibleObj,
} from '../functions.model';
import { AuthService } from 'src/app/services/auth.service';
import { FormsDataService } from '../../forms-data.service';
import { MatStepper } from '@angular/material/stepper';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-deductible',
  templateUrl: './deductible.component.html',
  styleUrls: ['./deductible.component.scss'],
})
export class DeductibleComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  signBody: signBody = {
    buttons: [
      {
        btnName: 'check1',
        config: {
          name: 'check1',
          text: 'כל הפרטים הנמסרים על ידי נכונים ומדויקים',
          type: 'checkbox',
        },
      },
      {
        btnName: 'check2',
        config: {
          name: 'check2',
          text: 'ידוע לי כי אם יימצא שהעובדות שמסרתי במסגרת הצהרתי זו הינן כוזבות, או יימצא כי העלמתי פרטים או עשיתי קנוניה עם אדם אחר ובכל זאת בנוגע לסיוע חומרי מבוקש אהיה צפוי לעונשים בהתאם לתקנה 7 לתקנות שירותי הסעד (פנייה למתן סעד או טיפול סוציאלי) תשכ"ז- 1967 ידוע לי כי על פי חוק שירותי הסעד, התשי"ח- 1958 בסמכותו של עובד סוציאלי שמונה לפי אותו חוק לבדוק אותי וכל אדם אחר בדבר מצבי בכלל זה נוגע להצהרותי לעיל',
          type: 'checkbox',
        },
      },
      {
        btnName: 'check3',
        config: {
          name: 'check3',
          text: 'ידוע לי כי כל סיוע הדורש השתתפות עצמית מותנה בחתימה על טופס הצהרת מחויבות הדדית ביני לבין המחלקה לשירותים חברתיים ועמידה בתנאיו היותו של הסיוע חלק מתכנית התערבות מוסכמת המצאת פרטים ומסמכים רלוונטיים, עדכניים ומדויקים על ידי קריטריונים הנקבעים על ידי משרד העבודה, הרווחה והשירותים החברתיים לאותו עניין סדרי עדיפויות, תקציביים של המחלקה לשירותים חברתיים בחינה מחדש, לפחות אחת לשנה, של הנזקקות ושל הזכאות בסיוע מתמשך (כגון השמה במסגרת)',
          type: 'checkbox',
        },
      },
    ],
    sign: [
      {
        name: 'חתימת הפונה',
      },
      {
        name: 'חתימת בן/ת הזוג',
      },
    ],
    needEnd: true,
  };

  deductibleForm: formDeme = {
    id: 50,
    levels: [
      {
        id: 1,
        lable: '',
        name: 'פרטי מגיש הבקשה',
        type: 'form',
        controls: [
          idconfig,
          lastNameconfig,
          firstNameconfig,
          maritalStatusConfig,
          emailconfig,
          phoneconfig,
          citySelectConfig,
          streetSelectConfig,
          houseNumberApartmentConfig,
        ],
      },
      {
        id: 2,
        lable: '',
        name: 'פרטי הבקשה',
        type: 'form',
        controls: [
          {
            controlType: 'input',
            inputControl: {
              config: {
                formControlName: 'parentName',
                inputType: 'input',
                label: 'שם בן/ת הזוג',
                name: 'parentName',
                required: true,
                type: 'input',
              },
            },
          },
          {
            controlType: 'input',
            inputControl: {
              config: {
                formControlName: 'parentID',
                inputType: 'number',
                label: 'תעודת זהות בן/ת הזוג',
                name: 'parentID',
                required: true,
                type: 'input',
              },
            },
          },
          {
            controlType: 'input',
            inputControl: {
              config: {
                formControlName: 'minorChildren',
                inputType: 'number',
                label: 'מספר ילדים עד גיל 18',
                name: 'minorChildren',
                required: true,
                type: 'input',
              },
            },
          },
          {
            controlType: 'input',
            inputControl: {
              config: {
                formControlName: 'numChildren',
                inputType: 'number',
                label: 'מספר ילדים המתגוררים בבית',
                name: 'numChildren',
                required: true,
                type: 'input',
                validation: 'plusNumbers',
              },
            },
          },
          {
            controlType: 'input',
            inputControl: {
              config: {
                formControlName: 'typeIncome',
                inputType: 'select',
                label: 'מעמד בעבודה',
                name: 'typeIncome',
                required: true,
                type: 'select',
              },
              selectconfig: {
                bigSelect: false,
                copyList: [],
                list: [],
                lable: 'מעמד בעבודה',
              },
            },
          },
          {
            controlType: 'input',
            inputControl: {
              config: {
                formControlName: '',
                type: 'anyThing',
                inputType: '',
                label: '',
                name: '',
                required: false,
              },
            },
          },
          {
            controlType: 'button',
            buttonControl: {
              btnName: 'nadlanAssets',
              config: {
                name: 'nadlanAssets',
                text: 'האם יש ברשותך נכסי נדל"ן?',
                type: 'toggle-group',
                required: true,
                toggleGroupName: 'nadlan',
                color: '#08BED1',
                needTitle: true,
              },
              toggleConfig: [
                {
                  name: 'כן',
                  value: 'true',
                  isDefult: true,
                  miniBtn: false,
                },
                {
                  name: 'לא',
                  value: 'false',
                  isDefult: true,
                  miniBtn: false,
                },
              ],
              childControls: [
                {
                  controlType: 'input',
                  inputControl: {
                    config: {
                      formControlName: 'erechAssets',
                      inputType: 'number',
                      label: '?בכמה מוערכים הנכסים',
                      name: 'erechAssets',
                      required: false,
                      type: 'input',
                    },
                  },
                },
              ],
            },
          },
        ],
      },
      {
        id: 3,
        lable: '',
        name: 'הצהרה וסיום',
        type: 'sign',
        signBody: this.signBody,
      },
      // {
      //   id: 4,
      //   lable: '',
      //   name: 'סיום',
      //   type: 'sendSucsses',
      // },
    ],
    name: 'המחלקה לשירותים חברתיים- הצהרה להשתתפות עצמית',
  };
  constructor(
    private muni: MuniService,
    private formsFunctions: FormsFunctionsService,
    private authService: AuthService,
    private formsData: FormsDataService,
    private loading: LoadingService
  ) {}
  ngOnInit(): void {
    this.formsData.citiesListSelect$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!res || res.length == 0) {
          this.formsData.getCities();
        } else {
          this.deductibleForm.levels[0].controls[6].inputControl.selectconfig.list =
            res;
          this.deductibleForm.levels[0].controls[6].inputControl.selectconfig.copyList =
            res;
        }
      });
    this.formsData.streetsListSelect$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && res.length > 0) {
          this.deductibleForm.levels[0].controls[7].inputControl.selectconfig.list =
            res;
          this.deductibleForm.levels[0].controls[7].inputControl.selectconfig.copyList =
            res;
        }
      });
    this.formsFunctions.existRequestData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: FormsExistData) => {
        if (res && res?.requestData?.id != '') {
          this.setLevel1Data(res);
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  setLevel1Data(res: FormsExistData) {
    this.deductibleForm.levels[0].controls[1].inputControl.config.value =
      res.requestData.applicantDetails.lastName;
    this.deductibleForm.levels[0].controls[2].inputControl.config.value =
      res.requestData.applicantDetails.firstName;
    this.deductibleForm.levels[0].controls[6].inputControl.selectconfig.selected =
      res.requestData.applicantDetails.address.cityID;
    this.formsData.getStreets(res.requestData.applicantDetails.address.cityID);
    this.deductibleForm.levels[0].controls[7].inputControl.selectconfig.selected =
      res.requestData.applicantDetails.address.streetID;
    if (res.requestData.applicantDetails.address.houseNum > 0)
      this.deductibleForm.levels[0].controls[8].inputControl.doubleConfig[0].value =
        res.requestData.applicantDetails.address.houseNum;
    if (res.requestData.applicantDetails.address.apartment > 0)
      this.deductibleForm.levels[0].controls[8].inputControl.doubleConfig[1].value =
        res.requestData.applicantDetails.address.apartment;
    this.deductibleForm.levels[0].controls[3].inputControl.selected =
      res.requestData.applicantDetails.familyStatus;
    this.deductibleForm.levels[0].controls[3].inputControl.selectconfig.selected =
      res.requestData.applicantDetails.familyStatus;
    this.deductibleForm.levels[0].controls[4].inputControl.config.value =
      res.requestData.applicantDetails.email;
    this.deductibleForm.levels[0].controls[5].inputControl.config.value =
      res.requestData.applicantDetails.phone;
  }
  setLevel2Data(res: saveDeductibleObj) {
    if (res.spouseName)
      this.deductibleForm.levels[1].controls[0].inputControl.config.value =
        res.spouseName;
    if (res.spouseID)
      this.deductibleForm.levels[1].controls[1].inputControl.config.value =
        res.spouseID;
    if (res.minorChildren > 0)
      this.deductibleForm.levels[1].controls[2].inputControl.config.value =
        res.minorChildren;
    if (res.childrenAtHouse > 0)
      this.deductibleForm.levels[1].controls[3].inputControl.config.value =
        res.childrenAtHouse;
    if (res.workStatus) {
      this.deductibleForm.levels[1].controls[4].inputControl.selected =
        res.workStatus;
      this.deductibleForm.levels[1].controls[4].inputControl.selectconfig.selected =
        res.workStatus;
    }
    if (res.isAssets) {
      this.deductibleForm.levels[1].controls[6].buttonControl.value = 'true';
      this.formsData.needSubmitInput$.next({
        name: this.deductibleForm.levels[1].controls[6].buttonControl.btnName,
        need: true,
      });
    } else {
      this.deductibleForm.levels[1].controls[6].buttonControl.value = 'false';
    }
  }
  saveDataLevel(event: saveDataLevel) {
    let level = this.deductibleForm.levels.find((l) => l.id == event.levelID);
    if (level) {
      switch (level.id) {
        case 1:
          const body1: FormPersonReq = {
            email: event.data.email,
            firstName: event.data.firstName,
            lastName: event.data.lastName,
            muniCode: this.muni.getMuniCode,
            pasport: event.data.id,
            phone: event.data.phone,
            formType: this.deductibleForm.id,
            familyStatus: event.data.maritalStatus,
            address: {
              apartment: event.data.apartment ? +event.data.apartment : 0,
              cityID: +event.data.city,
              entrance: null,
              houseNum: event.data.houseNumber ? event.data.houseNumber : 0,
              lineID: this.formsFunctions.existRequestData$.value?.requestData
                ?.lineID
                ? this.formsFunctions.existRequestData$.value?.requestData
                    ?.lineID
                : 0,
              postalCode: 0,
              streetID: event.data.street,
            },
          };
          this.formsFunctions
            .SaveApplicantRequestDetails(body1)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res) => {
                this.next(event.stepper);
                this.formsFunctions
                  .deductibleGetExist({
                    muniCode: this.muni.getMuniCode,
                    mainRequestID:
                      this.formsFunctions.existRequestData$.value.requestData
                        .lineID,
                  })
                  .subscribe((res) => {
                    this.setLevel2Data(res?.body);
                  });
              },
              (error) => {}
            );
          break;
        case 2:
          let body: saveDeductible = {
            assetsEstimate: event.data.erechAssets ? event.data.erechAssets : 0,
            childrenAtHouse: event.data.numChildren,
            isAssets: event.data.nadlanAssets == 'true' ? true : false,
            mainRequestId: this.formsFunctions.existRequestData$.value
              ?.requestData?.lineID
              ? this.formsFunctions.existRequestData$.value?.requestData?.lineID
              : 0,
            minorChildren: event.data.minorChildren,
            muniCode: this.muni.getMuniCode,
            workStatus: event.data.typeIncome,
          };
          let body2: SelfParticipationSpouseDetailsReq = {
            mainRequestId: this.formsFunctions.existRequestData$.value
              ?.requestData?.lineID
              ? this.formsFunctions.existRequestData$.value?.requestData?.lineID
              : 0,
            muniCode: this.muni.getMuniCode,
            spouseID: event.data.parentID,
            spouseName: event.data.parentName,
          };
          this.formsFunctions.deductibleSaveDetails(body).subscribe((es) => {
            this.formsFunctions
              .deductibleSaveSpouseDetails(body2)
              .subscribe((es) => {
                this.next(event.stepper);
              });
          });

          break;
        default:
          break;
      }
    }
  }
  next(mystepper: MatStepper) {
    this.loading.hide();
    setTimeout(() => {
      mystepper.selected.completed = true;
      mystepper.next();
    }, 1);
  }
}
