import { Component, Input, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { rate } from 'src/app/personal-details/asset/asset.model';
import { AssetService } from 'src/app/services/asset.service';

@Component({
  selector: 'app-property-tax',
  templateUrl: './property-tax.component.html',
  styleUrls: ['./property-tax.component.scss'],
})
export class PropertyTaxComponent implements OnInit {
  destroy$ = new Subject();
  propertyTaxRates: rate[] = [];
  displayedColumns = ['תאור תעריף', 'קוד תעריף', 'גודל'];
  constructor(private assetService: AssetService) {}
  ngOnInit(): void {
    this.assetService.PropertyTaxDataObject$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((res) => {
      if (res) {
        this.propertyTaxRates = res.propertyTaxRates;
      }
    });
  }
}
