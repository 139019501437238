import { Injectable } from '@angular/core';
import { BehaviorSubject, skipWhile } from 'rxjs';
import { AppService } from './app.service';
import {
  IDocument,
  IFamilyDetails,
  IMyAccountResponse,
  IPaymentArrangement,
  IsendType,
} from '../personal-details/personal.model';
import { SavedCreditCard } from '../forms/elementForm/payment-page/paymentPage.model';
import { LoadingService } from '../common/elements/loader/loading.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  public FamilyDetails$ = new BehaviorSubject<IFamilyDetails>({
    familyMembers: [],
    paymentName: '',
    paymentId: '',
    paymentAddress: '',
    paymentCity: '',
    email: '',
    cellphone: '',
  });
  public PaymentArrangements$ = new BehaviorSubject<IPaymentArrangement[]>([]);
  public CreditCards$ = new BehaviorSubject<SavedCreditCard[]>([]);
  public Documents$ = new BehaviorSubject<IDocument[]>([]);
  public sendVochersType$ = new BehaviorSubject<IsendType[]>([]);
  public EmailForSend$ = new BehaviorSubject<string>('');
  constructor(private app: AppService, private loading: LoadingService) {}
  GetFamilyDetails() {
    return this.app.post('User/GetFamilyDetails');
  }
  GetFamilyDetailsSub() {
    return this.app.post('User/GetFamilyDetails').subscribe((res) => {
      if (res) this.FamilyDetails$.next(res.body);
    });
  }
  checkCurrentPassword(password: string) {
    return this.app.post('User/checkCurrentPassword', {
      password: password,
    });
  }
  newPassword(currentPassword: string, newPassword: string) {
    return this.app.post('User/newPassword', {
      currentPassword,
      newPassword,
    });
  }
  getData() {
    this.loading.show();
    this.app
      .get('User/account')
      .pipe(skipWhile((x) => !x))
      .subscribe((res: IMyAccountResponse) => {
        this.CreditCards$.next(res.creditCards);
        this.Documents$.next(res.documents);
        this.FamilyDetails$.next(res.familyDetails);
        this.PaymentArrangements$.next(res.paymentArrangement);
        this.sendVochersType$.next(res.sendType);
        this.loading.hide();
      });
  }
  GetAssetSendType() {
    return this.app.post('User/GetAssetSendType').subscribe((res) => {
      if (res) this.sendVochersType$.next(res.body.assetSendTypeList);
    }, error=>{
    });
  }
  public ChangeBill2Mail(body: any) {
    let url = 'AssetsController/ChangeBill2Mail/';
    return this.app.post( url, body);
  }
  GetPaymentArrangement() {
    return this.app.post('User/GetPaymentArrangement').subscribe((res) => {
      if (res) this.PaymentArrangements$.next(res.body);
    });
  }
}
