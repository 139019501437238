<div class="wrapper">
  <table>
    <table class="widthTable">
      <tr
        [ngClass]="{
          miniTH: width < 1024
        }"
      >
        <th *ngFor="let title of displayedColumns">
          {{ title }}
        </th>
      </tr>
    </table>
    <tbody>
      <tr>
        <td class="body">
          <table class="line">
            <tr class="row" *ngFor="let element of data; let i = index">
              <td width="15%">{{ element.assetId }}</td>
              <td width="15%">{{ element.date | date : "dd/MM/yyyy" }}</td>
              <td width="15%">{{ element.chargeType }}</td>
              <td width="25%" *ngIf="width > 1024">
                {{ element.note }}
              </td>
              <td width="15%" *ngIf="width > 1024">
                {{ element.interest | yesNo }}
              </td>
              <td class="ltr" width="15%">
                ₪ {{ element.sum | number : "1.2-2" }}
              </td>
            </tr>

            <!-- class="width70" -->
          </table>
          <div class="row footer">
            <div class="name"></div>
            <span class="sum"> {{ voucher?.sum | number : "1.2-2" }} ₪ </span>
          </div>
        </td>
        <!-- class="width70" -->
      </tr>
    </tbody>
  </table>
</div>
