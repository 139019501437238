import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PaymentService } from '../payment.service';
import { AppService } from 'src/app/services/app.service';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { PaymentRes, paymentIdentifiers } from '../paymentPage.model';
import { MuniService } from 'src/app/services/muni.service';

@Component({
  selector: 'app-after-bit-payment',
  templateUrl: './after-bit-payment.component.html',
  styleUrls: ['./after-bit-payment.component.scss'],
})
export class AfterBitPaymentComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  isBillToMail: boolean;
  email = '';
  address: string;
  isGuest = false;
  constructor(
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private loading: LoadingService,
    private muni: MuniService
  ) {}
  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.loading.show();
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        let xfield = encodeURI(params?.uniqueID);
        if (xfield != '') this.onBackFromBit(xfield);
        else alert('שגיאה!');
      });
  }
  onBackFromBit(xField: string) {
    let paymentIdentifiers: paymentIdentifiers = {
      actType: 2,
      muniCode: this.muni.getMuniCode,
      uniqueID: xField,
      paymentInitiationId: '',
      transactionID: '',
      email: '',
    };
    let resh: PaymentRes = {
      paymentIdentifiers: paymentIdentifiers,
    };
    this.paymentService.paymentResponse$.next(resh);

    this.paymentService.afterPayment();
    // this.paymentService
    //   .backFromBit(xField)
    //   .subscribe((res) => {
    //   console.log(res);
    //   if (res?.rc === 0)
    //     this.router.navigate(['/successLandingUrl']);
    //   else if (res?.rc === -1)
    //     this.router.navigate(['/failureLandingUrl']);
    //   else if (res?.rc === -2)
    //     alert(res?.title);
    // },
    // (err) => {
    //   let message = 'שגיאה! ';
    //   message +=
    //     err.status == 400 ? 'יש להתחבר למערכת' : err.title || err.message;
    //   //לראות אם עובד במצב של אורח

    //   alert(message);
    // });
  }
}
