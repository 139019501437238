import { Component, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Subject, takeUntil } from 'rxjs';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { Voucher } from 'src/app/common/models/voucher.model';
import { Charge } from 'src/app/debt-balance/debt.model';
import { VoucherService, panelVoucher } from 'src/app/services/voucher.service';

@Component({
  selector: 'app-mobile-details',
  templateUrl: './mobile-details.component.html',
  styleUrls: ['./mobile-details.component.scss'],
})
export class MobileDetailsComponent implements OnInit {
  @ViewChild(MatExpansionPanel) pannel?: MatExpansionPanel;
  destroy$ = new Subject();
  text = {
    asset: 'מספר נכס',
    date: 'תקופת שובר :',
    ribit: 'ריבית: ',
    topay: 'סה"כ לתשלום',
  };
  data: Charge[];
  objects: panelVoucher[] = [];
  voucher: Voucher;
  error = '';
  constructor(
    private loading: LoadingService,
    private voucherService: VoucherService
  ) {}
  ngOnInit(): void {
    this.loading.hide();
    this.voucherService.oneVocher$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: Voucher) => {
        if (res) {
          this.loading.hide();
          this.data = res.charges;
          this.voucher = res;
          this.orgenaizeData(res);
        } else {
          this.error = 'לא קיים שובר';
        }
      });
  }
  orgenaizeData(res: Voucher) {
    this.objects = [];
    res.charges.map((ch) => {
      if (!this.objects.find((c) => c.assetNumber == ch.assetId)) {
        let object: panelVoucher = {
          assetNumber: ch.assetId,
          charge: [],
          period: res.voucherPeriod,
          sum: 0,
          opened: true,
        };
        let number = ch.assetId;
        res.charges.map((r) => {
          if (r.assetId == number) {
            object.charge.push(r);
            object.sum+=r.sum;
          }
        });
        this.objects.push(object);
      }
    });
    if (this.objects.length > 1) {
      this.objects.map((o) => (o.opened = false));
    }
  }
  close(index: number) {
    this.objects[index].opened = !this.objects[index].opened;
  }
}
