export const menuPayments = [
  {
    name: 'תשלום שובר',
    url: 'payVoucher',
  },
  {
    name: 'תשלום יתרת חוב',
    url: 'debtBalance',
  },
  {
    name: 'תשלומים שונים',
    url: 'randomPayments',
  }
 
];
export const menuConfirms = [
  {
    name: 'הנפקת אישור תושב',
    url: 'residentConfirmSimple',
  },
  {
    name: 'הנפקת אישור תושב למס הכנסה',
    url: 'residentMas',
  },
 
];
export const menuhevra = [

  {
    name: 'בקשה לפתיחת תיק',
    url: 'fileOpening',
  },
  {
    name: 'ויתור סודיות',
    url: 'ConfidentialityWaiver',
  },
  {
    name: 'הצהרה להשתתפות עצמית',
    url: 'deductible',
  },
];

export const menuArnona = [
  {
    name: 'בקשת הנחה לארנונה',
    url: 'https://assetdiscounttest.eprsys.co.il/home/dashboard?muni=1',
  },
  {
    name: 'החלפת משלמים',
    url: 'changePayments',
  },
  // {
  //   name: 'בקשה לאישור טאבו',
  //   url: '',
  // },
  {
    name: 'הצהרת מספר נפשות',
    url: 'numberPersons',
  },
];

export const menuPersonal = [
  {
    name: 'פרטים אישיים',
    url: 'personal',
  },
  {
    name: 'הנכס שלי',
    url: 'my-asset',
  },
  {
    name: 'התשלומים שלי',
    url: 'my-payments',
  },{
    name: 'הגדרות החשבון שלי',
    url: 'setting',
  },
  {
    name: 'ספק של הרשות? ',
    url: 'eprvendorst.eprmuni.co.il',
    link: 'כניסה לאזור האישי שלך',
  }
];
export const menuForms = [
  {
    name: 'החלפת משלמים',
    url: 'changePayments',
  },
  {
    name: 'פתיחת תיק - המחלקה לשירותים חברתיים',
    url: 'fileOpening',
  },
  {
    name: 'הצהרת מספר נפשות',
    url: 'numberPersons',
  },
  {
    name: 'בירור מצב חשבון',
    url: 'checkAccountStatus',
  },
  
]
