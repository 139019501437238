import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import {
  dataModel,
  fullControl,
  saveDataLevel,
} from '../elementForm/control.model';

import { formDeme, level } from '../elementForm/controlsDeme';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, takeUntil } from 'rxjs/operators';
import { MuniService } from 'src/app/services/muni.service';
import { FormsFunctionsService } from '../allForms/forms-functions.service';
import { GetAssetsByFamilyMainIDReq } from '../allForms/functions.model';
import { ChangePaymentsService } from '../allForms/change-payments/change-payments.service';
import { descBody } from '../popups/desc-form-popup/descFormPopup.model';
import { DescPopupService } from '../popups/desc-form-popup/desc-popup.service';
import { MatDialog } from '@angular/material/dialog';
import { DescFormPopupComponent } from '../popups/desc-form-popup/desc-form-popup.component';

@Component({
  selector: 'app-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss'],
})
export class FormHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('stepper') private myStepper: MatStepper;
  @Input() formDeme: formDeme;
  @Input() miniForm: boolean;
  @Output() saveDataLevel = new EventEmitter<any>();
  destroy$ = new Subject();
  levels: level[] = [];
  form: FormGroup;
  dataArray: dataModel[] = [];
  tab = 0;
  miniHeader = 0;
  canHaveMoreForms = 0;
  childControlsChosen$ = new BehaviorSubject<level[]>([]);
  stepperOrientation: Observable<StepperOrientation>;
  mobile = window.screen.width <= 850 ? true : false;
  constructor(
    private activeRouter: ActivatedRoute,
    private formFunctions: FormsFunctionsService,
    private muni: MuniService,
    private descService: DescPopupService,
    private changeService: ChangePaymentsService,
    private dialog: MatDialog,
    breakpointObserver: BreakpointObserver
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 100px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
  }

  ngOnInit(): void {
    if (this.formDeme) {
      this.formDeme.levels.map((level) => {
        // if (level.type == 'popup') {
          // this.descService.descBody = level.descPopupBody;
        //   this.dialog.open(DescFormPopupComponent, {
        //     width: this.mobile ? '95%' : '55%',
        //     autoFocus: false
        //   });
        // }
        // else
          this.levels.push(level);
        if (level.moreLevels) {
          if (!level.moreLevels.haveVersions) {
            this.childControlsChosen$.next(level.moreLevels.levels);
            this.canHaveMoreForms = 2;
          }
        }
      });
      if (this.levels.length == 4) {
        this.miniHeader = 2;
      } else if (this.levels.length <= 3) {
        this.miniHeader = 1;
      }
      // this.canNext(this.formDeme.levels[2].moreLevels[0].controls);
    }
    this.listenRouteParams();
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  ngAfterViewInit() { }
  listenRouteParams() {
    this.activeRouter.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        const tab = +params?.tab;
        if (tab > 0) {
          this.tab = tab;
        }
      });
  }
  canNext(controlsList: fullControl[]) {
    this.canHaveMoreForms = 1;
    let values = '';
    controlsList.map((ctrl) => {
      if (ctrl.controlType == 'button') {
        if (ctrl?.buttonControl?.value != '') {
          values += ctrl.buttonControl.value;
        }
        if (ctrl.buttonControl.childControls) {
          ctrl.buttonControl.childControls.map((ctrlch) => {
            if (ctrlch.controlType == 'button') {
              if (ctrlch?.buttonControl?.value != '') {
                values += ctrlch.buttonControl.value;
              }
            }
          });
        }
      }
    });
    // let version = versionsForm.find((form) => form.id == values);
    // if (version) {
    // this.childControlsChosen$.next(version.version);
    // }
  }
  addForm(formGroup: FormGroup, levelId: number, childid?: number) {
    if (childid && this.dataArray.length > 0) {
      let i = this.dataArray.findIndex((data) => data.levelID == levelId);
      if (i != -1) {
        if (!this.dataArray[i].childData) {
          this.dataArray[i].childData = [
            {
              childData: null,
              childID: childid,
              data: formGroup.value,
              levelID: levelId,
            },
          ];
        } else {
          this.dataArray[i].childData.push({
            childData: null,
            childID: childid,
            data: formGroup.value,
            levelID: levelId,
          });
        }
      }
    } else {
      let data: saveDataLevel = {
        levelID: levelId,
        childID: childid ? childid : null,
        data: formGroup.value,
        childData: null,
        stepper: this.myStepper,
      };
      this.dataArray.push(data);
      this.saveDataLevel.emit(data);
    }
  }
  plus(childLevels, index) {
    let prev = this.childControlsChosen$.value;
    if (prev[index].controls.length == 2) {
      prev[index].controls.push({
        controlType: null,
        buttonControl: null,
        inputControl: null,
      });
    }
    childLevels.controls.map((ctrl) => {
      prev[index].controls.push(ctrl);
    });
    this.childControlsChosen$.next(prev);
  }
  prev(mystepper: MatStepper) {
    this.canHaveMoreForms = 0;
    setTimeout(() => {
      mystepper.previous();
    }, 1);
  }
  next(mystepper: MatStepper, event?: any) {
    // this.canHaveMoreForms = 0;
    if (
      event &&
      event.onlyComplete &&
      this.formDeme.levels[this.formDeme.levels.length - 1]?.signBody?.needEnd
    ) {
      mystepper.selected.completed = true;

      const step = mystepper.steps.toArray()[mystepper.selectedIndex];
      step.completed = true;
    } else
      setTimeout(() => {
        mystepper.selected.completed = true;
        mystepper.next();
      }, 1);
  }
  getDataInput(
    event: { type: 'id' | 'paymentNumber'; value: any },
    levelId: number
  ) {
    if (levelId == 1 && event.type == 'id') {
      // if (this.formDeme.id == 50) {
      // this.formFunctions.getPersonDetailsGvia(event.value);
      // } else {
      this.formFunctions.getExistRequest(
        this.formDeme.id,
        event.value.toString()
      );
      // }
    } else if (levelId == 2 && event.type == 'paymentNumber') {
      this.changeService.getDataPaymentNumber(event.value)
    }
  }
}
