<div id="discount" #discount>
  <div class="container">
    <span class="title">{{ formdeme.name }}</span>
    <div class="main">
      <mat-stepper
        labelPosition="bottom"
        [linear]="false"
        #stepper
        class="steper"
      >
        <ng-template matStepperIcon="edit">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 96 960 960"
            width="24"
          >
            <path
              fill="#ffffff"
              d="M382 802.153 168.616 588.769l42.769-42.768L382 716.616l366.615-366.615 42.769 42.768L382 802.153Z"
            />
          </svg>
        </ng-template>

        <ng-template matStepperIcon="done">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 96 960 960"
            width="24"
          >
            <path
              fill="#ffffff"
              d="M382 802.153 168.616 588.769l42.769-42.768L382 716.616l366.615-366.615 42.769 42.768L382 802.153Z"
            />
          </svg>
        </ng-template>

        <mat-step
          [stepControl]="formdeme"
          *ngFor="let level of formdeme?.levels"
        >
          <ng-template matStepLabel>{{ level.name }}</ng-template>
          <ng-container [ngSwitch]="level.type">
            <ng-container *ngSwitchCase="'form'">
              <app-basic-form
                [stepper]="stepper"
                [controlsList]="level.controls"
                [btnText]="level.btnText"
                [clearControl]="clearControl"
                (submitFunction)="saveDataLevel($event)"
              ></app-basic-form>
            </ng-container>
            <ng-container *ngSwitchCase="'assetDiscount'">
              <app-discount-asset [level]="level"></app-discount-asset>
            </ng-container>
          </ng-container>
        </mat-step>
      </mat-stepper>
    </div>
  </div>
</div>
