<div class="wrapper">
  <form [formGroup]="form">
    <div class="inputDiv">
      <div class="inputLable">
        <div class="row">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path
              d="M184.615-200Q157-200 138.5-218.5 120-237 120-264.615v-430.77Q120-723 138.5-741.5 157-760 184.615-760h590.77Q803-760 821.5-741.5 840-723 840-695.385v430.77Q840-237 821.5-218.5 803-200 775.385-200h-590.77ZM480-475.384 160-684.615v420q0 10.769 6.923 17.692T184.615-240h590.77q10.769 0 17.692-6.923T800-264.615v-420L480-475.384ZM480-520l307.692-200H172.308L480-520ZM160-684.615V-720v455.385q0 10.769 6.923 17.692T184.615-240H160v-444.615Z"
            />
          </svg>
          <span class="text">
            {{ text.title }}
          </span>
        </div>

        <span>
          {{ text.label }}
        </span>
      </div>
      <div class="row">
        <input
          [(ngModel)]="email"
          id="input"
          formControlName="email"
          type="text"
          placeholder="{{ text.email }}"
        />
        <img src="../../../assets/svg/pencil.svg" (click)="focus()" />
      </div>
    </div>
  </form>
  <button [disabled]="!(email !='' && email.includes('@') && email.includes('.'))" (click)="save()">{{ text.save }}</button>
</div>
