import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-main-side-bar',
  templateUrl: './main-side-bar.component.html',
  styleUrls: ['./main-side-bar.component.scss'],
})
export class MainSideBarComponent implements OnInit {
  destroy$ = new Subject();
  user = false;
  constructor(
    private app: AppService,
    private router: Router,
    private authService: AuthService
  ) {}
  ngOnInit(): void {
    this.authService
      .getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && res.maintz != '' && res.maintz != null) this.user = true;
      });
  }
  nav(
    type:
      | 'logout'
      | 'personal'
      | 'setting'
      | 'home'
      | 'asset'
      | 'forms'
      | 'payments'
  ) {
    switch (type) {
      case 'asset':
        this.router.navigate(['my-asset']);
        break;
      case 'forms':
        this.router.navigate(['dashboard'], {
          queryParams: {
            div: 'toggle',
            type: 2,
          },
        });
        break;
      case 'home':
        this.router.navigate(['dashboard']);
        break;
      case 'setting':
        this.router.navigate(['setting']);
        break;
      case 'logout':
        this.authService.logout();
        break;
      case 'personal':
        this.router.navigate(['personal']);
        break;
      case 'payments':
        this.router.navigate(['my-payments']);
        break;
      default:
        break;
    }
  }
}
