<div class="wrapper">
  <div class="titleDiv">
    <span class="spantitle">{{ text.title }}</span>
    <span class="spantitle title">{{ text.mini }}</span>
  </div>
  <div  class="codeInput">
    <span class="inputcodetext">{{ text.inputcode }}</span>
    <code-input
      class="codeComp"
      [initialFocusField]="0"
      [codeLength]="6"
      (codeCompleted)="onCodeCompleted($event)"
    >
    </code-input>
    <span>{{error}}</span>
  </div>
</div>
