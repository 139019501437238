import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { PaymentService } from '../payment.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';

@Component({
  selector: 'app-payment-iframe',
  templateUrl: './payment-iframe.component.html',
  styleUrls: ['./payment-iframe.component.scss'],
})
export class PaymentIFrameComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  url: string = '';
  currentUrl: string;
  displayTime = new BehaviorSubject<string>('10:00');
  countUpInterval: any;
  timeSpent: number;
  dataForPopup = { id: 'payTomeOut', data: [''] };
  fromSaveCards = false;
  isGuest = false;
  constructor(
    public paymentService: PaymentService,
    public dialog: MatDialog,
    public router: Router,
    private authService: AuthService,
    private loading: LoadingService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.isGuest = !this.authService.isLoggedIn();
    this.paymentService.urlCG
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        // this.url = this.sanitizer.bypassSecurityTrustResourceUrl(res);
        this.url = res;
      });
    this.countDowntimer(10 * 60);
  }
  countDowntimer(sec) {
    if (!sec) {
      this.displayTime.next('UNLIMITED');
      return;
    }
    const milis = sec * 1000;
    const endDate = new Date().getTime() + milis;
    const x = setInterval(() => {
      // Get today's date and time
      const now = Date.now();
      // Find the distance between now and the count down date
      const distance = endDate - now;
      let minutes = Math.floor(
        (distance % (1000 * 60 * 60)) / (1000 * 60)
      ).toString();
      let seconds = Math.floor((distance % (1000 * 60)) / 1000).toString();
      minutes = minutes.toString().length === 1 ? '0' + minutes : minutes;
      seconds = seconds.toString().length === 1 ? '0' + seconds : seconds;
      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        this.timeOver();
        this.displayTime.next('TIME OVER');
        this.timeSpent = sec;
        this.save(true);
      } else {
        this.displayTime.next(minutes + ':' + seconds);
        this.timeSpent = Math.floor((milis - distance) / 1000);
      }
    }, 1000);
  }
  countUpTimer() {
    const startTime = Math.floor(Date.now() / 1000); // Get the starting time (right now) in seconds
    localStorage.setItem('startTime', startTime?.toString()); // Store it if I want to restart the timer on the next page
    this.countUpInterval = setInterval(
      () => this.startTimeCounter(startTime),
      500
    ); // set an interval to update the timer
  }
  startTimeCounter(startTime) {
    const now = Math.floor(Date.now() / 1000); // get the time now
    const diff = now - startTime; // diff in seconds between now and start
    let m = Math.floor(diff / 60); // get minutes value (quotient of diff)
    let s = Math.floor(diff % 60); // get seconds value (remainder of diff)
    m = this.checkTime(m); // add a leading zero if it's single digit
    s = this.checkTime(s); // add a leading zero if it's single digit
    this.displayTime.next(m + ':' + s); // update the element where the timer will appear
    this.timeSpent = diff;
  }
  checkTime(i) {
    if (i < 10) {
      i = '0' + i;
    } // add zero in front of numbers < 10
    return i;
  }
  timeOver() {}
  async save(complete) {
    document.getElementById('creditGuardIframe').remove();
    // title: 'נגמר הזמן לתשלום',
    // desc: 'דף התשלום שלנו מאובטח, תוכלו לבצע את התשלום מחדש',
  }
  uploadDone() {
    var iframe = document?.querySelector(
      '#creditGuardIframe'
    ) as HTMLIFrameElement;
    if (iframe.contentDocument)
      this.currentUrl = iframe.contentDocument.location.href;
    if (this.currentUrl.includes('/main')) {
      document.getElementById('creditGuardIframe').remove();
      this.router.navigate(['/failureLandingUrl']);
    } else if (
      this.currentUrl != 'about:blank' &&
      this.currentUrl != null &&
      this.currentUrl != '' &&
      !this.currentUrl.includes('loadingOnly')
    ) {
      document.getElementById('creditGuardIframe').remove();
      this.checkUniqIdAndToken(this.currentUrl);
      if (this.currentUrl.includes('successLandingUrl')) {
        this.router.navigate(['/successLandingUrl']);
      } else if (this.currentUrl.includes('failureLandingUrl')) {
        this.paymentService.urlCG.next('failureLandingUrl');
        this.router.navigate(['/failureLandingUrl']);
      }
      this.paymentService.afterPayment();
    }
  }
  checkUniqIdAndToken(url: string) {
    const res = this.paymentService.paymentResponse$.value;
    if (res != null) {
      let params = new URL(url).searchParams;
      let txId = params.get('txId');
      if (res.paymentIdentifiers.uniqueID == txId) {
        return true;
      } else return false;
    }
    return false;
  }
  saveCard(toSave: boolean) {
    // this.paymentService.saveCard = toSave;
  }
}
