import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { MuniService } from '../services/muni.service';
import { AuthService } from '../services/auth.service';
import { UserData } from '../common/models/auth.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss'],
})
export class SettingComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  text = {
    title: 'הגדרות חשבון',
    goPersonal: 'לאיזור האישי שלך',
  };
  user: UserData;
  isHaveUser: boolean= false;
 
  constructor(
    private authService: AuthService,
    private muni: MuniService,
    private router: Router
  ) {}
  
  ngOnInit(): void {
    this.authService
      .getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && res.firstName != '') {
          this.user = res;
          
          this.checkIfUserExist(this.user.maintz)
        }
      });

      
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  nav(){
    this.router.navigate(['personal'])
  }
  async checkIfUserExist(Maintz: string) {
    
    await this.authService.checkIfUserExist(Maintz).then((res) => {
      
      if (res.body.rc != 100)
      {
        this.isHaveUser=true;
      }
      else {
        this.isHaveUser= false;
      }
    },error=>{
      
    }
    );
  }
  
}