<div id="iframeDiv" class="change-flex iframeDiv">
  <div class="d-flex flex-column height">
    <!-- <div *ngIf="!fromSaveCards && !isGuest" class="d-flex flex-row mr-20"> -->
      <!-- <mat-checkbox -->
        <!-- #checkAll -->
        <!-- class="check-all" -->
        <!-- (change)="saveCard($event.checked)" -->
      <!-- > -->
      <!-- </mat-checkbox> -->
      <!-- <p class="mr-1">שמרו את הכרטיס לתשלום הבא</p> -->
    <!-- </div> -->
    <iframe
      id="creditGuardIframe"
      class="flex-1"
      [src]="url | safe"
      (load)="uploadDone()"
    >
    </iframe>
    <div id="timer" class="flex-1 d-flex flex-row">
      <div class="flex-1 time-box">
        <span>
          {{ displayTime | async }}
        </span>
      </div>
    </div>
  </div>
</div>
