import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { LoginComponent } from 'src/app/login/login.component';
import { AuthService } from 'src/app/services/auth.service';
import { MuniService } from 'src/app/services/muni.service';
import { LayoutService } from '../layout.service';
import { UserMenuComponent } from 'src/app/dashboard/header/user-menu/user-menu.component';
import { CallToLoginComponent } from '../call-to-login/call-to-login.component';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  text = {};
  name = '';
  logo = '';
  openedSidebar = false;
  isLoggedIn = false;
  mobile = false;
  needCallToLogin = ['changePayments'];
  callLogin = false;
  constructor(
    private muniService: MuniService,
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private layoutService: LayoutService
  ) {
    this.logo = muniService.getLogo;
  }
  ngOnInit(): void {
    this.muniService.muniLogo$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res != '') this.logo = res;
      });
    this.authService
      .getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && res.maintz && res.maintz != '') {
          this.isLoggedIn = true;
          this.name = res.firstName + ' ' + res.lastName;
        } else {
          this.isLoggedIn = false;
          this.name = 'התחברות';
          if (window.screen.width > 850)
            this.checkIfNeedCall(window.location.href);
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  openDialogLogin(
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ): void {
    if (!this.authService.isLoggedIn())
      this.dialog.open(LoginComponent, {
        width: '490px',
        height: '550px',
        enterAnimationDuration,
        exitAnimationDuration,
      });
    else
      this.dialog.open(UserMenuComponent, {
        position: {
          top: '90px',
          left: '250px',
        },
        autoFocus: false,
      });
  }
  openDialogCallLogin() {
    this.callLogin = true;
    setTimeout(() => {
      let div = document.getElementById('callLogin');
      if (div) {
        div.style.transition = 'all 1s ease';
        div.style.opacity = '1';
        div.style.transform = 'translate(0, 270px)';
      }
    }, 100);
  }
  goMain() {
    this.router.navigate(['']);
  }
  openSidebar() {
    this.layoutService.openMobileSideBar$.next(true);
  }
  close(event) {
    if (event.target.id != 'menu')
      this.layoutService.openMobileSideBar$.next(false);
  }
  checkIfNeedCall(url: string) {
    if (this.needCallToLogin.findIndex((f) => url.includes(f)) != -1) {
      this.openDialogCallLogin();
    }
  }
}
