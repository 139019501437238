import { Component } from '@angular/core';

@Component({
  selector: 'app-call-to-login',
  templateUrl: './call-to-login.component.html',
  styleUrls: ['./call-to-login.component.scss'],
})
export class CallToLoginComponent {
  text = {
    title: 'היי, אתם תושבי הרשות שלנו?',
  };
  text1 ={
    title1: 'שווה להתחבר אלינו!'
  }
  text2 ={
    title2: 'ככה נוכל להשלים לכם את המידע שיש אצלינו ואתם לא תעבדו קשה :)'
  }
  text3 ={
    title3: 'הבנתי,תודה. להמשיך ללא הזדהות'
}
}

