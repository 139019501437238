import { Component, OnDestroy, OnInit } from '@angular/core';
import { selectConfig } from '../common/elements/my-select/my-select.model';
import { DebtsBalanceService } from './debts-balance.service';
import { Subject, takeUntil } from 'rxjs';
import { Charge } from './debt.model';
import { LoadingService } from '../common/elements/loader/loading.service';
import { IListItem } from '../forms/elementForm/form-input/form-input.model';

@Component({
  selector: 'app-debt-balance',
  templateUrl: './debt-balance.component.html',
  styleUrls: ['./debt-balance.component.scss'],
})
export class DebtBalanceComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  yearsList = [];
  asstsIDsList = [];
  typesList = [];
  level = 1;
  assetSelectConfig: selectConfig = {
    lable: 'מספר נכס',
    list: [],
    copyList: [],
    bigSelect: false,
    shadow: true,
    textBlue: true
  };
  typeSelectConfig: selectConfig = {
    lable: 'סוג חיוב',
    list: [],
    copyList: [],
    bigSelect: false,
    shadow: true,
    textBlue: true
  };
  yearSelectConfig: selectConfig = {
    lable: 'עבור שנה',
    list: this.yearsList,
    copyList: this.yearsList,
    bigSelect: false,
    shadow: true,
    textBlue: true
  };
  assetSelected: any;
  yearSelected: any;
  typeSelected: any;
  chargesList: Charge[] = [];
  chargesListCopy: Charge[] = [];
  mobile = false;
  haveData = false;
  text = {
    title: 'תשלום יתרת חוב',
  };
  constructor(
    private debtsService: DebtsBalanceService,
    private loading: LoadingService
  ) {}
  ngOnInit(): void {
    if (window.screen.width <= 950) {
      this.mobile = true;
    }
    this.loading.show();
    this.debtsService.level$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.level = res;
    });
    this.debtsService
      .getCharges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.charges.length > 0) {
            res.charges.map((element) => {
              element.sumToPay = 0;
            });
            this.chargesList = res.charges;
            this.chargesListCopy = res.charges;
            this.setListSelects(res);
            this.haveData = true;
            this.loading.hide();
          } else {
            this.haveData = false;
            this.loading.hide();
          }
        },
        (error) => {
          this.loading.hide();
        }
      );
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
    this.level = 1;
    this.debtsService.level$.next(1);
  }
  setListSelects(res: any) {
    this.yearsList = res.charges
      .map((x) => x.year)
      .filter((value, index, a) => a.indexOf(value) == index);
    this.asstsIDsList = res.charges
      .map((x) => x.assetId)
      .filter((value, index, a) => a.indexOf(value) == index);
    this.typesList = res.charges
      .map((x) => x.chargeType)
      .filter((value, index, a) => a.indexOf(value) == index);
    this.yearSelectConfig.list = this.convertChargeToISelectItem(
      this.yearsList
    );
    this.yearSelectConfig.copyList = this.convertChargeToISelectItem(
      this.yearsList
    );
    this.assetSelectConfig.list = this.convertChargeToISelectItem(
      this.asstsIDsList
    );
    this.assetSelectConfig.copyList = this.convertChargeToISelectItem(
      this.asstsIDsList
    );
    this.typeSelectConfig.list = this.convertChargeToISelectItem(
      this.typesList
    );
    this.typeSelectConfig.copyList = this.convertChargeToISelectItem(
      this.typesList
    );
  }
  select(value, name: 'asset' | 'year' | 'type' | 'mobile') {
    switch (name) {
      case 'asset':
        this.assetSelected = value;
        break;
      case 'type':
        this.typeSelected = value;
        break;
      case 'year':
        this.yearSelected = value;
        break;
      case 'mobile':
        this.assetSelected = value.asset;
        this.yearSelected = value.year;
        this.typeSelected = value.type;
        break;
      default:
        break;
    }
    this.filter();
  }
  filter() {
    let tempList: Charge[] = [];
    if (this.assetSelected && this.assetSelected != -1) {
      tempList = this.chargesListCopy.filter(
        (c) => c.assetId == this.assetSelected
      );
    } else {
      tempList = this.chargesListCopy;
    }
    if (this.typeSelected && this.typeSelected != -1) {
      tempList = tempList.filter((c) => c.chargeType == this.typeSelected);
    }
    if (this.yearSelected && this.yearSelected != -1) {
      tempList = tempList.filter((c) => c.year == this.yearSelected);
    }
    this.chargesList = tempList;
  }
  goPrev(){
    this.level = 1
  }
  checkCurrentFilters() {}
  convertChargeToISelectItem(list: any[]): IListItem[] {
    let newList: IListItem[] = [];
    newList.push({ label: 'הכל', value: -1 });
    list.map((l) => {
      newList.push({ label: l, value: l });
    });
    return newList;
  }
}
