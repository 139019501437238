<div class="grid-container">
  <div class="itemWrapper" *ngFor="let card of cards; let i = index">
    <div class="headWrapper">
      <div
        (mouseout)="out(i)"
        (mouseover)="hover(i)"
        id="headEdit{{ i }}"
        class="headEdit"
        [ngClass]="{
        forms: type == 2,
      }"
      >
        <!-- *ngIf="!card.icon && card.icon == ''" -->
        <img
        *ngIf="card.icon && card.icon!=''"
          id="img{{ i }}"
          src="../../../assets/svg/cardsIcons/{{ card.icon }}.svg"
        />
        <img
        *ngIf="!card.icon || card.icon==''"
          id="img{{ i }}"
          src="../../../assets/svg/cardsIcons/resident.svg"
        />
        <!-- <img
          *ngIf="card.icon && card.icon != ''"
          id="img{{ i }}"
          src="../../../assets/svg/cardsIcons/{{ card.icon }}.svg"
        /> -->
      </div>
    </div>

    <div
      (click)="choose(i)"
      (mouseout)="out(i)"
      (mouseover)="hover(i)"
      id="item"
      class="item"
      [ngClass]="{
        formshover: type == 2,
    }"
    >
      <span class="title">{{ card.serviceName }}</span>
    </div>
  </div>
</div>
