import { Component, Input, OnInit } from '@angular/core';
import { selectConfig } from '../common/elements/my-select/my-select.model';
import { DebtsBalanceService } from '../debt-balance/debts-balance.service';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { IListItem } from '../forms/elementForm/form-input/form-input.model';
import { AuthService } from '../services/auth.service';
import { FormsFunctionsService } from '../forms/allForms/forms-functions.service';
import {
  CollectionAsset,
  GetAssetsByFamilyMainIDReq,
} from '../forms/allForms/functions.model';
import { MuniService } from '../services/muni.service';
import { propertyTax } from '../personal-details/asset/asset.model';
import { LoadingService } from '../common/elements/loader/loading.service';
import { AssetService } from '../services/asset.service';
import { MatDialog } from '@angular/material/dialog';
import { Route, Router } from '@angular/router';
import { MessageDialogComponent } from '../forms/popups/message-dialog/message-dialog.component';

@Component({
  selector: 'app-my-asset',
  templateUrl: './my-asset.component.html',
  styleUrls: ['./my-asset.component.scss'],
})
export class MyAssetComponent implements OnInit {
  destroy$ = new Subject();
  text = {
    title: 'הנכס שלי',
  };
  tabs = [
    {
      label: 'פרטי הנכס',
      panelOpened: false,
    },
    {
      panelOpened: false,
      label: 'הסדר תשלום',
    },
    {
      panelOpened: false,
      label: 'כתובות',
    },
    {
      panelOpened: false,
      label: 'תעריפי ארנונה',
    },
    {
      panelOpened: false,
      label: 'הנחות ארנונה',
    },
    {
      panelOpened: false,
      label: 'שובר למייל',
    },
  ];
  assetSelectConfig: selectConfig = {
    lable: 'מספר נכס',
    list: [],
    copyList: [],
    bigSelect: false,
    shadow: true,
  };
  data: CollectionAsset[];
  mobile = window.screen.width <= 850 ? true : false;
  constructor(
    private assetService: AssetService,
    private loading: LoadingService,
    private dialog: MatDialog,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.loading.show();
    this.assetService.GetAssetsNumbersServer();
    this.assetService.GetPropertyTaxData();
    this.assetService.assetsNumbers$
      .pipe(takeUntil(this.destroy$))
      .subscribe((assetsNumbers) => {
        if (assetsNumbers && assetsNumbers.length > 0) {
          this.assetSelectConfig.list = assetsNumbers;
          this.assetSelectConfig.copyList = assetsNumbers;
          this.dialog.closeAll();
        } else if(assetsNumbers.length==0) {
          let ref = this.dialog.open(MessageDialogComponent, {
            data: {
              title: ' לא נמצאו נכסים',
              body: ['למשתמש זה לא קיימים נכסים במערכת הגביה של הרשות'],
              btn: 'מעבר לעמוד הראשי',
            },
          });
          ref.afterClosed().subscribe((res) => {
            this.router.navigate(['']);
          });
        }
        this.loading.hide();
      });
    this.assetService.PropertyTaxData$.pipe(takeUntil(this.destroy$)).subscribe(
      (propertyTax: any) => {
        if (propertyTax && propertyTax.length > 0) {
          this.assetSelectConfig.selected = propertyTax[0].assetNum;
          this.assetService.PropertyTaxDataObject$.next(propertyTax[0]);
        }
      },
      (error) => {}
    );
  }
  select(event) {
    this.assetSelectConfig.selected = event;
    let obj = this.assetService.PropertyTaxData$.value.find(
      (f) => f.assetNum == event
    );
    if (obj) this.assetService.PropertyTaxDataObject$.next(obj);
  }
}
