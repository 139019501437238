<div #pay id="pay">
  <div class="wrapper">
    <span class="title">{{ text.title }}</span>
    <mat-tab-group *ngIf="!mobile" animationDuration="500ms">
      <mat-tab
        [labelClass]="color"
        *ngFor="let tab of tabs; let i = index"
        label="{{ tab.label }}"
      >
        <div class="bodyTab">
          <app-payment-site
            *ngIf="i == 0"
            [paymentSite]="paymentSite"
          ></app-payment-site>
          <div class="soondiv" *ngIf="i == 1">
            <span class="soon">בקרוב!</span>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div *ngIf="mobile" class="content">
    <div class="header">
      <div class="data">
        <mat-expansion-panel
          id="panel{{ i }}"
          *ngFor="let line of tabs; let i = index"
          hideToggle
          [expanded]="line.panelOpened"
          [ngClass]="{
            lastchild: i == tabs.length-1,
          }"
        >
          <mat-expansion-panel-header
            (click)="openClose(i)"
            [ngClass]="{
              borderBottom: line.panelOpened
            }"
          >
            <span>{{ line.label }}</span>
          </mat-expansion-panel-header>
          <div *ngIf="i == 0">
            <div *ngFor="let line of paymentSite">
              <app-payment-line-mobile [line]="line"></app-payment-line-mobile>
            </div>
          </div>
          <div *ngIf="i == 1">
            <span>בקרוב!</span>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </div>
</div>
