<div class="desktop wrapper">
  <div
    class="rigthSection"
    [ngClass]="{
      fullMargin: isLoggedIn
    }"
  >
    <img
      (click)="goMain()"
      class="logomoni"
      src="../../../assets/svg/muniLogo.svg"
    />
  </div>
  <div class="leftSection">
    <!-- <span (click)="openDialogLogin('0ms', '0ms')"> {{ name }}</span> -->
    <div *ngIf="!mobile">
      <span
        *ngIf="isLoggedIn"
        id="loginClick"
        class="login"
        (mouseenter)="openDialogLogin('0ms', '0ms')"
        >שלום, {{ name }}</span
      >
      <button
        class="loginbtn"
        *ngIf="!isLoggedIn"
        (click)="openDialogLogin('0ms', '0ms')"
      >
        <!-- <img src="../../../assets/svg/userLogin.svg" alt="" /> -->
        {{ name }}
      </button>
    </div>
    <div (click)="goMain()" class="logoDiv">
      <img src="{{ logo }}" />
    </div>
  </div>
</div>
<div class="mobile mobileWrapper" (click)="close($event)">
  <div class="rigthSection">
    <img
      id="menu"
      class="menu"
      src="../../../assets/svg/menu.svg "
      (click)="openSidebar()"
    />
    <span
      class="nameMobile"
      [ngClass]="{
        underline: !isLoggedIn
      }"
      >
      {{ name }}</span
    >
  </div>
  <div class="leftSection">
    <div (click)="goMain()" class="logoDiv">
      <img src="{{ logo }}" />
    </div>
  </div>
</div>

<app-call-to-login
  id="callLogin"
  #callLogin
  class="callLogin"
></app-call-to-login>
