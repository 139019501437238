import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  PaymentRes,
  PaymentsLogRequest,
  SavedCreditCard,
  basket,
  muniParams,
  paymentIdentifiers,
} from './paymentPage.model';
import { AppService } from 'src/app/services/app.service';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { Router } from '@angular/router';
import { MuniService } from 'src/app/services/muni.service';
import { VoucherService } from 'src/app/services/voucher.service';
import { GetOperatingSysService } from 'src/app/services/get-operating-sys.service';
import { DOCUMENT } from '@angular/common';
const PAYMENTCONTROLLER = 'Payment';
@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  public basketItems$ = new BehaviorSubject<basket[]>([]);
  public sum$ = new BehaviorSubject<number>(0);
  public CreditCards$ = new BehaviorSubject<SavedCreditCard[]>([]);
  isSaveCard$ = new BehaviorSubject<{ card: SavedCreditCard; is: boolean }>({
    card: {},
    is: false,
  });
  public payIframe$ = new BehaviorSubject<boolean>(false);
  public paymentResponse$ = new BehaviorSubject<PaymentRes | null>(null);
  public urlCG = new BehaviorSubject<string | null>('');
  public EmailForSend$ = new BehaviorSubject<string>('');
  public iframeFromDebit = false;
  public assetIDForDebit ;
  myAngularxQrCode;
  bitLink = '';
  userAgent: any;
  constructor(
    private appService: AppService,
    private loading: LoadingService,
    private router: Router,
    private muniService: MuniService,
    private voucherService: VoucherService,
    private OSservice: GetOperatingSysService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }
  getCreditCards() {
    return this.appService.post('User/getCreditCards').subscribe(
      (res) => {
        if (res) this.CreditCards$.next(res.body);
      },
      (error) => {}
    );
  }
  getMyPayments() {
    return this.appService.post(PAYMENTCONTROLLER +'/GetMyPayments')
  }
  public getUlrForPayment(body: PaymentsLogRequest) {
    let url = '/CreatePayment/';
    this.appService.post(PAYMENTCONTROLLER + url, body).subscribe((res) => {
      this.checkAmout(res.body);
      this.loading.hide();
      switch (res.body.status) {
        case 0:
          this.startPayment(res.body);
          break;
        case 1:
          this.startPayment(res.body);
          break;
        case 2:
          // title: 'נמצא תשלום על פריט זה',
          // desc: 'לא ניתן לבצע תשלום כפול'
          break;
        case 3:
          this.payIframe$.next(true);
          this.paymentResponse$.next(res.body);
          this.urlCG.next('failureLandingUrl');
          this.router.navigate(['/failureLandingUrl']);
          break;
        case 4:
          // title: 'התשלום נמצא בתהליך אצל חברת הסליקה',
          // desc: 'תוכל להמתין כ10 דקות ולנסות שנית',
          break;
        case 5:
          // title: 'התשלום נמצא בתהליך אצל נציג אנושי מחברת הסליקה',
          // desc: 'תוכל להמתין כשעתיים ולנסות שנית',
          break;
        case 6:
          this.payIframe$.next(false);
          this.paymentResponse$.next(res.body);
          this.urlCG.next('successLandingUrl');
          this.router.navigate(['/successLandingUrl']);
          break;
        case 7:
          //חסר פרטים
          // desc: 'לצערינו נתקלנו בתקלה בעת התשלום. נשמח שתנסה שוב כעת',
          break;
        case 10:
          let data = JSON.parse(res.body.err);
          let note = [];
          data.forEach((element) => {
            if (element.ErrorType == 10) {
              note.push(' אזל המלאי לרכישה של' + element.ServiceName);
            } else if (element.ErrorType == 11) {
              note.push(
                'קיימת מגבלת רכישה עבור ' +
                  element.ServiceName +
                  ', של ' +
                  element.Amount +
                  ' יחידות '
              );
            } else if (element.ErrorType == 12) {
              note.push(
                'בשירות ' +
                  element.ServiceName +
                  ' נותרו ' +
                  element.Amount +
                  ' יחידות לרכישה. הכמות שנבחרה חורגת מהמלאי הקיים '
              );
            }
          });
          // title: 'התשלום לא בוצע ',
          // desc: '  ',
          break;
      }
    }, error=>{
    });
  }
  public afterPayment() {
    let url = '/AfterPayment';
    let body: paymentIdentifiers = {
      actType: this.paymentResponse$.value.paymentIdentifiers.actType,
      muniCode: this.muniService.getMuniCode,
      paymentInitiationId:
        this.paymentResponse$.value.paymentIdentifiers.paymentInitiationId,
      uniqueID: this.paymentResponse$.value.paymentIdentifiers.uniqueID,
      transactionID:
        this.paymentResponse$.value.paymentIdentifiers.transactionID,
      email: this.paymentResponse$.value.paymentIdentifiers.email,
    };
    this.appService
      .post(PAYMENTCONTROLLER + url, body)
      .subscribe((res) => {
        this.loading.hide();
        if (res.body.paymentIdentifiers.email) {
          this.EmailForSend$.next(res.body.paymentIdentifiers.email);
        }
        switch (res.body.status) {
          case 0:
            if (this.paymentResponse$.value.paymentIdentifiers.actType == 2)
              this.router.navigate(['/failureLandingUrl']);
            break;
          case 2:
            if (this.paymentResponse$.value.paymentIdentifiers.actType == 2) {
              this.EmailForSend$.next(res.body.paymentIdentifiers.email);
              this.router.navigate(['/successLandingUrl']);
            }
            break;
          case 3:
            // title: 'באג...',
            // desc: 'לצערינו נתקלנו בתקלה בעת התשלום. צור קשר עם הרשות להמשך התהליך',
            break;
          case 4:
            // title: 'התשלום נמצא בתהליך אצל חברת הסליקה',
            // desc: 'תוכל להמתין כ10 דקות ולנסות שנית',
            break;
          case 5:
            // title: 'התשלום נמצא בתהליך אצל נציג אנושי מחברת הסליקה',
            // desc: 'תוכל להמתין כשעתיים ולנסות שנית',
            break;
          case 6:
            // if (this.paymentResponse$.value.paymentIdentifiers.actType == 2) {
            this.payIframe$.next(false);
            this.paymentResponse$.next(res.body);
            this.urlCG.next('successLandingUrl');
            this.router.navigate(['/successLandingUrl']);
            // }
            break;
          case 13:
            // if (this.paymentResponse$.value.paymentIdentifiers.actType == 2)
              this.router.navigate(['failureLandingUrl'], {
                queryParams: {
                  status: 13,
                },
              });
            break;
            break;
        }
        this.payIframe$.next(false);
        // this.appService.Guest.next({ email: '', guestID: 0 });
        this.voucherService.mainIdPaymentOneVoucher = 0;
        this.urlCG.next('');
        // if (this.saveCard == true) this.accountService.getCreditCards();
      });
  }

  private startPayment(res: PaymentRes) {
    if (res.paymentIdentifiers.actType != 2) {
      this.payIframe$.next(true);
      this.paymentResponse$.next(res);
      this.urlCG.next(res.generalUrl);
      this.EmailForSend$.next(res.paymentIdentifiers.email);
    } else {
      this.EmailForSend$.next(res.paymentIdentifiers.email);
      this.myAngularxQrCode = res?.androidUrl;
      this.bitLink = res?.generalUrl;
      if (res?.rc != -1 && res?.rc != -2) {
        var os = this.OSservice.getOS();
        if (os === 4 && res?.androidUrl) {
          let app = {
            launchApp: function () {
              //window.location.replace("bundlename://linkname"); //which page to open(now from mobile, check its authorization)
              setTimeout(this.openWebApp, 500);
            },
            openWebApp: function () {
              window.location.href = res?.androidUrl;
            },
          };
          app.launchApp();
        } else if (os === 5 && res?.iosUrl) {
          let app = {
            launchApp: function () {
              setTimeout(function () {
                window.location.href = res?.iosUrl;
              }, 25);
              //window.location.href = "bundlename://linkname"; //which page to open(now from mobile, check its authorization)
            },
            openWebApp: function () {
              window.location.href = res?.iosUrl;
            },
          };
          app.launchApp();
        } else window.location.href = this.bitLink;
        // window.open(this.bitLink, '_blank');
      }
    }
  }

  private checkAmout(res) {
    if (this.sum$.value != res.paymentAmount) {
      this.sum$.next(res.paymentAmount);
    }
  }
  private detectBrowser() {
     this.userAgent = window.navigator.userAgent.toLowerCase();
    // if (userAgent.includes('chrome')) {
    //   console.log('User is using Chrome');
    // } else if (userAgent.includes('firefox')) {
    //   console.log('User is using Firefox');
    // } else if (userAgent.includes('safari')) {
    //   console.log('User is using Safari');
    // } else if (userAgent.includes('edge')) {
    //   console.log('User is using Edge');
    // } else if (userAgent.includes('opera') || userAgent.includes('opr')) {
    //   console.log('User is using Opera');
    // } else {
    //   console.log('User is using an unknown browser');
    // }
  }
}
