import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MyDateService } from './my-date.service';
import { InputConfig } from '../form-input/form-input.model';
import { emitObj } from '../control.model';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'my-date',
  templateUrl: './my-date.component.html',
  styleUrls: ['./my-date.component.scss'],
})
export class MyDateComponent implements OnInit, OnDestroy {
  @Input() config: InputConfig;
  @Input() day: number;
  @Input() month: number;
  @Input() year: number;
  @Input() errorRes: string;
  @Input() errorAnother: string;
  @Output() haveFullDate = new EventEmitter<emitObj>();
  @Output() tabClick = new EventEmitter<void>();
  destroy$ = new Subject();
  text = {
    fromDate: 'מתאריך',
    toDate: 'עד תאריך',
  };
  timeout: any = null;
  fullDate: Date;
  control = new FormControl();
  error = {
    error: '',
    is: 'הוא',
    must: 'חובה',
  };
  constructor(private myDateService: MyDateService) {}
  ngOnInit(): void {
    this.myDateService.clear$.subscribe((res) => {
      this.day = null;
      this.year = null;
      this.month = null;
      this.fullDate = null;
    });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  setDate(value: string, needSet: boolean): void {
    if (value) {
      if (needSet) {
        const dateObject = moment.utc(value, 'MM/DD/YYYY').toDate();
        this.day = dateObject.getDate();
        this.month = dateObject.getMonth() + 1;
        this.year = dateObject.getFullYear();
        this.fullDate = dateObject;
      }
      if (
        this.config?.validation != 'none' ||
        (this.errorRes && this.errorRes != '')
      ) {
        this.validations(value, this.config.validation);
      }
      let em: emitObj = {
        errors: this.control.errors,
        name: this.config.formControlName,
        value: needSet
          ?  moment(this.fullDate).format('DD/MM/YYYY')
          : value,
        isDate: true,
      };
      this.haveFullDate.emit(em);
    }
  }
  onKeySearch(event: any, index) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.checkLengthValidation(event.target.value, index);
      }
    }, 50);
  }
  checkLengthValidation(value, index) {
    switch (index) {
      case 1:
        if (!value || value == '') {
        } else if (value.toString().length == 2) {
          if (+value >= 1 && +value <= 31) {
            this.executeListing(value, index);
            this.control.setErrors({ dateAfterToday: false });
          } else {
            this.day = null;
            this.month = null;
            this.year = null;
            this.control.setErrors({ dateAfterToday: true });
          }
        }
        break;
      case 2:
        if (value.toString().length == 2) {
          if (+value >= 1 && +value <= 12) {
            this.executeListing(value, index);
            this.control.setErrors({ dateAfterToday: false });
          } else {
            this.month = null;
            this.year = null;
            this.control.setErrors({ dateAfterToday: true });
          }
        }
        break;
      case 3:
        if (value.toString().length == 4) {
          if (+value >= 1600 && +value <= 2100) {
            this.executeListing(value, index);
            this.control.setErrors({ dateAfterToday: false });
          } else {
            this.year = null;
            this.control.setErrors({ dateAfterToday: true });
          }
        }
        break;
      default:
        break;
    }
  }
  select(index: 1 | 2 | 3) {
    switch (index) {
      case 1:
        const s: HTMLInputElement = document.getElementById(
          'day' + this.config.name
        ) as HTMLInputElement;
        if (s.value) s.select();
        else s.focus();
        break;
      case 2:
        const a: HTMLInputElement = document.getElementById(
          'month' + this.config.name
        ) as HTMLInputElement;
        if (a.value) a.select();
        else if (this.day == null) this.select(1);
        else if (this.day != null) s.focus();
        break;
      case 3:
        const z: HTMLInputElement = document.getElementById(
          'year' + this.config.name
        ) as HTMLInputElement;
        if (z.value) z.select();
        else if (this.month == null) this.select(1);
        else if (this.month != null) s.focus();
        break;
      default:
        break;
    }
  }
  onTabClick(type: 1 | 2 | 3) {
    switch (type) {
      case 1:
        const a: HTMLInputElement = document.getElementById(
          'month' + this.config.name
        ) as HTMLInputElement;
        a.focus();
        break;
      case 2:
        const z: HTMLInputElement = document.getElementById(
          'year' + this.config.name
        ) as HTMLInputElement;
        z.focus();
        break;
      case 3:
        this.tabClick.emit();
        break;
      default:
        break;
    }
  }
  validations(value, typeValidation) {
    switch (typeValidation) {
      case 'dateAfterToday':
        if (value < new Date()) {
          this.control.setErrors({ dateAfterToday: true });
        } else {
          this.errorRes = '';
        }
        break;
      case 'none':
        this.errorRes = '';
        break;
    }
  }
  private executeListing(value: number, index: 1 | 2 | 3) {
    switch (index) {
      case 1:
        this.day = value;
        let s: HTMLInputElement = document.getElementById(
          'month' + this.config.name
        ) as HTMLInputElement;
        s.select();
        break;
      case 2:
        this.month = value;
        let a: HTMLInputElement = document.getElementById(
          'year' + this.config.name
        ) as HTMLInputElement;
        a.select();
        break;
      case 3:
        this.year = value;
        this.setDate(this.day + '/' + this.month + '/' + this.year, false);
        break;
      default:
        break;
    }
  }
}
