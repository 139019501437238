import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Subject } from 'rxjs';
import { Charge, panelDebts } from '../debt.model';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { selectConfig } from 'src/app/common/elements/my-select/my-select.model';
import { DebtsBalanceService } from '../debts-balance.service';

@Component({
  selector: 'app-debts-balance-mobile',
  templateUrl: './debts-balance-mobile.component.html',
  styleUrls: ['./debts-balance-mobile.component.scss'],
})
export class DebtsBalanceMobileComponent implements OnInit, OnChanges {
  @Input() data: Charge[];
  @Input() yearSelectConfig: selectConfig;
  @Input() typeSelectConfig: selectConfig;
  @Input() assetSelectConfig: selectConfig;
  @Input() assetSelected: any;
  @Input() typeSelected: any;
  @Input() yearSelected: any;
  @Output() filter = new EventEmitter<any>();
  @ViewChild(MatExpansionPanel) pannel?: MatExpansionPanel;
  destroy$ = new Subject();
  text = {
    asset: 'מספר נכס',
    date: 'תקופת שובר :',
    ribit: 'ריבית: ',
    topay: 'סה"כ לתשלום',
    titleFilter: 'סינון וחיפוש',
    update: 'עדכון',
    year: 'שנה: ',
  };
  objects: panelDebts[] = [];
  error = '';
  sum = 0;
  constructor(
    private loading: LoadingService,
    private debtsService: DebtsBalanceService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.data.firstChange && this.data.length > 0) {
      this.loading.hide();
      this.orgenaizeData(this.data);
    }
  }

  ngOnInit(): void {}
  orgenaizeData(res: Charge[]) {
    this.objects = [];
    res.map((ch) => {
      if (!this.objects.find((c) => c.assetNumber == ch.assetId)) {
        let object: panelDebts = {
          assetNumber: ch.assetId,
          assetAddress: ch.assetAddress,
          charge: [],
          sum: 0,
          opened: true,
          isToPay: false,
          sumToPay: 0,
        };
        let number = ch.assetId;
        res.map((r) => {
          if (r.assetId == number) {
            object.charge.push(r);
            object.sum += r.sum;
          }
        });
        this.objects.push(object);
      }
    });
    if (this.objects.length > 1) {
      this.objects.map((o) => (o.opened = false));
    }
  }
  close(index: number) {
    this.objects[index].opened = !this.objects[index].opened;
  }
  check(value, index: number, line: number) {
    if (value.checked) {
      this.objects[index].charge[line].isToPay = true;
      this.objects[index].sumToPay = this.objects[index].charge[line].sum;
      this.sum += this.objects[index].charge[line].sum;
    } else {
      this.objects[index].charge[line].isToPay = false;
      this.objects[index].sumToPay -= this.objects[index].charge[line].sum;
      this.sum -= this.objects[index].charge[line].sum;
    }
  }
  checkAsset(value, index: number) {
    if (value.checked) {
      this.objects[index].sumToPay = this.objects[index].sum;
      this.objects[index].isToPay = true;
      this.sum += this.objects[index].sum;
    } else {
      this.objects[index].sumToPay = 0;
      this.objects[index].isToPay = false;
      this.sum -= this.objects[index].sum;
    }
  }
  checkAll(event) {
    if (event.checked) {
      this.objects.map((d, i) => {
        d.sumToPay = d.sum;
        d.isToPay = true;
        this.sum += d.sum;
      });
    } else {
      this.objects.map((d) => {
        d.sumToPay = 0;
        d.isToPay = false;
      });
      this.sum = 0;
    }
  }
  next() {
    let dataForBasket = [];
    this.objects.map((element) => {
      if (element.isToPay)
        element.charge.map((ch) => {
          dataForBasket.push({
            chargeType: ch.chargeType,
            sum: ch.sum,
            assetId: ch.assetId,
            ChargeID: ch.chargeID,
            isInterest: ch.interest,
            yearOfProduct: ch.year.toString(),
          });
        });
      else {
        element.charge.map((line) => {
          if (line.isToPay) {
            dataForBasket.push({
              chargeType: line.chargeType,
              sum: line.sum,
              assetId: line.assetId,
              ChargeID: line.chargeID,
              isInterest: line.interest,
              yearOfProduct: line.year.toString(),
            });
          }
        });
      }
    });
    this.debtsService.setBasketItemsDebts(dataForBasket);
    this.debtsService.level$.next(2);
  }
  filterOpen() {
    let el = document.getElementById('filterPopup');
    if (el) {
      el.style.transform = 'translate(0, 500px)';
    }
  }
  select(value, type: 'asset' | 'year' | 'type') {
    switch (type) {
      case 'asset':
        this.assetSelected = value;
        break;
      case 'type':
        this.typeSelected = value;
        break;
      case 'year':
        this.yearSelected = value;
        break;
      default:
        break;
    }
  }
  filterEmit() {
    let body = {
      year: this.yearSelected,
      type: this.typeSelected,
      asset: this.assetSelected,
    };
    this.filter.emit(body);
    let el = document.getElementById('filterPopup');
    if (el) {
      el.style.transition = 'all 0.6s ease';
      el.style.transform = 'translate(0, -500px)';
    }
  }
}
