<div class="wrapper" *ngIf="!mobile">
  <div
    (click)="open(action)"
    class="cardImg"
    *ngFor="let action of actions; let i = index"
  >
    <div class="card">
      <img
        class="image"
        *ngIf="action?.commonActionImage"
        src="../../../assets/grayImg/{{ action.commonActionImage }}.png"
      />
      <span>{{ action.serviceName }}</span>
    </div>
    <div class="colors"></div>
  </div>
</div>
<div class="wrapper" *ngIf="mobile">
  <div class="cardImg mobileFlex" (swipe)="onSwipe($event, i)">
    <div class="card" *ngIf="index > 0">
      <!-- (click)="index = index - 1" -->
      <img
        class="image"
        *ngIf="actions[index - 1]?.commonActionImage"
        src="../../../assets/grayImg/{{
          actions[index - 1].commonActionImage
        }}.png"
      />
      <span>{{ actions[index - 1].serviceName }}</span>
    </div>
    <div class="card cardMobile">
      <img
        (click)="open(actions[index])"
        class="imageMobile"
        *ngIf="actions[index]?.commonActionImage"
        src="../../../assets/grayImg/{{ actions[index].commonActionImage }}.png"
      />
      <span>{{ actions[index].serviceName }}</span>
    </div>
    <div class="card" *ngIf="index + 1 < actions.length">
      <img
        class="image"
        *ngIf="actions[index + 1]?.commonActionImage"
        src="../../../assets/grayImg/{{
          actions[index + 1].commonActionImage
        }}.png"
      />
      <span>{{ actions[index + 1].serviceName }}</span>
    </div>
  </div>
</div>