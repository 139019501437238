import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { UserData } from 'src/app/common/models/auth.model';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  @Input() user: UserData;
  text = {
    sisma: 'דרכי התקשרות',
    phone: 'נייד',
    email: 'אימייל',
    clalim1: 'נשמח ליצור איתך קשר לעידכונים אודות הטפסים והפניות שלך.',
    clalim2: 'אל דאגה, מבטיחים לא לשגע!',
    after: 'איפה היית רוצה לקבל את ההתראות?',
    btn: 'עדכן דרכי התקשרות',
  };
  hide: boolean = true;
  hideNew: boolean = true;
  hideConfirm: boolean = true;
  form: FormGroup;
  typeSend = 1;
  constructor(private fb: UntypedFormBuilder) {}
  ngOnInit(): void {
    this.form = this.fb.group({
      phone: [],
      email: [],
    });
    if (this.user) {
      this.form.controls['email'].setValue(this.user.email);
      this.form.controls['phone'].setValue(this.user.phone);
    }
  }
  typeSendCheck(value, type: 'phone' | 'email') {
  }
  focus(type: 1 | 2) {
    switch (type) {
      case 1:
        document.getElementById('phone')?.focus();
        break;
      case 2:
        document.getElementById('email')?.focus();
        break;
      default:
        break;
    }
  }
}
