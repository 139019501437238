import { Component, OnDestroy, OnInit } from '@angular/core';
import { getSimple } from '../resident.model';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { MuniService } from 'src/app/services/muni.service';
import { ResisentConfirmService } from '../resisent-confirm.service';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { ResidentMasService } from '../../resident-mas/resident-mas.service';
import { TablePasswordResidentRes } from '../../resident-mas/mas.model';

@Component({
  selector: 'app-resident-popup',
  templateUrl: './resident-popup.component.html',
  styleUrls: ['./resident-popup.component.scss'],
})
export class ResidentPopupComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  text = {
    title: 'אימות קוד',
    mini: 'לצורך הפקת האישור - נדרש אימות של הקוד שנשלח אליך',
    inputcode: 'הקוד שקיבלת',
  };
  public workingWithTable = false;
  public error = '';
  constructor(
    private loading: LoadingService,
    private muni: MuniService,
    private residentService: ResisentConfirmService,
    private dialog: MatDialog,
    private residentMasService: ResidentMasService
  ) {}
  ngOnInit(): void {
    this.residentMasService.workWithTableParam$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && res.paramID != 0) {
          this.workingWithTable = res.active;
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  onCodeCompleted(event: any) {
    this.loading.show();
    if (!this.workingWithTable) {
      this.sendCodeSimple(event);
    } else {
      this.sendCodeMas(event);
    }
  }
  private sendCodeSimple(event) {
    let body: getSimple = {
      muniCode: this.muni.getMuniCode,
      password: event,
      tempTableLineID: this.residentService.TempTableLineID,
    };
    this.residentService
      .GetSimpleResidentConfirm(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.residentService.fileFromRes = res;
          this.loading.hide();
          this.dialog.closeAll();
        },
        (error) => {
          this.loading.hide();
          //bug
        }
      );
  }
  private sendCodeMas(event) {
    this.residentMasService
      .checkPasswordTableResident(this.residentMasService.idTemp, event)
      .subscribe((res) => {
        this.loading.hide();
        if (res && res.body && res.body.id > 0) {
          this.error = '';
          this.residentMasService.residentDetails = res.body;
          this.dialog.closeAll();
        } else {
          this.error = 'סיסמא לא תואמת. נסו שוב';
        }
      });
  }
}
