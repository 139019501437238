<div id="residentSimple" #residentSimple>
  <span class="title"
    >{{ text.title }}
    <span class="addnote">
      {{ text.addnote }}
    </span></span
  >
  <div class="form" id="formID">
    <app-basic-form
      [controlsList]="controls"
      [hideNext]="2"
      [hidePrev]="true"
      [hideNext]="2"
      [miniForm]="false"
      [autoSave]="true"
      [enterNext]="true"
      [btnText]=""
      [focusControl]="focusControl"
      (getDataInput)="getDataInput($event)"
      (submitFunction)="saveDataLevel($event)"
    ></app-basic-form>

    <div class="errorDiv">
      <span>{{ error }}</span>
    </div>

    <div class="nextdiv" *ngIf="!haveEntitlement">
      <button
        [disabled]="disabledfunc()"
        class="next"
        mat-button
        (click)="getCode()"
      >
        להפקת האישור
        <img src="../../../assets/png/leftPay.png" />
      </button>
    </div>
  </div>
  <div class="haveEntitlement" *ngIf="haveEntitlement">
    <div class="rigtn">
      <img src="../../../../assets/svg/residentSend.svg" />
      <div class="textSend" *ngIf="!isSending">
        <span class="title">{{ text.your }}</span>
        <span class="mini">{{ text.what }}</span>
        <span class="mini red" *ngIf="emailNote != ''">{{ emailNote }}</span>
      </div>
      <div class="textSend" *ngIf="isSending">
        <span class="title">{{ text.send }} </span>
        <span class="mini">{{ text.sendto }} - {{ email }}</span
        ><br />
      </div>
    </div>

    <div class="btns">
      <button *ngIf="!mobile" class="print" (click)="print()">
        <img src="../../../../assets/svg/residentPrint.svg" />
        {{ text.print }}
      </button>
      <button class="email" *ngIf="!isSending" (click)="sendEmail()">
        <img src="../../../../assets/svg/residentEmail.svg" />
        {{ text.email }}
      </button>
      <button
        class="email center"
        *ngIf="mobile && isSending"
        (click)="goHome()"
      >
        חזרה למסך הבית
      </button>
    </div>
  </div>
</div>
