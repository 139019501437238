import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from 'src/app/layout/layout.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-people-enter',
  templateUrl: './people-enter.component.html',
  styleUrls: ['./people-enter.component.scss'],
})
export class PeopleEnterComponent implements OnInit {
  text = {
    all: 'כל מה שמעניין בתיק האישי שלך...',
    home: 'בית דיגיטלי מול הרשות זה בדיוק מה שהיית צריך ',
    place: 'כל הפתרונות והמידע במקום אחד',
    btn: 'כניסה והזדהות',
    part1:
      'EPR מערכות בע"מ מקבוצת TSG, נושמים וחיים את התחום המוניציפאלי מעל 20 שנה',
    part2: 'אנחנו מספקים פתרונות לעיריות, מועצות ורשויות מקומיות בכל התחומים!',
    part3:
      'השירותים הדיגיטליים החדשניים של Muni מאפשרים תקשורת דיגיטלית ומהירה בין התושב ולבין הרשות.',
    part4:
      'אצלנו חווית התושב בעדיפות עליונה ולכן פיתחנו כלים נגישים, מכל מקום ובכל זמן שנח לכם.',
    enter: 'הכנסו לראות עוד מתחומי הפעילות שלנו',
    aboutUs: 'קצת עלינו',
  };
  mobile = window.screen.width <= 850 ? true : false;

  constructor(private layoutService: LayoutService, private authService: AuthService) {}
  ngOnInit(): void {}
  go() {
    window.open('https://www.eprsystems.co.il/', '_blank');
  }
  login() {
    if (!this.mobile) {
      this.authService.needPersonalPage$.next(true);
      let e = document.getElementById('dashboardContent');
      if (e)
        e.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      let l = document.getElementById('loginClick');
      if (l) l.click();
    } else {
      this.layoutService.openMobileLogin$.next(true);
    }
  }
}
