import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-faild-payment',
  templateUrl: './faild-payment.component.html',
  styleUrls: ['./faild-payment.component.scss'],
})
export class FaildPaymentComponent implements OnInit {
  destroy$ = new Subject();
  text = {
    title: 'התשלום נכשל!',
    title2: 'ניתן לנסות שוב, או לפנות למחלקת הגביה ברשות להשלמת העסקה',
    btn: 'חזרה למסך הראשי',
    link: 'לאיזור האישי שלך',
  };
  constructor(private router: Router, private route: ActivatedRoute) {}
  ngOnInit(): void {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        if (params.status && params.status == 13) {
          this.text.title = ' סירבת לביצוע התשלום :(';
        }
      });
  }
  navigat(index: number) {
    if (index == 1) {
      this.router.navigate(['']);
    } else if (index == 2) {
      this.router.navigate(['']);
    }
  }
}
