<div id="residentMas" #residentSimple>
  <span class="title"
    >{{ text.title }}
    <span class="addnote">
      {{ text.addnote }}
    </span>
  </span>
  <div class="form" *ngIf="!haveEntitlement">
    <app-basic-form
      [controlsList]="controls"
      [hideNext]="2"
      [hidePrev]="true"
      [hideNext]="2"
      [miniForm]="true"
      [autoSave]="true"
      [enterNext]="true"
      [btnText]=""
      [focusControl]="focusControl"
      (submitFunction)="saveDataLevel($event)"
    ></app-basic-form>
    <div class="errorDiv">
      <span>{{ error }}</span>
    </div>
    <div class="nextdiv">
      <button
        [disabled]="disabledfunc()"
        class="next"
        mat-button
        (click)="getCode()"
      >
        להפקת האישור
        <img src="../../../assets/png/leftPay.png" />
      </button>
    </div>
  </div>
  <div class="haveEntitlementForm" id="formID" #formID *ngIf="haveEntitlement">
    <app-basic-form
      [controlsList]="controlsHaveEntitlement"
      [hideNext]="2"
      [hidePrev]="true"
      [miniForm]="false"
      [autoSave]="true"
      [enterNext]="true"
      [btnText]=""
      [focusControl]="focusControl"
      (getDataInput)="getDataInput($event)"
      (submitFunction)="saveDataLevelHaveEntitlement($event)"
    ></app-basic-form>
  </div>
  <div class="haveEntitlement" *ngIf="haveEntitlement">
    <div class="rigtn">
      <img *ngIf="!needDetails" src="../../../../assets/svg/residentSend.svg" />
      <div *ngIf="needDetails && !mobile" class="circle">
        <img src="../../../../assets/png/tap.png" />
      </div>
      <div class="textSend" *ngIf="needDetails && !mobile">
        <span class="title">{{ text.needDetails }}</span>
        <span class="mini">{{ text.soon }}</span>
      </div>
      <div class="textSend" *ngIf="!isSending && !needDetails">
        <span class="title">{{ text.your }}</span>
        <span class="mini">{{ text.what }}</span>
        <span class="mini red" *ngIf="emailNote != ''">{{ emailNote }}</span>
      </div>
      <div class="textSend" *ngIf="isSending">
        <span class="title">{{ text.send }} </span>
        <span class="mini">{{ text.sendto }} - {{ email }}</span
        ><br />
      </div>
    </div>

    <div class="btns">
      <button
        [disabled]="!(!needDetails && haveEntitlement)"
        *ngIf="!mobile"
        class="print"
        (click)="print()"
      >
        <img src="../../../../assets/svg/residentPrint.svg" />
        {{ text.print }}
      </button>
      <button
        [disabled]="!(!needDetails && haveEntitlement)"
        class="email"
        *ngIf="!isSending"
        (click)="sendEmail()"
      >
        <img src="../../../../assets/svg/residentEmail.svg" />
        {{ text.email }}
      </button>
    </div>
  </div>
</div>
