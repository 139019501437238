import { FormControl, FormGroup, Validators } from "@angular/forms";
import { inputConfig } from "../my-big-input/my-big-input.model";

export function generateForm(inputs: inputConfig[]): FormGroup {
    const formGroup = new FormGroup({});
    inputs.forEach((input) => {
      formGroup.addControl(
        input.formControlName,
        new FormControl(
          '',
          input.required ? Validators.required : Validators.nullValidator
        )
      );
    });
    return formGroup;
  }