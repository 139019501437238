<div class="wrapper">
  <table class="main">
    <table class="widthTable">
      <tr>
        <th *ngFor="let title of displayedColumns">
          <span> {{ title }}</span>
        </th>
      </tr>
    </table>
    <tbody class="widthTable">
      <tr>
        <td class="body">
          <table class="widthTable">
            <tr
              class="row"
              *ngFor="let element of propertyTaxDiscounts; let i = index"
            >
              <td class="td" width="20%">{{ element.description }}</td>
              <td class="td" width="20%">
                {{ element.fromDate | date : "dd/MM/yyyy" }}
              </td>
              <td class="td" width="20%">
                {{ element.untilDate | date : "dd/MM/yyyy" }}
              </td>
              <td class="td" width="30%">{{ element.note }}</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr></tr>
    </tbody>
  </table>
</div>
