import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { AccountService } from '../services/account.service';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss'],
})
export class PersonalDetailsComponent implements OnInit, OnDestroy {
  @ViewChild(MatExpansionPanel) panel?: MatExpansionPanel;
  destroy$ = new Subject();
  text = {
    title: 'האזור האישי שלי',
    what: 'מה מעניין אתכם?',
  };
  options = [
    {
      icon: 'user.svg',
      title: 'פרטים אישיים',
      id: 1,
      panelOpened: true,
    },
    {
      icon: 'home.png',
      title: 'הנכס שלי',
      id: 2,
      panelOpened: true,
    },

    {
      icon: 'briefcase.svg',
      title: 'העסק שלי',
      id: 5,
      panelOpened: true,
    },
    // {
    //   icon: 'forms.svg',
    //   title: 'טפסים שמילאתי',
    //   id: 3,
    //   panelOpened: false,
    // },
    // {
    //   icon: 'credit-card.svg',
    //   title: 'הודעות שלי',
    //   id: 4,
    //   panelOpened: false,
    // },

    // {
    //   icon: 'problem-report.png',
    //   title: 'הדוחות שלי',
    // },

    // {
    //   icon: 'dog.svg',
    //   title: 'בעל החיים שלי',
    // },
    // {
    //   icon: 'customer-support.svg',
    //   title: 'פניה למוקד 106',
    // },
  ];
  mobile = false;
  panelsOpen = [];
  constructor(private accountService: AccountService) {}
  ngOnInit(): void {
    if (window.screen.width <= 850) {
      this.mobile = true;
    }
    this.accountService.getData();
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  scroll(icon) {
    switch (icon.id) {
      case 1:
        var e = document.getElementById('personal');
        if (e)
          e.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        break;
      case 2:
        var e = document.getElementById('asset');
        if (e)
          e.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        break;
      case 3:
        var e = document.getElementById('forms');
        if (e)
          e.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        break;
      case 4:
        var e = document.getElementById('notifications');
        if (e)
          e.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        break;
      case 5:
        var e = document.getElementById('bussines');
        if (e)
          e.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        break;
      default:
        break;
    }
  }

  openClose(i: number) {
    this.options[i].panelOpened = !this.options[i].panelOpened;
  }
}
