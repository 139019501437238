<div #header id="header">
  <div class="container">
    <!-- <div class="savediv">
    <div class="savebtn">
      <span class="saveContinue">שמור והמשך מאוחר יותר</span>
    </div>
  </div> -->
    <span class="title">{{ formDeme.name }}</span>
    <div
      class="main"
      [ngClass]="{
        miniHeader: miniHeader == 1,
        secondHeader: miniHeader == 2
      }"
    >
      <mat-stepper
        ngClass="{{ 'last-edited-step-' + stepper.selectedIndex }}"
        [orientation]="(stepperOrientation | async)!"
        labelPosition="bottom"
        [linear]="false"
        #stepper
        selectedIndex="{{ tab }}"
        class="steper"
      >
        <ng-template matStepperIcon="edit">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 96 960 960"
            width="24"
          >
            <path
              fill="#ffffff"
              d="M382 802.153 168.616 588.769l42.769-42.768L382 716.616l366.615-366.615 42.769 42.768L382 802.153Z"
            />
          </svg>
        </ng-template>

        <ng-template matStepperIcon="done">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 96 960 960"
            width="24"
          >
            <path
              fill="#ffffff"
              d="M382 802.153 168.616 588.769l42.769-42.768L382 716.616l366.615-366.615 42.769 42.768L382 802.153Z"
            />
          </svg>
        </ng-template>

        <mat-step
          [stepControl]="fromDeme"
          *ngFor="let level of levels; let i = index"
        >
          <!-- <form [formGroup]="from"> -->
          <div
            class="z-index"
            *ngIf="canHaveMoreForms == 0 || canHaveMoreForms == 2"
          >
            <ng-template matStepLabel>{{ level.name }}</ng-template>
            <span class="upLable name" *ngIf="mobile">{{ level.name }}</span>
            <span class="upLable" *ngIf="level.lable != ''">{{
              level.lable
            }}</span>
            <div *ngIf="level.type == 'form'">
              <app-basic-form
                (canGoNext)="canNext($event)"
                [stepper]="stepper"
                [controlsList]="level.controls"
                [hideNext]="
                  level.moreLevels ? (level.moreLevels.haveVersions ? 1 : 4) : 0
                "
                [hidePrev]="i == 0 ? true : false"
                [miniForm]="miniForm"
                [btnText]="level.btnText"
                [autoSave]="level.moreLevels ? true : false"
                (submitFunction)="addForm($event, level.id)"
                (getDataInput)="getDataInput($event, level.id)"
              ></app-basic-form>
              <!-- <div *ngFor="let ctrl of level.controls">
                <div class="textarea" *ngIf="ctrl?.inputControl?.config?.type == 'textarea'">
                    <form-input
                      class="form-input"
                      [fullControl]="ctrl.inputControl"
                      (submitFunction)="submitInput($event, false)"
                    ></form-input>
                </div>
              </div> -->
            </div>
            <div *ngIf="level.type == 'table'">
              <app-form-table
                *ngIf="level.tableBodyName == 'payVoucher'"
              ></app-form-table>
              <!-- && !mobile -->
              <app-table-lives-file-open
                *ngIf="level.tableBodyName == 'openBug'"
                [formType]="formDeme.id"
                (next)="next(stepper)"
              ></app-table-lives-file-open>
              <!-- <app-mobile-members
                *ngIf="level.tableBodyName == 'openBug' && mobile"
                (next)="next(stepper)"
              ></app-mobile-members> -->
              <app-open-bug-documents
                *ngIf="level.tableBodyName == 'openBugDocuments'"
                (next)="next(stepper)"
              ></app-open-bug-documents>
            </div>
            <div *ngIf="level.type == 'sign'">
              <sign
                [formType]="formDeme.id"
                [signBody]="level.signBody"
                (next)="next(stepper, $event)"
              ></sign>
            </div>
            <div *ngIf="level.type == 'sendSucsses'">
              <app-sucsses-send></app-sucsses-send>
            </div>
            <div *ngIf="level.type == 'popup'">
              <app-desc-form-popup
                [descBody]="level.descPopupBody"
                (next)="next(stepper)"
              ></app-desc-form-popup>
            </div>
          </div>

          <div
            *ngIf="
              (canHaveMoreForms == 1 || canHaveMoreForms == 2) &&
              level.moreLevels
            "
          >
            <div
              [ngClass]="{ childForm: level.moreLevels.withStyle == true }"
              *ngFor="
                let childLevel of childControlsChosen$ | async;
                let i = index
              "
            >
              <span class="name">{{ childLevel.name }}</span>
              <app-basic-form
                [hideNext]="2"
                [stepper]="stepper"
                (canGoNext)="canNext($event)"
                [controlsList]="childLevel.controls"
                (submitFunction)="
                  addForm($event, level.id, level.moreLevels.levels[i].id)
                "
                [autoSave]="true"
              ></app-basic-form>
            </div>
            <div class="nextdiv" *ngIf="!hideNext || hideNext == 0">
              <button class="prev" mat-button (click)="prev(stepper)">
                הקודם
              </button>
              <button class="next" mat-button>הבא</button>
            </div>
          </div>
          <!-- </form> -->
        </mat-step>
      </mat-stepper>
    </div>
  </div>
</div>
