import { Injectable } from '@angular/core';
import { TablePasswordResidentRes, residentPdf, tableResidentDetailsreq } from './mas.model';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/services/app.service';
import { BehaviorSubject } from 'rxjs';
import { muniParams } from '../../elementForm/payment-page/paymentPage.model';
import { MuniService } from 'src/app/services/muni.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ResidentMasService {
  public workWithTableParam$ = new BehaviorSubject<muniParams | null>(null);
  public idTemp = 0;
  public residentDetails: TablePasswordResidentRes;
  constructor(private app: AppService, private muni: MuniService,     private http: HttpClient) {}
  public checkDetailsTableResident(body: tableResidentDetailsreq) {
    return this.app.post('reports/checkDetailsTableResident/', body);
  }
  public checkPasswordTableResident(id: number, pass: string) {
    let body = {
      id: id,
      password: pass.toString(),
      muniCode: this.muni.getMuniCode,
    };
    return this.app.post('reports/checkPasswordTableResident/', body);
  }
  public GetResidentConfirmLoginUser() {
    return this.app.get('reports/GetResidentConfirmLoginUser');
  }
  public GetResidentConfirm(body: any, isAuth: boolean) {
    if (isAuth) {
      return this.app.post('reports/ResidentConfirmAuthorize/', body);
    } else {
      return this.app.post('reports/ResidentConfirm/', body);
    }
  }
  public GetResidentConfirmPDF(body: residentPdf, isAuth: boolean) {
    const API = environment.api;
    if(isAuth){
      return this.http
      .post(API + 'reports/ResidentConfirmAuthorize/', body, {
        responseType: 'blob' as 'json',
        observe: 'response',
      })
    } else {
      return this.http
      .post(API + 'reports/ResidentConfirm/', body, {
        responseType: 'blob' as 'json',
        observe: 'response',
      })
    }

  }
}
