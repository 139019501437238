<div class="footer" *ngIf="isLoggedIn">
  <!-- <img src="../../../assets/svg/usermenu/buld.svg" alt="" /> -->
  <img
    (click)="openUserDetails()"
    src="../../../assets/svg/usermenu/user.svg"
    alt=""
  />
  <span>{{ name }}</span>
</div>
<div class="footer center" *ngIf="!isLoggedIn">
  <button class="loginbtn"  (click)="login()">
    <img src="../../../assets/svg/userLogin.svg" alt="" />
    כניסה/הרשמה
  </button>
</div>
<div class="login footer" id="loginMobile">
  <div class="circle" (click)="closeLogin(false)">
    <img src="../../../assets/svg/arroeSidebar.svg" alt="" />
  </div>
  <app-login (closeLogin)="closeLogin(true)"></app-login>
</div>
<div class="userDetails footer" id="userDetails">
  <div class="circle" (click)="closeUserDetails(false)">
    <img src="../../../assets/svg/arroeSidebar.svg" alt="" />
  </div>
  <app-user-menu (close)="closeUserDetails($event)"></app-user-menu>
</div>
