<div class="main" *ngIf="section == 1">
  <div *ngIf="level == 1" id="level1" class="wrapper">
    <span class="title">{{ text.title }}</span>
    <span class="title"
      >{{ text.choose }}
      <b>{{ text.siba }}</b>
    </span>
    <div class="cards">
      <div
        (click)="choose(1, 1)"
        class="card"
        [ngClass]="{
          choose: iLevel1 == 1
        }"
      >
        <div class="circleDiv">
          <svg
            id="circle1"
            *ngIf="iLevel1 == 1"
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="38"
            viewBox="0 0 38 38"
          >
            <g
              id="Group_3640"
              data-name="Group 3640"
              transform="translate(-8793 13811)"
            >
              <circle
                id="Ellipse_88"
                data-name="Ellipse 88"
                cx="19"
                cy="19"
                r="19"
                transform="translate(8793 -13811)"
                fill="#08bed1"
              />
              <path
                id="check_2_"
                data-name="check (2)"
                d="M21.479,75.938a1.784,1.784,0,0,1-.526,1.27L9.631,88.529a2.717,2.717,0,0,1-3.837,0L.645,83.38a1.8,1.8,0,1,1,2.539-2.539l4.528,4.528,10.7-10.7a1.8,1.8,0,0,1,3.065,1.27Z"
                transform="translate(8801.2 -13873.731)"
                fill="#fff"
              />
            </g>
          </svg>
        </div>

        <img
          *ngIf="iLevel1 != 1"
          src="../../../../assets/svg/changePayment/gray/startRent.svg"
        />
        <img
          *ngIf="iLevel1 == 1"
          src="../../../../assets/svg/changePayment/azure/srartRent.svg"
        />
        <span [ngClass]="{ color: iLevel1 == 1 }">{{ text.rent }}</span>
      </div>
      <div
        (click)="choose(2, 1)"
        class="card"
        [ngClass]="{
          choose: iLevel1 == 2
        }"
      >
        <div class="circleDiv">
          <svg
            id="circle2"
            *ngIf="iLevel1 == 2"
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="38"
            viewBox="0 0 38 38"
          >
            <g
              id="Group_3640"
              data-name="Group 3640"
              transform="translate(-8793 13811)"
            >
              <circle
                id="Ellipse_88"
                data-name="Ellipse 88"
                cx="19"
                cy="19"
                r="19"
                transform="translate(8793 -13811)"
                fill="#08bed1"
              />
              <path
                id="check_2_"
                data-name="check (2)"
                d="M21.479,75.938a1.784,1.784,0,0,1-.526,1.27L9.631,88.529a2.717,2.717,0,0,1-3.837,0L.645,83.38a1.8,1.8,0,1,1,2.539-2.539l4.528,4.528,10.7-10.7a1.8,1.8,0,0,1,3.065,1.27Z"
                transform="translate(8801.2 -13873.731)"
                fill="#fff"
              />
            </g>
          </svg>
        </div>
        <img
          *ngIf="iLevel1 != 2"
          src="../../../../assets/svg/changePayment/gray/buy-home.svg"
        />
        <img
          *ngIf="iLevel1 == 2"
          src="../../../../assets/svg/changePayment/azure/buy-home.svg"
        />
        <span [ngClass]="{ color: iLevel1 == 2 }">{{ text.home }}</span>
      </div>
    </div>
    <div class="space"></div>
  </div>
  <div *ngIf="level == 2" id="level2" class="wrapper">
    <span class="title">{{ text.title }}</span>
    <span class="title">{{ text.cavod }} </span>
    <div class="cards">
      <div
        (click)="choose(1, 2)"
        class="card"
        [ngClass]="{
          choose: iLevel2 == 1
        }"
      >
        <div class="circleDiv">
          <svg
            *ngIf="iLevel2 == 1"
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="38"
            viewBox="0 0 38 38"
          >
            <g
              id="Group_3640"
              data-name="Group 3640"
              transform="translate(-8793 13811)"
            >
              <circle
                id="Ellipse_88"
                data-name="Ellipse 88"
                cx="19"
                cy="19"
                r="19"
                transform="translate(8793 -13811)"
                fill="#08bed1"
              />
              <path
                id="check_2_"
                data-name="check (2)"
                d="M21.479,75.938a1.784,1.784,0,0,1-.526,1.27L9.631,88.529a2.717,2.717,0,0,1-3.837,0L.645,83.38a1.8,1.8,0,1,1,2.539-2.539l4.528,4.528,10.7-10.7a1.8,1.8,0,0,1,3.065,1.27Z"
                transform="translate(8801.2 -13873.731)"
                fill="#fff"
              />
            </g>
          </svg>
        </div>
        <img
          *ngIf="iLevel2 != 1"
          src="../../../../assets/svg/changePayment/gray/seller.svg"
        />
        <img
          *ngIf="iLevel2 == 1"
          src="../../../../assets/svg/changePayment/azure/seller.svg"
        />
        <span [ngClass]="{ color: iLevel2 == 1 }">{{ text.moher }}</span>
      </div>
      <div
        (click)="choose(2, 2)"
        class="card"
        [ngClass]="{
          choose: iLevel2 == 2
        }"
      >
        <div class="circleDiv">
          <svg
            *ngIf="iLevel2 == 2"
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="38"
            viewBox="0 0 38 38"
          >
            <g
              id="Group_3640"
              data-name="Group 3640"
              transform="translate(-8793 13811)"
            >
              <circle
                id="Ellipse_88"
                data-name="Ellipse 88"
                cx="19"
                cy="19"
                r="19"
                transform="translate(8793 -13811)"
                fill="#08bed1"
              />
              <path
                id="check_2_"
                data-name="check (2)"
                d="M21.479,75.938a1.784,1.784,0,0,1-.526,1.27L9.631,88.529a2.717,2.717,0,0,1-3.837,0L.645,83.38a1.8,1.8,0,1,1,2.539-2.539l4.528,4.528,10.7-10.7a1.8,1.8,0,0,1,3.065,1.27Z"
                transform="translate(8801.2 -13873.731)"
                fill="#fff"
              />
            </g>
          </svg>
        </div>
        <img
          *ngIf="iLevel2 != 2"
          src="../../../../assets/svg/changePayment/gray/House Key.svg"
        />
        <img
          *ngIf="iLevel2 == 2"
          src="../../../../assets/svg/changePayment/azure/House Key.svg"
        />
        <span [ngClass]="{ color: iLevel2 == 2 }">{{ text.buy }}</span>
      </div>
    </div>
    <button (click)="back()" class="btn">חזור</button>
  </div>
  <div *ngIf="level == 3" id="level3" class="wrapper">
    <span class="title">{{ text.title }}</span>
    <span class="title">{{ text.cavod }} </span>
    <div class="cards">
      <div
        (click)="choose(1, 3)"
        class="card"
        [ngClass]="{
          choose: iLevel3 == 1
        }"
      >
        <div class="circleDiv">
          <svg
            *ngIf="iLevel3 == 1"
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="38"
            viewBox="0 0 38 38"
          >
            <g
              id="Group_3640"
              data-name="Group 3640"
              transform="translate(-8793 13811)"
            >
              <circle
                id="Ellipse_88"
                data-name="Ellipse 88"
                cx="19"
                cy="19"
                r="19"
                transform="translate(8793 -13811)"
                fill="#08bed1"
              />
              <path
                id="check_2_"
                data-name="check (2)"
                d="M21.479,75.938a1.784,1.784,0,0,1-.526,1.27L9.631,88.529a2.717,2.717,0,0,1-3.837,0L.645,83.38a1.8,1.8,0,1,1,2.539-2.539l4.528,4.528,10.7-10.7a1.8,1.8,0,0,1,3.065,1.27Z"
                transform="translate(8801.2 -13873.731)"
                fill="#fff"
              />
            </g>
          </svg>
        </div>
        <img
          *ngIf="iLevel3 != 1"
          src="../../../../assets/svg/changePayment/gray/rentEnter.svg"
        />
        <img
          *ngIf="iLevel3 == 1"
          src="../../../../assets/svg/changePayment/azure/rentEnter.svg"
        />
        <span [ngClass]="{ color: iLevel3 == 1 }">{{ text.soher }}</span>
      </div>
      <div
        (click)="choose(2, 3)"
        class="card"
        [ngClass]="{
          choose: iLevel3 == 2
        }"
      >
        <div class="circleDiv">
          <svg
            *ngIf="iLevel3 == 2"
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="38"
            viewBox="0 0 38 38"
          >
            <g
              id="Group_3640"
              data-name="Group 3640"
              transform="translate(-8793 13811)"
            >
              <circle
                id="Ellipse_88"
                data-name="Ellipse 88"
                cx="19"
                cy="19"
                r="19"
                transform="translate(8793 -13811)"
                fill="#08bed1"
              />
              <path
                id="check_2_"
                data-name="check (2)"
                d="M21.479,75.938a1.784,1.784,0,0,1-.526,1.27L9.631,88.529a2.717,2.717,0,0,1-3.837,0L.645,83.38a1.8,1.8,0,1,1,2.539-2.539l4.528,4.528,10.7-10.7a1.8,1.8,0,0,1,3.065,1.27Z"
                transform="translate(8801.2 -13873.731)"
                fill="#fff"
              />
            </g>
          </svg>
        </div>
        <img
          *ngIf="iLevel3 != 2"
          src="../../../../assets/svg/changePayment/gray/seller.svg"
        />
        <img
          *ngIf="iLevel3 == 2"
          src="../../../../assets/svg/changePayment/azure/seller.svg"
        />
        <span [ngClass]="{ color: iLevel3 == 2 }"
          >{{ text.soher2 }}
          <p class="marg">
            {{ text.soher3 }}
          </p>
        </span>
      </div>
    </div>
    <button (click)="back()" class="btn">חזור</button>
  </div>
</div>
<div class="flex" *ngIf="section == 2">
  <span class="prev" (click)="prev()">
    <img src="../../../../assets/svg/arrow-right.svg" alt="">
    {{text.prev}}</span>
  <app-form-header
    [formDeme]="changePaymentsForm"
    [miniForm]="false"
    (saveDataLevel)="saveDataLevel($event)"
  ></app-form-header>
</div>
