import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { selectConfig } from '../../common/elements/my-select/my-select.model';
import { AppService } from 'src/app/services/app.service';
import { MuniService } from 'src/app/services/muni.service';
import { MuniCodeReq, MuniServiceCard } from 'src/app/common/models/muni.model';
import { IListItem } from 'src/app/forms/elementForm/form-input/form-input.model';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-muni-choose',
  templateUrl: './muni-choose.component.html',
  styleUrls: ['./muni-choose.component.scss'],
})
export class MuniChooseComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  text = {
    allActions1: 'כל ',
    allActions2: 'הפעולות ',
    allActions3: ' מול',
    allActions4: ' הרשות',
    mhirut: 'במהירות.',
    welcome: 'ברוכים הבאים למרחב הדיגיטלי החדש שלכם!',
    here: 'כאן תוכלו לבצע פעולות און ליין מול הרשות ולחסוך זמן יקר',
  };
  muncipalities: IListItem[] = [];
  muniSelectConfig: selectConfig = {
    lable: 'בחרו את הרשות',
    list: this.muncipalities,
    copyList: this.muncipalities,
    bigSelect: true,
  };
  muniSelected: any;
  mobile = false;
  constructor(
    private app: AppService,
    private muniService: MuniService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (window.screen.width <= 550) {
      this.mobile = true;
    }
    this.muniService.updateMuniCode$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.muniSelected = this.muniService.getMuniCode;
      });
    this.muniService
      .getMunicipalities()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          res.map((muni) => {
            this.muncipalities.push({
              label: muni.muniName,
              value: muni.muniCode,
            });
          });
          if (this.muniService.getMuniCode != 0) {
            this.muniSelected = this.muniService.getMuniCode;
          }
          this.cdr.detectChanges();
        },
        (error) => {}
      );
    this.animationTitle();
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  animationTitle() {
    setTimeout(() => {
      let title4 = document.getElementById('mobileImg');
      if (title4) {
        title4.style.transition = 'all 1s ease';
        title4.style.opacity = '1';
        title4.style.transform = 'translate(0, -100px)';
      }
    }, 100);

    setTimeout(() => {
      let title1 = document.getElementById('title1');
      if (title1) {
        title1.style.transition = 'all 1s ease';
        title1.style.transform = 'translate(0, 200px)';
      }
      setTimeout(() => {
        let title2 = document.getElementById('title2');
        if (title2) {
          title2.style.transition = 'all 1s ease';
          title2.style.transform = 'translate(0,200px)';
        }
        setTimeout(() => {
          let title3 = document.getElementById('title3');
          if (title3) {
            title3.style.transition = 'all 1s ease';
            title3.style.transform = 'translate(0, 200px)';
          }
          setTimeout(() => {
            let title4 = document.getElementById('title4');
            if (title4) {
              title4.style.transition = 'all 1s ease';
              title4.style.transform = 'translate(0, 200px)';
            }
          }, 100);
        }, 100);
      }, 100);
    }, 200);
    setTimeout(() => {
      let title4 = document.getElementById('muniLineText');
      if (title4) {
        title4.style.opacity = '1';
        title4.style.transition = 'all 1s ease';
        title4.style.transform = 'translate(-200px, 0)';
      }
    }, 700);
  }

  getMuniData(value: any) {
    this.muniService.getMuniData(
      value,
      this.muncipalities.find((m) => m.value == value).label
    );
  }
}
