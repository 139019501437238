import { Injectable } from '@angular/core';
import { formDeme, level } from '../forms/elementForm/controlsDeme';
import { fullControl } from '../forms/elementForm/control.model';

@Injectable({
  providedIn: 'root',
})
export class DiscoutsService {
  level1Controls: fullControl[] = [
    {
      controlType: 'input',
      inputControl: {
        config: {
          formControlName: 'id',
          inputType: 'number',
          label: 'תעודת זהות',
          name: 'id',
          required: true,
          type: 'input',
        },
      },
    },
    {
      controlType: 'input',
      inputControl: {
        config: {
          formControlName: 'paymentNumberD',
          inputType: 'number',
          label: 'מספר משלם',
          name: 'paymentNumberD',
          required: true,
          type: 'input',
        },
      },
    },
    {
      controlType: 'input',
      inputControl: {
        config: {
          formControlName: 'city',
          inputType: 'select',
          label: 'עיר / יישוב',
          name: 'city',
          required: true,
          type: 'select',
        },
        selectconfig: {
          bigSelect: false,
          copyList: [],
          lable: 'עיר / יישוב',
          list: [],
        },
      },
    },
    {
      controlType: 'input',
      inputControl: {
        config: {
          formControlName: 'firstName',
          inputType: 'text',
          label: 'שם פרטי',
          name: 'firstName',
          required: true,
          type: 'input',
        },
      },
    },
    {
      controlType: 'input',
      inputControl: {
        config: {
          formControlName: 'lastName',
          inputType: 'text',
          label: 'שם משפחה',
          name: 'lastName',
          required: true,
          type: 'input',
        },
      },
    },
    {
      controlType: 'input',
      inputControl: {
        config: {
          formControlName: 'email',
          inputType: 'text',
          label: 'אימייל',
          name: 'email',
          required: false,
          type: 'input',
        },
      },
    },
    {
      controlType: 'input',
      inputControl: {
        config: {
          formControlName: 'street',
          inputType: 'select',
          label: 'רחוב',
          name: 'street',
          required: true,
          type: 'select',
        },
        selectconfig: {
          bigSelect: false,
          copyList: [],
          lable: 'רחוב',
          list: [],
        },
      },
    },
    {
      controlType: 'input',
      inputControl: {
        config: {
          formControlName: 'houseNumber',
          inputType: 'number',
          label: 'מספר בית',
          name: 'houseNumber',
          required: true,
          type: 'input',
        },
      },
    },
    {
      controlType: 'input',
      inputControl: {
        config: {
          formControlName: 'apartment',
          inputType: 'number',
          label: 'דירה',
          name: 'apartment',
          required: false,
          type: 'input',
        },
      },
    },
    {
      controlType: 'input',
      inputControl: {
        config: {
          formControlName: 'enter',
          inputType: 'text',
          label: 'כניסה',
          name: 'enter',
          required: false,
          type: 'input',
        },
      },
    },
    {
      controlType: 'input',
      inputControl: {
        config: {
          formControlName: 'postalCode',
          inputType: 'number',
          label: 'מיקוד',
          name: 'postalCode',
          required: false,
          type: 'input',
        },
      },
    },
  ];

  levels: level[] = [
    {
      id: 1,
      lable: '',
      name: 'פרטי מגיש הבקשה',
      type: 'form',
      controls: this.level1Controls,
    },
    {
      id: 2,
      lable: '',
      name: 'פרטי הנכס',
      type: 'assetDiscount',
    },
    {
      id: 3,
      lable: '',
      name: 'פרטי המתגוררים',
      type: 'liversDiscount',
    },
    {
      id: 4,
      lable: '',
      name: 'פרטי ההכנסות',
      type: 'incomesDiscount',
    },
    {
      id: 5,
      lable: '',
      name: 'מסמכים',
      type: 'documentsDiscount',
    },
    {
      id: 6,
      lable: '',
      name: 'הצהרה וסיום',
      type: 'signDiscount',
    },
  ];
  discountFormDeme: formDeme = {
    id: 500,
    levels: this.levels,
    name: 'טופס בקשת הנחה לארנונה',
  };
  constructor() {}
}
