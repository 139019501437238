<div class="footer">
  <div class="section w500">
    <!-- <img src="../../../assets/png/peopleIcon.png"> -->
    <!-- <span>{{text.accessibility}}</span> -->
    <!-- <span>{{text.private}}</span> -->
    <p>.Copyright © 2022 EPR Systems</p>
    <span></span>
  </div>
  <div class="section ">
    <img src="../../../assets/png/tsg.png">
    <img src="../../../assets/png/epr.png">
  </div>
</div>
