<div class="wrapper">
  <div class="titleDiv">
    <span class="spantitle">{{ text.title1 }}</span>
    <div class="flex">
      <span class="spantitle title">{{ text.title2 }}</span>
      <span class="spantitle title">{{ text.title3 }}</span>
    </div>
  </div>
  <form [formGroup]="voucherForm">
    <div class="inputs">
      <my-input
        [config]="paymentNumberConfig"
        (blur)="fillForm($event)"
      ></my-input>
      <my-input [config]="voucherConfig" (blur)="fillForm($event)"></my-input>
    </div>
  </form>
  <span class="error">{{error}}</span>
  <div class="btnLogin">
    <button (click)="continueRegister()">
      {{ text.btn }}
    </button>
    <span class="graytext"
      >{{ text.registerQ }}
      <span class="link" (click)="goLogin()">{{ text.registerLink }}</span>
    </span>
  </div>
</div>
