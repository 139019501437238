<div class="wrapper">
  <button class="close" [mat-dialog-close]="false">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.2"
      height="14.2"
      viewBox="0 0 14.2 14.2"
    >
      <path
        id="reject_2_"
        data-name="reject (2)"
        d="M18.807,6.607a1,1,0,0,0-1.414-1.414L12,10.586,6.607,5.193A1,1,0,0,0,5.193,6.607L10.586,12,5.193,17.393a1,1,0,0,0,1.414,1.414L12,13.414l5.393,5.393a1,1,0,0,0,1.414-1.414L13.414,12Z"
        transform="translate(-4.9 -4.9)"
        fill="#484848"
      />
    </svg>
  </button>
  <div class="title">{{ data.title }}</div>

  <div class="body" *ngFor="let err of data.body">
    <span>{{ err }}</span
    ><br />
  </div>
  <br />
  <button class="btn" (click)="close()">{{data.btn}}</button>
</div>
