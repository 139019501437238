import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import {
  IRegisterDetails,
  QuickEnterGetPassRes,
  User,
  UserData,
} from '../common/models/auth.model';
import { MuniService } from './muni.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { LoadingService } from '../common/elements/loader/loading.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  needPersonalPage$ = new BehaviorSubject<boolean>(false);
  prevUrl;
  string = '';
  user$ = new BehaviorSubject<UserData>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    muniCode: 0,
    mspkod: 0,
    maintz: '',
    lastLogin: new Date(),
  });
  constructor(
    private app: AppService,
    private muniService: MuniService,
    private router: Router,
    private loading: LoadingService
  ) {}
  getQuickLoginPass(id: number, cellPhone: string): Observable<any> {
    const muniCode: number = this.muniService.getMuniCode;
    let data;
    if (cellPhone.includes('@'))
      data = {
        email: cellPhone,
        id: id.toString(),
        muniCode: muniCode.toString(),
      };
    else
      data = {
        muniCode: muniCode.toString(),
        cellPhone: cellPhone,
        id: id.toString(),
      };
    return this.app.post('User/QuickEnterGetPass', data);
  }
  CheckQuickLoginPass(id: number, pass: string) {
    let boby = {
      muniCode: this.muniService.getMuniCode.toString(),
      pass: pass,
      id: id,
    };
    return this.app.post('User/QuickEnterLogin', boby);
  }

  setSession(authResult: User) {
    const expiresAt = moment().add(authResult.expiresIn, 'second'); // בשרת להחזיר זמן תפוגה של סשן בשניות
    localStorage.setItem('id_token', authResult.token);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
    localStorage.setItem('userDataMuni', JSON.stringify(authResult.userData));
    this.user$.next(authResult.userData);
  }

  login(mainId: string, password: string) {
    const muniCode: number = this.muniService.getMuniCode;
    let body = {
      MuniCode: muniCode,
      MainId: mainId.toString(),
      Password: password,
    };
    return this.app.post('User/Login', body).pipe(tap((res) => res));
  }
  logout() {
    this.loading.show();
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('userDataMuni');
    this.user$.next({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      muniCode: 0,
      mspkod: 0,
      maintz: '',
      lastLogin: new Date(),
    });
    this.router.navigate(['/']);
    this.loading.hide();
  }
  async checkIfUserExist(mainTz: string) {
    const muniCode: number = this.muniService.getMuniCode;
    let body = {
      mainID: mainTz.toString(),
      muniCode: muniCode,
    };
    let response = await this.app.post('User/checkIfUserExist', body);
    if (response) {
      return response.toPromise();
    } else return null;
  }
  register(user: IRegisterDetails) {
    user.MuniCode = this.muniService.getMuniCode;
    return this.app.post('User/Register', user).pipe(tap((res) => res));
  }
  getUser(): BehaviorSubject<UserData> {
    if (this.user$.value.mspkod == 0)
      if (localStorage.getItem('userDataMuni') && this.isNotExpired())
        this.user$.next(JSON.parse(localStorage.getItem('userDataMuni') ?? ''));
    return this.user$;
  }
  newPassword(currentPassword: string, newPassword: string) {
    return this.app.post('User/newPassword', {
      currentPassword,
      newPassword,
    });
  }
  forgetPassword(details: { phone: string; id: number }): Observable<any> {
    const muniCode: number = this.muniService.getMuniCode;
    let data;
    if (details.phone.includes('@'))
      data = {
        email: details.phone,
        id: details.id.toString(),
        muniCode: muniCode.toString(),
      };
    else
      data = {
        muniCode: muniCode.toString(),
        phone: details.phone,
        id: details.id.toString(),
      };
    return this.app.post('User/forgetPassword', data);
  }
  public getToken() {
    let b = localStorage.getItem('id_token');
    return b;
  }
  public getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    if (expiration) {
      const expiresAt = JSON.parse(expiration);
      return moment(expiresAt);
    } else {
      return moment('1970-01-01');
    }
  }
  public isNotExpired() {
    let c = moment().isBefore(this.getExpiration());
    return c;
  }
  public isLoggedIn() {
    let token = this.getToken();
    let ex = this.isNotExpired();
    if (token && !ex) {
      localStorage.removeItem('expires_at');
      localStorage.removeItem('id_token');
    }
    return token && ex;
  }
}
