import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { LayoutService } from 'src/app/layout/layout.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-mobile-footer',
  templateUrl: './mobile-footer.component.html',
  styleUrls: ['./mobile-footer.component.scss'],
})
export class MobileFooterComponent implements OnInit {
  destroy$ = new Subject();
  name = '';
  isLoggedIn = false;
  constructor(private authService: AuthService, private dialog: MatDialog, private layoutService: LayoutService) {}
  ngOnInit(): void {
    // this.authService.user$.subscribe((res) => {
    //   if (res && res.firstName != '') {
    //     this.name = 'שלום, ' + res.firstName;
    //   }
    // });
    this.authService
      .getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && res.maintz && res.maintz != '') {
          this.name = 'שלום, ' + res.firstName;
        }
      });
    if (this.authService.isLoggedIn()) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
    this.layoutService.openMobileLogin$.pipe(takeUntil(this.destroy$)).subscribe(res=>{
      if(res==true){
        this.login()
      } else {
        this.closeLogin(false);
      }
    })
  }
  login() {
    let el = document.getElementById('loginMobile');
    if (el) {
      el.style.display = 'flex';
      el.style.transition = 'all 1s ease';
      el.style.transform = 'translate(0, -500px)';
    }
  }
  closeLogin(isEmit: boolean) {
    let el = document.getElementById('loginMobile');
    if (el) {
      el.style.transition = 'all 1s ease';
      el.style.transform = 'translate(0, 500px)';
      if (isEmit) this.isLoggedIn = true;
    }
  }
  openUserDetails() {
    let el = document.getElementById('userDetails');
    if (el) {
      el.style.display = 'flex';
      el.style.transition = 'all 1s ease';
      el.style.transform = 'translate(0, -400px)';
    }
  }
  closeUserDetails(isLogout: boolean) {
    let el = document.getElementById('userDetails');
    if (el) {
      el.style.transition = 'all 1s ease';
      el.style.transform = 'translate(0, 400px)';
      if(isLogout) this.isLoggedIn = false;
    }
  }
}
