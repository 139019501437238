import { Injectable } from '@angular/core';
import { BehaviorSubject, skipWhile } from 'rxjs';
import { Voucher } from '../common/models/voucher.model';
import { AppService } from './app.service';
import { MuniService } from './muni.service';
import { Charge } from '../debt-balance/debt.model';
const CONTROLLER = 'FinancialData';
@Injectable({
  providedIn: 'root',
})
export class VoucherService {
  public oneVocher$ = new BehaviorSubject<Voucher | null>(null);
  public numVocher = 0;
  mainIdPaymentOneVoucher: number;
  columnsVoucher$ = new BehaviorSubject<string[]>([
    'מספר נכס',
    'תאריך ערך',
    'סוג חיוב',
    'הערה',
    'ריבית',
    'סכום',
  ]);
  constructor(private app: AppService, private muniService: MuniService) {}

  public getOneVoucher(voucherId: string, paymentId: number) {
    this.oneVocher$.next(null);
    let url;
    let body;
    if (paymentId == 0) {
      url = '/Voucher';
      body = {
        voucherId: voucherId.toString(),
      };
    } else {
      url = '/VoucherGuest';
      body = {
        muniCode: this.muniService.getMuniCode,
        voucherId: voucherId.toString(),
        mainTz: paymentId.toString(),
      };
    }
    return this.app.post(CONTROLLER + url, body);
  }
  public getVoucherImg() {
    return this.app.post('App/GetThem', {
      muniCode: this.muniService.getMuniCode,
    });
  }
}
export interface panelVoucher {
  assetNumber: number;
  charge: Charge[];
  period: string;
  sum: number;
  opened: boolean;
}
