import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { signBody } from '../controlsDeme';
import { NgxCaptureService } from 'ngx-capture';
import { UploadFile } from '../my-file/file.model';
import { MuniService } from 'src/app/services/muni.service';
import { AuthService } from 'src/app/services/auth.service';
import { FilesService } from '../my-file/files.service';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { FormsFunctionsService } from '../../allForms/forms-functions.service';
import { sendRequestReq } from '../../allForms/functions.model';
import { FormsDataService } from '../../forms-data.service';
import { signText } from '../../models/form.model';
import { FormType } from '../../allForms/functionEnum.model';
import { buttonControl } from '../control.model';

@Component({
  selector: 'sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss'],
})
export class SignComponent implements OnInit, OnDestroy {
  @Input() signBody: signBody;
  @Input() formType: FormType;
  @Output() next = new EventEmitter<any>();
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('canvas2') canvas2: ElementRef;
  @ViewChild('screen', { static: true }) screen: any;
  destroy$ = new Subject();
  public width = 200;
  public height = 100;
  private cx: CanvasRenderingContext2D;
  private cx2: CanvasRenderingContext2D;
  prevPos: any;
  subscriberTouch: Subscription;
  subscriberMouse: Subscription;
  personName = '';
  date = new Date();
  valid: boolean;
  blankUrl: any;
  mobile = window.screen.width <= 850 ? true: false;
  text = {
    negisha: 'אני רוצה לסמן חתימה נגישה',
    btnText: 'הגשת הבקשה וסיום',
  };
  isEnd = false;
  haveSign = false;
  haveSign2 = false;
  signtext: signText[] = [];
  constructor(
    private captureService: NgxCaptureService,
    private muni: MuniService,
    private authService: AuthService,
    private filesService: FilesService,
    private loading: LoadingService,
    private formsFunctions: FormsFunctionsService,
    private formsData: FormsDataService
  ) {}

  ngOnInit(): void {
     this.formsData.signText$.subscribe((res) => {
      if (res && res.length > 0) {
        res.map((r) => {
          if (r.formType == this.formType) this.signtext.push(r);
        });
        this.signBody.buttons = [];
        this.signtext.map((btn, i) => {
          let button: buttonControl = {
            btnName: 'btn' + i,
            config: {
              name: 'btn' + i,
              text: btn.text,
              type: 'checkbox',
            },
          };
          this.signBody.buttons.push(button);
        });
      } else {
        this.formsData.getDeclerationText();
      }
    });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  ngAfterViewInit(): void {
    const canvasEl: HTMLCanvasElement = this.canvas
      ?.nativeElement as HTMLCanvasElement;
    this.doForCanvas(canvasEl);
    const canvasEl2: HTMLCanvasElement = this.canvas2
      ?.nativeElement as HTMLCanvasElement;
    this.doForCanvas2(canvasEl2);
  }
  doForCanvas(canvasEl: HTMLCanvasElement) {
    this.cx = canvasEl.getContext('2d');
    canvasEl.width = this.width;
    canvasEl.height = this.height;
    this.edit();
    this.cx.lineWidth = 2;
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = '#000';
    //this.cx.fillStyle = '#fff';
    this.cx.fillStyle = '#F6F6F6';
    this.cx.fillRect(
      0,
      0,
      this.canvas.nativeElement.width,
      this.canvas.nativeElement.height
    );

    const image = new Image();
    image.onload = () => {
      this.cx.drawImage(
        image,
        0,
        0,
        this.canvas.nativeElement.width,
        this.canvas.nativeElement.height
      );
    };
    // image.src = this.data;
    this.blankUrl = this.cx.canvas.toDataURL();
  }
  doForCanvas2(canvasEl: HTMLCanvasElement) {
    if (canvasEl) {
      this.cx2 = canvasEl?.getContext('2d');
      canvasEl.width = this.width;
      canvasEl.height = this.height;
    }
    this.edit(true);
    if (this.cx2) {
      this.cx2.lineWidth = 2;
      this.cx2.lineCap = 'round';
      this.cx2.strokeStyle = '#000';
      //this.cx.fillStyle = '#fff';
      this.cx2.fillStyle = '#F6F6F6';
      this.cx2.fillRect(
        0,
        0,
        this.canvas2.nativeElement.width,
        this.canvas2.nativeElement.height
      );

      const image = new Image();
      image.onload = () => {
        this.cx2.drawImage(
          image,
          0,
          0,
          this.canvas2.nativeElement.width,
          this.canvas2.nativeElement.height
        );
      };

      // image.src = this.data;
      this.blankUrl = this.cx2.canvas.toDataURL();
    }
  }
  public clear(is2?: boolean) {
    if (is2) {
      if (this.cx2) {
        this.cx2.clearRect(0, 0, this.width, this.height);
      }
    } else {
      if (this.cx) {
        this.cx.clearRect(0, 0, this.width, this.height);
      }
    }

    this.valid = false;
  }
  public edit(is2?: boolean) {
    if (!is2) {
      this.captureEvents();
    } else {
      this.captureEvents2();
    }
  }
  save() {
    this.loading.show();
    let div = document.getElementById('screen');
    this.captureService
      .getImage(div, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe((img) => {
        let base64 = img.split(',', 2);
        var doc: UploadFile = {
          content: base64[1],
          fileName: 'sign.png',
          fileType: 20,
          description: '',
          muniCode: this.muni.getMuniCode,
          path: '',
          personID: this.authService.getUser().value.maintz.toString(),
        };
        this.filesService
          .UploadSimpleFile(doc)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (res) => {
              const body: sendRequestReq = {
                muniCode: this.muni.getMuniCode,
                requestId:
                  this.formsFunctions.existRequestData$.value.requestData
                    .lineID,
                signatureFileID: res.body.fileID,
              };
              this.formsFunctions
                .sendRequest(body)
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                  (res) => {
                    this.loading.hide();
                    if (this.signBody.needEnd) {
                      this.isEnd = true;
                    } else this.next.emit();
                    this.formsFunctions.sendReportToMail().subscribe(
                      (res) => {},
                      (error) => {}
                    );
                  },
                  (error) => {
                    this.loading.hide();
                  }
                );
            },
            (error) => {
              this.loading.hide();
            }
          );
      });
  }
  private captureEvents() {
    const canvasEl = this.canvas.nativeElement;
    const mousedown = fromEvent(canvasEl, 'mousedown');
    const mousemove = fromEvent(canvasEl, 'mousemove');
    const mouseup = fromEvent(canvasEl, 'mouseup');
    const mouseleave = fromEvent(canvasEl, 'mouseleave');

    const touchstart = fromEvent(canvasEl, 'touchstart');
    const touchmove = fromEvent(canvasEl, 'touchmove');
    const touchend = fromEvent(canvasEl, 'touchend');
    const touchcancel = fromEvent(canvasEl, 'touchcancel');

    this.subscriberMouse = mousedown.subscribe(() => {
      mousemove
        .pipe(
          takeUntil(mouseup),
          takeUntil(mouseleave),
          takeUntil(this.destroy$)
        )
        .subscribe(
          (res: MouseEvent) => {
            const rect = canvasEl.getBoundingClientRect();
            if (!this.prevPos) {
              this.prevPos = {
                x: res.clientX - rect.left,
                y: res.clientY - rect.top,
              };
            }

            const currentPos = {
              x: res.clientX - rect.left,
              y: res.clientY - rect.top,
            };

            this.drawOnCanvas(this.prevPos, currentPos);
            this.prevPos = currentPos;
          },
          (error) => {},
          () => {
            this.prevPos = undefined;
          }
        );
    });

    this.subscriberTouch = touchstart
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        touchmove
          .pipe(
            takeUntil(touchend),
            takeUntil(touchcancel),
            takeUntil(this.destroy$)
          )
          .subscribe(
            (res: TouchEvent) => {
              const rect = canvasEl.getBoundingClientRect();
              if (!this.prevPos) {
                this.prevPos = {
                  x: res.touches[0].clientX - rect.left,
                  y: res.touches[0].clientY - rect.top,
                };
              }
              const currentPos = {
                x: res.touches[0].clientX - rect.left,
                y: res.touches[0].clientY - rect.top,
              };
              this.drawOnCanvas(this.prevPos, currentPos);
              this.prevPos = currentPos;
            },
            (error) => {},
            () => {
              this.prevPos = undefined;
            }
          );
      });
  }
  private captureEvents2() {
    const canvasEl = this.canvas2?.nativeElement;
    if (canvasEl) {
      const mousedown = fromEvent(canvasEl, 'mousedown');
      const mousemove = fromEvent(canvasEl, 'mousemove');
      const mouseup = fromEvent(canvasEl, 'mouseup');
      const mouseleave = fromEvent(canvasEl, 'mouseleave');

      const touchstart = fromEvent(canvasEl, 'touchstart');
      const touchmove = fromEvent(canvasEl, 'touchmove');
      const touchend = fromEvent(canvasEl, 'touchend');
      const touchcancel = fromEvent(canvasEl, 'touchcancel');

      this.subscriberMouse = mousedown
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          mousemove
            .pipe(
              takeUntil(mouseup),
              takeUntil(mouseleave),
              takeUntil(this.destroy$)
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res: MouseEvent) => {
                const rect = canvasEl.getBoundingClientRect();
                if (!this.prevPos) {
                  this.prevPos = {
                    x: res.clientX - rect.left,
                    y: res.clientY - rect.top,
                  };
                }

                const currentPos = {
                  x: res.clientX - rect.left,
                  y: res.clientY - rect.top,
                };

                this.drawOnCanvas2(this.prevPos, currentPos);
                this.prevPos = currentPos;
              },
              (error) => {},
              () => {
                this.prevPos = undefined;
              }
            );
        });

      this.subscriberTouch = touchstart
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          touchmove
            .pipe(
              takeUntil(touchend),
              takeUntil(touchcancel),
              takeUntil(this.destroy$)
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res: TouchEvent) => {
                const rect = canvasEl.getBoundingClientRect();
                if (!this.prevPos) {
                  this.prevPos = {
                    x: res.touches[0].clientX - rect.left,
                    y: res.touches[0].clientY - rect.top,
                  };
                }
                const currentPos = {
                  x: res.touches[0].clientX - rect.left,
                  y: res.touches[0].clientY - rect.top,
                };
                this.drawOnCanvas2(this.prevPos, currentPos);
                this.prevPos = currentPos;
              },
              (error) => {},
              () => {
                this.prevPos = undefined;
              }
            );
        });
    }
  }
  private drawOnCanvas(
    prevPos: { x: number; y: number },
    currentPos: { x: number; y: number }
  ) {
    if (!this.cx) {
      return;
    }
    this.haveSign = true;
    this.cx.beginPath();
    if (prevPos) {
      this.cx.moveTo(prevPos.x, prevPos.y); // from
      this.cx.lineTo(currentPos.x, currentPos.y);
      this.cx.stroke();
    }

    this.valid = true;
  }
  private drawOnCanvas2(
    prevPos: { x: number; y: number },
    currentPos: { x: number; y: number }
  ) {
    if (!this.cx2) {
      return;
    }
    this.haveSign2 = true;
    this.cx2.beginPath();

    if (prevPos) {
      this.cx2.moveTo(prevPos.x, prevPos.y); // from
      this.cx2.lineTo(currentPos.x, currentPos.y);
      this.cx2.stroke();
    }

    this.valid = true;
  }
  submitInput(event, i) {
    this.signBody.buttons[i].value = event;
  }
  submitForm() {}
  nextEmit() {
    this.save();
  }
  disabled(): boolean {
    let count = 0;
    this.signBody.buttons.map((b) => {
      if (b.value) count++;
    });
    if (count == this.signBody.buttons.length && this.haveSign) {
      if (this.signBody?.sign.length == 2) {
        if (this.haveSign2) return false;
        else return true;
      } else return false;
    } else return true;
  }
}
