<div class="guest">
  <img
    (click)="prev()"
    class="arrowPrevMobile"
    *ngIf="mobile"
    src="../../../assets/svg/arrowPrevMobile.svg"
  />
  <span class="bigTitle">
    <span *ngIf="!mobile">...</span>

    {{ text.title }}
    <span *ngIf="mobile">...</span>
  </span>
  <div
    class="flex"
    [ngClass]="{
      mobileContainer: mobile
    }"
  >
    <div class="minitext">
      <span class="mini">{{ text.text }}</span>
      <span class="mini">{{ text.text2 }}</span>
    </div>
    <span class="subTitle"
      >{{ text.subTitle }}
      <span class="subTitle click" (click)="register()">{{ text.click }}</span>
    </span>
    <form [formGroup]="guestForm">
      <div class="inputs">
        <div class="ltr" *ngFor="let config of controls">
          <my-input
            [config]="config"
            [selectconfig]="config?.selectConfig"
            [selected]="config?.selectConfig?.selected"
            (blur)="fillForm($event)"
          ></my-input>
        </div>
      </div>
    </form>
    <span>{{ error }}</span>
    <div class="btnLogin">
      <button (click)="save()">
        {{ text.btn }}
      </button>
    </div>
  </div>
</div>
