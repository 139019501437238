import { Component, Input, OnInit } from '@angular/core';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { PaymentService } from 'src/app/forms/elementForm/payment-page/payment.service';
import {
  PaymentsLogRequest,
  paymentsLog,
} from 'src/app/forms/elementForm/payment-page/paymentPage.model';
import { propertyTax } from 'src/app/personal-details/asset/asset.model';
import { IPaymentArrangement } from 'src/app/personal-details/personal.model';
import { AccountService } from 'src/app/services/account.service';
import { AssetService } from 'src/app/services/asset.service';
import { AuthService } from 'src/app/services/auth.service';
import { MuniService } from 'src/app/services/muni.service';

@Component({
  selector: 'app-payment-arrangement',
  templateUrl: './payment-arrangement.component.html',
  styleUrls: ['./payment-arrangement.component.scss'],
})
export class PaymentArrangementComponent implements OnInit {
  destroy$ = new Subject();
  text = {
    title: 'הסדר התשלום לנכס זה הוא',
    btn: 'לעדכון כרטיס אשראי',
  };
  options = ['ה.ק בנקאית', 'ה.ק בכרטיס אשראי', 'שובר'];
  name = '';
  number = '**** **** **** ';
  tokef = '';
  payIframe = false;
  paymentsArr: IPaymentArrangement[];
  payment: IPaymentArrangement;
  assetNum = ''
  constructor(
    private accountService: AccountService,
    private authService: AuthService,
    private paymentService: PaymentService,
    private muni: MuniService,
    private loading: LoadingService,
    private assetService: AssetService
  ) {}
  ngOnInit(): void {
    this.accountService.GetPaymentArrangement();

    combineLatest([
      this.assetService.PropertyTaxDataObject$,
      this.accountService.PaymentArrangements$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([propertyTax, PaymentArrangements]: [propertyTax, any]) => {
        if (
          propertyTax &&
          PaymentArrangements &&
          PaymentArrangements.length > 0
        ) {
          this.paymentsArr = PaymentArrangements;
          this.assetNum  = propertyTax.assetNum
          this.setData();
        }
      });

    this.paymentService.payIframe$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.payIframe = res;
        if (res) {
          this.loading.hide();
        }
      });
  }
  setData() {
    let obj = this.paymentsArr.find((p) => p.assetId == this.assetNum);
    if (obj) {
      this.payment = obj;
      this.name = 'ת.ז ' + this.payment.creditOwnerId;
      this.number =
        this.payment.creditCard.substr(this.payment.creditCard.length - 4) +
        ' ' +
        this.number;
      this.tokef = this.payment.validity;
    }
  }
  openPay() {
    this.loading.show();
    this.payIframe = true;
    this.paymentService.iframeFromDebit = true;
    this.paymentService.assetIDForDebit = this.assetNum;
    let CGrequest: PaymentsLogRequest;
    let paymentsLogElement: paymentsLog = {
      usury: 0,
      quantity: 0,
      paymentAmount: 1,
      assetId: this.assetNum.toString(),
    };
    this.authService.getUser().subscribe((res) => {
      CGrequest = {
        PaymentLog: [paymentsLogElement],
        muniCode: this.muni.getMuniCode,
        ID: +res.maintz,
        guest: false,
        clientDomain: new URL(window.location.href).origin,
        actType: 5,
      };
      // if (this.paymentService.saveCard) CGrequest.actType = 6;
      // else {
      CGrequest.actType = 5;
      // }
      this.paymentService.getUlrForPayment(CGrequest);
    });
  }
}
