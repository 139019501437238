import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { selectConfig } from 'src/app/common/elements/my-select/my-select.model';
import {
  CasualService,
  CasualServicesCategories,
  randomPanel,
  selectedService,
} from '../random.model';
import { IListItem } from 'src/app/forms/elementForm/form-input/form-input.model';
import { RandomService } from '../random.service';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-random-item',
  templateUrl: './random-item.component.html',
  styleUrls: ['./random-item.component.scss'],
})
export class RandomItemComponent implements OnInit {
  @ViewChild(MatExpansionPanel) pannel?: MatExpansionPanel;
  @Input() panel: randomPanel;
  @Output() deletePanel = new EventEmitter<number>();
  destroy$ = new Subject();
  categorySelected;
  amount = 1;
  categorySelectConfig: selectConfig = {
    lable: 'קטגוריה',
    list: [],
    copyList: [],
    bigSelect: false,
    normal: true,
  };
  serviceSelected;
  serviceSelectConfig: selectConfig = {
    lable: 'שירות לתשלום',
    list: [],
    copyList: [],
    bigSelect: false,
    normal: true,
  };
  service: CasualService;
  servicesByCategories: CasualService[] = [];
  categories: CasualServicesCategories[] = [];
  text = {
    amount: 'כמות',
    sum: 'סה"כ',
    sumOne: 'מחיר ליחידה',
    sumToPay: 'סה"כ לתשלום',
    cancel: 'ביטול',
    add: 'הוספה',
    edit: 'עדכון',
    delete: 'מחיקה',
  };
  headerVisible = false;
  isEdit = false;
  errorQuantity = '';
  errorDynamic = '';
  mobile = false;
  constructor(
    private randomService: RandomService,
    private route: ActivatedRoute
  ) {}
  ngOnInit(): void {
    if (window.screen.width <= 950) {
      this.mobile = true;
    }
    combineLatest([
      this.randomService.categories$,
      this.randomService.services$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ([categories, services]: [
          CasualServicesCategories[],
          CasualService[]
        ]) => {
          if (categories && categories.length > 0) {
            this.categories = categories;
            this.categorySelectConfig.list =
              this.convertCategoryToIitemList(categories);
            this.serviceSelectConfig.copyList = this.categorySelectConfig.list;
          }
          if (services && services.length > 0) {
            this.servicesByCategories = services;
            this.serviceSelectConfig.list =
              this.convertCasualToIitemList(services);
            this.serviceSelectConfig.copyList = this.serviceSelectConfig.list;
          }
          if (
            categories &&
            categories.length > 0 &&
            services &&
            services.length > 0
          ) {
            this.listenToRoute();
          }
        }
      );
  }
  listenToRoute() {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        if (params.type && params.id) {
          if (params.type == 1) {
            this.selectCategory(params.id);
          } else if (params.type == 2) {
            this.selectService(params.id);
          }
        }
      });
  }
  selectCategory(value) {
    if (value == '0') {
      this.serviceSelectConfig.list = this.convertCasualToIitemList(
        this.servicesByCategories
      );
      this.serviceSelectConfig.copyList = this.serviceSelectConfig.list;
      this.serviceSelected = null;
    } else {
      this.serviceSelectConfig.list = this.convertCasualToIitemList(
        this.servicesByCategories.filter(
          (service) => service.serviceCategory == value
        )
      );
      this.serviceSelectConfig.copyList = this.serviceSelectConfig.list;
      this.categorySelected = +value;
      this.serviceSelected = null;
      // this.selectedServices.splice(this.selectedServicesIndex, 1);
      this.panel.haveService = false;
      this.amount = 1;
    }
  }
  selectService(value) {
    this.errorQuantity = '';
    // while (this.dynamicFields.length) this.dynamicFields.pop();
    // this.inputRequerd = '';
    this.service = this.servicesByCategories.find(
      (s) => s.serviceCode == value
    );
    this.serviceSelected = this.service.serviceCode;
    this.amount = 1;
    this.panel.haveService = true;
    // this.randomPaymentForm.controls.amount.enable();
    const cate = this.categories.find(
      (element) => element.categoryId == this.service.serviceCategory
    );
    if (cate) {
      this.categorySelected = cate.categoryId;
    }
  }
  plus() {
    if (this.checkQuantity(this.amount + 1)) {
      this.errorQuantity = '';
      this.amount++;
    }
  }
  minus() {
    if (this.checkQuantity(this.amount - 1)) {
      this.errorQuantity = '';
      if (this.amount > 1) {
        this.amount--;
      }
    }
  }
  cancel() {
    if (this.service && this.service.serviceCode != 0) {
      this.deleteOne();
    }
    this.panel.accordionOpened = false;
    this.headerVisible = true;
    this.pannel.close();
  }
  edit() {
    this.isEdit = true;
    this.headerVisible = false;
    this.pannel.open();
  }
  add() {
    if (this.service.dynamicFields && this.service.dynamicFields.length > 0) {
      let withoutAnswer = [];
      this.service.dynamicFields.map((d) => {
        if (!d.answerValue) {
          withoutAnswer.push(d.placeholder);
        }
      });
      if (withoutAnswer.length > 0) {
        this.errorDynamic = 'יש למלא ';
        withoutAnswer.map((w) => {
          this.errorDynamic += w + ' ,';
        });
        this.errorDynamic = this.errorDynamic.slice(
          0,
          this.errorDynamic.lastIndexOf(',')
        );
        return;
      }
    }
    let prev: selectedService[] = this.randomService.selectedServices$.value
      ? this.randomService.selectedServices$.value
      : [];
    prev.push({ amount: this.amount, service: this.service });
    this.randomService.selectedServices$.next(prev);
    this.panel.accordionOpened = false;
    this.headerVisible = true;
    this.pannel.close();
  }
  openPanel() {
    this.pannel.open();
    this.headerVisible = false;
  }
  changeAmount(value: number) {
    if (this.checkQuantity(value)) {
      this.errorQuantity = '';
      if (value > 0 && this.service) {
        this.amount = value;
      } else {
        this.amount = 1;
      }
    }
  }
  disabledAdd(): boolean {
    if (!this.service || !this.service.serviceCode) return true;
    else if (
      this.service.dynamicFields &&
      this.service.dynamicFields.length > 0
    ) {
      let dis = 0;
      this.service.dynamicFields.map((d) => {
        if (!d.answerValue) dis++;
      });
      if (dis > 0) return true;
      else return false;
    }
    return false;
  }
  saveEditAmount() {
    let prevIndex = this.randomService.selectedServices$.value.findIndex(
      (s) => s.service.serviceCode == this.service.serviceCode
    );
    if (prevIndex != -1) {
      let prev: selectedService[] = this.randomService.selectedServices$.value
        ? this.randomService.selectedServices$.value
        : [];
      prev[prevIndex].amount = this.amount;
      this.randomService.selectedServices$.next(prev);
      this.panel.accordionOpened = false;
      this.headerVisible = true;
      this.pannel.close();
    }
  }
  deleteOne() {
    this.deletePanel.emit(this.service.serviceCode);
  }
  private checkMaxReg(amount) {
    if (this.service.remainCasuals && amount <= this.service.remainCasuals) {
      return true;
    } else {
      if (!this.service.remainCasuals) {
        return true;
      }
      this.amount = this.service.remainCasuals;
      this.changeAmount(this.amount);
      this.errorQuantity =
        'בשירות זה נותרו ' + this.service.remainCasuals + ' יחידות לרכישה.';
      return false;
    }
  }
  private checkQuantity(amount): boolean {
    if (this.checkMaxReg(amount)) {
      if (this.service.maxQuantity && amount <= this.service.maxQuantity) {
        return true;
      } else {
        if (!this.service.maxQuantity) {
          return true;
        }
        this.amount = this.service.maxQuantity;
        this.changeAmount(this.amount);
        this.errorQuantity =
          'בשירות זה קיימת הגבלה של  ' +
          this.service.maxQuantity +
          ' יחידות לרכישה.';
        return false;
      }
    } else {
      return false;
    }
  }
  private convertCategoryToIitemList(
    res: CasualServicesCategories[]
  ): IListItem[] {
    let newList: IListItem[] = [
      {
        label: 'הכל',
        value: 0,
      },
    ];
    res.map((r) => {
      newList.push({
        label: r.categoryName,
        value: r.categoryId,
      });
    });
    return newList;
  }
  private convertCasualToIitemList(res: CasualService[]): IListItem[] {
    let newList: IListItem[] = [];
    res.map((r) => {
      newList.push({
        label: r.serviceName,
        value: r.serviceCode,
      });
    });
    return newList;
  }
}
