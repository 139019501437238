import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { MuniService } from 'src/app/services/muni.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  @Output() close = new EventEmitter<boolean>();
  destroy$ = new Subject();
  name = '';
  muniName = '';
  logo = '';
  mobile = window.screen.width <= 850 ? true : false;

  constructor(
    private authService: AuthService,
    private app: AppService,
    public dialogRef: MatDialogRef<UserMenuComponent>,
    private muniService: MuniService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.authService
      .getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && res.firstName != '') {
          this.name = res.firstName + ' ' + res.lastName;
        }
      });
    this.muniService.muniLogo$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res != '') {
          this.logo = res;
        }
      });
    this.muniName = localStorage.getItem('muniName');
  }

  nav(type: 1 | 2 | 3 | 4) {
    if (!this.mobile) this.dialogRef.close();
    else this.close.emit(false);
    switch (type) {
      case 1:
        this.router.navigate(['personal']);
        break;
      case 2:
        this.router.navigate(['my-asset']);
        break;
      case 3:
        this.router.navigate(['my-payments']);
        break;
      case 4:
        this.router.navigate(['setting']);
        break;

      default:
        break;
    }
  }
  logout(): void {
    if (!this.mobile) {
      this.dialogRef.close();
      window.location.reload();
    } else this.close.emit(true);
    // this.authService.deleteLockedRequests().subscribe(
    // (res) => {
    this.authService.logout();
    // },
    // (error) => {
    // this.app.loaderOff();
    // }
    // );
  }
}
