import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './dashboard/header/header.component';
import { MuniChooseComponent } from './dashboard/muni-choose/muni-choose.component';
import { ElementsModule } from './common/elements/elements.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActionsLineComponent } from './dashboard/actions-line/actions-line.component';
import { HowCanHelpComponent } from './dashboard/how-can-help/how-can-help.component';
import { cardsDataComponent } from './dashboard/cards-data/cards-data.component';
import { PeopleEnterComponent } from './dashboard/people-enter/people-enter.component';
import { FooterComponent } from './dashboard/footer/footer.component';
import { MatMenuModule } from '@angular/material/menu';
import { DigitalMenuComponent } from './dashboard/header/digital-menu/digital-menu.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { LoginComponent } from './login/login.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CodeInputModule } from 'angular-code-input';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RegisterComponent } from './register/register.component';
import { DetailsRegisterComponent } from './register/details-register/details-register.component';
import { MainHeaderComponent } from './layout/main-header/main-header.component';
import { MainSideBarComponent } from './layout/main-side-bar/main-side-bar.component';
import { FormsServiceModule } from './forms/FormsService.module';
import { TokenInterceptor } from './gurad/token.interceptol';
import { PopupGetMuniComponent } from './common/popup-get-muni/popup-get-muni.component';
import { FormsTypeComponent } from './dashboard/forms-type/forms-type.component';
import { FormTypeCardComponent } from './dashboard/forms-type/form-type-card/form-type-card.component';
import { DebtBalanceComponent } from './debt-balance/debt-balance.component';
import { DebtTableComponent } from './debt-balance/debt-table/debt-table.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { RandomPaymentsComponent } from './random-payments/random-payments.component';
import { RandomItemComponent } from './random-payments/random-item/random-item.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { RandomSumComponent } from './random-payments/random-sum/random-sum.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SettingComponent } from './setting/setting.component';
import { PasswordsComponent } from './setting/passwords/passwords.component';
import { ContactComponent } from './setting/contact/contact.component';
import { MoreSettingComponent } from './setting/more-setting/more-setting.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MobileSideBarComponent } from './layout/mobile-side-bar/mobile-side-bar.component';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { PersonalComponent } from './personal-details/personal/personal.component';
import { FamilyMembersComponent } from './personal-details/personal/family-members/family-members.component';
import { AssetComponent } from './personal-details/asset/asset.component';
import { MyFormsComponent } from './personal-details/my-forms/my-forms.component';
import { NotificationsComponent } from './personal-details/notifications/notifications.component';
import { BussinesComponent } from './personal-details/bussines/bussines.component';
import { FormsTableComponent } from './personal-details/my-forms/forms-table/forms-table.component';
import { RequestStatusPipe } from './personal-details/my-forms/forms-table/request-status.pipe';
import { DebtsBalanceMobileComponent } from './debt-balance/debts-balance-mobile/debts-balance-mobile.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MyAssetComponent } from './my-asset/my-asset.component';
import { AssetDetailsComponent } from './my-asset/asset-details/asset-details.component';
import { PaymentArrangementComponent } from './my-asset/payment-arrangement/payment-arrangement.component';
import { AddressesComponent } from './my-asset/addresses/addresses.component';
import { PropertyTaxComponent } from './my-asset/property-tax/property-tax.component';
import { DiscountComponent } from './my-asset/discount/discount.component';
import { VoucherToMailComponent } from './my-asset/voucher-to-mail/voucher-to-mail.component';
import { NoneValuePipe } from './common/pipes/none-value.pipe';
import { GuestDetailsComponent } from './random-payments/guest-details/guest-details.component';
import { MyPaymentsComponent } from './my-payments/my-payments.component';
import { PaymentSiteComponent } from './my-payments/payment-site/payment-site.component';
import { DiscountsModule } from './dicsountProject/discounts.module';
import { UserMenuComponent } from './dashboard/header/user-menu/user-menu.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ForgetPopupComponent } from './forget-password/forget-popup/forget-popup.component';
import { PaymentLineMobileComponent } from './my-payments/payment-line-mobile/payment-line-mobile.component';
import { MobileFooterComponent } from './dashboard/mobile-footer/mobile-footer.component';
import { MatTreeModule } from '@angular/material/tree';
import { ChooseNewPasswordComponent } from './login/choose-new-password/choose-new-password.component';
import {
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { GenderPipe } from './common/pipes/gender.pipe';
import { FamilyStatusPipe } from './common/pipes/family-status.pipe';
import { ProximityPipe } from './common/pipes/proximity.pipe';
import { AddNewUserComponent } from './setting/add-new-user/add-new-user.component';
import { CallToLoginComponent } from './layout/call-to-login/call-to-login.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    MuniChooseComponent,
    ActionsLineComponent,
    HowCanHelpComponent,
    cardsDataComponent,
    PeopleEnterComponent,
    FooterComponent,
    DigitalMenuComponent,
    LoginComponent,
    RegisterComponent,
    DetailsRegisterComponent,
    MainHeaderComponent,
    MainSideBarComponent,
    PopupGetMuniComponent,
    FormsTypeComponent,
    FormTypeCardComponent,
    DebtBalanceComponent,
    DebtTableComponent,
    RandomPaymentsComponent,
    RandomItemComponent,
    RandomSumComponent,
    SettingComponent,
    PasswordsComponent,
    ContactComponent,
    MoreSettingComponent,
    MobileSideBarComponent,
    PersonalDetailsComponent,
    PersonalComponent,
    FamilyMembersComponent,
    MyFormsComponent,
    NotificationsComponent,
    BussinesComponent,
    FormsTableComponent,
    RequestStatusPipe,
    AssetComponent,
    DebtsBalanceMobileComponent,
    MyAssetComponent,
    AssetDetailsComponent,
    PaymentArrangementComponent,
    AddressesComponent,
    PropertyTaxComponent,
    DiscountComponent,
    VoucherToMailComponent,
    NoneValuePipe,
    GuestDetailsComponent,
    MyPaymentsComponent,
    PaymentSiteComponent,
    UserMenuComponent,
    ForgetPasswordComponent,
    ForgetPopupComponent,
    PaymentLineMobileComponent,
    MobileFooterComponent,
    ChooseNewPasswordComponent,
    AddNewUserComponent,
    CallToLoginComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ElementsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatMenuModule,
    SlickCarouselModule,
    MatDialogModule,
    CodeInputModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsServiceModule,
    MatCheckboxModule,
    FormsServiceModule,
    MatStepperModule,
    MatExpansionModule,
    MatDividerModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatTabsModule,
    DiscountsModule,
    MatTreeModule,
    HammerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: TokenInterceptor,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
