<div id="perso" #perso>
  <mat-expansion-panel
    *ngIf="!mobile"
    [expanded]="panelOpen"
    #panel
    hideToggle
    class="row margin-b"
    disabled
  >
    <mat-expansion-panel-header>
      <div class="wrapper">
        <div class="muniLineText">
          <span class="title">{{ text.title }}</span>
          <div *ngIf="panelOpen" class="muniLine"></div>
        </div>
        <div class="details">
          <div class="item" *ngFor="let item of details">
            <div class="label">{{ item.label }}:</div>
            <span class="value"> {{ item.value }} </span>
          </div>
        </div>
        <button class="btnborder" (click)="panelOpen = true">עוד פרטים</button>
      </div>
    </mat-expansion-panel-header>
    <app-family-members [members]="familyMembers"></app-family-members>
    <button class="btn" (click)="panelOpen = false">פחות פרטים</button>
  </mat-expansion-panel>
  <div *ngIf="mobile" class="list">
    <div class="itemMobile" *ngFor="let item of details">
      <span class="label">{{ item.label }}:</span>
      <span class="value"> {{ item.value }} </span>
    </div>
    <!-- <button class="btnborder left" (click)="close()">עוד פרטים</button> -->
    <button class="btnborder left" (click)="members = true">עוד פרטים</button>
    <app-family-members *ngIf="mobile && members" [members]="familyMembers"></app-family-members>
  </div>
</div>
