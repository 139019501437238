<div #asset class="wrapper">
  <span class="title">{{ text.title }}</span>
  <my-select
    [config]="assetSelectConfig"
    [selected]="assetSelectConfig.selected"
    (selectionChange)="select($event)"
  ></my-select>
  <mat-tab-group *ngIf="!mobile" animationDuration="500ms">
    <mat-tab
      [labelClass]="color"
      *ngFor="let tab of tabs; let i = index"
      label="{{ tab.label }}"
    >
      <div class="bodyTab">
        <app-asset-details
          *ngIf="i == 0"
        ></app-asset-details>
        <app-payment-arrangement
          *ngIf="i == 1"
          [assetNumber]="assetSelectConfig.selected"
        ></app-payment-arrangement>
        <app-addresses
          *ngIf="i == 2"
        ></app-addresses>
        <app-property-tax
          *ngIf="i == 3"
        ></app-property-tax>
        <app-discount
          *ngIf="i == 4"
        ></app-discount>
        <app-voucher-to-mail
          *ngIf="i == 5"
        ></app-voucher-to-mail>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div *ngIf="mobile" class="content">
    <div class="header">
      <div class="data">
        <mat-expansion-panel
          id="panel{{ i }}"
          *ngFor="let line of tabs; let i = index"
          hideToggle
          [expanded]="line.panelOpened"
          [ngClass]="{
            lastchild: i == tabs.length-1,
          }"
        >
          <mat-expansion-panel-header
            (click)="openClose(i)"
            [ngClass]="{
              borderBottom: line.panelOpened
            }"
          >
            <span>{{ line.label }}</span>
          </mat-expansion-panel-header>
          <div class="bodyTab">
            <app-asset-details
              *ngIf="i == 0"
            ></app-asset-details>
            <app-payment-arrangement
              *ngIf="i == 1"
              [assetNumber]="assetSelectConfig.selected"
            ></app-payment-arrangement>
            <app-addresses
              *ngIf="i == 2"
            ></app-addresses>
            <app-property-tax
              *ngIf="i == 3"
            ></app-property-tax>
            <app-discount
              *ngIf="i == 4"
            ></app-discount>
            <app-voucher-to-mail
              *ngIf="i == 5"
            ></app-voucher-to-mail>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </div>
</div>
