import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { formDeme } from 'src/app/forms/elementForm/controlsDeme';
import { DiscoutsService } from '../discouts.service';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-discount-new-req',
  templateUrl: './discount-new-req.component.html',
  styleUrls: ['./discount-new-req.component.scss'],
})
export class DiscountNewReqComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') private myStepper: MatStepper;
  destroy$ = new Subject();
  text = {};
  clearControl = new BehaviorSubject<string>('');
  formdeme: formDeme;
  constructor(private discounts: DiscoutsService) {
    this.formdeme = discounts.discountFormDeme;
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  ngOnInit(): void {}
  saveDataLevel(event: any){}
}
