<div class="openPopup">
  <div class="flex">
    <span class="bold">{{ text.title }},</span>
    <span>{{ descBody.headText }}.</span>
    <span>{{ descBody.innerText }}.</span>
  </div>
  <div class="flex start">
    <span class="line">{{ text.text2 }}:</span>
    <span class="row" *ngFor="let t of descBody.docsList">
      <div class="circle"></div>
      {{ t.label }}
    </span>
  </div>
  <div class="flex">
    <span>{{ text.thank }}</span>
    <span>רשות מקומית {{ muniName }}</span>

  </div>
  <!-- <div class="flex"> -->
  <!-- <span>{{ text.and }}</span> -->
  <!-- </div> -->

  <button (click)="nextEmit()">עבור לפתיחת הבקשה</button>
</div>