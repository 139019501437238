<div class="wrapper" *ngIf="level != 3 ">
  <span class="title" >{{ text.title }}</span>
  <div class="page" *ngIf="level == 1">
    <div class="content">
      <app-random-item
        *ngFor="let element of panels; let i = index"
        [services]="servicesByCategories"
        [categories]="categories"
        [panel]="element"
        [isEdit]="element.isEdit"
        (deletePanel)="deletePanel($event)"
      ></app-random-item>

      <button  class="plus" (click)="plus()">+</button>
    </div>
    <div id="footerM" class="footerMobile" *ngIf="mobile">
      <span>סיכום שירותים שנבחרו({{count}})</span>
      <button class="pay" (click)="pay()">לתשלום {{sum| currency : "ILS"}}</button>
    </div>
    <div class="sum" *ngIf="!mobile">
      <app-random-sum></app-random-sum>
      <button class="pay" (click)="pay()">לתשלום </button>
    </div>
  </div>
  <div class="payPage" *ngIf="level == 2">
    <app-payment-page (goPrev)="goPrev(false)"></app-payment-page>
  </div>
 
</div>
<div class="payPage" *ngIf="level == 3 && mobile">
  <app-guest-details (goPrev)="goPrev(true)"></app-guest-details>
</div>