import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { emitObj, fullControl, multyFiels } from '../elementForm/control.model';
import {
  InputConfig,
  addControlToForm,
  generateForm,
} from '../elementForm/form-input/form-input.model';
import { AllValidationErrors } from '../models/form.model';
import { BehaviorSubject, Subject, from, takeUntil } from 'rxjs';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { FormsFunctionsService } from '../allForms/forms-functions.service';
import { FormsDataService } from '../forms-data.service';

@Component({
  selector: 'app-basic-form',
  templateUrl: './basic-form.component.html',
  styleUrls: ['./basic-form.component.scss'],
})
export class BasicFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() stepper: MatStepper;
  @Input() controlsList: fullControl[];
  @Input() hideNext: number;
  @Input() hidePrev: boolean;
  @Input() miniForm: boolean;
  @Input() btnText: string;
  @Input() autoSave: boolean;
  @Input() enterNext: boolean;
  @Input() singleForm: boolean;
  @Input() clearControl = new BehaviorSubject<string>('');
  @Input() focusControl = new BehaviorSubject<string>('');
  @Output() submitFunction = new EventEmitter<FormGroup>();
  @Output() getDataInput = new EventEmitter<{
    type: 'id' | 'paymentNumber' | 'resident' | 'idResident' | 'saveAllInputs';
    value: any;
    name?: string;
  }>();
  @Output() enterNextGo = new EventEmitter<any>();
  destroy$ = new Subject();
  form: FormGroup;
  disable = true;
  afterClick = false;
  clickValue = '';
  needSign = false;
  focus = 0;
  childsInputsData: emitObj[] = [];
  multyFilesData: multyFiels[] = [];
  childsInputsDeletedData: number[] = [];
  childControls: fullControl[] = [];
  constructor(
    private loading: LoadingService,
    private formsData: FormsDataService,
    private formsFunctions: FormsFunctionsService
  ) {}

  ngOnInit(): void {
    this.generateForm();
    this.formsData.haveChildControls$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && res.need) this.checkIfHaveChildFileID();
      });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  ngAfterViewInit(): void {
    if (this.enterNext) {
      this.focusInput(this.controlsList[0].inputControl.config.formControlName);
    }
  }
  focusInput(name: string) {
    window.setTimeout(function () {
      document.getElementById('fieldinput' + name)?.focus();
    }, 0);
  }
  checkIfHaveChildFileID() {
    this.controlsList.map((ctrl: fullControl) => {
      if (ctrl?.buttonControl?.childControls?.length > 0) {
        this.addChildControlToFileID(ctrl);
      }
    });
  }
  addChildControlToFileID(ctrl?: fullControl) {
    if (ctrl) {
      this.afterClick = true;
      this.clickValue = 'true';
      ctrl.buttonControl.childControls.map((file) => {
        this.childsInputsData.push({
          name: file.inputControl.config.formControlName,
          value: file.inputControl.config.file.fileID,
          errors: [],
        });
        this.childControls.push({
          controlType: 'input',
          inputControl: {
            config: {
              formControlName:
                'fileSefach' + (this.childsInputsData.length - 1),
              inputType: 'file',
              label: 'צילום תעודת זהות + ספח',
              name: 'fileSefach' + (this.childsInputsData.length - 1),
              required: true,
              type: 'file',
              file: {
                fileID: file.inputControl.config.file.fileID,
                fileName: file.inputControl.config.file.fileName,
              },
            },
          },
        });
      });
    } else {
      this.childControls.push({
        controlType: 'input',
        inputControl: {
          config: {
            formControlName: 'fileSefach' + this.childsInputsData.length,
            inputType: 'file',
            label: 'צילום תעודת זהות + ספח',
            name: 'fileSefach' + this.childsInputsData.length,
            required: true,
            type: 'file',
          },
        },
      });
    }
  }
  deleteChild(index: any, name: string, isMulty?: boolean) {
    if (isMulty) {
      let nameIndex = this.multyFilesData.findIndex((f) => f.name == name);
      if (nameIndex != -1) {
        let fileIndex = this.multyFilesData[nameIndex].values.findIndex(
          (f) => f == index
        );
        if (fileIndex != -1) {
          this.multyFilesData[nameIndex].values.splice(fileIndex, 1);
        }
      }
    } else {
      if (this.childsInputsData[index]?.value)
        this.childsInputsDeletedData.push(this.childsInputsData[index].value);
      this.childControls.splice(index, 1);
      this.childsInputsData.splice(index, 1);
      let value = -1;
      this.controlsList.map((ctrl: fullControl) => {
        if (ctrl?.buttonControl?.childControls?.length > 0) {
          ctrl?.buttonControl?.childControls.map((file, i) => {
            if (file.inputControl.config.formControlName == name) {
              value = i;
            }
          });
        }
      });
      let ctrlIndex = this.controlsList.findIndex(
        (f) => f?.buttonControl?.childControls?.length > 0
      );
      if (ctrlIndex != -1) {
        let inputIndex = this.controlsList[
          ctrlIndex
        ].buttonControl.childControls.findIndex(
          (f) => f?.inputControl.config.formControlName == name
        );
        if (inputIndex != -1) {
          this.controlsList[ctrlIndex].buttonControl.childControls.splice(
            inputIndex,
            1
          );
        }
      }
    }
  }
  submitInput(
    obj: emitObj,
    isBtn?: boolean,
    isAddPersons?: boolean,
    isChildData?: boolean,
    isMultyFiles?: boolean
  ) {
    if (isChildData) {
      let find = this.childsInputsData.findIndex((f) => f.name == obj.name);
      if (find != -1) {
        this.childsInputsData[find] = obj;
      } else this.childsInputsData.push(obj);
    } else if (isAddPersons) {
      this.afterClick = true;
      this.clickValue = obj.value.toString();
      let btn = this.controlsList.findIndex(
        (ctrl) => ctrl?.buttonControl?.config?.name == obj.name
      );
      if (btn >= 0) {
        this.addChildControlToFileID();
      }
    } else if (isBtn) {
      this.afterClick = true;
      this.clickValue = obj.value;
      let btn = this.controlsList.findIndex(
        (ctrl) => ctrl?.buttonControl?.config?.name == obj.name
      );
      if (btn >= 0) {
        this.controlsList[btn].buttonControl.value = obj.value;
        this.form?.controls[obj.name]?.setValue(obj.value);
        if (this.controlsList[btn].buttonControl.childControls.length > 0)
          this.childControls =
            this.controlsList[btn].buttonControl.childControls;
      } else if (this.controlsList[0].buttonControl.childControls) {
        let btn2 = this.controlsList[0].buttonControl.childControls.findIndex(
          (ctrl) => ctrl.buttonControl.config.name == obj.name
        );
        if (btn2 >= 0) {
          this.controlsList[0].buttonControl.childControls[
            btn2
          ].buttonControl.value = obj.value;
        }
      }
    } else if (isMultyFiles) {
      if (this.form?.controls[obj.name].value) {
        let prev = this.form?.controls[obj.name].value;
        prev.push(obj.value);
        this.form?.controls[obj.name]?.setValue(prev);
      } else {
        this.form?.controls[obj.name]?.setValue([obj.value]);
      }
    } else if (obj.value || obj.value == 0) {
      this.form?.controls[obj.name]?.setValue(obj.value);
      if (obj.errors) {
        const controlErrors: ValidationErrors = obj.errors;
        if (controlErrors !== null) {
          Object.keys(controlErrors).forEach((keyError) => {
            if (controlErrors[keyError] == true)
              this.form?.controls[obj.name]?.setErrors(controlErrors);
          });
        }
      } else if (this.formsData.currentFormType == 404) {
        //fileopeningmobile
        this.getDataInput.emit({
          type: 'saveAllInputs',
          value: obj.value,
          name: obj.name,
        });
      }
      if (
        this.formsData.currentFormType == 40 &&
        (obj.name == 'birthLand' || obj.name == 'relationship')
      ) {
        //fileOpening
        this.getDataInput.emit({
          type: 'saveAllInputs',
          value: obj.value,
          name: obj.name,
        });
      }
      if (this.formsData.currentFormType == 70) {
        //residentconfirm
        if (obj.name == 'id') {
          this.formsFunctions.personID$.next(obj.value);
        }
        this.getDataInput.emit({
          type: 'resident',
          value: obj.value,
          name: obj.name,
        });
      } else if (obj.name == 'id') {
        this.formsFunctions.personID$.next(obj.value);
        this.getDataInput.emit({ type: 'id', value: obj.value });
      } else if (obj.name == 'paymentNumber') {
        this.getDataInput.emit({ type: 'paymentNumber', value: obj.value });
      }
      if (this.form.valid) {
        if (this.autoSave) {
          this.submitFunction.emit(this.form);
        }
        this.disable = false;
      }
    }
  }
  submitForm() {
    if (this.controlsList[0].controlType == 'input') {
      if (this.form?.valid) {
        this.loading.show();
        if (this.childsInputsData.length > 0) {
          this.childsInputsData.map((child) => {
            let obj = this.childControls.find(
              (c) => c.inputControl.config.formControlName == child.name
            );
            if (obj) addControlToForm(this.form, obj.inputControl.config);
            this.form.controls[child.name].setValue(child.value);
          });
          if (this.childsInputsDeletedData.length > 0) {
            this.childsInputsDeletedData.map((d, i) => {
              let config: InputConfig = {
                formControlName: 'deleteFile' + i,
                inputType: 'file',
                label: 'deleteFile' + i,
                name: 'deleteFile' + i,
                required: false,
                type: 'file',
              };
              addControlToForm(this.form, config);
              this.form.controls['deleteFile' + i].setValue(d);
            });
          }
        }
        this.submitFunction.emit(this.form);
      } else {
        this.getErrorsForPopup(this.form.controls);
        this.stepper.selected.completed = false;
      }
    } else if (this.controlsList[0].controlType == 'button') {
      this.controlsList.map((ctrl) => {
        if (!ctrl.buttonControl.value) {
        }
        // if (
        //   ctrl.buttonControl.childControls &&
        //   ctrl.buttonControl.childControls.length > 0
        // ) {
        //   ctrl.buttonControl.childControls.map((child) => {
        //     if (!child.buttonControl.value) {
        //       return true;
        //     }

        //   });
        // }
      });
    }
  }
  nextEnterGo(index: number) {
    if (index == this.controlsList.length - 1) {
      this.enterNextGo.emit();
    } else {
      let name =
        this.controlsList[index + 1].inputControl.config.formControlName;
      this.focusInput(name);
    }
  }
  tabClickDate(i: number) {
    if (this.controlsList[i + 1])
      this.focusControl.next(
        this.controlsList[i + 1].inputControl.config.formControlName
      );
  }
  private isHaveErrors(controls: any): AllValidationErrors[] {
    let errors: AllValidationErrors[] = [];
    Object.keys(controls).forEach((key) => {
      const controlErrors: ValidationErrors = controls[key].errors;
      if (controlErrors !== null) {
        let a = this.controlsList.find(
          (c) =>
            (c.inputControl && c.inputControl.config.formControlName == key) ||
            (c.buttonControl && c.buttonControl.config.name == key)
        );
        if (a) {
          if (
            a?.inputControl?.config?.type != 'select' &&
            a.inputControl?.config?.value
          ) {
            this.form.controls[key].setValue(a.inputControl.config.value);
          } else if (
            a.inputControl?.config.type == 'select' &&
            (a.inputControl?.selected || a.inputControl?.selectconfig?.selected)
          ) {
            this.form.controls[key].setValue(
              a.inputControl.selected
                ? a.inputControl.selected
                : a.inputControl.selectconfig.selected
            );
          } else if (
            a.inputControl?.config.type == 'file' &&
            a.inputControl?.config?.file?.fileID
          ) {
            this.form.controls[key].setValue(a.inputControl.config.file.fileID);
          }
          // else if (
          //   (a.inputControl?.config.type == 'gender' &&
          //     a.inputControl?.config?.value == 0) ||
          //   a.inputControl?.config?.value == 1
          // ) {
          //   this.form.controls[key].setValue(a.inputControl.config.value);
          // }
          else if (
            a.inputControl?.config?.type == 'date' &&
            a.inputControl?.config.day
          ) {
            this.form.controls[key].setValue(
              a.inputControl.config.month +
                '/' +
                +a.inputControl.config.day +
                '/' +
                a.inputControl.config.year
            );
          } else if (
            a?.buttonControl &&
            (a.buttonControl?.config?.type == 'addPersons' ||
              a.buttonControl?.config?.type == 'toggle-group')
          ) {
            if (this.childsInputsData) {
              this.childsInputsData.map((data) => {
                this.form.controls[data.name].setValue(data.value);
              });
            }
          } else {
            Object.keys(controlErrors).forEach((keyError) => {
              let ctrl = this.controlsList.find(
                (c) =>
                  (c.inputControl &&
                    c.inputControl.config.formControlName == key) ||
                  (c.buttonControl && c.buttonControl.config.name == key)
              );
              if (ctrl)
                errors.push({
                  control_name: a.inputControl.config.label,
                  form_control_name: ctrl.inputControl.config.formControlName,
                  error_name: keyError,
                  error_value: controlErrors[keyError],
                });
              else {
                errors.push({
                  control_name: 'כל השדות',
                  form_control_name: '',
                  error_name: keyError,
                  error_value: controlErrors[keyError],
                });
              }
            });
          }
        } else {
          let btnChild = this.controlsList.findIndex(
            (c) => c.buttonControl && c.buttonControl.childControls?.length > 0
          );
          if (btnChild != -1) {
            this.childsInputsData.map((data) => {
              this.form.controls[data.name].setValue(data.value);
            });
          } else {
            Object.keys(controlErrors).forEach((keyError) => {
              let ctrl = this.controlsList.find(
                (c) =>
                  (c.inputControl &&
                    c.inputControl.config.formControlName == key) ||
                  (c.buttonControl && c.buttonControl.config.name == key)
              );
              if (ctrl)
                errors.push({
                  control_name: ctrl.inputControl.config.label,
                  form_control_name: ctrl.inputControl.config.formControlName,
                  error_name: keyError,
                  error_value: controlErrors[keyError],
                });
              else {
                errors.push({
                  control_name: 'כל השדות',
                  form_control_name: '',
                  error_name: keyError,
                  error_value: controlErrors[keyError],
                });
              }
            });
          }
        }
      } else {
        let a = this.controlsList.find(
          (c) =>
            c.inputControl?.doubleConfig &&
            (c.inputControl?.doubleConfig[0]?.formControlName == key ||
              c.inputControl?.doubleConfig[1]?.formControlName == key)
        );
        if (
          a &&
          (a.inputControl?.doubleConfig[0].value ||
            a.inputControl?.doubleConfig[1].value)
        ) {
          this.form.controls[
            a.inputControl.doubleConfig[0].formControlName
          ].setValue(a.inputControl.doubleConfig[0].value);
          this.form.controls[
            a.inputControl.doubleConfig[1].formControlName
          ].setValue(a.inputControl.doubleConfig[1].value);
        }
      }
    });
    return errors;
  }
  private getErrorsForPopup(controls: any) {
    Object.keys(controls).forEach((key) => {
      let a = this.controlsList.find(
        (c) => c.inputControl && c.inputControl.config.formControlName == key
      );
      if (a && !a.inputControl.config.required) {
        if (
          a &&
          a.inputControl.config.type != 'select' &&
          a.inputControl.config.value
        ) {
          this.form.controls[key].setValue(a.inputControl.config.value);
        } else if (
          a &&
          a.inputControl.config.type == 'select' &&
          (a.inputControl.selected || a.inputControl.selectconfig.selected)
        ) {
          this.form.controls[key].setValue(
            a.inputControl.selected
              ? a.inputControl.selected
              : a.inputControl.selectconfig.selected
          );
        } else if (
          a &&
          a.inputControl.config.type == 'file' &&
          a.inputControl.config?.file?.fileID
        ) {
          this.form.controls[key].setValue(a.inputControl.config.file.fileID);
        } else if (
          a &&
          a.inputControl.config.type == 'date' &&
          a.inputControl.config.day
        ) {
          this.form.controls[key].setValue(
            a.inputControl.config.month +
              '/' +
              +a.inputControl.config.day +
              '/' +
              a.inputControl.config.year
          );
        }
      }
      if (
        a &&
        a.inputControl.config.type == 'multy-files' &&
        a.inputControl.config.fileList
      ) {
        let arr = [];
        a.inputControl.config.fileList.map((f) => {
          arr.push(f.fileID);
        });
        this.form.controls[key].setValue(arr);
      }
    });
    let errors = this.isHaveErrors(controls);
    if (errors.length > 0) {
      this.generateErrors(errors);
    } else {
      this.submitForm();
    }
  }
  private generateErrors(errors): string[] {
    let str: string[] = [];
    for (let i = 0; i < errors.length; i++) {
      let ctrl = this.controlsList.findIndex(
        (v) =>
          v.inputControl.config.formControlName == errors[i].form_control_name
      );
      switch (errors[i].error_name) {
        case 'required':
          this.controlsList[ctrl].inputControl.config.errors = 'required';
          // str.push(i + 1 + '. יש למלא ' + errors[i].control_name);
          break;
        case 'phone':
          this.controlsList[ctrl].inputControl.config.errors = 'phone';
          // str.push(i + 1 + ' יש למלא נייד תקין.');
          break;
        case 'id':
          this.controlsList[ctrl].inputControl.config.errors = 'id';
          // str.push(i + 1 + ' יש למלא מספר זהות תקין.');
          break;
        case 'email':
          this.controlsList[ctrl].inputControl.config.errors = 'email';
          // str.push(i + 1 + ' יש למלא כתובת אימייל תקינה.');
          break;
        case 'name':
          this.controlsList[ctrl].inputControl.config.errors = 'name';
          // str.push(i + 1 + ' יש למלא שמות תקינים.');
          break;
        case 'plusNumbers':
          this.controlsList[ctrl].inputControl.config.errors = 'plusNumbers';
          // str.push(i + 1 + ' יש למלא מספרים גדולים מאפס.');
          break;
        case 'dateAfterToday':
          this.controlsList[ctrl].inputControl.config.errors = 'dateAfterToday';
          // str.push(i + 1 + ' יש למלא מספרים גדולים מאפס.');
          break;
      }
    }
    return str;
  }
  private generateForm() {
    if (this.controlsList?.length > 0) {
      let configs: any[] = [];
      this.controlsList.map((ctrl) => {
        if (ctrl.inputControl && !ctrl.inputControl.doubleConfig)
          configs.push(ctrl.inputControl?.config);
        else {
          if (ctrl.buttonControl) {
            configs.push(ctrl.buttonControl?.config);
            if (ctrl.buttonControl.childControls?.length > 0) {
              ctrl.buttonControl.childControls.map((child) => {
                configs.push(child.inputControl.config);
              });
            }
          } else
            ctrl.inputControl.doubleConfig.map((c) => {
              configs.push(c);
            });
        }
        if (ctrl.controlType == 'sign') {
          this.needSign = true;
        }
      });
      this.form = generateForm(configs);
      this.controlsList.map((ctrl) => {
        if (ctrl.inputControl.config.value) {
          this.form.controls[ctrl.inputControl.config.formControlName].setValue(
            ctrl.inputControl.config.value
          );
        } else if (ctrl.inputControl.selectconfig?.selected) {
          this.form.controls[ctrl.inputControl.config.formControlName].setValue(
            ctrl.inputControl.selectconfig.selected
          );
        }
      });
    }
  }
}
