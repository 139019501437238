import { Component, Input } from '@angular/core';
import { ILoader } from './loader.model';
import { Observable, map } from 'rxjs';
import { LoadingService } from './loading.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
   public message = '' ;


  constructor(private loadingService: LoadingService) {
    // this.loadingService.message.subscribe(res=>{
      // this.message = res;
    // })
   }

  ngOnInit() {
  }
}
