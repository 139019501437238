<div class="wrapper">
  <table >
    <table class="table widthTable miniInner">
      <tr>
        <th *ngFor="let title of displayedColumns">
          <mat-checkbox
            *ngIf="title == 'checkAll'"
            #field
            id="field"
            [value]="selected"
            (change)="checkAll($event)"
          ></mat-checkbox>

          <span *ngIf="title != 'checkAll'"> {{ title }}</span>
        </th>
      </tr>
    </table>
    <tbody>
      <tr>
        <td class="body scroll">
          <table class=" table miniInner">
            <tr class="row" *ngFor="let element of data; let i = index">
              <td width="5%">
                <mat-checkbox
                 id="checkbox"
                  id="field"
                  [value]="selected"
                  [checked]="element.isToPay"
                  (change)="check($event, i)"
                ></mat-checkbox>
              </td>
              <td width="13.5%">{{ element.assetId }}</td>
              <td width="13.5%">{{ element.chargeType }}</td>
              <td width="13.5%">{{ element.year }}</td>
              <td width="13.5%">
                {{ element.interest | yesNo }}
              </td>
              <td width="13.5%" class="ltr">
                {{ element.sum | currency : "ILS" }}
              </td>
              <td width="13.5%" class="ltr">
                {{ element.sumToPay | currency : "ILS" }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr></tr>
      <tr class="footer">
        <td class="body">
          <table class="table haveBtn">
            <tr class="row">
              <td width="15%">{{ text.sumTitle }} ({{ count }})</td>
              <td width="3%"></td>
              <td width="13.5%"></td>
              <td width="13.5%"></td>
              <td width="13.5%"></td>
              <td width="13.5%" ></td>
              <td width="13.5%" class="ltr sum">{{ sum | currency : "ILS" }}</td>
            </tr>
          </table>
          <button class="next" [disabled]="sum <=0" mat-button (click)="pay()">
            {{ text.pay }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
