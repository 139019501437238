import { buttonControl, formVersion, fullControl } from './control.model';
import {
  IButton,
  IButtonToggleConfig,
  setIButton,
} from './my-button/my-button.model';
import { getInputConfig, InputConfig } from './form-input/form-input.model';
import { FormType } from '../allForms/functionEnum.model';
import { familyStatusList } from '../allForms/open-bug/openBug.model';
import { numLiversList } from './formsVersions';
import { descBody } from '../popups/desc-form-popup/descFormPopup.model';
import { inputConfig } from 'src/app/common/elements/my-big-input/my-big-input.model';
export const typeRequest = setIButton(
  'toggle-group',
  'בחר את האופציה המתאימה',
  'typeRequest',
  null,
  null,
  false,
  'typeRequest',
  true,
  true
);

export const allowWater = setIButton(
  'checkbox',
  'אני מאשר לעדכן את תאגיד המים בנתונים',
  'allowWater',
  null,
  null,
  false,
  'allowWater',
  true,
  true
);
export const child = setIButton(
  'toggle-group',
  'סמן:',
  'yesno',
  null,
  null,
  false,
  'imBuyOrNot',
  true,
  true
);
export const btnHaveAfter18 = setIButton(
  'toggle-group',
  'האם קיימות נפשות מעל גיל 18 המתגוררות בנכס?',
  'btnHaveAfter18',
  null,
  null,
  false,
  'btnHaveAfter18',
  true,
  true
);
export const child2 = setIButton(
  'toggle-group',
  'האם אתה מיופה כח / עורך דין / מתווך?',
  'child2',
  '',
  '',
  false,
  'isMeyoope',
  true,
  true
);
export const toggleConfigTypeReq: IButtonToggleConfig[] = [
  {
    value: '1',
    name: 'מכירה / קניה של הנכס',
    miniBtn: false,
  },
  {
    value: '2',
    name: 'התחלת שכירות',
    miniBtn: false,
  },
  {
    value: '3',
    name: 'סיום שכירות',
    miniBtn: false,
  },
];
export const toggleConfigYesNo: IButtonToggleConfig[] = [
  {
    value: '1',
    name: 'כן',
    miniBtn: true,
  },
  {
    value: '2',
    name: 'לא',
    miniBtn: true,
  },
];
export const toggleConfigChild: IButtonToggleConfig[] = [
  {
    value: '1',
    name: 'אני הקונה',
    miniBtn: true,
  },
  {
    value: '2',
    name: 'אני המוכר',
    miniBtn: true,
  },
];
export const btnBuyChildControl: buttonControl = {
  config: child,
  btnName: 'btnBuyChild',
  toggleConfig: toggleConfigChild,
};
export const btnHaveAfter18Control: buttonControl = {
  config: btnHaveAfter18,
  btnName: 'btnHaveAfter18',
  value: '123456',
  toggleConfig: toggleConfigYesNo,
};
export const btnYesNoControl: buttonControl = {
  config: child2,
  btnName: 'btnYesNo',
  toggleConfig: toggleConfigYesNo,
};
export const file: InputConfig = getInputConfig(
  'file',
  'file',
  'fileid',
  'fileid',
  'none',
  true,
  'יש לצרף צילום תעודת זהות',
  false,
  false
);
export const fileSefach: InputConfig = getInputConfig(
  'file',
  'file',
  'fileSefach',
  'fileSefach',
  'none',
  true,
  'צילום ת.ז + ספח של מגיש הבקשה',
  false,
  false
);
export const fileSefachAnyone: InputConfig = getInputConfig(
  'file',
  'file',
  'fileSefachAnyone',
  'fileSefachAnyone',
  'none',
  true,
  'צילום תעודת זהות+ספח ',
  false,
  false
);
export const filecommissioned: InputConfig = getInputConfig(
  'file',
  'file',
  'filecommissioned',
  'filecommissioned',
  'none',
  true,
  'צילום מסמך ייפוי כח',
  false,
  false
);
export const fileWaterMone: InputConfig = getInputConfig(
  'file',
  'file',
  'fileWaterMone',
  'fileWaterMone',
  'none',
  true,
  'יש לצרף צילום של מונה המים',
  false,
  false
);
export const tabo: InputConfig = getInputConfig(
  'file',
  'file',
  'tabo',
  'tabo',
  'none',
  true,
  'קובץ נוסח טאבו',
  false,
  false
);
export const tabo2: InputConfig = getInputConfig(
  'file',
  'file',
  'tabo2',
  'tabo2',
  'none',
  false,
  'קובץ נוסף במקרה הצורך',
  false,
  false
);
export const taboDira: InputConfig = getInputConfig(
  'file',
  'file',
  'taboDira',
  'taboDira',
  'none',
  false,
  'תצהיר לפטור לדירה עד 140מ"ר',
  false,
  false
);
export const taboAnother: InputConfig = getInputConfig(
  'file',
  'file',
  'taboDira',
  'taboDira',
  'none',
  false,
  'צוואה/העברה ללא תמורה/אחר',
  false,
  false
);
export const taboNotes: InputConfig = getInputConfig(
  'input',
  'input',
  'taboNotes',
  'taboNotes',
  'none',
  false,
  'הערות',
  false,
  false
);
export const fileshoper: InputConfig = getInputConfig(
  'file',
  'file',
  'proofFile',
  'proofFile',
  'none',
  true,
  'יש לצרף הסכם מכר/נסח טאבו',
  false,
  false
);
export const fileRent: InputConfig = getInputConfig(
  'file',
  'file',
  'proofFile',
  'proofFile',
  'none',
  true,
  'הסכם שכירות / הצהרת בעל הנכס',
  false,
  false
);
export const lastName: InputConfig = getInputConfig(
  'input',
  'input',
  'lastName',
  'lastName',
  'name',
  true,
  'שם משפחה',
  false,
  false
);
export const firstName: InputConfig = getInputConfig(
  'input',
  'input',
  'firstName',
  'firstName',
  'name',
  true,
  'שם פרטי',
  false,
  false
);
export const phone: InputConfig = getInputConfig(
  'input',
  'number',
  'phone',
  'phone',
  'phone',
  true,
  'נייד',
  false,
  false
);
export const phoneMain: InputConfig = getInputConfig(
  'input',
  'number',
  'phoneMain',
  'phoneMain',
  'phone',
  true,
  'מספר טלפון ראשי',
  false,
  false
);
export const postalCode: InputConfig = getInputConfig(
  'input',
  'number',
  'postalCode',
  'postalCode',
  'none',
  true,
  'מיקוד',
  false,
  false
);
export const phoneHome: InputConfig = getInputConfig(
  'input',
  'number',
  'phoneHome',
  'phoneHome',
  'none',
  true,
  'מספר טלפון בבית',
  false,
  false
);
export const paymentNumber: InputConfig = {
  formControlName: 'paymentNumber',
  inputType: 'number',
  label: 'מספר משלם',
  name: 'paymentNumber',
  required: true,
  type: 'input',
  validation: 'plusNumbers',
  disabled: false,
};
export const voucherNumber: InputConfig = {
  formControlName: 'voucherNumber',
  inputType: 'number',
  label: 'מספר שובר/מסלקה',
  name: 'voucherNumber',
  required: true,
  type: 'input',
  icon: 'voucher.svg',
  validation: 'plusNumbers',
};
export const id: InputConfig = getInputConfig(
  'input',
  'number',
  'id',
  'id',
  'id',
  true,
  ' מספר זהות - 9 ספרות',
  false,
  false,

);
export const email: InputConfig = getInputConfig(
  'input',
  'input',
  'email',
  'email',
  'email',
  true,
  'אימייל',
  false,
  false
);
export const assetType: InputConfig = getInputConfig(
  'select',
  'select',
  'assetType',
  'assetType',
  'none',
  true,
  'סוג הנכס',
  false,
  false
);
export const assetTypeOwner: InputConfig = getInputConfig(
  'select',
  'select',
  'assetTypeOwner',
  'assetTypeOwner',
  'none',
  true,
  'סוג שימוש',
  false,
  false
);
export const assetTypeTabo: InputConfig = getInputConfig(
  'input',
  'input',
  'assetTypeTabo',
  'assetTypeTabo',
  'none',
  true,
  'סוג נכס',
  false,
  true
);
export const approvalPurpose: InputConfig = getInputConfig(
  'select',
  'select',
  'approvalPurpose',
  'approvalPurpose',
  'none',
  true,
  'מטרת אישור',
  false,
  false
);
export const enterTypeOwner: InputConfig = getInputConfig(
  'select',
  'select',
  'enterTypeOwner',
  'enterTypeOwner',
  'none',
  true,
  'צרכן נכנס',
  false,
  false
);
export const assetNumber: InputConfig = getInputConfig(
  'select',
  'select',
  'assetNumber',
  'assetNumber',
  'plusNumbers',
  true,
  'מספר הנכס',
  false,
  false
);
export const assetNumberInput: InputConfig = getInputConfig(
  'input',
  'text',
  'assetNumber',
  'assetNumber',
  'plusNumbers',
  true,
  'מספר הנכס',
  false,
  false
);
export const address: InputConfig = getInputConfig(
  'input',
  'input',
  'address',
  'address',
  'name',
  true,
  'כתובת הקונה',
  false,
  false
);
export const addressAsset: InputConfig = getInputConfig(
  'input',
  'input',
  'addressAsset',
  'addressAsset',
  'name',
  true,
  'כתובת הנכס',
  false,
  true
);
export const addressBuyer: InputConfig = getInputConfig(
  'input',
  'input',
  'addressBuyer',
  'addressBuyer',
  'name',
  true,
  'כתובת השוכר',
  false,
  false
);
export const addressBaal: InputConfig = getInputConfig(
  'input',
  'input',
  'addressBaal',
  'addressBaal',
  'name',
  true,
  'כתובת בעל הנכס',
  false,
  false
);
export const bankName: InputConfig = getInputConfig(
  'input',
  'input',
  'bankName',
  'bankName',
  'name',
  true,
  'שם הבנק/החברה אליה מופנה האישור',
  false,
  false
);
export const numCardWater: InputConfig = getInputConfig(
  'input',
  'number',
  'numCardWater',
  'numCardWater',
  'plusNumbers',
  true,
  'מספר מד מים',
  false,
  false
);
export const numLiversNow: InputConfig = getInputConfig(
  'input',
  'number',
  'numLivers',
  'numLivers',
  'plusNumbers',
  true,
  '?כמה מתגוררים מעודכנים כיום',
  false,
  false
);
export const numLiversLetter: InputConfig = getInputConfig(
  'input',
  'number',
  'numLivers',
  'numLivers',
  'plusNumbers',
  true,
  'לכמה מתגוררים לעדכן?',
  false,
  false
);
export const endMone: InputConfig = getInputConfig(
  'input',
  'number',
  'endMone',
  'endMone',
  'plusNumbers',
  true,
  'קריאת מונה ליום הכניסה',
  false,
  false
);

export const city: InputConfig = getInputConfig(
  'input',
  'text',
  'city',
  'city',
  'none',
  true,
  'יישוב',
  false,
  false,
  [],
  null,
  true
);

export const citySelect: InputConfig = getInputConfig(
  'select',
  'select',
  'city',
  'city',
  'none',
  true,
  'יישוב',
  false,
  false,
  [],
  null,
  true
);
export const street: InputConfig = getInputConfig(
  'input',
  'text',
  'street',
  'street',
  'none',
  true,
  'רחוב',
  false,
  false,
  [],
  null,
  true
);
export const streetSelect: InputConfig = getInputConfig(
  'select',
  'select',
  'street',
  'street',
  'none',
  true,
  'רחוב',
  false,
  false,
  [],
  null,
  true
);
export const houseNumber: InputConfig = getInputConfig(
  'input',
  'number',
  'houseNumber',
  'houseNumber',
  'plusNumbers',
  false,
  'מספר בית',
  true,
  false
);
export const apartment: InputConfig = getInputConfig(
  'input',
  'number',
  'apartment',
  'apartment',
  'plusNumbers',
  false,
  'דירה',
  true,
  false
);
export const houseEnter: InputConfig = getInputConfig(
  'input',
  'text',
  'houseEnter',
  'houseEnter',
  'plusNumbers',
  false,
  'כניסה',
  true,
  false
);
export const floorHouseEnter: InputConfig = getInputConfig(
  'input',
  'text',
  'floorHouseEnter',
  'floorHouseEnter',
  'plusNumbers',
  false,
  '',
  true,
  false
);
export const houseNumberApartment: InputConfig = getInputConfig(
  'input',
  'text',
  'houseNumberApartment',
  'houseNumberApartment',
  'plusNumbers',
  false,
  '',
  true,
  false
);
export const floor: InputConfig = getInputConfig(
  'input',
  'number',
  'floor',
  'floor',
  'plusNumbers',
  false,
  'קומה',
  true,
  false
);
export const dateChange: InputConfig = getInputConfig(
  'date',
  'date',
  'dateChange',
  'dateChange',
  'none',
  true,
  'תאריך ביצוע החלפת משלמים',
  false,
  false,
  [],
  null,
  true
);
export const dateOutOwner: InputConfig = getInputConfig(
  'date',
  'date',
  'dateOutOwner',
  'dateOutOwner',
  'none',
  true,
  'תאריך עזיבת הנכס',
  false,
  false,
  [],
  null,
  true
);
export const birthDate: InputConfig = getInputConfig(
  'date',
  'date',
  'birthDate',
  'birthDate',
  'none',
  true,
  'תאריך לידה',
  false,
  false,
  [],
  null,
  true
);
export const age: InputConfig = getInputConfig(
  'input',
  'number',
  'age',
  'age',
  'none',
  true,
  'גיל',
  false,
  false,
  [],
  null,
  true
);
export const dateInOwner: InputConfig = getInputConfig(
  'date',
  'date',
  'dateInOwner',
  'dateInOwner',
  'none',
  true,
  'תאריך כניסה לנכס',
  false,
  false,
  [],
  null,
  true
);
export const dateReadMone: InputConfig = getInputConfig(
  'date',
  'date',
  'dateReadMone',
  'dateReadMone',
  'none',
  true,
  'תאריך קריאת מונה',
  false,
  false,
  [],
  null,
  true
);
export const btnYesNoConfig: fullControl = {
  controlType: 'button',
  buttonControl: btnYesNoControl,
};

export const btnBuyChildConfig: fullControl = {
  controlType: 'button',
  buttonControl: btnBuyChildControl,
};
export const btnHaveAfter18Config: fullControl = {
  controlType: 'button',
  buttonControl: btnHaveAfter18Control,
};

export const btnBuyControl: buttonControl = {
  config: typeRequest,
  btnName: 'btnBuy',
  toggleConfig: toggleConfigTypeReq,
  childControls: [btnBuyChildConfig, btnYesNoConfig],
};
export const allowWaterControl: buttonControl = {
  config: allowWater,
  btnName: 'allowWater',
};
export const btnBuyConfig: fullControl = {
  controlType: 'button',
  buttonControl: btnBuyControl,
};
export const allowWaterConfig: fullControl = {
  controlType: 'button',
  buttonControl: allowWaterControl,
};
export const maritalStatusConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: {
      formControlName: 'maritalStatus',
      inputType: 'text',
      label: 'מצב משפחתי',
      name: 'maritalStatus',
      required: true,
      type: 'select',
    },
    selectconfig: {
      bigSelect: false,
      copyList: familyStatusList,
      lable: 'מצב משפחתי',
      list: familyStatusList,
    },
  },
};
export const numLiversNowSelectConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: {
      formControlName: 'numLiversNow',
      inputType: 'text',
      label: 'כמה מתגוררים מעודכנים כיום?',
      name: 'numLiversNow',
      required: true,
      type: 'select',
    },
    selectconfig: {
      bigSelect: false,
      copyList: numLiversList,
      lable: 'כמה מתגוררים מעודכנים כיום?',
      list: numLiversList,
    },
  },
};
export const numLiversLetterSelectConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: {
      formControlName: 'numLiversLetter',
      inputType: 'text',
      label: 'לכמה מתגוררים לעדכן?',
      name: 'numLiversLetter',
      required: true,
      type: 'select',
    },
    selectconfig: {
      bigSelect: false,
      copyList: numLiversList,
      lable: 'לכמה מתגוררים לעדכן?',
      list: numLiversList,
    },
  },
};
export const streetConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: street,
    //  selectconfig: {
    //   bigSelect: false,
    //   copyList: [
    //     {
    //       label: 'הנביאים',
    //       value: 1,
    //     },
    //     {
    //       label: 'בן יהודה',
    //       value: 2,
    //     },
    //     {
    //       label: 'יפו',
    //       value: 3,
    //     },
    //   ],
    //   lable: 'רחוב',
    //   list:  [
    //     {
    //       label: 'הנביאים',
    //       value: 1,
    //     },
    //     {
    //       label: 'בן יהודה',
    //       value: 2,
    //     },
    //     {
    //       label: 'יפו',
    //       value: 3,
    //     },
    //   ],
    //  }
  },
};
export const streetSelectConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: streetSelect,
    selectconfig: {
      bigSelect: false,
      copyList: [],
      lable: 'רחוב',
      list: [],
    },
  },
};
export const cityConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: city,
    // selectconfig:{
    //   bigSelect: false,
    //   copyList:  [
    //     {
    //       label: 'ירושלים',
    //       value: 1,
    //     },
    //     {
    //       label: 'תל אביב',
    //       value: 2,
    //     },
    //     {
    //       label: 'כפר סבא',
    //       value: 3,
    //     },
    //   ],
    //   lable: 'יישוב',
    //   list:  [
    //     {
    //       label: 'ירושלים',
    //       value: 1,
    //     },
    //     {
    //       label: 'תל אביב',
    //       value: 2,
    //     },
    //     {
    //       label: 'כפר סבא',
    //       value: 3,
    //     },
    //   ],
    // }
  },
};
export const citySelectConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: citySelect,
    selectconfig: {
      bigSelect: false,
      copyList: [],
      lable: 'יישוב',
      list: [],
    },
  },
};
export const firstNameconfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: firstName,
  },
};
export const lastNameconfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: lastName,
  },
};
export const emailconfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: email,
  },
};
export const phoneconfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: phone,
  },
};
export const phoneMainconfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: phoneMain,
  },
};
export const postalCodeConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: postalCode,
  },
}
export const phoneHomeconfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: phoneHome,
  },
};
export const paymentNumberconfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: paymentNumber,
  },
};
export const voucherNumberConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: voucherNumber,
    icon: 'voucher.svg',
  },
};
export const idconfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: id,
  },
};
export const fileconfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: file,
  },
};
export const fileSefachconfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: fileSefach,
  },
};
export const fileSefachAnyoneConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: fileSefachAnyone,
  },
};
export const filecommissionedconfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: filecommissioned,
  },
};
export const fileWaterMoneconfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: fileWaterMone,
  },
};

export const fileShoperconfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: fileshoper,
  },
};
export const fileRentconfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: fileRent,
  },
};
export const assetTypeConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: assetType,
    selectconfig: {
      bigSelect: false,
      copyList: [
        {
          label: 'פרטי',
          value: 1,
        },
        {
          label: 'עיסקי',
          value: 2,
        },
      ],
      lable: 'סוג נכס',
      list: [
        {
          label: 'פרטי',
          value: 1,
        },
        {
          label: 'עיסקי',
          value: 2,
        },
      ],
    },
  },
};
export const assetNumberInputConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: assetNumberInput,
    selectconfig: {
      bigSelect: false,
      copyList: [],
      lable: 'מספר הנכס',
      list: [],
    },
  },
};
export const assetNumberConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: assetNumber,
    selectconfig: {
      bigSelect: false,
      copyList: [],
      lable: 'מספר הנכס',
      list: [],
    },
  },
};
export const houseNumberConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: houseNumber,
  },
};
export const apartmentConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: apartment,
  },
};
export const houseEnterConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: houseEnter,
  },
};
export const floorHouseEnterConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: floorHouseEnter,
    doubleConfig: [floor, houseEnter],
  },
};
export const houseNumberApartmentConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: houseNumberApartment,
    doubleConfig: [houseNumber, apartment],
  },
};
export const fileTaboconfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: tabo,
  },
};
export const fileTabo2config: fullControl = {
  controlType: 'input',
  inputControl: {
    config: tabo2,
  },
};
export const filetaboDiraconfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: taboDira,
  },
};
export const filetaboAnotherconfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: taboAnother,
  },
};
export const filetaboNotesconfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: taboNotes,
  },
};
export const dateChangeConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: dateChange,
  },
};
export const dateOutOwnerConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: dateOutOwner,
  },
};
export const birthDateConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: birthDate,
  },
};
export const ageConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: age,
  },
};
export const dateInOwnerConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: dateInOwner,
  },
};
export const dateReadConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: dateReadMone,
  },
};
export const addressConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: address,
  },
};
export const addressAssetConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: addressAsset,
  },
};
export const addressBuyerConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: addressBuyer,
  },
};
export const addressBaalConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: addressBaal,
  },
};
export const bankNameConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: bankName,
  },
};
export const numCardWaterConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: numCardWater,
  },
};
export const numLiversNowConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: numLiversNow,
  },
};
export const endMoneConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: endMone,
  },
};

export const assetControls: fullControl[] = [
  assetTypeConfig,
  assetNumberConfig,
  cityConfig,
  streetConfig,
  houseNumberConfig,
  apartmentConfig,
  dateChangeConfig,
];
export const controlsBtns: fullControl[] = [btnBuyConfig];
export const waterControls: fullControl[] = [
  numCardWaterConfig,
  endMoneConfig,
  dateReadConfig,
  fileWaterMoneconfig,
  numLiversNowConfig,
];

export const water2Controls: fullControl[] = [btnHaveAfter18Config];
export const water3Controls: fullControl[] = [firstNameconfig, fileconfig];
export const water4Controls: fullControl[] = [allowWaterConfig];


export const OwnerOutControls: fullControl[] = [
  firstNameconfig,
  lastNameconfig,
  addressConfig,
  idconfig,
  phoneconfig,
  emailconfig,
  dateOutOwnerConfig,
  fileWaterMoneconfig,
];
export const OwnerIn2Controls: fullControl[] = [
  firstNameconfig,
  lastNameconfig,
  addressConfig,
  phoneconfig,
  emailconfig,
  idconfig,
  fileconfig,
  dateInOwnerConfig,
  fileRentconfig,
  fileWaterMoneconfig,
];
export const taboFilesControls: fullControl[] = [
  fileTaboconfig,
  fileTabo2config,
  filetaboDiraconfig,
  filetaboAnotherconfig,
  filetaboNotesconfig,
];
export const mortgageDetalisControls: fullControl[] = [
  paymentNumberconfig,
  firstNameconfig,
  lastNameconfig,
  phoneconfig,
  emailconfig,
  idconfig,
];
export const simplePeopleDetails: fullControl[] = [
  firstNameconfig,
  lastNameconfig,
  emailconfig,
  phoneconfig,
  idconfig,
  fileconfig,
];
export const simpleAddress: fullControl[] = [
  firstNameconfig,
  lastNameconfig,
  emailconfig,
  phoneconfig,
  idconfig,
  fileconfig,
  addressConfig,
];
export const simpleFileMecher: fullControl[] = [
  firstNameconfig,
  lastNameconfig,
  emailconfig,
  phoneconfig,
  idconfig,
  fileconfig,
  fileShoperconfig,
];
export const simpleFileMecherAddress: fullControl[] = [
  firstNameconfig,
  lastNameconfig,
  emailconfig,
  phoneconfig,
  idconfig,
  fileconfig,
  fileShoperconfig,
  addressConfig,
];
export const commissionedDetails: fullControl[] = [
  firstNameconfig,
  lastNameconfig,
  idconfig,
  fileconfig,
  filecommissionedconfig,
];

export interface formDeme {
  levels: level[];
  name: string;
  id: FormType;
}
export interface signBody {
  buttons: buttonControl[];
  sign: {
    name: string;
  }[];
  needEnd: boolean;
}
export interface level {
  id: number;
  name: string;
  lable: string;
  signBody?: signBody;
  tableBodyName?: 'openBug' | 'payVoucher' | 'openBugDocuments';
  controls?: fullControl[];
  type:
  | 'form'
  | 'table'
  | 'sign'
  | 'sendSucsses'
  | 'assetDiscount'
  | 'liversDiscount'
  | 'incomesDiscount'
  | 'documentsDiscount'
  | 'signDiscount'
  | 'popup';
  moreLevels?: moreLevels;
  btnText?: string;
  functionLevel?: string;
  descPopupBody?: descBody
}
export interface moreLevels {
  levels: level[];
  haveVersions: boolean;
  versions?: formVersion[];
  withStyle?: boolean;
}
export const genderConfig: fullControl = {
  controlType: 'input',
  inputControl: {
    config: {
      formControlName: 'gender',
      type: 'select',
      inputType: 'select',
      label: 'מגדר',
      name: 'gender',
      required: true,
    },
    selectconfig: {
      bigSelect: false,
      copyList: [
        {
          label: 'זכר',
          value: 1,
        },
        {
          label: 'נקבה',
          value: 2,
        },
        {
          label: 'אחר',
          value: 3,
        },
      ],
      lable: 'מגדר',
      list: [
        {
          label: 'זכר',
          value: 1,
        },
        {
          label: 'נקבה',
          value: 2,
        },
        {
          label: 'אחר',
          value: 3,
        },
      ],

    },
  },
};
