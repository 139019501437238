import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsTypesService } from './forms-types.service';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { MuniService } from 'src/app/services/muni.service';
import { MuniServiceCard, MuniTitle } from 'src/app/common/models/muni.model';

@Component({
  selector: 'app-forms-type',
  templateUrl: './forms-type.component.html',
  styleUrls: ['./forms-type.component.scss'],
})
export class FormsTypeComponent implements OnInit , AfterViewInit, OnDestroy{
  route1 = 'הבית הדיגיטלי שלך';
  route22 = 'טפסים מקוונים';
  route2 = 'פעולות נוספות';
  route3 = '';
  text = {
    title: 'מגוון טפסים שמאפשרים לכם להשאר בבית',
    more: 'לעוד פעולות',
  };
  card: MuniServiceCard;
  forms: MuniServiceCard[];
  destroy$ = new Subject();
  destroyParam$ = new Subject();
  newForms = [];
  filterd = [];
  sum = 0;
  titleID: number;
  titles: MuniTitle[] = [];
  typeCards: 1 | 2 | -1 = -1;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formsTypesService: FormsTypesService,
    private muniService: MuniService
  ) {
    
  }
  ngAfterViewInit(): void {
    combineLatest([
      this.muniService.muniServices$,
      this.muniService.muniTitles$,
    ])
      .pipe(takeUntil(this.destroyParam$))
      .subscribe(([muniServices, titles]: [MuniServiceCard[], MuniTitle[]]) => {
        if (muniServices && titles) {
          this.forms = muniServices;
          this.titles = titles;
          this.route.queryParams
            .pipe(takeUntil(this.destroyParam$))
            .subscribe((params) => {
              if (params.index) {
                this.route3 = this.titles[+encodeURI(params.index)-1].title;
                let length = this.route3.length;
                let t = document.getElementById('title');
                if (t) {
                  let c = length * 14;
                  t.style.width = c.toString() + 'px';
                }
                this.filterForms(+encodeURI(params.index));
                this.titleID = +encodeURI(params.index);
              }
              if (params.typeCards) {
                this.typeCards = params.typeCards;
              }
            });
        }
      });  }
  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.destroy$.complete();
    this.destroyParam$.complete();
   }
  filterForms(index: number) {
    this.newForms = [];
    this.filterd = [];
    this.forms.map((f) => {
      if (f.titleID == index) {
        this.filterd.push(f);
      }
    });
    if (this.filterd.length > 0) {
      this.sum = this.filterd.length;
      if (this.filterd.length >= 6)
        for (let i = 0; i < 6; i++) {
          this.newForms.push(this.filterd[i]);
        }
      else {
        for (let i = 0; i < this.filterd.length; i++) {
          this.newForms.push(this.filterd[i]);
        }
      }
    }
    this.destroyParam$.complete();
  }
  nav(type: number) {
    if (type == 1) {
      this.router.navigate(['dashboard']);
    } else if (type == 2) {
      this.router.navigate(['dashboard']);

      this.router.navigate(['dashboard'], {
        queryParams: {
          div: 'toggle',
          type: this.typeCards,
        },
      });
    }
  }
  add() {
    let leng = this.newForms.length;
    if (this.filterd.length > 0)
      if (this.filterd.length > leng + 6)
        for (let i = leng; i < leng + 6; i++) {
          this.newForms.push(this.filterd[i]);
        }
      else {
        for (let i = leng; i < this.filterd.length; i++) {
          this.newForms.push(this.filterd[i]);
        }
      }
  }
}
