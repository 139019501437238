<div class="wrapper" *ngIf="!mobile">
  <span class="title">{{ text.title }}</span>
  <table>
    <table class="table widthTable">
      <tr>
        <th *ngFor="let title of displayedColumns">
          <span> {{ title }}</span>
        </th>
      </tr>
    </table>
    <tbody>
      <tr>
        <td class="body">
          <table class="table">
            <tr class="row" *ngFor="let element of members; let i = index">
              <td class="td" width="10%">{{ element.closeness }}</td>
              <td class="td" width="20%">{{ element.name }}</td>
              <td class="td" width="15%">{{ element.id }}</td>
              <td class="td" width="13%">
                {{ element.birthDate | date : "dd/MM/yyyy" }}
              </td>
              <td class="td" width="7%">{{ element.gender }}</td>
              <td class="td" width="15%">{{ element.cellphone }}</td>
              <td class="td" width="10%">{{ element.email }}</td>
              <td class="td last" width="10%">
                <!-- <img src="../../../../assets/svg/delete.svg" /> -->
                <!-- <img src="../../../../assets/svg/pencil.svg" /> -->
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr></tr>
    </tbody>
  </table>
  <!-- <button class="plus" (click)="addLine()">
    +
  </button> -->
</div>
<div *ngIf="mobile" class="content">
  <div class="header">
    <div class="data">
      <mat-expansion-panel
        id="panel{{ i }}"
        *ngFor="let mem of members; let i = index"
        hideToggle
        [expanded]="mem.openedPanel"
        [disabled]="true"
        [ngClass]="{
          lastchild: i == members.length-1,
        }"
      >
        <mat-expansion-panel-header>
          <div class="memberDetails">
            <div class="first">
              <span class="line">{{ mem.name }}</span>
              <span class="mini">{{ mem.closeness }}</span>
              <span class="mini">מין: {{ mem.gender == 'ז' ? 'זכר' : 'נקבה' }}</span>
              <div class="flex" *ngIf="!mem.openedPanel" (click)="open(i)">
                <div class="point"></div>
                <div class="point"></div>
                <div class="point"></div>
              </div>
            </div>
            <div class="second">
              <span class="line">{{ mem.id }}</span>
              <span class="mini">{{
                mem.birthDate | date : "dd/MM/yyyy"
              }}</span>
            </div>
          </div>
        </mat-expansion-panel-header>
        <div class="memberDetails body">
          <div class="first">
            <span class="mini">נייד: {{ mem.cellphone }}</span>
            <span class="mini">אימייל: {{ mem.email }}</span>
            <div class="flex" *ngIf="mem.openedPanel" (click)="close(i)">
              <div class="point"></div>
              <div class="point"></div>
              <div class="point"></div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
</div>
