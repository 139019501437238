import { fullControl } from 'src/app/forms/elementForm/control.model';
import {
  genderConfig,
  maritalStatusConfig,
} from 'src/app/forms/elementForm/controlsDeme';
import { AbilityList, WorkStatusList } from '../../openBug.model';

export const secondControlsList: fullControl[] = [
  {
    controlType: 'input',
    inputControl: {
      config: {
        formControlName: 'populationGroup',
        inputType: 'select',
        label: 'קבוצת אוכלוסיה',
        name: 'populationGroup',
        required: true,
        type: 'select',
      },
      selectconfig: {
        bigSelect: false,
        copyList: [],
        list: [],
        lable: 'קבוצת אוכלוסיה',
      },
    },
  },
  {
    controlType: 'input',
    inputControl: {
      config: {
        formControlName: 'work',
        inputType: 'select',
        label: 'מעמד בעבודה',
        name: 'work',
        required: true,
        type: 'select',
      },
      selectconfig: {
        bigSelect: false,
        copyList: WorkStatusList,
        list: WorkStatusList,
        lable: 'מעמד בעבודה',
      },
    },
  },
  {
    controlType: 'input',
    inputControl: {
      config: {
        formControlName: 'socialSecurity',
        inputType: 'select',
        label: 'קצבת ביטוח לאומי',
        name: 'socialSecurity',
        required: false,
        type: 'select',
      },
      selectconfig: {
        bigSelect: false,
        copyList: [],
        list: [],
        lable: 'קצבת ביטוח לאומי',
      },
    },
  },
  {
    controlType: 'input',
    inputControl: {
      config: {
        formControlName: 'education',
        inputType: 'select',
        label: 'השכלה',
        name: 'education',
        required: false,
        type: 'select',
      },
      selectconfig: {
        bigSelect: false,
        copyList: [],
        list: [],
        lable: 'השכלה',
      },
    },
  },
  {
    controlType: 'input',
    inputControl: {
      config: {
        formControlName: 'language',
        inputType: 'select',
        label: 'שפה מדוברת',
        name: 'language',
        required: false,
        type: 'select',
      },
      selectconfig: {
        bigSelect: false,
        copyList: [],
        list: [],
        lable: 'שפה מדוברת',
      },
    },
  },
  {
    controlType: 'input',
    inputControl: {
      config: {
        formControlName: 'anotherLanguage',
        inputType: 'select',
        label: 'שפה זרה',
        name: 'anotherLanguage',
        required: false,
        type: 'select',
      },
      selectconfig: {
        bigSelect: false,
        copyList: [],
        list: [],
        lable: 'שפה זרה',
      },
    },
  },
  {
    controlType: 'input',
    inputControl: {
      config: {
        formControlName: 'speechAbility',
        inputType: 'select',
        label: 'יכולת דיבור',
        name: 'speechAbility',
        required: false,
        type: 'select',
      },
      selectconfig: {
        bigSelect: false,
        copyList: AbilityList,
        list: AbilityList,
        lable: 'יכולת דיבור',
      },
    },
  },
  {
    controlType: 'input',
    inputControl: {
      config: {
        formControlName: 'readAbility',
        inputType: 'select',
        label: 'יכולת קריאה',
        name: 'readAbility',
        required: false,
        type: 'select',
      },
      selectconfig: {
        bigSelect: false,
        copyList: AbilityList,
        list: AbilityList,
        lable: 'יכולת קריאה',
      },
    },
  },
  {
    controlType: 'input',
    inputControl: {
      config: {
        formControlName: '',
        type: 'anyThing',
        inputType: '',
        label: '',
        name: '',
        required: false,
      },
    },
  },
];
export const firstControlsList: fullControl[] = [
  {
    controlType: 'input',
    inputControl: {
      config: {
        formControlName: 'relationship',
        inputType: 'select',
        label: 'קרבה',
        name: 'relationship',
        required: true,
        type: 'select',
      },
      selectconfig: {
        bigSelect: false,
        copyList: [],
        list: [],
        lable: 'קרבה',
      },
    },
  },
  {
    controlType: 'input',
    inputControl: {
      config: {
        formControlName : 'id',
        inputType : 'number',
        label : 'מספר זהות',
        name:'id',
        required : true,
        type: 'input',
      },
    },
  },
  {
    controlType: 'input',
    inputControl: {
      config: {
        formControlName : 'lastName',
        inputType : 'text',
        label : 'שם משפחה',
        name:'lastName',
        required : true,
        type: 'input',
      },
    },
  },
  {
    controlType: 'input',
    inputControl: {
      config: {
        formControlName : 'firstName',
        inputType : 'text',
        label : 'שם פרטי',
        name:'firstName',
        required : true,
        type: 'input',
      },
    },
  },
  {
    controlType: 'input',
    inputControl: {
      config: {
        formControlName : 'birthDate',
        inputType : 'date',
        label : 'תאריך לידה',
        name:'birthDate',
        required : true,
        type: 'date',
      },
    },
  },
  {
    controlType: 'input',
    inputControl: {
      config: {
        formControlName: 'birthLand',
        inputType: 'select',
        label: 'ארץ לידה',
        name: 'birthLand',
        required: true,
        type: 'select',
      },
      selectconfig: {
        bigSelect: false,
        copyList: [],
        list: [],
        lable: 'ארץ לידה',
      },
    },
  },
  genderConfig,
  maritalStatusConfig,
  {
    controlType: 'input',
    inputControl: {
      config: {
        formControlName: 'fatherName',
        inputType: 'text',
        label: 'שם האב',
        name: 'fatherName',
        required: false,
        type: 'input',
      },
    },
  },
  {
    controlType: 'input',
    inputControl: {
      config: {
        formControlName: 'phone',
        inputType: 'number',
        validation: 'phone',
        label: 'טלפון נייד',
        name: 'phone',
        required: false,
        type: 'input',
      },
    },
  },
  {
    controlType: 'input',
    inputControl: {
      config: {
        formControlName: 'email',
        inputType: 'text',
        validation: 'email',
        label: 'אימייל',
        name: 'email',
        required: false,
        type: 'input',
      },
    },
  },
  {
    controlType: 'input',
    inputControl: {
      config: {
        formControlName: '',
        type: 'anyThing',
        inputType: '',
        label: '',
        name: '',
        required: false,
      },
    },
  },

];
export const addEmergencyControl: fullControl = {
  controlType: 'button',
  buttonControl: {
    btnName: 'addEmergency',
    config: {
      name: 'addEmergency',
      text: 'האם אפשר לצרף אותך למאגר שיש לנו לשעת חירום?',
      type: 'toggle-group',
      required: true,
      toggleGroupName: 'addEmergency',
      color: '#08BED1',
      needTitle: true,
    },
    toggleConfig: [
      {
        name: 'כן',
        value: 'true',
        isDefult: true,
        miniBtn: false,
      },
      {
        name: 'לא',
        value: 'false',
        isDefult: true,
        miniBtn: false,
      },
    ],
  },
};

export interface fullPersonFileOpening {
  birthDate: Date;
  birthLand?: number;
  comeDate?: Date;
  comeLand?: number;
  email: string;
  fatherName?: string;
  firstName: string;
  gender: number;
  id: string;
  lastName: string;
  maritalStatus: number;
  phone: string;
  relationship: number;
  panelOpen?: boolean;
}
export interface anotherDetailsPerson {
  allowance: number;
  anotherLanguage: number;
  education: number;
  group: number;
  language: number;
  readAbility: number;
  speechAbility: number;
  work: number;
}
