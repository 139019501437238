import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gender'
})
export class GenderPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value == '1' || value ==1) return 'גבר';
    else if (value == '2' || value ==2) return 'אשה';
    else if (value == '3' || value ==3) return 'אחר';
    else return ''
  }

}
