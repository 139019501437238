<div class="tableDivLives">
  <mat-accordion>
    <div class="panelFlex th" *ngIf="!mobile">
      <th *ngFor="let title of displayedColumns">{{ title }}</th>
    </div>
    <mat-expansion-panel
      #panel
      hideToggle
      [ngClass]="{
        openPanel: panel.expanded
      }"
      [expanded]="element.panelOpen"
      *ngFor="let element of data; let i = index"
      (click)="openLine(i)"
    >
      <!-- (click)="closePanel(panel)" -->
      <mat-expansion-panel-header (opened)="panel.open()">
        <mat-panel-title>
          <div class="panelFlex" *ngIf="!mobile">
            <td width="7%">{{ element.pasport }}</td>
            <td width="10%">{{ element.lastName }}</td>
            <td width="10%">{{ element.firstName }}</td>
            <td width="7%">{{ element.dateOfBirth | date : "dd/MM/yyyy" }}</td>
            <td width="3%">{{ calcAge(element.dateOfBirth) }}</td>
            <td width="7%">{{ element.gender | gender }}</td>
            <td width="10%">{{ element.relationship | proximity }}</td>
            <td width="10%">{{ element.familyStatus | familyStatus }}</td>
            <td width="15%" class="ltr">
              <img
                class="arrow"
                src="../../../../../../assets/svg/arrowDown.svg"
              />
            </td>
          </div>
          <div class="panelFlex" *ngIf="mobile">
            <div class="row">
              <div class="column">
                <span class="bold"
                  >{{ element.firstName }} {{ element.lastName }}</span
                >
                <span>גיל: {{ calcAge(element.dateOfBirth) }}</span>
                <span
                  >מצב משפחתי: {{ element.familyStatus | familyStatus }}</span
                >
              </div>
              <div class="column end">
                <span class="bold">{{ element.id }}</span>
                <span>{{ element.dateOfBirth | date : "dd/MM/yyyy" }}</span>
                <img
                  class="arrow"
                  src="../../../../../../assets/svg/arrowDown.svg"
                />
              </div>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-edit-person
        [person]="element"
        (saveChanges)="saveChanges($event, i)"
      ></app-edit-person>
    </mat-expansion-panel>
  </mat-accordion>
  <button class="plus" (click)="addLine()">+</button>
</div>
<div class="nextdiv">
  <button class="prev" mat-button matStepperPrevious>
    <img src="../../../../../../assets/svg/arrow-right.svg" />
    הקודם
  </button>
  <button class="next" mat-button (click)="nextEmit()">
    הבא
    <img src="../../../../../../assets/png/leftPay.png" />
  </button>
</div>
