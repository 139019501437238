<div class="wrapper">
  <div class="head">
    <img class="logo" src="{{ logo }}" />
    <div class="text">
      <span>{{ name }}</span>
      <span class="muni">{{ muniName }}</span>
    </div>
  </div>
  <div class="options">
    <span (click)="nav(1)">
      <img src="../../../../assets/svg/usermenu/user.svg" />
      האיזור האישי שלי</span
    >
    <span (click)="nav(2)">
      <img src="../../../../assets/svg/usermenu/buld.svg" />
      הנכס שלי</span
    >
    <span (click)="nav(3)">
      <img src="../../../../assets/svg/usermenu/pay.svg" />
      התשלומים שלי</span
    >
    <span (click)="nav(4)">
      <img src="../../../../assets/svg/usermenu/setting.svg" />
      הגדרות חשבון</span
    >
  </div>
  <button class="logout" (click)="logout()"> 
    התנתקות</button>
</div>
