import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LayoutService } from '../layout.service';
import {
  menuConfirms,
  menuForms,
  menuPayments,
  menuPersonal,
} from 'src/app/dashboard/header/digital-menu/menu.model';

@Component({
  selector: 'app-mobile-side-bar',
  templateUrl: './mobile-side-bar.component.html',
  styleUrls: ['./mobile-side-bar.component.scss'],
})
export class MobileSideBarComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  name = '';
  muniName = '';
  isLoggedIn = false;
  text = {
    hi: 'היי',
    maybe: 'אולי אנחנו מכירים?',
    shave: 'שווה להתחבר כדי להנות מחיים קלים',
    title3: 'תשלומים',
    title5: 'הנפקת אישורים',
    title4: 'האיזור האישי שלי',
  };
  menuPayments = menuPayments;
  menuPersonal = menuPersonal;
  menuConfirms = menuConfirms;
  menuForms = menuForms;
  menu = 0;
  constructor(
    private auth: AuthService,
    private router: Router,
    private authService: AuthService,
    private layoutService: LayoutService
  ) {}
  ngOnDestroy(): void {}
  ngOnInit(): void {
    this.auth
      .getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && res.maintz != null && res.maintz != '') {
          this.name = res.firstName + ' ' + res.lastName;
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;
        }
      });
    this.muniName = localStorage.getItem('muniName');
  }
  nav(type: string) {
    this.prev();
    switch (type) {
      case 'asset':
        this.router.navigate(['my-asset']);
        break;
      case 'forms':
        this.router.navigate(['dashboard'], {
          queryParams: {
            div: 'toggle',
            type: 2,
          },
        });
        break;
      case 'home':
        this.router.navigate(['dashboard']);
        break;
      case 'logout':
        this.authService.logout();
        break;
      case 'payments':
        this.router.navigate(['my-payments']);
        break;
      default:
        this.router.navigate([type]);
        break;
    }
  }
  prev() {
    this.layoutService.openMobileSideBar$.next(false);
  }
  login() {
    this.prev();
    this.layoutService.openMobileLogin$.next(true);
  }
  opemMenu(type: 1 | 2 | 3) {
    switch (type) {
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
      default:
        break;
    }
  }
}
