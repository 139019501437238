import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { BehaviorSubject, Subject, skipWhile, take, takeUntil } from 'rxjs';
import { LoadingService } from '../common/elements/loader/loading.service';
import { IListItem } from '../forms/elementForm/form-input/form-input.model';
import { propertyTax } from '../personal-details/asset/asset.model';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  destroy$ = new Subject();
  public assetsNumbers$ = new BehaviorSubject<IListItem[]>(null);
  public PropertyTaxData$ = new BehaviorSubject<propertyTax[]>([]);
  public PropertyTaxDataObject$ = new BehaviorSubject<propertyTax>(null);
  constructor(private app: AppService, private loading: LoadingService) {}
  public async GetPropertyTaxData() {
    let url = 'AssetsController/getPropertyTaxData/';
    await this.app
      .get(url)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.PropertyTaxData$.next(res.propertyTaxData);
        }
      }, error=>{
        
      });
  }

  public async GetAssetsNumbersServer() {
     await this.app
      .get('Assets')
      .pipe(skipWhile((x) => !x))
      .subscribe(
        (res) => {
          this.assetsNumbers$.next(this.getListSelectFromAssetList(res.assets));
        },
        (error) => {
        }
      );
  }

  public getListSelectFromAssetList(list: any[]): IListItem[] {
    let newList: IListItem[] = [];
    list.map((l) => {
      newList.push({ label: l.num, value: l.num });
    });

    return newList;
  }
}
