import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { selectConfig } from './my-select.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'my-select',
  templateUrl: './my-select.component.html',
  styleUrls: ['./my-select.component.scss'],
})
export class MySelectComponent implements OnInit, AfterViewInit {
  @Input() config: selectConfig;
  @Input() selected: any;
  @Output() selectionChange = new EventEmitter<any>();
  control = new FormControl();
  focusInput = false;
  iAfterFilter = false;
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    if (this.config.selected != null && this.config.selected != undefined) {
      this.selected = this.config.selected;
      this.control.setValue(this.selected);
    }
  }
  opened() {
    this.focusInput = true;
  }
  closed() {
    this.focusInput = false;
  }
  selectionChangeEmit(event: any) {
    this.selectionChange.emit(event.value);
  }
  filter(event: Event) {
    const string = (event.target as HTMLInputElement).value;
    const newArray = this.config.copyList.filter(function (el) {
      return ('' + el.label).includes(string);
    });
    this.iAfterFilter = string.length > 0 ? true : false;
    this.config.list = newArray;
  }
}
