import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { inputConfig } from './my-big-input.model';
import { MuniService } from 'src/app/services/muni.service';
import { MuniServiceCard } from '../../models/muni.model';
import { IListItem } from 'src/app/forms/elementForm/form-input/form-input.model';

@Component({
  selector: 'my-big-input',
  templateUrl: './my-big-input.component.html',
  styleUrls: ['./my-big-input.component.scss'],
})
export class MyBigInputComponent implements OnInit {
  @Input() config: inputConfig;
  @Input() menuList: Array<IListItem>;
  @Output() submitInput = new EventEmitter<any>();
  @Output() chooseOneMenu = new EventEmitter<any>();
  timeout: any = null;
  focusInput = false;
  constructor() {}
  ngOnInit(): void {}
  search(text: string) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      $this.submitInput.emit({
        value: text,
      });
    }, 400);
  }
  choose (event){
    this.chooseOneMenu.emit(event)
  }
}
