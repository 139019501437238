<form *ngIf="!doubleConfig" #myinput id="myinput">
  <div class="titlediv">
    <span
      *ngIf="
        (getValue() ||
          config.disabled ||
          (config.value && config.type != 'gender')) &&
        config.type != 'textarea'
      "
      >{{ config?.label }}</span
    >
    <!-- (config.type == 'select' && selectconfig.selected) -->
  </div>
  <div class="wrapperinput">
    <div class="required">
      <span
        *ngIf="
          config?.required && !config?.disabled && config?.type != 'textarea'
        "
        >*</span
      >
      <img
        class="lock"
        *ngIf="config?.disabled"
        src="../../../../assets/svg/padlock.svg"
      />
    </div>
    <div
      id="myinputsimple"
      [ngClass]="{
        miniInupt: config?.miniInput
      }"
    >
      <div
        *ngIf="
          config?.type != 'select' &&
          config?.type != 'gender' &&
          config?.type != 'textarea' &&
          config?.type != 'password'
        "
        class="flex"
      >
        <img
          *ngIf="config?.icon"
          class="icon"
          src="../../../../assets/svg/{{ config?.icon }}"
        />
        <input
          [ngClass]="{
            grayBack: config?.value
          }"
          class="input"
          #fieldinput
          id="fieldinput{{ config.formControlName }}"
          [placeholder]="config.disabled ? '' : config?.label"
          [formControl]="control"
          name="{{ config.formControlName }}"
          [readOnly]="config.disabled"
          [value]="config.value"
          type="{{ config?.inputType == 'number' ? 'tel' : config?.inputType }}"
          inputmode="{{ config?.inputType == 'number' ? 'numeric' : 'text' }}"
          [ngClass]="{
            miniInupt: config?.miniInput
          }"
          (keyup)="onKeySearch($event, config?.formControlName)"
          (keyup.enter)="enter()"
        />

        <!-- (blur)="onBlur($event)" -->
      </div>
      <div
        *ngIf="config?.type == 'select'"
        [ngClass]="{
          grayBack: config?.value || selectconfig?.selected
        }"
        class="flex myinputsimple"
        id="myinputselect"
      >
        <my-select
          [config]="selectconfig"
          [selected]="selected"
          (selectionChange)="onSelect($event)"
        ></my-select>
      </div>
      <!-- <div class="genderDiv" *ngIf="config?.type == 'gender'">
        <span>{{ config?.label }}</span>
        <mat-radio-group
          (click)="saveGender($event)"
          aria-label="{{ config?.label }}"
        >
          <mat-radio-button value="0">
            <img
              matTooltipClass="tooltip"
              #tooltip="matTooltip"
              matTooltip="גבר"
              matTooltipPosition="above"
              *ngIf="gender != 0 && config?.value != 0"
              src="../../../../assets/svg/manGender.svg"
            />
            <img
              matTooltipClass="tooltip"
              #tooltip="matTooltip"
              matTooltip="גבר"
              matTooltipPosition="above"
              *ngIf="gender == 0 || config?.value == 0"
              src="../../../../assets/svg/gender.png"
            />
          </mat-radio-button>
          <mat-radio-button value="1">
            <img
              matTooltipClass="tooltip"
              #tooltip="matTooltip"
              matTooltip="אישה"
              matTooltipPosition="above"
              *ngIf="gender != 1 && config?.value != 1"
              src="../../../../assets/svg/womenGender.svg"
            />
            <img
              matTooltipClass="tooltip"
              #tooltip="matTooltip"
              matTooltip="אישה"
              matTooltipPosition="above"
              *ngIf="gender == 1 || config?.value == 1"
              src="../../../../assets/svg/women.png"
            />
          </mat-radio-button>
        </mat-radio-group>
      </div> -->
      <div
        *ngIf="config?.type == 'textarea'"
        [ngClass]="{
          grayBackText: config?.value || selectconfig?.selected
        }"
        class="textareaDiv"
      >
        <div class="requeiredTextarea">
          <span class="required" *ngIf="config?.required && !config?.disabled"
            >*</span
          >

          <span >{{
            config?.titleTextarea
          }}</span>
        </div>

        <textarea
          class="input"
          #fieldinputTextarea
          id="fieldinput{{ config.formControlName }}"
          [placeholder]="config.disabled ? '' : config?.label"
          [formControl]="control"
          [readOnly]="config.disabled"
          [value]="config.value"
          [type]="config?.inputType"
          [ngClass]="{
            miniInupt: config?.miniInput
          }"
          (keyup)="onKeySearch($event, config?.formControlName)"
          (keyup.enter)="enter()"
        ></textarea>
      </div>
      <div *ngIf="config?.type == 'password'">
        <img
          *ngIf="config?.icon"
          class="icon"
          src="../../../../assets/svg/{{ config?.icon }}"
        />
        <input
          class="input"
          #fieldinput
          id="fieldinput{{ config.formControlName }}"
          [placeholder]="config.disabled ? '' : config?.label"
          [formControl]="control"
          [readOnly]="config.disabled"
          [value]="config.value"
          type="password"
          [ngClass]="{
            miniInupt: config?.miniInput
          }"
          (keyup)="onKeySearch($event, config?.formControlName)"
          (keyup.enter)="enter()"
        />
      </div>
      <div *ngIf="config?.type == 'anything'"></div>
    </div>
  </div>
  <div class="errorInput">
    <span
      *ngIf="
        control.hasError('required') ||
        errorRes == 'required' ||
        (config.errors == 'required' && config.inputType == 'select')
      "
    >
      {{ config.label }} {{ error.is }} <strong>{{ error.must }}</strong>
    </span>
    <span *ngIf="control.hasError('id') || errorRes == 'id'">
      תז לא תקינה
    </span>
    <span *ngIf="control.hasError('email') || errorRes == 'email'">
      יש למלא כתובת מייל תקינה
    </span>
    <span *ngIf="control.hasError('emailPhone') || errorRes == 'emailPhone'">
      יש למלא מייל / נייד תקינים
    </span>
    <span *ngIf="control.hasError('name') || errorRes == 'name'">
      יש למלא שם
    </span>
    <span *ngIf="control.hasError('phone') || errorRes == 'phone'">
      יש למלא מספר נייד תקין
    </span>
    <span *ngIf="control.hasError('plusNumbers') || errorRes == 'plusNumbers'">
      יש למלא מספרים גדולים מאפס
    </span>
    <span
      *ngIf="control.hasError('dateAfterToday') || errorRes == 'dateAfterToday'"
    >
      תאריך לא תקין
    </span>
    <span *ngIf="errorRes == 'another' && errorAnother != ''">
      {{ errorAnother }}
    </span>
  </div>
</form>

<form *ngIf="doubleConfig" class="doubleConfigForm" #myinput id="myinput">
  <div class="wrapperDouble">
    <div class="titlediv double">
      <span
        *ngIf="
          getValue() ||
          doubleConfig[0].disabled ||
          (doubleConfig[0]?.value && doubleConfig[0].type != 'select')
        "
        >{{ doubleConfig[0]?.label }}</span
      >
    </div>
    <div class="wrapperinput">
      <div class="required">
        <span *ngIf="doubleConfig[0]?.required">*</span>
      </div>
      <div
        id="myinputsimple"
        [ngClass]="{
          miniInupt: doubleConfig[0]?.miniInput
        }"
      >
        <div class="flex" *ngIf="doubleConfig[0]?.type != 'select'">
          <input
            [value]="doubleConfig[0].value"
            class="input"
            #fieldinput
            [placeholder]="
              doubleConfig[0].disabled ? '' : doubleConfig[0]?.label
            "
            [formControl]="control"
            [readOnly]="doubleConfig[0].disabled"
            type="{{ doubleConfig[0]?.inputType }}"
            [ngClass]="{
              miniInupt: doubleConfig[0]?.miniInput,
              grayBack: doubleConfig[0]?.value
            }"
            (keyup)="onKeySearch($event, doubleConfig[0]?.formControlName)"
          />
          <!-- (blur)="onBlur($event)" -->
          <img
            *ngIf="doubleConfig[0]?.icon"
            class="icon"
            src="../../../../assets/svg/{{ doubleConfig[0]?.icon }}"
          />
        </div>
        <div
          class="flex myinputsimple"
          id="myinputselect"
          *ngIf="doubleConfig[0]?.type == 'select'"
        >
          <my-select
            [config]="selectconfig"
            [selected]="selected"
            (selectionChange)="onSelect($event)"
          ></my-select>
        </div>
      </div>
    </div>
  </div>
  <div class="wrapperDouble">
    <div class="titlediv double">
      <span
        *ngIf="
          getValue(true) ||
          doubleConfig[1].disabled ||
          (doubleConfig[1]?.value && doubleConfig[1].type != 'select')
        "
        >{{ doubleConfig[1]?.label }}</span
      >
    </div>
    <div class="wrapperinput">
      <div class="required">
        <span *ngIf="doubleConfig[1]?.required">*</span>
      </div>
      <div
        id="myinputsimple"
        [ngClass]="{
          miniInupt: doubleConfig[1]?.miniInput
        }"
      >
        <div class="flex" *ngIf="doubleConfig[1]?.type != 'select'">
          <input
            [value]="doubleConfig[1].value"
            class="input"
            #fieldinput
            [placeholder]="
              doubleConfig[1].disabled ? '' : doubleConfig[1]?.label
            "
            [formControl]="control2"
            [readOnly]="doubleConfig[1].disabled"
            type="{{ doubleConfig[1]?.inputType }}"
            [ngClass]="{
              miniInupt: doubleConfig[1]?.miniInput,
              grayBack: doubleConfig[1]?.value
            }"
            (keyup)="onKeySearch($event, doubleConfig[1]?.formControlName)"
          />
          <!-- (blur)="onBlur($event)" -->
          <img
            *ngIf="doubleConfig[1]?.icon"
            class="icon"
            src="../../../../assets/svg/{{ doubleConfig[1]?.icon }}"
          />
        </div>
        <div
          class="flex myinputsimple"
          id="myinputselect"
          *ngIf="doubleConfig[1]?.type == 'select'"
        >
          <my-select
            [config]="selectconfig"
            [selected]="selected"
            (selectionChange)="onSelect($event)"
          ></my-select>
        </div>
      </div>
    </div>
  </div>
</form>
