import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
  ViewChildren,
  QueryList,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { emitObj } from '../control.model';
import { InputConfig } from '../form-input/form-input.model';
import { MuniService } from 'src/app/services/muni.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { FormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { FormsFunctionsService } from '../../allForms/forms-functions.service';
import { FileConfig, UploadFile, fileDownload } from '../my-file/file.model';
import { FilesService } from '../my-file/files.service';
import { FormsDataService } from '../../forms-data.service';
import { FileOpeningService } from '../../allForms/open-bug/file-opening.service';

@Component({
  selector: 'multy-files',
  templateUrl: './multy-files.component.html',
  styleUrls: ['./multy-files.component.scss'],
})
export class MultyFilesComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() config: InputConfig;
  @Input() filesList: FileConfig[];
  @Input() errorRes: string;
  @Input() errorAnother: string;
  @Input() withoutLine: boolean;
  @Input() fileType: number;
  @Output() submitFunction = new EventEmitter();
  @Output() deletedFile = new EventEmitter();
  @ViewChildren('btnFile') btnFiles: QueryList<ElementRef>;

  destroy$ = new Subject();
  text = {
    file: 'בחר קובץ חדש',
  };
  error = {
    error: '',
    is: 'הוא',
    must: 'חובה',
  };
  base64Output: any;
  control = new FormControl();
  personID: string = '';
  constructor(
    private filesService: FilesService,
    private muni: MuniService,
    private loading: LoadingService,
    private formsFunctions: FormsFunctionsService,
    private formsData: FormsDataService,
    private fileOpeningservice: FileOpeningService
  ) {}
  ngAfterViewInit(): void {
    if (!this.filesList || this.filesList.length == 0) {
      this.filesList = [{ fileID: 0, fileName: '' }];
    }
  }
  ngOnInit(): void {
    if (this.formsData.currentFormType == 40) {
      this.fileOpeningservice.fileOpeningFiles$
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res && res.length > 0) {
            res.map((file) => {
              if (this.fileType == file.fileType) {
                if (
                  this.filesList.length == 1 &&
                  this.filesList[0].fileID == 0
                ) {
                  this.filesList.pop();
                }
                this.filesList.push(file);
              }
            });
          }
        });
    }
    this.formsFunctions.personID$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res != '') this.personID = res;
      });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  deleteFile(i: number) {
    this.deletedFile.emit(this.filesList[i].fileID);
    this.filesList[i] = {
      fileID: 0,
      fileName: '',
    };
    this.filesList.splice(i, 1);
  }
  disabledFiles(i: number) {
    if (this.filesList[i] && this.filesList[i].fileID > 0) return true;
    else return false;
  }
  onFileSelected(event, index: number) {
    if (!this.filesService.validFileSize(event)) {
      return;
    }
    for (let i = 0; i < event.target.files.length; i++) {
      this.filesService
        .convertFile(event.target.files[i])
        .pipe(takeUntil(this.destroy$))
        .subscribe((base64) => {
          this.loading.show('');
          this.base64Output = base64;
          this.errorRes = '';
          if (!this.fileType) {
            switch (this.config.formControlName) {
              case 'proofFile':
                this.fileType = 30;
                break;
              case 'fileWaterMone':
                this.fileType = 40;
                break;
              case 'fileBank':
                this.fileType = 50;
                break;
              case 'fileIncome':
                this.fileType = 60;
                break;
              case 'personalFiles':
                this.fileType = 70;
                break;
              default:
                this.fileType = 10;
                break;
            }
          }
          var doc: UploadFile = {
            content: this.base64Output,
            fileName: event.target.files[i].name,
            fileType: this.fileType,
            description: '',
            muniCode: this.muni.getMuniCode,
            path: '',
            personID: this.personID,
          };
          this.filesService
            .UploadSimpleFile(doc)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res) => {
                this.filesList[index] = {
                  fileID: res.body.fileID,
                  fileName: event.target.files[i].name,
                };
                let obj: emitObj = {
                  name: this.config.formControlName,
                  value: this.filesList[index].fileID,
                  errors: null,
                };
                this.loading.hide();
                this.submitFunction.emit(obj);
              },
              (error) => {
                this.loading.hide();
                this.errorRes = 'another';
                this.errorAnother = 'שגיאה בהעלאת הקובץ';
              }
            );
        });
    }
  }
  downloadFile(i: number) {
    let body: fileDownload = {
      fileID: this.filesList[i].fileID,
      muniCode: this.muni.getMuniCode,
    };
    this.filesService
      .DownloadFile(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.filesService.openFile(res.body, this.filesList[i].fileName);
        },
        (error) => {
          if (error.status == 200) {
            this.filesService.openFile(
              error.error.text,
              this.filesList[i].fileName
            );
          }
        }
      );
  }
  addLine() {
    this.filesList.push({ fileID: 0, fileName: null });
    setTimeout(() => {
      this.btnFiles.forEach((btnFile, index) => {
        // if (index === this.filesList.length - 1) {
        // btnFile.nativeElement.click();
        // }
      });
    }, 0);
  }
}
