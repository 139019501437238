import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GetOperatingSysService {
  constructor() {}
  getOS() {
    var userAgent = navigator.userAgent || navigator.vendor;
    //platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
    var macosPlatforms = /Macintosh|MacIntel|MacPPC|Mac68K/,
      windowsPlatforms = /Win32|Win64|Windows|WinCE/,
      iosPlatforms = /iPhone|iPad|iPod/,
      os = null;
    if (/windows phone/i.test(userAgent)) {
      os = 6; //windows phone
    } else if (macosPlatforms.test(userAgent)) {
      os = 2; //'Mac OS';
    } else if (iosPlatforms.test(userAgent)) {
      os = 5; //'iOS';
    } else if (windowsPlatforms.test(userAgent)) {
      os = 1; //'Windows';
    } else if (/Android/i.test(userAgent)) {
      os = 4; //'Android';
    } else if (/Linux/.test(userAgent)) {
      os = 3; //'Linux';
    }

    return os;
  }
}
