import { Component, Input } from '@angular/core';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { PaymentService } from 'src/app/forms/elementForm/payment-page/payment.service';
import { myPayment } from '../paymentSite.model';

@Component({
  selector: 'app-payment-site',
  templateUrl: './payment-site.component.html',
  styleUrls: ['./payment-site.component.scss'],
})
export class PaymentSiteComponent {
  @Input() paymentSite: myPayment[] = [];
  displayedColumns = ['תאריך', 'סוג תשלום', 'על מה שילמתי?', 'סכום'];
  constructor(
    private paymentService: PaymentService,
    private loading: LoadingService
  ) {}
  ngOnInit(): void {
   
  }
}
