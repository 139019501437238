<div class="wrapper">
  <span class="title"
    >{{ text.title }}
    <span class="bold">{{ options[1] }}</span>
  </span>
  <div *ngIf="!payIframe" class="flex">
    <div class="card">
      <span class="namecard">CARD</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
      >
        <path
          fill="white"
          d="M200-120q-26.653 0-45.634-18.98-18.98-18.981-18.98-45.634 0-26.654 18.98-45.634 18.981-18.981 45.634-18.981t45.634 18.981q18.98 18.98 18.98 45.634 0 26.653-18.98 45.634Q226.653-120 200-120Zm495.386 0q0-116-44-217.5t-120.5-178q-76.5-76.5-178-120.5t-217.5-44v-89.229q134.461 0 252.691 51.193 118.23 51.192 206.192 139.153 87.961 87.961 139.153 206.199Q784.614-254.447 784.614-120h-89.228Zm-240 0q0-133-93.5-226.5t-226.5-93.5v-89.229q85.076 0 159.562 32.171 74.485 32.171 129.883 87.654 55.399 55.482 87.591 129.905Q544.614-205.076 544.614-120h-89.228Z"
        />
      </svg>
      <span>{{ name }}</span>
      <span>{{ number }}</span>
      <span>{{ tokef }}</span>
    </div>
  </div>

  <button *ngIf="!payIframe" class="btn" (click)="openPay()">{{ text.btn }}</button>

<app-payment-iframe *ngIf="payIframe"></app-payment-iframe>
</div>
