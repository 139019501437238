import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  citySelectConfig,
  emailconfig,
  firstNameconfig,
  floorHouseEnterConfig,
  formDeme,
  houseNumberApartmentConfig,
  idconfig,
  lastNameconfig,
  phoneHomeconfig,
  phoneMainconfig,
  postalCodeConfig,
  signBody,
  streetSelectConfig,
} from '../../elementForm/controlsDeme';
import { saveDataLevel } from '../../elementForm/control.model';
import {
  FormPersonReq,
  FormsAddress,
  FormsExistData,
  Person,
  fileOpeningGetExistRes,
} from '../functions.model';
import { MuniService } from 'src/app/services/muni.service';
import { FormsFunctionsService } from '../forms-functions.service';
import { Subject, takeUntil } from 'rxjs';
import { FormsDataService } from '../../forms-data.service';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { MatStepper } from '@angular/material/stepper';
import { FileConfig } from '../../elementForm/my-file/file.model';
import { FileOpeningService } from './file-opening.service';
import { RequestCauseDetails } from './openBug.model';

@Component({
  selector: 'app-open-bug',
  templateUrl: './open-bug.component.html',
  styleUrls: ['./open-bug.component.scss'],
})
export class OpenBugComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  signBody: signBody = {
    buttons: [
      {
        btnName: 'check1',
        config: {
          name: 'check1',
          text: 'בשליחת טופס זה הריני מצהיר/ה בזה כי כל הפרטים הרשומים בבקשתי זו הם נכונים, מדויקים ומלאים .וכי לא העלמתי פרט כלשהו מהפרטים שנתבקשתי למלא וכי אני מסכים לתנאי השימוש באתר והצהרת הפרטיות כפי שמופעים באתר .משלוח טופס זה מהווה הסכמה לכך שהרשות המקומית תיצור איתי קשר באמצעות הטלפון בשיחה ו/או מסרון ובאמצעות הדוא"ל לצורך טיפול בפנייה ובנושאים אחרים בכל עת .ידוע לי כי דיווח חלקי ו/או כוזב בבקשתי זו הינו עבירה על החוק, וכי לרשות המקומית יש זכות לבדוק את נכונות הפרטים שמסרתי בבקשתי זו בכל האמצעים העומדים לרשותה בחוק.זהו שמי וזו תעודת הזהות שלי, ותוכן הצהרה זו - אמת',
          type: 'checkbox',
        },
      },
      {
        btnName: 'check2',
        config: {
          name: 'check2',
          text: 'אני מסכים לתנאי השימוש ולמדיניות הפרטיות של האתר',
          type: 'checkbox',
        },
      },
    ],
    sign: [
      {
        name: 'חתימת הפונה',
      },
    ],
    needEnd: true,
  };
  iGetTheData = false;
  openBugForm: formDeme = {
    id: 40,
    levels: [
      {
        id: 0,
        lable: '',
        name: 'הסבר',
        type: 'popup',
        descPopupBody: {
          headText: 'לפניך טופס עבור בחינת פתיחת תיק באגף הרווחה',
          innerText:
            'חשוב למלא את הפרטים בצורה מדויקת עד כמה שניתן, זה יקל על המשך התהליך',
          docsList: [
            {
              label: 'צילום תז',
              value: 0,
            },
            {
              label: 'אישורי הכנסה',
              value: 1,
            },
            {
              label: 'תדפיס עו"ש',
              value: 2,
            },
          ],
        },
      },
      {
        id: 1,
        lable: '',
        name: 'פרטי מגיש הבקשה',
        type: 'form',
        controls: [
          idconfig,
          lastNameconfig,
          firstNameconfig,
          emailconfig,
          phoneMainconfig,
          phoneHomeconfig,
          citySelectConfig,
          streetSelectConfig,
          houseNumberApartmentConfig,
          postalCodeConfig,
          floorHouseEnterConfig,
          {
            controlType: 'input',
            inputControl: {
              config: {
                formControlName: '',
                type: 'anyThing',
                inputType: '',
                label: '',
                name: '',
                required: false,
              },
            },
          },
          {
            controlType: 'input',
            inputControl: {
              config: {
                formControlName: 'treatmentArea',
                inputType: 'select',
                label: 'באיזה תחום הפניה?',
                name: 'treatmentArea',
                required: true,
                type: 'select',
              },
              selectconfig: {
                bigSelect: false,
                copyList: [],
                list: [],
                lable: 'באיזה תחום הפניה?',
              },
            },
          },
          {
            controlType: 'input',
            inputControl: {
              config: {
                formControlName: 'FileOpeningCause',
                inputType: 'text',
                label: 'יש לפרט את סיבת הפניה למחלקה',
                name: 'FileOpeningCause',
                required: true,
                type: 'textarea',
                titleTextarea: 'סיבת הפניה',
              },
            },
          },
          // genderConfig,
          // maritalStatusConfig,
          // fileSefachconfig,
        ],
      },
      {
        id: 2,
        lable: '',
        name: 'בני המשפחה',
        type: 'table',
        tableBodyName: 'openBug',
      },
      {
        id: 3,
        lable: '',
        name: 'מסמכים',
        type: 'table',
        tableBodyName: 'openBugDocuments',
        // controls: [
        // {
        //   controlType: 'input',
        //   inputControl: {
        //     config: {
        //       formControlName: 'fileBank',
        //       inputType: 'file',
        //       label: 'תדפיס חשבון הבנק ל3 חודשים אחרונים',
        //       name: 'fileBank',
        //       required: true,
        //       type: 'file',
        //     },
        //   },
        // },
        // {
        //   controlType: 'input',
        //   inputControl: {
        //     config: {
        //       formControlName: 'fileIncome',
        //       inputType: 'file',
        //       label: 'אישורי הכנסה',
        //       name: 'fileIncome',
        //       required: true,
        //       type: 'multy-files',
        //       tooltip: 'לדוגמא: תלושים, קצבאות וכדו',
        //     },
        //   },
        // },
        // {
        //   controlType: 'input',
        //   inputControl: {
        //     config: {
        //       formControlName: 'personalFiles',
        //       inputType: 'file',
        //       label: 'מסמכים אישיים',
        //       name: 'personalFiles',
        //       required: true,
        //       type: 'multy-files',
        //       tooltip:
        //         'לדוגמא: דוח רפואי, דוח בית ספר, דוח פסיכולוג, דוח איבחון',
        //     },
        //   },
        // },
        // ],
      },
      {
        id: 4,
        lable: '',
        name: 'הצהרה וסיום',
        type: 'sign',
        signBody: this.signBody,
      },
    ],
    name: 'טופס פתיחת תיק - האגף לשירותים חברתיים',
  };
  familyList: Person[] = [];
  constructor(
    private muni: MuniService,
    private formsFunctions: FormsFunctionsService,
    private formsData: FormsDataService,
    private loading: LoadingService,
    private fileOpeningService: FileOpeningService
  ) {}
  ngOnInit(): void {
    this.getControlsLists();
    this.formsFunctions.existRequestData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: FormsExistData) => {
        if (res && res?.requestData?.id != '') {
          this.setLevel1Data(res);
          this.getExistReq();
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  getControlsLists() {
    this.fileOpeningService
      .GetFileOpeningCauseList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        let i = this.openBugForm.levels[1].controls.findIndex(
          (c) => c.inputControl.config.formControlName == 'treatmentArea'
        );
        if (i != -1) {
          this.openBugForm.levels[1].controls[
            i
          ].inputControl.selectconfig.list =
            this.fileOpeningService.convertResListTiItem(res);
          this.openBugForm.levels[1].controls[
            i
          ].inputControl.selectconfig.copyList =
            this.fileOpeningService.convertResListTiItem(res);
        }
      });
    this.formsData.citiesListSelect$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!res || res.length == 0) {
          this.formsData.getCities();
        } else {
          this.openBugForm.levels[1].controls[6].inputControl.selectconfig.list =
            res;
          this.openBugForm.levels[1].controls[6].inputControl.selectconfig.copyList =
            res;
        }
      });
    this.formsData.streetsListSelect$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && res.length > 0) {
          this.openBugForm.levels[1].controls[7].inputControl.selectconfig.list =
            res;
          this.openBugForm.levels[1].controls[7].inputControl.selectconfig.copyList =
            res;
        }
      });
  }
  setAddress(res: FormsAddress) {
    this.openBugForm.levels[1].controls[6].inputControl.selectconfig.selected =
      res.cityID;
    this.formsData.getStreets(res.cityID);
    this.openBugForm.levels[1].controls[7].inputControl.selectconfig.selected =
      res.streetID;
    if (res.houseNum != 0)
      this.openBugForm.levels[1].controls[8].inputControl.doubleConfig[0].value =
        res.houseNum;
    if (res.apartment != 0)
      this.openBugForm.levels[1].controls[8].inputControl.doubleConfig[1].value =
        res.apartment;

    this.openBugForm.levels[1].controls[9].inputControl.config.value =
      res.postalCode;
    this.openBugForm.levels[1].controls[10].inputControl.doubleConfig[1].value =
      res.entrance;
    if (res.floor)
      this.openBugForm.levels[1].controls[10].inputControl.doubleConfig[0].value =
        res.floor;
  }
  setLevel1Data(res: FormsExistData) {
    this.openBugForm.levels[1].controls[1].inputControl.config.value =
      res.requestData.applicantDetails.lastName;
    this.openBugForm.levels[1].controls[2].inputControl.config.value =
      res.requestData.applicantDetails.firstName;
    this.openBugForm.levels[1].controls[3].inputControl.config.value =
      res.requestData.applicantDetails.email;
    this.openBugForm.levels[1].controls[4].inputControl.config.value =
      res.requestData.applicantDetails.phone;
    this.openBugForm.levels[1].controls[5].inputControl.config.value =
      res.requestData.applicantDetails.landlinePhone;
    if (res.requestData.applicantDetails.address) {
      this.setAddress(res.requestData.applicantDetails.address);
    }
    // if (res.requestData.applicantDetails.pasportFile)
    //   this.openBugForm.levels[1].controls[10].inputControl.config.file = {
    //     fileID: res.requestData.applicantDetails.pasportFile.fileID,
    //     fileName: res.requestData.applicantDetails.pasportFile.fileName,
    //   };
  }
  setFiles(res: fileOpeningGetExistRes) {
    let f50: FileConfig[] = [];
    let f60: FileConfig[] = [];
    let f70: FileConfig[] = [];
    res.files.map((f) => {
      if (f.fileType == 50)
        f50.push({ fileID: f.fileID, fileName: f.fileName });
      else if (f.fileType == 60)
        f60.push({ fileID: f.fileID, fileName: f.fileName });
      else if (f.fileType == 70)
        f70.push({ fileID: f.fileID, fileName: f.fileName });
    });
    this.openBugForm.levels[3].controls[3].inputControl.config.file = f50[0];
    this.openBugForm.levels[3].controls[4].inputControl.config.fileList = f60;
    this.openBugForm.levels[3].controls[5].inputControl.config.fileList = f70;
  }
  getExistReq() {
    this.fileOpeningService
      .fileOpeningGetExist()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res && res.body) {
            this.iGetTheData = true;
            if (res.body.familyMembers && res.body.familyMembers.length > 0)
              this.fileOpeningService.fileOpeningFamilyMembers$.next({
                needSet: true,
                persons: res.body.familyMembers,
              });
            if (res.body.files && res.body.files.length > 0) {
              this.fileOpeningService.fileOpeningFiles$.next(res.body.files);
            }
            if (this.iGetTheData) {
              if (res.body.requestCause) {
                let i = this.openBugForm.levels[1].controls.findIndex(
                  (c) =>
                    c.inputControl.config.formControlName == 'FileOpeningCause'
                );
                if (i != -1)
                  this.openBugForm.levels[1].controls[
                    i
                  ].inputControl.config.value = res.body.requestCause;
              }
              if (res.body.treatmentArea) {
                let i = this.openBugForm.levels[1].controls.findIndex(
                  (c) =>
                    c.inputControl.config.formControlName == 'treatmentArea'
                );
                if (i != -1)
                  this.openBugForm.levels[1].controls[
                    i
                  ].inputControl.selectconfig.selected = res.body.treatmentArea;
              }
            }
          }
        },
        (error) => {}
      );
  }
  saveDataLevel(event: saveDataLevel) {
    let level = this.openBugForm.levels.find((l) => l.id == event.levelID);
    if (level) {
      switch (level.id) {
        case 1:
          const body1: FormPersonReq = {
            email: event.data.email,
            firstName: event.data.firstName,
            lastName: event.data.lastName,
            muniCode: this.muni.getMuniCode,
            pasport: event.data.id,
            phone: event.data.phoneMain,
            formType: this.openBugForm.id,
            familyStatus: event.data.maritalStatus,
            pasportFileID: event.data.fileSefach,
            landlinePhone: event.data.phoneHome,
            requestId:
              this.formsFunctions.existRequestData$.value.requestData.lineID,
            address: {
              apartment: event.data.apartment ? event.data.apartment : 0,
              cityID: event.data.city,
              entrance: event.data.houseEnter ? event.data.houseEnter : '',
              houseNum: event.data.houseNumber ? event.data.houseNumber : 0,
              lineID: 0,
              postalCode: event.data.postalCode ? event.data.postalCode : 0,
              streetID: event.data.street,
              floor: event.data.floor ? event.data.floor : 0,
            },
          };
          const couseBody: RequestCauseDetails = {
            mainRequestId:
              this.formsFunctions.existRequestData$.value.requestData.lineID,
            muniCode: this.muni.getMuniCode,
            requestCause: event.data.FileOpeningCause,
            treatmentArea: event.data.treatmentArea,
          };

          this.formsFunctions
            .SaveApplicantRequestDetails(body1)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res) => {
                this.loading.hide();
                if (!this.iGetTheData) this.getExistReq();
                this.next(event.stepper);
              },
              (error) => {
                this.loading.hide();
              }
            );
          this.fileOpeningService
            .SaveRequestCauseDetails(couseBody)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res) => {},
              (error) => {}
            );
          break;
        
        default:
          break;
      }
    }
  }
  next(mystepper: MatStepper) {
    this.loading.hide();
    setTimeout(() => {
      mystepper.selected.completed = true;
      mystepper.next();
    }, 1);
  }
}
