import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { emitObj, fullControl } from '../control.model';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { InputConfig, generateForm } from '../form-input/form-input.model';
import {
  PaymentsLogRequest,
  SavedCreditCard,
  basket,
  dataForm,
  emailPayControl,
  paymentsLog,
  typePayControl,
  typeSelectConfig,
} from './paymentPage.model';
import { selectConfig } from 'src/app/common/elements/my-select/my-select.model';
import { PaymentService } from './payment.service';
import { BehaviorSubject, Subject, max, takeUntil } from 'rxjs';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { MuniService } from 'src/app/services/muni.service';
import { Voucher } from 'src/app/common/models/voucher.model';
import { VoucherService } from 'src/app/services/voucher.service';
import { RandomService } from 'src/app/random-payments/random.service';

@Component({
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss'],
})
export class PaymentPageComponent implements OnInit, OnDestroy {
  @Input() haveStepper: boolean;
  @Output() goPrev = new EventEmitter();
  destroy$ = new Subject();
  sum: number = 0;
  text = {
    title: 'בחר את אמצעי התשלום',
    topay: 'סה"כ לתשלום',
  };
  cards = [
    {
      label: 'כרטיס אשראי',
      icon: 'credit-card.svg',
      value: 0,
    },
  ];
  payNumberConfig: InputConfig = {
    formControlName: 'payNumber',
    icon: null,
    label: 'יש לבחור צורת תשלום',
    required: false,
    type: 'select',
    list: [],
    inputType: 'select',
    name: 'payNumber',
  };
  payNumberControl: fullControl = {
    controlType: 'input',
    inputControl: {
      config: this.payNumberConfig,
    },
  };
  payNumberSelectConfig: selectConfig = {
    bigSelect: false,
    copyList: [],
    lable: 'מספר תשלומים',
    list: [],
  };
  form: FormGroup;
  items: basket[];
  emailPayControl = emailPayControl;
  typePayControl = typePayControl;
  typeSelectConfig: selectConfig = typeSelectConfig;
  controlsList: fullControl[] = [
    this.emailPayControl,
    this.typePayControl,
    this.payNumberControl,
  ];
  creditCards$ = new BehaviorSubject<SavedCreditCard[]>([]);
  payQuestions = false;
  creditType = 0;
  needNumOfPay = false;
  numOfPayments = 1;
  note = '';
  payIframe = false;
  iChooseCards = -1;
  iChooseSaved = -1;
  isBit = false;
  dataforForm: dataForm = {
    haveBit: false,
    haveCredit: false,
    havePayments: false,
    numCredit: 1,
    numOfPayments: 1,
  };
  mobile = window.screen.width <= 850 ? true : false;
  constructor(
    private paymentService: PaymentService,
    private loadingService: LoadingService,
    private app: AppService,
    private authService: AuthService,
    private muniService: MuniService,
    private voucherService: VoucherService,
    private muni: MuniService,
    private random: RandomService
  ) {}

  ngOnInit(): void {
    this.muni.GetMuniParams();
    this.muni.MuniParams$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res) {
        this.setParams(res);
      }
    });
    this.generateForm();
    this.paymentService.sum$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res != 0) {
        this.sum = res;
        if (
          this.paymentService.sum$.value > 25 &&
          this.dataforForm.haveCredit
        ) {
          this.typeSelectConfig.list.splice(2, 1);
        }
      }
    });
    if (this.paymentService.CreditCards$.value.length == 0)
      this.paymentService.getCreditCards();
    this.paymentService.CreditCards$.pipe(takeUntil(this.destroy$)).subscribe(
      (res) => {
        this.payQuestions = false;
        this.creditCards$.next(res);
      }
    );
    this.paymentService.payIframe$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.payIframe = res;
        if (res) {
          this.loadingService.hide();
        }
      });
  }
  ngOnDestroy(): void {
    this.paymentService.payIframe$.next(false);
    this.paymentService.sum$.next(0);
    this.destroy$.complete();
  }
  setParams(res) {
    res.forEach((element) => {
      if (window.location.href.includes('payVoucher')) {
        if (element.paramID == 1 && element.active == true) {
          this.dataforForm.haveBit = true;
        }
        if (element.paramID == 2 && element.active == true) {
          this.dataforForm.havePayments = true;
          this.dataforForm.numOfPayments = +element.paramContent;
        }
        if (element.paramID == 3 && element.active == true) {
          this.dataforForm.haveCredit = true;
          this.dataforForm.numCredit = +element.paramContent;
        }
      } else if (window.location.href.includes('debtBalance')) {
        if (element.paramID == 7 && element.active == true) {
          this.dataforForm.haveBit = true;
        }
        if (element.paramID == 8 && element.active == true) {
          this.dataforForm.havePayments = true;
          this.dataforForm.numOfPayments = +element.paramContent;
        }
        if (element.paramID == 9 && element.active == true) {
          this.dataforForm.haveCredit = true;
          this.dataforForm.numCredit = +element.paramContent;
        }
      } else if (window.location.href.includes('randomPayments')) {
        if (element.paramID == 4 && element.active == true) {
          this.dataforForm.haveBit = true;
        }
        if (element.paramID == 5 && element.active == true) {
          this.dataforForm.havePayments = true;
          this.dataforForm.numOfPayments = this.getMaxNumPaymentsRandom(
            +element.paramContent
          );
        }
        if (element.paramID == 6 && element.active == true) {
          this.dataforForm.haveCredit = true;
          this.dataforForm.numCredit = +element.paramContent;
        }
      }
    });
    this.setListParams();
  }
  getMaxNumPaymentsRandom(param: number): number {
    let maxNum = 20;
    this.paymentService.basketItems$.value.map((service) => {
      if (service.numOfPayments && service.numOfPayments > 0) {
        if (maxNum > service.numOfPayments) {
          maxNum = service.numOfPayments;
        }
      } else if (maxNum > param) {
        maxNum = param;
      }
    });
    if (maxNum == 20) maxNum = param;
    else if (maxNum < param) maxNum = param;
    return maxNum;
  }
  setListParams() {
    if (this.dataforForm.haveBit == true) {
      this.cards.push({
        label: 'תשלום ב - bit',
        icon: 'bit.png',
        value: 1,
      });
    }
    if (this.dataforForm.havePayments == true) {
      let f = typeSelectConfig.list.find((t) => t.value == 8);
      if (!f) {
        this.typeSelectConfig.list.push({ label: 'תשלומים', value: 8 });
      }
    }
    if (this.dataforForm.haveCredit == true) {
      let f = typeSelectConfig.list.find((t) => t.value == 6);
      if (!f) {
        this.typeSelectConfig.list.push({ label: 'קרדיט', value: 6 });
      }
    }
  }
  submitInput(obj: emitObj) {
    if (obj.name == 'typePay') {
      this.payMethod(obj.value);
    } else if (obj.name == 'payNumber') {
      this.numOfPayments = obj.value;
    } else if (obj.value) {
      this.form?.controls[obj.name]?.setValue(obj.value);
      if (obj.errors) {
        const controlErrors: ValidationErrors = obj.errors;
        if (controlErrors !== null) {
          // Object.keys(controlErrors).forEach((keyError) => {
          this.form?.controls[obj.name]?.setErrors(controlErrors);
          // });
        }
      }
    }
  }
  payMethod(value: any) {
    if (value != 1) {
      if (value == 8) {
        this.creditType = 8;
        this.needNumOfPay = true;
        this.payNumberSelectConfig.list = [];
        for (let i = 2; i <= this.dataforForm.numOfPayments; i++) {
          this.payNumberSelectConfig.list.push({
            label: i.toString(),
            value: i,
          });
        }
      } else if (value == 6) {
        this.creditType = 6;
        this.needNumOfPay = true;
        this.payNumberSelectConfig.list = [];
        for (let i = 3; i <= this.dataforForm.numCredit; i++) {
          this.payNumberSelectConfig.list.push({
            label: i.toString(),
            value: i,
          });
        }
      }
    } else {
      this.creditType = 1;
      this.needNumOfPay = false;
    }
  }
  chooseCard(card: any, isSaved: boolean) {
    this.paymentService.isSaveCard$.next({ card: card, is: isSaved });
    this.payQuestions = true;
    if (isSaved) {
      this.iChooseSaved = card.cardID;
      this.iChooseCards = -1;
    } else {
      this.iChooseCards = card.value;
      this.iChooseSaved = -1;
      if (card.value == 1) {
        this.isBit = true;
      } else {
        this.isBit = false;
      }
    }
  }
  createPayment() {
    if (!this.form.get('email').value && !this.authService.isLoggedIn()) {
      this.note = 'יש למלא כתובת מייל ';
      return;
    } else if (
      !this.form.get('email').value.includes('@') &&
      !this.authService.isLoggedIn()
    ) {
      this.note = 'יש למלא כתובת מייל תקינה';
      return;
    } else {
      this.note = '';
    }
    // }
    if (this.creditType == 0) {
      this.createBitPayment();
    } else if (
      this.creditType == 8 ||
      this.creditType == 6 ||
      this.creditType == 1
    ) {
      this.note = '';
      this.loadingService.show('כבר עוברים לתשלום');
      let CGrequest: PaymentsLogRequest = {
        muniCode: this.muniService.getMuniCode,
        guest: this.authService.isLoggedIn() ? false : true,
        clientDomain: new URL(window.location.href).origin,
        creditType: this.creditType,
        numberOfPayments: this.numOfPayments,
        email: this.form.get('email').value,
      };
      if (this.paymentService.isSaveCard$.value.is) {
        CGrequest.actType = 3;
        CGrequest.cardID = this.paymentService.isSaveCard$.value.card.cardID;
        CGrequest.cardToken =
          this.paymentService.isSaveCard$.value.card.cardToken;
      } else {
        CGrequest.actType = 0;
      }
      // if (this.paymentService.saveCard) {
      //   CGrequest.actType = 1;
      // }
      if (window.location.href.includes('randomPayments')) {
        CGrequest.paymentProcessCategory = 2;
      } else if (window.location.href.includes('debtBalance')) {
        CGrequest.paymentProcessCategory = 3;
      } else if (window.location.href.includes('payVoucher')) {
        let voucher: Voucher;
        CGrequest.paymentProcessCategory = 1;
        if (this.voucherService.oneVocher$.value) {
          voucher = this.voucherService.oneVocher$.value;
        }
        let paymentsLogElement: paymentsLog = {
          yearOfProduct: parseInt(voucher.voucherPeriod.substr(3, 4)),
          voucherSerialNumber: voucher.voucherId.toString(),
          paymentAmount: voucher.sum,
          usury: 0,
          assetId: '',
        };
        CGrequest.PaymentLog = [paymentsLogElement];
        CGrequest.ID = 0;
        CGrequest.personID = this.authService.isLoggedIn()
          ? ''
          : this.voucherService.mainIdPaymentOneVoucher.toString();
      }
      if (
        CGrequest.paymentProcessCategory == 2 ||
        CGrequest.paymentProcessCategory == 3
      ) {
        this.items = this.paymentService.basketItems$.value;
        this.items.map((item) => {
          let paymentsLogElement: paymentsLog;
          if (CGrequest.paymentProcessCategory == 2) {
            paymentsLogElement = {
              casualPaymentID: parseInt(item.id),
              quantity: item.NumOfOrderedItems,
              paymentAmount: item.price * item.NumOfOrderedItems,
              assetId: item.assetID,
              answers: item.DynamicField,
            };
          } else if (CGrequest.paymentProcessCategory == 3) {
            paymentsLogElement = {
              yearOfProduct: +item.yearOfProduct,
              casualPaymentID: parseInt(item.id),
              usury: +item.isInterest,
              paymentAmount: item.price,
              assetId: item.assetID,
            };
          }
          if (!CGrequest.PaymentLog || CGrequest.PaymentLog?.length == 0)
            CGrequest.PaymentLog = [paymentsLogElement];
          else CGrequest.PaymentLog.push(paymentsLogElement);
        });
        if (!this.authService.isLoggedIn()) {
          if (this.random.Guest?.value)
            CGrequest.ID = this.random.Guest.value.guestID;
        } else {
          CGrequest.ID = 0;
        }
      }
      // if (this.needEmail)
      this.paymentService.EmailForSend$.next(this.form.get('email').value);
      this.paymentService.getUlrForPayment(CGrequest);
    } else {
      this.note = 'יש לבחור צורת תשלום';
    }
  }
  createBitPayment() {
    this.loadingService.show();
    let CGrequest: PaymentsLogRequest = {
      muniCode: this.muniService.getMuniCode,
      guest: this.authService.isLoggedIn() ? false : true,
      clientDomain: new URL(window.location.href).origin,
      creditType: 1,
      actType: 2,
      numberOfPayments: this.numOfPayments,
      email: this.form.get('email').value,
    };
    if (!this.authService.isLoggedIn()) {
      if (this.random.Guest?.value)
        CGrequest.ID = this.random.Guest.value.guestID;
    } else {
      CGrequest.ID = 0;
    }
    if (window.location.href.includes('randomPayments')) {
      CGrequest.paymentProcessCategory = 2;
    } else if (window.location.href.includes('debtBalance')) {
      CGrequest.paymentProcessCategory = 3;
    } else if (window.location.href.includes('payVoucher')) {
      let voucher: Voucher;
      CGrequest.paymentProcessCategory = 1;
      if (this.voucherService.oneVocher$.value) {
        voucher = this.voucherService.oneVocher$.value;
      }
      let paymentsLogElement: paymentsLog = {
        yearOfProduct: parseInt(voucher.voucherPeriod.substr(3, 4)),
        voucherSerialNumber: voucher.voucherId.toString(),
        paymentAmount: voucher.sum,
        usury: 0,
        assetId: '',
      };
      CGrequest.PaymentLog = [paymentsLogElement];
      CGrequest.ID = 0;
      CGrequest.personID = !this.authService.isLoggedIn()
        ? this.voucherService.mainIdPaymentOneVoucher.toString()
        : '';
    }
    if (
      CGrequest.paymentProcessCategory == 2 ||
      CGrequest.paymentProcessCategory == 3
    ) {
      this.items = this.paymentService.basketItems$.value;
      this.items.map((item) => {
        let paymentsLogElement: paymentsLog;
        if (CGrequest.paymentProcessCategory == 2) {
          paymentsLogElement = {
            casualPaymentID: parseInt(item.id),
            quantity: item.NumOfOrderedItems,
            paymentAmount: item.price * item.NumOfOrderedItems,
            assetId: item.assetID,
            answers: item.DynamicField,
          };
        } else if (CGrequest.paymentProcessCategory == 3) {
          paymentsLogElement = {
            yearOfProduct: +item.yearOfProduct,
            casualPaymentID: parseInt(item.id),
            usury: +item.isInterest,
            paymentAmount: item.price,
            assetId: item.assetID,
          };
        }
        if (!CGrequest.PaymentLog || CGrequest.PaymentLog?.length == 0)
          CGrequest.PaymentLog = [paymentsLogElement];
        else CGrequest.PaymentLog.push(paymentsLogElement);
      });
      if (!this.authService.isLoggedIn()) {
        if (this.random.Guest?.value)
          CGrequest.ID = this.random.Guest.value.guestID;
      } else {
        CGrequest.ID = 0;
      }
    }
    // if (this.needEmail)
    this.paymentService.EmailForSend$.next(this.form.get('email').value);
    this.paymentService.getUlrForPayment(CGrequest);
  }
  prevNormal() {
    this.goPrev.emit();
  }
  disabled(): boolean {
    if (
      (this.iChooseCards != -1 || this.iChooseSaved != -1) &&
      (this.creditType != 0 || this.isBit) &&
      this.form.value.email
    )
      return false;
    else return true;
  }
  private generateForm() {
    if (this.controlsList?.length > 0) {
      let configs: InputConfig[] = [];
      this.controlsList.map((ctrl) => {
        configs.push(ctrl.inputControl?.config);
      });
      this.form = generateForm(configs);
    }
  }
}
