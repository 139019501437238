export interface IButton{
    type: 'action' | 'option' |'toggle-group' | 'checkbox' | 'addPersons';
    text: string;
    name: string;
    image?: string;
    color?: string;
    textMini?: string[];
    haveCircle?:boolean;
    toggleGroupName?:string;
    needTitle?: boolean;
    required?: boolean;
}

export  interface IButtonToggleConfig{
  value: string;
  name: string;
  miniBtn?: boolean;
  isDefult?: boolean;
}

export function setIButton(  
    type:'action' | 'option' |'toggle-group'| 'checkbox',
    text:string,
    name: string,
    image?:string,
    color?:string,
    haveCircle?: boolean,
    toggleGroupName?:string,
    needTitle?: boolean,
    required?: boolean
  ): IButton {
    return  {
      type,
      text,
      name,
      image,
      color,
      haveCircle:!!haveCircle,
      toggleGroupName,
      needTitle:needTitle,
      required :!!required
    } 
  }
  