import { Injectable } from '@angular/core';
import {
  SendWaterInfo,
  changeData,
  exsistRequestChange,
  getExistReq,
  saveDetailsData,
} from './changePayments.model';
import {
  assetNumberInputConfig,
  assetTypeConfig,
  citySelectConfig,
  dateChangeConfig,
  dateInOwnerConfig,
  dateOutOwnerConfig,
  dateReadConfig,
  emailconfig,
  endMoneConfig,
  fileRentconfig,
  fileSefachconfig,
  fileShoperconfig,
  fileWaterMoneconfig,
  fileconfig,
  firstNameconfig,
  floorHouseEnterConfig,
  formDeme,
  houseNumberApartmentConfig,
  idconfig,
  lastNameconfig,
  level,
  numCardWaterConfig,
  numLiversNowConfig,
  paymentNumberconfig,
  phoneconfig,
  signBody,
  streetConfig,
  streetSelectConfig,
} from '../../elementForm/controlsDeme';
import { fullControl } from '../../elementForm/control.model';
import { AppService } from 'src/app/services/app.service';
import { MuniService } from 'src/app/services/muni.service';
import { FormsFunctionsService } from '../forms-functions.service';
import { HoldersExchangeType } from '../functionEnum.model';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { GetAssetsByFamilyMainIDReq } from '../functions.model';
import { FormsDataService } from '../../forms-data.service';
import { IListItem } from '../../elementForm/form-input/form-input.model';

@Injectable({
  providedIn: 'root',
})
export class ChangePaymentsService {
  destroy$ = new Subject();
  HoldersExchangeType: HoldersExchangeType;
  changeDate: string;
  exsistRequestChangeData$ = new BehaviorSubject<exsistRequestChange | null>(
    null
  );
  constructor(
    private app: AppService,
    private muni: MuniService,
    private formsService: FormsFunctionsService,
    private formsData: FormsDataService
  ) {}
  saveFormType(data: changeData) {
    if (data.typeForm == 'buyOrSell' && data.buyOrSellPerson == 'seller') {
      this.HoldersExchangeType = 1;
    } else if (
      data.typeForm == 'buyOrSell' &&
      data.buyOrSellPerson == 'buyer'
    ) {
      this.HoldersExchangeType = 2;
    } else if (data.typeForm == 'rent' && data.rentPerson == 'enter') {
      this.HoldersExchangeType = 4;
    } else if (data.typeForm == 'rent' && data.rentPerson == 'go') {
      this.HoldersExchangeType = 3;
    }
  }
  createForm(data: changeData): formDeme {
    let labelLevel3: string;
    let level3Controls: fullControl[] = [];
    let water: fullControl[] = [
      numCardWaterConfig,
      endMoneConfig,
      dateReadConfig,
      fileWaterMoneconfig,
      numLiversNowConfig,
    ];
    
    let assetDetails: fullControl[] = [
      paymentNumberconfig,
      // assetNumberConfig,
      assetNumberInputConfig,
      // assetTypeTaboConfig,
      assetTypeConfig,
      // addressAssetConfig,
      citySelectConfig,
      streetSelectConfig,
      houseNumberApartmentConfig,
      // floorHouseEnterConfig,
      dateChangeConfig,
    ];
    this.saveFormType(data);
    if (
      (data.typeForm == 'buyOrSell' && data.buyOrSellPerson == 'seller') ||
      (data.typeForm == 'buyOrSell' && data.buyOrSellPerson == 'buyer')
    ) {
      
      level3Controls = [idconfig, lastNameconfig, firstNameconfig, phoneconfig];
      if (data.buyOrSellPerson == 'seller') {
        labelLevel3 = 'מיהו בעל הנכס?';
        //פה שיהיה שדות לא חובה
        level3Controls.push(
          {
            controlType: 'input',
            inputControl: {
              config: {
                formControlName: 'email',
                inputType: 'text',
                label: 'אימייל',
                name: 'email',
                required: false,
                type: 'input',
              },
            },
          },
          {
            controlType: 'input',
            inputControl: {
              config: {
                formControlName: 'fileid',
                inputType: 'file',
                label: 'יש לצרף צילום תעודת זהות',
                name: 'fileid',
                required: false,
                type: 'file',
              },
            },
          },
          {
            controlType: 'input',
            inputControl: {
              config: {
                formControlName: 'city',
                inputType: 'select',
                label: 'יישוב',
                name: 'city',
                required: false,
                type: 'select',
                upLable: true,
              },
              selectconfig: {
                bigSelect: false,
                copyList: [],
                lable: 'יישוב',
                list: [],
              },
            },
          },
          {
            controlType: 'input',
            inputControl: {
              config: {
                formControlName: 'street',
                inputType: 'select',
                label: 'רחוב',
                name: 'street',
                required: false,
                type: 'select',
                upLable: true,
              },
              selectconfig: {
                bigSelect: false,
                copyList: [],
                lable: 'רחוב',
                list: [],
              },
            },
          },
          houseNumberApartmentConfig
        );
        level3Controls.push(fileShoperconfig);
        water.pop();
        assetDetails.pop();
        assetDetails.push(dateOutOwnerConfig);
      } else if (data.buyOrSellPerson == 'buyer') {
        labelLevel3 = 'מי המוכר של הנכס?';
        level3Controls.push(
          emailconfig,
          fileconfig,
          citySelectConfig,
          streetSelectConfig,
          houseNumberApartmentConfig
        );
        //פה להשאיר את החובה
        assetDetails.push(fileShoperconfig);
      }
    } else if (
      (data.typeForm == 'rent' && data.rentPerson == 'enter') ||
      (data.typeForm == 'rent' && data.rentPerson == 'go')
    ) {
      level3Controls = [
        {
          controlType: 'input',
          inputControl: {
            config: {
              formControlName: 'id',
              inputType: 'text',
              label: 'תעודת זהות',
              name: 'id',
              required: true,
              type: 'input',
            },
          },
        },
        {
          controlType: 'input',
          inputControl: {
            config: {
              formControlName: 'lastName',
              inputType: 'text',
              label: 'שם משפחה',
              name: 'lastName',
              required: true,
              type: 'input',
            },
          },
        },
        {
          controlType: 'input',
          inputControl: {
            config: {
              formControlName: 'firstName',
              inputType: 'text',
              label: 'שם פרטי',
              name: 'firstName',
              required: true,
              type: 'input',
            },
          },
        },
        {
          controlType: 'input',
          inputControl: {
            config: {
              formControlName: 'email',
              inputType: 'text',
              label: 'אימייל',
              name: 'email',
              required: true,
              type: 'input',
            },
          },
        },
        {
          controlType: 'input',
          inputControl: {
            config: {
              formControlName: 'phone',
              inputType: 'text',
              label: 'נייד',
              name: 'phone',
              required: true,
              type: 'input',
            },
          },
        },
        {
          controlType: 'input',
          inputControl: {
            config: {
              formControlName: 'fileid',
              inputType: 'file',
              label: 'יש לצרף צילום תעודת זהות ',
              name: 'fileid',
              required: true,
              type: 'file',
            },
          },
        },
        citySelectConfig,
        streetSelectConfig,
        houseNumberApartmentConfig,
      ];
      if (data.rentPerson == 'enter') {
        labelLevel3 = 'מי השוכר היוצא או בעל הנכס?';
        // level3Controls.push(addressBuyerConfig);
        assetDetails.pop();
        assetDetails.push(dateInOwnerConfig);
        assetDetails.push(fileRentconfig);

      } else if (data.rentPerson == 'go') {
        labelLevel3 = 'מיהו השוכר הנכנס?';
        // level3Controls.push(addressBaalConfig);
        level3Controls.push(fileRentconfig);
      }
    }


    let identification: fullControl[] = [
      idconfig,
      lastNameconfig,
      firstNameconfig,
      emailconfig,
      phoneconfig,
      fileSefachconfig,
    ];
    let signBody: signBody = {
      buttons: [
        {
          btnName: 'check1',
          config: {
            name: 'check1',
            text: 'בשליחת טופס זה הריני מצהיר/ה בזה כי כל הפרטים הרשומים בבקשתי זו הם נכונים, מדויקים ומלאים .וכי לא העלמתי פרט כלשהו מהפרטים שנתבקשתי למלא וכי אני מסכים לתנאי השימוש באתר והצהרת הפרטיות כפי שמופעים באתר .משלוח טופס זה מהווה הסכמה לכך שהרשות המקומית תיצור איתי קשר באמצעות הטלפון בשיחה ו/או מסרון ובאמצעות הדוא"ל לצורך טיפול בפנייה ובנושאים אחרים בכל עת .ידוע לי כי דיווח חלקי ו/או כוזב בבקשתי זו הינו עבירה על החוק, וכי לרשות המקומית יש זכות לבדוק את נכונות הפרטים שמסרתי בבקשתי זו בכל האמצעים העומדים לרשותה בחוק.זהו שמי וזו תעודת הזהות שלי, ותוכן הצהרה זו - אמת',
            type: 'checkbox',
          },
        },
        {
          btnName: 'check2',
          config: {
            name: 'check2',
            text: 'אני מסכים לתנאי השימוש ולמדיניות הפרטיות של האתר',
            type: 'checkbox',
          },
        },
      ],
      sign: [{ name: 'חתימת הפונה' }],
      needEnd: true,
    };
    let levels: level[] = [
      {
        controls: identification,
        id: 1,
        name: 'פרטי מגיש הבקשה',
        type: 'form',
        lable: 'נצטרך קצת פרטים עליך...',
        functionLevel: '',
      },

      {
        controls: assetDetails,
        id: 2,
        name: 'פרטי הנכס',
        type: 'form',
        lable: 'על איזה נכס מדובר?',
      },
      {
        controls: level3Controls,
        id: 3,
        name: 'פרטי הבקשה',
        type: 'form',
        lable: labelLevel3,
      },
      {
        controls: water,
        id: 4,
        name: 'נתוני מים',
        type: 'form',
        lable: '',
      },
      {
        id: 5,
        name: ' אישור הבקשה',
        type: 'sign',
        lable: 'כמעט מסיימים...',
        signBody: signBody,
      },
      // {
      //   id: 6,
      //   name: 'סיום',
      //   type: 'sendSucsses',
      //   lable: '',
      // },
    ];

    let changePaymnetform: formDeme = {
      id: 10,
      levels: levels,
      name: 'החלפת משלמים',
    };
    return changePaymnetform;
  }
  getExistRequestHoldersExchange() {
    let body: getExistReq = {
      muniCode: this.muni.getMuniCode,
      mainRequestID:
        this.formsService.existRequestData$.value.requestData.lineID,
    };
    this.app.postF('Forms/HoldersExchange/GetExistRequest', body).subscribe(
      (res) => {
        if (res && res.body) this.exsistRequestChangeData$.next(res.body);
      },
      (error) => {}
    );
  }
  saveDetailsHoldersExchange(body: saveDetailsData) {
    return this.app.postF('Forms/HoldersExchange/SaveDetails', body);
  }
  SendWaterInfo(body: SendWaterInfo) {
    return this.app.postF('Forms/HoldersExchange/SendWaterInfo', body);
  }
  getDataPaymentNumber(val) {
    let body: GetAssetsByFamilyMainIDReq = {
      id: val,
      muniCode: this.muni.getMuniCode,
    };
    this.formsService.GetAssetsByFamilyMainID(body);
    this.getExistRequestHoldersExchange();
  }
}
