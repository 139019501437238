import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MuniServiceCard } from 'src/app/common/models/muni.model';

@Injectable({
  providedIn: 'root',
})
export class FormsTypesService {

  constructor() {}
}
