import { Component, Input, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { MyDateService } from 'src/app/forms/elementForm/my-date/my-date.service';
import {
  assetDetails,
  generalAssetDetails,
  propertyTax,
} from 'src/app/personal-details/asset/asset.model';
import { AssetService } from 'src/app/services/asset.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.scss'],
})
export class AssetDetailsComponent implements OnInit {
  destroy$ = new Subject();
  text = {
    title: 'נתוני משלם',
    title2: 'נתונים כלליים',
  };
  list = [
    {
      title: 'שם משלם',
      value: '',
    },
    {
      title: 'קוד משלם',
      value: '',
    },
    {
      title: 'סוג נכס',
      value: '',
    },
    {
      title: 'איזור',
      value: '',
    },
    {
      title: 'כתובת הנכס',
      value: '',
    },
    {
      title: 'שובר לדואר',
      value: '',
    },
  ];
  list2 = [
    {
      title: 'שכונה',
      value: '',
    },
    {
      title: 'רחוב',
      value: '',
    },
    {
      title: 'מספר',
      value: '',
    },
    {
      title: 'ת. התחלת חיוב',
      value: '',
    },
    {
      title: 'ת. הפסקת פעילות',
      value: '',
    },
    {
      title: 'ת. פתיחת נכס',
      value: '',
    },
    {
      title: 'ת. סיום שכירות',
      value: '',
    },
    {
      title: 'מגרש / משק',
      value: '',
    },
    {
      title: 'דירה',
      value: '',
    },
    {
      title: 'מס בקשה',
      value: '',
    },
    {
      title: 'גוש',
      value: '',
    },
    {
      title: 'מס חדרים',
      value: '',
    },
    {
      title: 'חלקה',
      value: '',
    },
    {
      title: 'תת חלקה',
      value: '',
    },
  ];
  maintz;
  mobile = window.screen.width <= 850 ? true : false;
  constructor(
    private authService: AuthService,
    private assetService: AssetService,
    private dateService: MyDateService
  ) {}
  ngOnInit(): void {
    this.assetService.PropertyTaxDataObject$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((res) => {
      if (res) {
        this.convertToList(res.assetDetails);
        this.convertToList2(res.generalAssetDetails);
      }
    });
    this.authService.getUser().subscribe((res) => {
      this.maintz = res.maintz;
    });
  }
  public isDate(value: string): boolean {
    return value && value.includes('T') && value.includes(':') ? true : false;
  }
  private convertToList(assetDetails: assetDetails) {
    this.list[0].value = assetDetails.fullName;
    this.list[1].value = this.maintz;
    this.list[2].value = assetDetails.assetType;
    this.list[3].value = assetDetails.area;
    this.list[4].value = assetDetails.assetAddress;
    this.list[5].value = assetDetails.printEnvelope ? 'כן' : 'לא';
  }
  private convertToList2(generalAssetDetails: generalAssetDetails) {
    this.list2[0].value = generalAssetDetails.neighborhood;
    this.list2[1].value = generalAssetDetails.street;
    this.list2[2].value = generalAssetDetails.houseNumber.toString();
    this.list2[3].value = generalAssetDetails.billingStartDate.toString();
    this.list2[4].value = generalAssetDetails.activityPauseDate.toString();
    this.list2[5].value = generalAssetDetails.assetOpeningDate.toString();
    this.list2[6].value = generalAssetDetails.rentEndDate.toString();
    this.list2[7].value = generalAssetDetails.court;
    this.list2[8].value = generalAssetDetails.apartmentNum.toString();
    this.list2[9].value = generalAssetDetails.requestNum.toString();
    this.list2[10].value = generalAssetDetails.block;
    this.list2[11].value = generalAssetDetails.roomNumbers.toString();
    this.list2[12].value = generalAssetDetails.division.toString();
    this.list2[13].value = generalAssetDetails.subLot.toString();
  }
}
