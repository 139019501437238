<div class="container">
  <div class="muniLineText">
    <span class="name">{{ text.sisma }}</span>
    <div class="muniLine"></div>
  </div>

  <div class="text">
    <span>{{ text.clalim1 }}</span>
    <span>{{ text.clalim2 }}</span>
    <!-- <span class="bold">{{ text.after }}</span> -->
  </div>
  <form [formGroup]="form">
    <div class="passwordInputs">
      <div class="inputDiv">
        <div class="inputLable">
          <!-- <mat-checkbox
            #field
            id="field"
            [value]="typeSend==1"
            class="example-margin"
            (change)="typeSendCheck($event.target.value, 'phone')"
          ></mat-checkbox> -->
          <span>
            {{ text.phone }}
          </span>
        </div>
        <div class="row">
          <input id="phone" formControlName="phone" type="number" [readonly]="true"/>
          <!-- <img src="../../../assets/svg/pencil.svg" (click)="focus(1)"> -->
        </div>
      </div>
      <div class="inputDiv">
        <div class="inputLable">
          <!-- <mat-checkbox
            #field
            id="field"
            [value]="typeSend==2"
            class="example-margin"
            (change)="typeSendCheck($event.target.value, 'email')"
          ></mat-checkbox> -->
          <span>{{ text.email }}</span>
        </div>
        <div class="row">
          <input id="email" formControlName="email" type="text" [readonly]="true" />
          <!-- <img src="../../../assets/svg/pencil.svg" (click)="focus(2)"> -->

        </div>
      </div>
    </div>
  </form>
  <!-- <button class="confirm">{{ text.btn }}</button> -->
</div>
