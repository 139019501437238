import { Component, Input, OnInit } from '@angular/core';
import { selectConfig } from 'src/app/common/elements/my-select/my-select.model';
import { buttonControl } from 'src/app/forms/elementForm/control.model';
import { level } from 'src/app/forms/elementForm/controlsDeme';

@Component({
  selector: 'app-discount-asset',
  templateUrl: './discount-asset.component.html',
  styleUrls: ['./discount-asset.component.scss'],
})
export class DiscountAssetComponent implements OnInit {
  @Input() level: level;
  assetSelectConfig: selectConfig = {
    lable: 'מספר הנכס',
    list: [],
    copyList: [],
    bigSelect: false,
    shadow: true,
  };
  assetSelected: any;
  text = {
    asset: 'מספר הנכס',
    address: 'כתובת הנכס',
    type: 'סוג הנכס',
    exist: 'הנחה קיימת על נכס זה',
  };
  btnControls: buttonControl[] = [
    {
      btnName: 'myApartment',
      config: {
        name: 'myApartment',
        text: 'האם הדירה בבעלותי?',
        type: 'toggle-group',
        required: true,
        toggleGroupName: 'myApartment',
        needTitle: true,
      },
      toggleConfig: [
        {
          value: 'yes',
          name: 'כן',
        },
        {
          value: 'no',
          name: 'לא',
        },
      ],
      childControls: [
        {
          controlType: 'input',
          inputControl: {
            config: {
              formControlName: 'rentSum',
              inputType: 'number',
              label: 'עלות שכירות חודשית',
              name: 'rentSum',
              required: false,
              type: 'input',
            },
          },
        },
        {
          controlType: 'input',
          inputControl: {
            config: {
              formControlName: 'dateEnd',
              inputType: 'date',
              label: 'תאריך סיום חוזה',
              name: 'dateEnd',
              required: false,
              type: 'date',
            },
          },
        },
        {
          controlType: 'input',
          inputControl: {
            config: {
              formControlName: 'rentFile',
              inputType: 'file',
              label: 'צילום חוזה שכירות',
              name: 'rentFile',
              required: false,
              type: 'file',
            },
          },
        },
      ],
    },
    {
      btnName: 'more',
      config: {
        name: 'more',
        text: 'האם בבעלותך דירות נוספות?',
        type: 'toggle-group',
        required: true,
        toggleGroupName: 'more',
        needTitle: true,
      },
      toggleConfig: [
        {
          value: 'yes',
          name: 'כן',
        },
        {
          value: 'no',
          name: 'לא',
        },
      ],
      childControls: [
        {
          controlType: 'input',
          inputControl: {
            config: {
              formControlName: 'allSumApartment',
              inputType: 'number',
              label: 'הכנסה כוללת מהדירות',
              name: 'allSumApartment',
              required: false,
              type: 'input',
            },
          },
        },
        {
          controlType: 'input',
          inputControl: {
            config: {
              formControlName: 'rentFile',
              inputType: 'file',
              label: 'צילום חוזה שכירות',
              name: 'rentFile',
              required: false,
              type: 'file',
            },
          },
        },
      ],
    },
    {
      btnName: 'car',
      config: {
        name: 'car',
        text: 'האם בבעלותך רכב?',
        type: 'toggle-group',
        required: true,
        toggleGroupName: 'car',
        needTitle: true,
      },
      toggleConfig: [
        {
          value: 'yes',
          name: 'כן',
        },
        {
          value: 'no',
          name: 'לא',
        },
      ],
      childControls: [
        {
          controlType: 'input',
          inputControl: {
            config: {
              formControlName: 'carYear',
              inputType: 'number',
              label: 'שנת ייצור הרכב',
              name: 'carYear',
              required: false,
              type: 'input',
            },
          },
        },
      ],
    },
  ];
  hisApartment = true;
  haveAnother = false;
  haveCar = false;
  ngOnInit(): void {}
  select(event) {
    this.assetSelected = event;
  }
  chooseBtn(event, i) {
    this.btnControls[i].value = event.value;
    switch (i) {
      case 0:
        if (event.value == 'no') {
          this.hisApartment = false;
        }
        break;
      case 1:
        if (event.value == 'yes') {
          this.haveAnother = true;
        }
        break;
      case 2:
        if (event.value == 'yes') {
          this.haveCar = true;
        }
        break;
      default:
        break;
    }
  }
  submitInput(event) {}
}
