<div class="container">
  <form [formGroup]="form">
    <div class="muniLineText">
      <span class="name">{{ text.sisma }}</span>
      <div class="muniLine"></div>
    </div>
    <div *ngIf="!codeInput && !ChooseAnotherPassword">
      <div class="passwordInputs">
        <div class="inputDiv">
          <div class="inputLable">
            <span *ngIf="form.value.id">
              {{ text.pass }}
            </span>
          </div>
          <div class="row">
            <input
              formControlName="id"
              #id
              type="text"
              placeholder="{{ text.pass }}"
            />
          </div>
          <span class="error">
            <span>{{ errorPass }}</span></span
          >
        </div>
        <div class="inputDiv">
          <div class="inputLable">
            <span *ngIf="form.value.device">{{ text.place1 }}</span>
          </div>
          <div class="row">
            <input
              #device
              name="email"
              formControlName="device"
              type=" 'text' "
              placeholder="{{ text.place1 }}"
            />
          </div>
          <span class="error">
            <span>{{ errprNew }}</span></span
          >
        </div>
      </div>
      <span>{{ note }}</span>
      <div class="text">
        <span>{{ text.clalim1 }}</span>
        <span class="bold">{{ text.after }}</span>
      </div>
      <button [disabled]="disabled()" class="confirm" (click)="sendCode()">
        {{ text.btn }}
      </button>
    </div>
    <div *ngIf="codeInput && !ChooseAnotherPassword" class="codeInput">
      <span class="inputcodetext">{{ text.inputcode }}</span>
      <code-input
        class="codeComp"
        [initialFocusField]="0"
        [codeLength]="6"
        (codeCompleted)="onCodeCompleted($event)"
      >
      </code-input>
      <button [disabled]="disabled()" class="confirm" (click)="sendCode()">
        {{ text.btn2 }}
      </button>
    </div>
    <div *ngIf="ChooseAnotherPassword && !codeInput">
      <app-choose-new-password></app-choose-new-password>
    </div>
  </form>
</div>
