import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { MuniService } from './services/muni.service';
import { LayoutService } from './layout/layout.service';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  url = '';
  openMobile = false;
  isLoggedIn = false;
  mobile = window.screen.width <= 850 ? true : false;

  constructor(
    private route: ActivatedRoute,
    private muniService: MuniService,
    private router: Router,
    private layoutService: LayoutService,
    private authService: AuthService
   
    
  ) {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        if (params.muni) {
          const muniCode = encodeURI(params.muni);
          if (muniCode) muniService.getMuniData(+muniCode, '');
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  ngOnInit(): void {
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          this.url = event.url.split('?')[0];
        }
      });
    this.layoutService.openMobileSideBar$.subscribe((res) => {
      if (res == true) {
        this.openMobile = res;
        let el = document.getElementById('mobileSideBar');
        if (el) {
          el.style.transition = 'all 0.5s ease';
          el.style.transform = 'translate(-300px, 0)';
          el.style.zIndex = '150';
        }
      } else {
        let el = document.getElementById('mobileSideBar');
        if (el) {
          el.style.transition = 'all 0.5s ease';
          el.style.transform = 'translate(300px, 0)';
        }
      }
    });
    this.authService
      .getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && res.maintz && res.maintz != '') {
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;
        }
      });
  }

}
