<div
  class="content"
  [ngClass]="{
  singleForm: singleForm == true,
}"
>
  <div class="form">
    <form
      [formGroup]="form"
      [ngClass]="{
        chengeFlex: miniForm == true,
        sign: needSign === true
      }"
    >
      <div *ngFor="let c of controlsList; let i = index">
        <ng-container [ngSwitch]="c.controlType">
          <ng-container *ngSwitchCase="'input'">
            <div class="center" [ngSwitch]="c.inputControl.config.type">
              <ng-container *ngSwitchCase="'select'">
                <!-- [formControlName]="c.inputControl.config.formControlName" -->
                <form-input
                  class="form-input"
                  [fullControl]="c.inputControl"
                  [errorRes]="c.inputControl.config.errors"
                  [errorAnother]="c.inputControl.config.anotherError"
                  [selectconfig]="c.inputControl.selectconfig"
                  (submitFunction)="submitInput($event, false)"
                ></form-input>
              </ng-container>
              <ng-container *ngSwitchCase="'input'">
                <form-input
                  class="form-input"
                  [fullControl]="c.inputControl"
                  [enterNext]="enterNext"
                  [value]="c.inputControl.value"
                  [errorRes]="c.inputControl.config.errors"
                  [errorAnother]="c.inputControl.config.anotherError"
                  [clearControl]="clearControl"
                  [focusControl]="focusControl"
                  (nextEnterGo)="nextEnterGo(i)"
                  (submitFunction)="submitInput($event, false)"
                ></form-input>
              </ng-container>
              <ng-container *ngSwitchCase="'file'">
                <my-file
                  [config]="c.inputControl.config"
                  [file]="c.inputControl.config.file"
                  [personID]="personID"
                  [errorRes]="c.inputControl.config.errors"
                  [errorAnother]="c.inputControl.config.anotherError"
                  (submitFunction)="submitInput($event, false)"
                ></my-file>
              </ng-container>
              <ng-container *ngSwitchCase="'multy-files'">
                <multy-files
                  [config]="c.inputControl.config"
                  [filesList]="c.inputControl.config.fileList"
                  [personID]="personID"
                  [errorRes]="c.inputControl.config.errors"
                  [errorAnother]="c.inputControl.config.anotherError"
                  (submitFunction)="
                    submitInput($event, false, false, false, true)
                  "
                  (deletedFile)="
                    deleteChild(
                      $event,
                      c.inputControl.config.formControlName,
                      true
                    )
                  "
                ></multy-files>
              </ng-container>
              <ng-container *ngSwitchCase="'date'">
                <my-date
                  class="form-input"
                  [config]="c.inputControl.config"
                  [errorRes]="c.inputControl.config.errors"
                  [errorAnother]="c.inputControl.config.anotherError"
                  [day]="c.inputControl.config.day"
                  [month]="c.inputControl.config.month"
                  [year]="c.inputControl.config.year"
                  (haveFullDate)="submitInput($event, false)"
                  (tabClick)="tabClickDate(i)"
                ></my-date>
              </ng-container>
              <ng-container *ngSwitchCase="'checkbox'">
                <form-input
                  class="form-input"
                  [fullControl]="c.inputControl"
                  (submitFunction)="submitInput($event, false)"
                ></form-input>
              </ng-container>
              <ng-container *ngSwitchCase="'gender'">
                <form-input
                  class="form-input"
                  [fullControl]="c.inputControl"
                  [errorRes]="c.inputControl.config.errors"
                  [errorAnother]="c.inputControl.config.anotherError"
                  (submitFunction)="submitInput($event, false)"
                ></form-input>
              </ng-container>
              <ng-container *ngSwitchCase="'textarea'">
                <form-input
                  class="form-input textarea"
                  [fullControl]="c.inputControl"
                  [errorRes]="c.inputControl.config.errors"
                  [errorAnother]="c.inputControl.config.anotherError"
                  (submitFunction)="submitInput($event, false)"
                ></form-input>
              </ng-container>
            </div>
          </ng-container>
          <ng-container class="twogrid" *ngSwitchCase="'button'">
            <div class="alignBtn">
              <my-button
                [config]="c?.buttonControl?.config"
                [value]="c?.buttonControl?.value"
                [toggleConfig]="c?.buttonControl?.toggleConfig"
                (buttonSelected)="
                  submitInput(
                    $event,
                    true,
                    c?.buttonControl?.config.type == 'addPersons'
                  )
                "
              ></my-button>
            </div>
          </ng-container>
          <ng-container class="anyThing" *ngSwitchCase="'anyThing'">
          </ng-container>
        </ng-container>
      </div>
    </form>
    <div *ngIf="afterClick == true && clickValue == 'true'">
      <div class="flex" *ngIf="childControls?.length > 0">
        <div
          class="child"
          *ngFor="let control of childControls; let chi = index"
        >
          <form-input
            *ngIf="control.inputControl.config.type != 'file'"
            class="form-input"
            [fullControl]="control.inputControl"
            (submitFunction)="submitInput($event, false, false, true)"
          ></form-input>
          <div class="row">
            <my-file
              *ngIf="control.inputControl.config.type == 'file'"
              [config]="control.inputControl.config"
              [file]="control.inputControl.config.file"
              [personID]="personID"
              [errorRes]="control.inputControl.config.errors"
              [errorAnother]="control.inputControl.config.anotherError"
              (submitFunction)="submitInput($event, false, false, true)"
              (deletedFile)="
                deleteChild(chi, control.inputControl.config.formControlName)
              "
            ></my-file>
            <span
              class="link"
              (click)="
                deleteChild(chi, control.inputControl.config.formControlName)
              "
              >מחק</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="nextdiv" *ngIf="!hideNext || hideNext == 0">
      <button *ngIf="!hidePrev" class="prev" mat-button matStepperPrevious>
        <!-- <img src="../../../assets/svg/arrow-right.svg" /> -->
        הקודם
      </button>
      <button class="next" mat-button (click)="submitForm()">
        {{ btnText && btnText != "" ? btnText : "הבא" }}
        <!-- <img src="../../../assets/png/leftPay.png" /> -->
      </button>
      <!-- [disabled]="disable" -->
    </div>

    <div class="nextdiv" *ngIf="hideNext == 1">
      <button (click)="continue()" class="next" mat-button>המשך</button>
    </div>
  </div>
</div>
