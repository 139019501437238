import { Injectable } from '@angular/core';
import { AppService } from '../services/app.service';
import { BehaviorSubject, skipWhile } from 'rxjs';
import { basket } from '../forms/elementForm/payment-page/paymentPage.model';
import { PaymentService } from '../forms/elementForm/payment-page/payment.service';

@Injectable({
  providedIn: 'root'
})
export class DebtsBalanceService {
  level$ = new BehaviorSubject<number>(1);
  constructor(private app: AppService, private paymentService: PaymentService) { }
  getCharges() {
    return this.app
      .get('FinancialData/GetAccountBalance')
      .pipe(skipWhile((x) => !x));
  }
  setBasketItemsDebts(inputs: any[]) {
    let basket = inputs;
    if (basket) {
      let arr: basket[] = [];
      let sum = 0;
      basket.map((element) => {
        arr.push({
          isInterest: element.isInterest,
          title: element.chargeType,
          id: element.ChargeID,
          assetID: element.assetId,
          price: element.sum,
          yearOfProduct: element.yearOfProduct,
        });
        sum += element.sum;
      });
      this.paymentService.basketItems$.next(arr);
      this.paymentService.sum$.next(sum);
    }
  }
}
