import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MuniServiceCard } from 'src/app/common/models/muni.model';

@Component({
  selector: 'app-form-type-card',
  templateUrl: './form-type-card.component.html',
  styleUrls: ['./form-type-card.component.scss'],
})
export class FormTypeCardComponent implements OnInit {
  @Input() form: MuniServiceCard;
  img = 'formArrow';
  constructor(private router: Router) {}
  ngOnInit(): void {}
  hover() {
    this.img = 'formArrowLine';
  }

  unhover() {
    setTimeout(() => {
      this.img = 'formArrow';
    }, 200);
  }
  go() {
    if (this.form.link.includes('/')) {
      window.open(this.form.link, "_blank");
    } else {
      this.router.navigate([this.form.link]);
    }
  }
}
