import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { inputConfig } from '../common/elements/my-big-input/my-big-input.model';
import { selectConfig } from '../common/elements/my-select/my-select.model';
import { LoadingService } from '../common/elements/loader/loading.service';
import { MuniService } from '../services/muni.service';
import { generateForm } from '../common/elements/my-input/my-input.functions';
import { AuthService } from '../services/auth.service';
import { EMPTY, Subject, of, skipWhile, take, takeUntil, tap } from 'rxjs';
import { IListItem } from '../forms/elementForm/form-input/form-input.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @Output() closeLogin = new EventEmitter();
  destroy$ = new Subject();
  quickLogin = false;
  isForgetPassword = false;
  text = {
    title: '!ברוכים הבאים',
    title2: '?שנתחבר',
    code: 'כניסה מהירה',
    password: 'יש לי סיסמא',
    btn1: 'שלח לי קוד',
    btn2: 'כניסה',
    inputcode: 'הזן קוד אימות שקיבלת לכתובת המייל או הטלפון',
    registerQ: 'לא רשומים?',
    registerLink: 'לחצו כאן',
    click: 'לחץ כאן',
    forgetTitle: 'איפוס סיסמא',
    addnote: 'ההזדהות נעשית על פי הנתונים המעודכנים במערכת הגביה של הרשות המקומית'
  };
  muncipalities: IListItem[] = [];
  muniselectConfig: selectConfig = {
    icon: 'home.svg',
    lable: 'בחר רשות',
    list: this.muncipalities,
    bigSelect: false,
    copyList: this.muncipalities,
  };
  muniSelected: any;

  muniConfig: inputConfig = {
    icon: 'home.svg',
    lable: 'בחר רשות',
    type: 'select',
    list: this.muncipalities,
    required: false,
    formControlName: 'muni',
  };
  paymentNumberConfig: inputConfig = {
    icon: 'man.svg',
    lable: 'מספר משלם',
    type: 'number',
    required: false,
    formControlName: 'id',
  };
  idConfig: inputConfig = {
    icon: 'man.svg',
    lable: 'תעודת זהות',
    type: 'number',
    required: false,
    formControlName: 'id',
  };
  deviceConfig: inputConfig = {
    icon: 'phone.svg',
    lable: 'כתובת אימייל / נייד',
    type: 'input',
    required: false,
    formControlName: 'email',
  };
  passwordConfig: inputConfig = {
    icon: 'password.svg',
    lable: 'סיסמא',
    type: 'password',
    required: false,
    formControlName: 'password',
  };
  loginForm = generateForm([
    this.muniConfig,
    this.idConfig,
    this.deviceConfig,
    this.passwordConfig,
  ]);
  codeInput = false;
  isRegister = false;
  loginID = 0;
  error = '';
  mobile = window.screen.width <= 850 ? true : false;
  chooseNewPassword = false;
  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    private loading: LoadingService,
    private muniService: MuniService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.muniService
      .getMunicipalities()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          res.map((muni) => {
            this.muncipalities.push({
              label: muni.muniName,
              value: muni.muniCode,
            });
          });
          if (this.muniService.getMuniCode != 0) {
            this.muniSelected = this.muniService.getMuniCode;
            this.loginForm.controls['muni'].setValue(this.muniSelected);
          }
          this.cdr.detectChanges();
        },
        (error) => {}
      );
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  sendCodeOrLogin(isQuick: boolean) {
    if (this.isForgetPassword) {
      this.sendCodeForgetPassword();
    } else if (isQuick) {
      this.sendCode();
    } else {
      this.login();
    }
  }
  sendCodeForgetPassword() {
    this.loading.show();
    this.authService
      .forgetPassword({
        id: this.loginForm.value.id,
        phone: this.loginForm.value.email,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.loading.hide();
          if (res?.body && res.body.loginID > 0) {
            this.codeInput = true;
            this.loginID = res.body.loginID;
          } else if (res && res.body.rc == -1) {
            this.codeInput = false;
            this.error = 'ת.ז. לא תואמת למספר הטלפון / אימייל';
          } else if (res && res.body.rc == 1) {
            this.codeInput = false;
            this.error = 'זוהתה שגיאה. יש לנסות לבצע רישום לאתר';
          } else {
            this.codeInput = false;
            this.error = 'לא ניתן לבצע שינוי סיסמא עם פרטים אלו';
          }
        },
        (error) => {
          this.loading.hide();
        }
      );
  }
  sendCode() {
    if (this.checkGetCodeValidation()) {
      this.loading.show('');
      this.authService
        .getQuickLoginPass(this.loginForm.value.id, this.loginForm.value.email)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            this.loading.hide();
            if (res?.body && res.body.rc == 0) {
              this.codeInput = true;
              this.loginID = res.body.loginID;
            } else if (res && res.body.rc == -1) {
              this.codeInput = false;
              this.error = 'ת.ז. לא תואמת למספר הטלפון / אימייל';
            } else if (res && res.body.rc == 1) {
              this.codeInput = false;
              this.error = 'זוהתה שגיאה. יש לנסות לבצע רישום לאתר';
            } else if (res && res.body.rc == -2) {
              this.codeInput = false;
              this.error = 'לא ניתן לבצע אימות עם פרטים אלו';
            }
          },
          (error) => {
            this.loading.hide();
          }
        );
    }
  }
  login() {
    this.loading.show('כמה רגעים ואתם בתוך הבית הדיגיטלי שלכם');
    this.authService
      .login(
        this.loginForm.controls.id.value,
        this.loginForm.controls.password.value
      )
      .pipe(
        skipWhile((x) => !x),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (user) => {
          if (user.body.token) {
            this.authService.setSession(user.body);
            this.error = '';
            this.loading.hide();
            if (this.mobile) {
              this.closeLogin.emit();
            } else {
              this.dialogRef.close();
            }
            if (this.authService.prevUrl != '') {
              if (this.authService.needPersonalPage$.value == true)
                this.router.navigate(['personal']);
              else this.router.navigate([this.authService.prevUrl]);
            }
            return of(user.body);
          } else {
            this.error = 'קוד משלם או סיסמא לא נכונים';
            return EMPTY;
          }
        },
        (e) => {
          if (e.status !== 401) this.error = 'קרתה שגיאה, נא נסו שנית';
          else this.error = 'קוד משלם או סיסמא לא נכונים';
          this.loading.hide();
        }
      );
  }
  havePassword() {
    this.quickLogin = !this.quickLogin;
    this.codeInput = false;
  }
  register() {
    this.isRegister = !this.isRegister;
  }
  onCodeCompleted(event: any) {
    this.loading.show('כמה רגעים ואתם בתוך הבית הדיגיטלי שלכם');
    this.authService
      .CheckQuickLoginPass(this.loginID, event)
      .pipe(
        takeUntil(this.destroy$),
        skipWhile((x) => !x),
        take(1)
      )
      .subscribe(
        (user) => {
          this.loading.hide();
          if (user.body.token) {
            this.authService.setSession(user.body);
            this.error = '';
            if (this.isForgetPassword) {
              this.chooseNewPassword = true;
            } else if (this.authService.prevUrl && this.authService.prevUrl != '') {
              this.router.navigate([this.authService.prevUrl]);
            }
            // return of(user.body);
          } else {
            this.error = 'סיסמה לא נכונה';
            // return EMPTY;
          }
          if (!this.chooseNewPassword) {
            if (this.mobile) {
              this.closeLogin.emit();
            } else {
              this.dialogRef.close();
            }
          }
          // return EMPTY;
        },
        (e) => {
          this.loading.hide();
          if (e.status !== 401) this.error = 'קרתה שגיאה, נא נסו שנית';
          else this.error = 'קוד לא תקין. נסו שנית';
        }
      );
  }
  fillForm(event: { name: string; value: any }) {
    if (event.name == 'muni') {
      this.muniService.getMuniData(
        event.value,
        this.muncipalities.find((m) => m.value == event.value).label
      );
      this.muniSelected = event.value;
    }
    this.loginForm.controls[event.name]?.setValue(event.value);
  }
  checkGetCodeValidation(): boolean {
    let details = this.loginForm.value;
    if (details.id != '' && details.email != '') return true;
    else return false;
  }
  disabledLogin(): boolean {
    if (this.isForgetPassword) {
      return !(
        this.loginForm.controls.email.value &&
        this.loginForm.controls.email.value != '' &&
        this.loginForm.controls.id.value &&
        this.loginForm.controls.id.value != '' &&
        this.muniSelected
      );
    } else if (this.quickLogin) {
      return !(
        this.loginForm.controls.password.value &&
        this.loginForm.controls.password.value != '' &&
        this.loginForm.controls.id.value &&
        this.loginForm.controls.id.value != '' &&
        this.muniSelected
      );
    } else {
      return !(
        this.loginForm.controls.email.value &&
        this.loginForm.controls.email.value != '' &&
        this.loginForm.controls.id.value &&
        this.loginForm.controls.id.value != '' &&
        this.muniSelected
      );
    }
  }
  forgetPassword() {
    this.isForgetPassword = true;
  }
}
