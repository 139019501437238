import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { descBody } from './descFormPopup.model';
import { DescPopupService } from './desc-popup.service';

@Component({
  selector: 'app-desc-form-popup',
  templateUrl: './desc-form-popup.component.html',
  styleUrls: ['./desc-form-popup.component.scss'],
})
export class DescFormPopupComponent implements OnInit {
  @Input() descBody: descBody;
  @Output() next = new EventEmitter()
  text = {
    title: 'תושב יקר',
    text2: 'להלן רשימת מסמכים הנדרשים לצרף לטופס',
    thank: 'תודה על שיתוף הפעולה.',
    and: 'בברכה,',
  };
  muniName = '';
  constructor(private descService: DescPopupService) {}
  ngOnInit(): void {
    this.muniName = localStorage.getItem('muniName');
    // this.descBody = this.descService.descBody
  }
  nextEmit(){
    this.next.emit()
  }
}
