<div class="wrapper">
  <div class="head">
    <div class="row" *ngIf="isLoggedIn">
      <span class="name">{{ name }}</span>
      <span class="muniName">{{ muniName }}</span>
    </div>
    <div class="row" *ngIf="!isLoggedIn">
      <span class="name">{{ text.hi }}</span>
      <span class="name">{{ text.maybe }}</span>
      <span class="name">{{ text.shave }}</span>
    </div>
  </div>
  <!-- <div class="icons">
    <div class="img" (click)="nav('home')">
      <span class="tooltiptext">דף הבית</span>
      <img class="home" src="../../../assets/png/sidebar/desktop.png" />
    </div>
    <div class="img" (click)="nav('personal')">
      <span class="tooltiptext">אזור אישי</span>
      <img class="home" src="../../../assets/png/sidebar/user.png" />
    </div> -->
  <!-- <div class="img" (click)="nav('forms')">
      <span class="tooltiptext">טפסים מקוונים</span>
      <img class="home" src="../../../assets/png/sidebar/form.svg" />
    </div> -->
  <!-- <div class="img" (click)="nav('asset')">
      <span class="tooltiptext">הנכס שלי</span>
      <img class="home" src="../../../assets/png/sidebar/asset.png" />
    </div>
    <div class="img" (click)="nav('payments')">
      <span class="tooltiptext">התשלומים שלי</span>
      <img class="home" src="../../../assets/png/sidebar/desktop.png" />
    </div>
    <div class="img" (click)="nav('setting')">
      <span class="tooltiptext">הגדרות</span>
      <img class="home" src="../../../assets/png/sidebar/setting.svg" />
    </div>
  </div> -->

  <div class="icons">
    <div class="img" (click)="nav('home')">
      <img class="white" src="../../../assets/svg/arrowDown.svg" />
      <span class="tooltiptext">דף הבית</span>
    </div>
    <div class="img">
      <img
        class="dowm"
        src="../../../assets/svg/arrowDown.svg"
        (click)="menu = 1"
        [ngClass]="{
          top: menu == 1
        }"
      />
      <span class="tooltiptext">תשלומים</span>
    </div>
    <div class="menu" *ngIf="menu == 1">
      <span (click)="nav(link.url)" *ngFor="let link of menuPayments">{{
        link.name
      }}</span>
    </div>
    <div class="img">
      <img
        class="dowm"
        src="../../../assets/svg/arrowDown.svg"
        (click)="menu = 2"
        [ngClass]="{
          top: menu == 2
        }"
      />
      <span class="tooltiptext">הנפקת אישורים</span>
    </div>
    <div class="menu" *ngIf="menu == 2">
      <span (click)="nav(link.url)" *ngFor="let link of menuConfirms">{{
        link.name
      }}</span>
    </div>
    <div class="img">
      <img
        class="dowm"
        src="../../../assets/svg/arrowDown.svg"
        (click)="menu = 3"
        [ngClass]="{
          top: menu == 3
        }"
      />
      <span class="tooltiptext">טפסים</span>
    </div>
    <div class="menu" *ngIf="menu == 3">
      <span (click)="nav(link.url)" *ngFor="let link of menuForms">{{
        link.name
      }}</span>
    </div>
    <div class="img">
      <img
        class="dowm"
        src="../../../assets/svg/arrowDown.svg"
        (click)="menu = 4"
        [ngClass]="{
          top: menu == 4
        }"
      />
      <span class="tooltiptext">האיזור האישי שלי</span>
    </div>
    <div class="menu" *ngIf="menu == 4">
      <span (click)="nav(link.url)" *ngFor="let link of menuPersonal">{{
        link.name
      }}</span>
    </div>
  </div>

  <div class="bottom">
    <div class="mini" *ngIf="isLoggedIn">
      <button class="btn" (click)="nav('logout')">התנתקות</button>
    </div>
    <div
      class="mini"
      [ngClass]="{
        border: isLoggedIn
      }"
      *ngIf="!isLoggedIn"
    >
      <button class="enter" (click)="login()">כניסה</button>
      <span class="regiater">לרישום <span class="blue"> לחץ כאן</span> </span>
    </div>
  </div>
  <div class="prev" (click)="prev()">
    <img class="arrow" src="../../../assets/svg/arroeSidebar.svg" alt="" />
  </div>
</div>
