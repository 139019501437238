import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bussines',
  templateUrl: './bussines.component.html',
  styleUrls: ['./bussines.component.scss'],
})
export class BussinesComponent implements OnInit {
  @Input() mobile: boolean;
  text = {
    title: 'העסק שלי',
  };
  panelOpen = false;
  constructor() {}
  ngOnInit(): void {}
  nav() {
    window.open('https://vendorsb.eprmuni.co.il/welcome')
  }
}
