import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormsFunctionsService } from '../../allForms/forms-functions.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sucsses-send',
  templateUrl: './sucsses-send.component.html',
  styleUrls: ['./sucsses-send.component.scss'],
})
export class SucssesSendComponent implements OnInit {
  destroy$ = new Subject();
  text = {
    title: 'בקשתך נקלטה בהצלחה ונשלחת כעת!',
    num: 'מספר הבקשה:',
    btn: 'חזרה למסך הבית',
  };
  numberReq  = '';
  constructor(
    private router: Router,
    private functionForms: FormsFunctionsService
  ) {}
  ngOnInit(): void {
    this.functionForms.existRequestData$.pipe(takeUntil(this.destroy$)).subscribe(res=>{
      this.numberReq = res?.requestData?.lineID;

    })
  }
  go() {
    this.router.navigate(['']);
  }
}
