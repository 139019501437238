<div class="wrapper">
  <table class="main">
    <table class="widthTable">
      <tr>
        <th *ngFor="let title of displayedColumns">
          <span> {{ title }}</span>
        </th>
      </tr>
    </table>
    <tbody class="widthTable">
      <tr>
        <td class="body">
          <table class="widthTable">
            <tr class="row" *ngFor="let element of propertyTaxRates; let i = index">
              <td class="td" width="33%">{{ element.rateDescriprion }}</td>
              <td class="td" width="33%">{{ element.rateCode }}</td>
              <td class="td" width="33%">{{ element.size }}</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr></tr>
    </tbody>
  </table>
</div>
