<div *ngIf="url != '/' && url != '/dashboard'" class="allPage">
  <app-main-header ></app-main-header>

  <div class="content">
    <div id="mobileSideBar" class="mobileSideBar">
      <app-mobile-side-bar
        (click)="$event.stopPropagation()"
        *ngIf="openMobile"
      ></app-mobile-side-bar>
    </div>
    <app-main-side-bar  *ngIf="isLoggedIn" class="onlyDesktop"></app-main-side-bar>
    <div class="pages">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<div
  class="mobileFooter"
  *ngIf="mobile"
  [ngClass]="{ isnotDashboard: url != '/' && url != '/dashboard' }"
>
  <app-mobile-footer></app-mobile-footer>
</div>
<div *ngIf="url == '/' || url == '/dashboard'" class="allPage">
  <div class="content">
    <div id="mobileSideBar" class="mobileSideBar">
      <app-mobile-side-bar
        (click)="$event.stopPropagation()"
        *ngIf="openMobile"
      ></app-mobile-side-bar>
    </div>
    <div class="pagesDesk">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
