<div class="line">
  <div class="flexRow">
    <span>{{ line.paymentProcessCategoryName }}</span>
    <span class="bold">{{ line.amount | currency : "ILS" }} </span>
  </div>
  <div>
    <span class="mini" *ngFor="let desc of line.casualPayments; let i = index"
      >{{ desc.casualPaymentName }}
      <span *ngIf="i < line.casualPayments.length - 1">, </span>
    </span>
  </div>

  <span class="mini">{{ line.payDate | date : "dd/MM/yyyy" }}</span>
</div>
