<div class="titleDiv " *ngIf="!isEnd">
  <div id="screen" #screen >
    <div class="flex">
      <div *ngFor="let btn of signBody.buttons; let i = index">
        <my-button
          [config]="btn.config"
          [value]="btn.value"
          (buttonSelected)="submitInput($event, i)"
        ></my-button>
        <br />
      </div>
    </div>
    <div class="allsigns">
      <div *ngIf="signBody?.sign[0]" class="sign">
        <span>{{ signBody?.sign[0]?.name }}</span>
        <div class="flexs">
          <div class="signature">
            <canvas class="" padPreventDefult #canvas> </canvas>

            <div class="textDiv">
              <div class="clear" (click)="clear()">
                <img src="../../../../assets/svg/garbage.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="signBody?.sign[1]" class="sign">
        <span>{{ signBody?.sign[1]?.name }}</span>
        <div class="flexs">
          <div class="signature">
            <canvas class="" padPreventDefult #canvas2> </canvas>

            <div class="textDiv">
              <div class="clear" (click)="clear(true)">
                <img src="../../../../assets/svg/garbage.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="nextdiv">
    <button class="prev" mat-button matStepperPrevious>הקודם</button>
    <button [disabled]="disabled()" class="next" mat-button (click)="nextEmit()">
      {{ mobile ? "שליחה" : text.btnText }}
    </button>
    <!-- [disabled]="disable" -->
  </div>
</div>
<app-sucsses-send *ngIf="isEnd"></app-sucsses-send>