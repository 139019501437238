import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { inputConfig } from 'src/app/common/elements/my-big-input/my-big-input.model';
import { generateForm } from 'src/app/common/elements/my-input/my-input.functions';
import { LoginComponent } from 'src/app/login/login.component';
import { chackFormValid } from 'src/app/register/details-register/formValid';
import { RegisterComponent } from 'src/app/register/register.component';
import isIsraeliIdValid from 'israeli-id-validator';
import { paymentGuestDetails } from '../random.model';
import { MuniService } from 'src/app/services/muni.service';
import { RandomService } from '../random.service';
import { FormsDataService } from 'src/app/forms/forms-data.service';
import { Subject, takeUntil } from 'rxjs';
import { selectConfig } from 'src/app/common/elements/my-select/my-select.model';
import { LayoutService } from 'src/app/layout/layout.service';

@Component({
  selector: 'app-guest-details',
  templateUrl: './guest-details.component.html',
  styleUrls: ['./guest-details.component.scss'],
})
export class GuestDetailsComponent implements OnInit {
  @Output() goPrev = new EventEmitter<void>();
  destroy$ = new Subject();
  text = {
    title: 'רגע! לפני התשלום, חסרים לנו פרטים עליך',
    subTitle: 'רשומים למערכת? כדאי',
    click: 'ללחוץ כאן',
    text: 'על מנת להשלים את התהליך ולעבור לתשלום',
    text2: 'עליך למלא פרטים אישיים לצורך הזדהות או ליצור חשבון חדש',
    btn: 'המשך לתשלום',
  };
  controls: inputConfig[] = [
    {
      icon: '',
      lable: 'תעודת זהות',
      type: 'number',
      required: true,
      formControlName: 'id',
    },
    {
      icon: '',
      lable: 'שם משפחה',
      type: 'input',
      required: true,
      formControlName: 'lastName',
    },
    {
      icon: '',
      lable: 'שם פרטי',
      type: 'input',
      required: true,
      formControlName: 'firstName',
    },
    {
      icon: '',
      lable: 'מספר נייד',
      type: 'input',
      required: true,
      formControlName: 'phone',
    },
    {
      icon: '',
      lable: 'כתובת מייל',
      type: 'input',
      required: true,
      formControlName: 'email',
    },
    {
      icon: '',
      lable: 'יישוב',
      type: 'select',
      required: true,
      formControlName: 'city',
      selectConfig: {
        icon: '',
        lable: 'יישוב',
        list: [],
        bigSelect: false,
        copyList: [],
      },
    },
    {
      icon: '',
      lable: 'רחוב',
      type: 'select',
      required: true,
      formControlName: 'street',
      selectConfig: {
        icon: '',
        lable: 'רחוב',
        list: [],
        bigSelect: false,
        copyList: [],
      },
    },
    {
      icon: '',
      lable: 'מספר בית',
      type: 'number',
      required: true,
      formControlName: 'houseNumber',
    },
  ];

  guestForm = generateForm(this.controls);
  error = '';
  mobile = window.screen.width <= 850 ? true : false;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<GuestDetailsComponent>,
    private muni: MuniService,
    private randomService: RandomService,
    private formsData: FormsDataService,
    private layoutService: LayoutService
  ) {}
  ngOnInit(): void {
    this.formsData.citiesListSelect$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!res || res.length == 0) {
          this.formsData.getCities();
        } else {
          this.controls[5].selectConfig.list = res;
          this.controls[5].selectConfig.copyList = res;
        }
      });
    this.formsData.streetsListSelect$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && res.length > 0) {
          this.controls[6].selectConfig.list = res;
          this.controls[6].selectConfig.copyList = res;
        }
      });
  }
  register() {
    this.dialogRef.close();
    this.dialog.open(LoginComponent);
  }
  fillForm(event: { name: string; value: any }) {
    this.guestForm.controls[event.name]?.setValue(event.value);
  }
  async save() {
    let err = chackFormValid(this.guestForm.controls);
    if (err == '') {
      if (!isIsraeliIdValid(this.guestForm.controls.id.value)) {
        this.error = 'יש למלא מספר זהות תקין';
      } else {
        let city = this.controls[5].selectConfig.list.find(
          (c) => c.value == this.guestForm.value.city
        );
        let street = this.controls[6].selectConfig.list.find(
          (c) => c.value == this.guestForm.value.street
        );
        let paymentGuestDetails: paymentGuestDetails = {
          firstName: this.guestForm.value.firstName,
          lastName: this.guestForm.value.lastName,
          id: this.guestForm.value.id,
          email: this.guestForm.value.email,
          phone: this.guestForm.value.phone,
          address:
            city.label +
            ',' +
            street.label +
            ' ' +
            this.guestForm.value.hoseNumber,
          note: this.guestForm.value.note,
          muniCode: this.muni.getMuniCode,
          guestID: 0,
        };
        await this.randomService.GuestDetailsForPay(paymentGuestDetails);
        this.error = '';
        if (!this.mobile) {
          this.dialog.closeAll();
          this.dialogRef.close();
        } else {
          if (this.randomService.Guest?.value?.guestID) {
            this.randomService.setBasketItemsRandom();
            this.randomService.level$.next(2);
          }
        }

        // this.voucherService.mainIdPaymentOneVoucher = 0;
        // this.Router.navigate(['/payment']);
      }
    } else {
      this.error = err;
    }
  }
  prev() {
    this.goPrev.emit();
  }
}
