<div class="wrapper">
  <mat-expansion-panel
    #panel
    hideToggle
    class="row margin-b"
    disabled
    [expanded]="!panel.accordionOpened"
  >
    <!-- (click)="closePanel(panel)" -->
    <mat-expansion-panel-header *ngIf="headerVisible">
      <mat-panel-title *ngIf="!mobile">
        <div class="titleDiv">
          <span class="title">{{ service ? service.serviceName : null }}</span>
          <div
            *ngIf="
              service &&
              service.dynamicFields &&
              service.dynamicFields.length > 0
            "
          >
            <span class="mini" *ngFor="let field of service.dynamicFields">
              {{ field.placeholder }}: {{ field.answerValue }}</span
            >
          </div>
        </div>
        <div class="amount">
          <span>{{ text.amount }} {{ amount }} יחידות</span>
        </div>
        <div class="sum">
          <span
            >{{ text.sum }}
            {{
              service
                ? (service.price * amount | currency : "ILS")
                : (0 | currency : "ILS")
            }}</span
          >
        </div>
        <div class="editDiv">
          <img (click)="edit()" src="../../../assets/svg/pen (1).svg" />
          <!-- <img src="../../../assets/svg/deleteRed.svg" /> -->
        </div>
      </mat-panel-title>
      <div class="titleMobile" *ngIf="mobile == true">
        <div class="editDiv">
          <span class="title">{{ service ? service.serviceName : null }}</span>
          <div class="editDiv">
            <img (click)="edit()" src="../../../assets/svg/pen (1).svg" />
            <!-- <img src="../../../assets/svg/deleteRed.svg" /> -->
          </div>
        </div>
        <div
          *ngIf="
            service && service.dynamicFields && service.dynamicFields.length > 0
          "
        >
          <span class="miniText" *ngFor="let field of service.dynamicFields">
            {{ field.placeholder }}: {{ field.answerValue }}</span
          >
        </div>
        <div class="amount">
          <span>{{ text.amount }} {{ amount }} יחידות</span>
          <span
            >{{ text.sum }}
            {{
              service
                ? (service.price * amount | currency : "ILS")
                : (0 | currency : "ILS")
            }}</span
          >
        </div>
      </div>
    </mat-expansion-panel-header>

    <div *ngIf="isEdit == false">
      <div class="content">
        <my-select
          [config]="categorySelectConfig"
          [selected]="categorySelected"
          (selectionChange)="selectCategory($event)"
        ></my-select>
        <my-select
          [config]="serviceSelectConfig"
          [selected]="serviceSelected"
          (selectionChange)="selectService($event)"
        ></my-select>
        <div class="amountInput">
          <span class="minus" (click)="minus()">-</span>
          <input
            (keyup)="changeAmount($event.target.value)"
            type="number"
            value="{{ amount }}"
          />
          <span class="plus" (click)="plus()">+</span>
        </div>
        <span class="error">{{ errorQuantity }}</span>
      </div>
      <div
        *ngIf="
          service && service.dynamicFields && service.dynamicFields.length > 0
        "
        class="dynamicFields"
      >
        <mat-divider></mat-divider>
        <div class="dynamic" *ngFor="let field of service.dynamicFields">
          <div class="required">
            <span>*</span>
            <div class="inputDiv">
              <mat-label>{{ field.placeholder }}</mat-label>
              <input
                class="inputDynamic"
                type="{{ field.fieldSubType }}"
                value="{{ field.fieldValue }}"
                [(ngModel)]="field.answerValue"
              />
            </div>
          </div>
        </div>
        <span class="error">{{ errorDynamic }}</span>
      </div>
    </div>
    <div class="content contentEdit" *ngIf="isEdit == true">
      <div class="titleEdit">
        <span class="title">{{ service ? service.serviceName : null }}</span>
      </div>
      <div class="amountInput">
        <span class="minus" (click)="minus()">-</span>
        <input type="number" value="{{ amount }}" />
        <span class="plus" (click)="plus()">+</span>
      </div>
      <span class="error">{{ errorQuantity }}</span>
    </div>

    <div class="sum">
      <mat-divider></mat-divider>
      <div class="text">
        <span>{{ text.sumOne }}</span>
        <span>{{
          service ? (service.price | currency : "ILS") : (0 | currency : "ILS")
        }}</span>
      </div>
      <div class="text">
        <span class="bold">{{ text.sumToPay }}</span>
        <span class="bold">{{
          service
            ? (service.price * amount | currency : "ILS")
            : (0 | currency : "ILS")
        }}</span>
      </div>
      <div class="btns">
        <button *ngIf="!isEdit" class="cancel" (click)="cancel()">
          {{ text.cancel }}
        </button>
        <img
          *ngIf="isEdit"
          (click)="deleteOne()"
          src="../../../assets/svg/deleteRed.svg"
        />
        <button
          *ngIf="!isEdit"
          [disabled]="disabledAdd()"
          class="add"
          (click)="add()"
        >
          {{ text.add }}
        </button>
        <button *ngIf="isEdit" class="add" (click)="saveEditAmount()">
          {{ text.edit }}
        </button>
      </div>
    </div>
  </mat-expansion-panel>
</div>
