<div *ngIf="!mobile" class="wrapper">
  <div class="payment">
    <span class="title">{{ text.title }}</span>
    <div class="paymentDetails">
      <div class="line" *ngFor="let line of list">
        <span>{{ line.title }}:</span>
        <span class="value">{{ line.value }}</span>
      </div>
    </div>

    <div class="list2">
      <span class="title">{{ text.title2 }}</span>
      <div class="line" *ngFor="let line of list2">
        <span class="t">{{ line.title }}:</span>
        <span class="value">{{ line.value | noneValue }}</span>
      </div>
    </div>
  </div>
</div>
<div *ngIf="mobile" class="list">
  <span class="title">{{ text.title }}</span>
  <div class="itemMobile" *ngFor="let item of list">
    <span class="label">{{ item.title }}:</span>
    <span class="value"> {{ item.value }} </span>
  </div>
  <span class="title">{{ text.title2 }}</span>
  <div class="itemMobile" *ngFor="let line of list2">
    <span class="t">{{ line.title }}:</span>
    <span class="value" >{{
      line.value | noneValue
    }}</span>
    <!-- <span class="value" *ngIf="isDate(line.value)">{{
      line.value | date : "dd/MM/yyyy"
    }}</span> -->
  </div>
  <button class="btnborder" (click)="close()">פחות פרטים</button>
</div>
