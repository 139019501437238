<div class="wrapper">
  <div class="titleDiv">
    <span class="spantitle">{{ text.title1 }}</span>
    <span class="spantitle title">{{ text.title2 }}</span>
  </div>
  <form [formGroup]="registerForm">
    <div class="inputs">
      <div *ngFor="let config of controls">
        <my-input [config]="config" (blur)="fillForm($event)"></my-input>
      </div>
    </div>
  </form>
  <span>{{ error }}</span>
  <div class="btnLogin">
    <button (click)="regiter()">
      {{ text.btn }}
    </button>
    <span class="graytext"
      >{{ text.registerQ }}
      <span class="link" (click)="goLogin()">{{ text.registerLink }}</span>
    </span>
  </div>
</div>
