import { inputConfig } from 'src/app/common/elements/my-big-input/my-big-input.model';
import { IListItem } from '../../elementForm/form-input/form-input.model';
import { FileConfig } from '../../elementForm/my-file/file.model';
import { Person } from '../functions.model';

// export const existAllowance: IListItem[] = [
//   {
//     label: 'הבטחת הכנסה עם השלמת הכנסה',
//     value: 1,
//   },
//   {
//     label: 'הבטחת הכנסה בלי השלמת הכנסה',
//     value: 2,
//   },
//   {
//     label: 'קצבת זקנה עם השלמת הכנסה',
//     value: 3,
//   },
//   {
//     label: 'קצבת זקנה בלי השלמת הכנסה',
//     value: 4,
//   },
//   {
//     label: 'קצבת ניצולי שואה',
//     value: 5,
//   },
//   {
//     label: 'אבטלה',
//     value: 6,
//   },
//   {
//     label: 'חסידי אומות העולם',
//     value: 7,
//   },
//   {
//     label: 'ילד נכה',
//     value: 8,
//   },
//   {
//     label: 'מזונות עם השלמת הכנסה',
//     value: 9,
//   },
//   {
//     label: 'מזונות בלי השלמת הכנסה',
//     value: 10,
//   },
//   {
//     label: 'ניידות',
//     value: 11,
//   },
//   {
//     label: 'נכות כללית',
//     value: 12,
//   },
//   {
//     label: 'נפגעי פעולות איבה',
//     value: 13,
//   },
//   {
//     label: 'סיעוד',
//     value: 14,
//   },
//   {
//     label: 'פיצוי נפגעי גזזת',
//     value: 15,
//   },
//   {
//     label: 'פיצוי נפגעי פוליו',
//     value: 16,
//   },
//   {
//     label: 'שאירים',
//     value: 17,
//   },
//   {
//     label: 'שרותים מיוחדים לנכים',
//     value: 18,
//   },
//   {
//     label: 'תגמול לילד שהתייתם עקב אלימות במשפחה',
//     value: 19,
//   },
//   {
//     label: 'ללא קצבה',
//     value: 20,
//   },
// ];
export const familyStatusList: IListItem[] = [
  {
    label: 'נשוי/אה',
    value: 1,
  },
  {
    label: 'רווק/ה',
    value: 2,
  },
  {
    label: 'גרוש/ה',
    value: 3,
  },
  {
    label: 'אלמן/ה',
    value: 4,
  },
  {
    label: 'פרוד/ה',
    value: 5,
  },
  {
    label: 'ידוע/ה בציבור',
    value: 6,
  },
  {
    label: 'אחר',
    value: 7,
  },
];
// public enum FamilyStatus
//     {
//         married = 1, unmarried, divorced, widower, separated, commonLawSpouse, other
//     }
export const AbilityList: IListItem[] = [
  {
    label: 'גבוהה',
    value: 1,
  },
  {
    label: 'בינונית',
    value: 2,
  },
  {
    label: 'נמוכה',
    value: 3,
  },
  {
    label: 'לא רלוונטי',
    value: 4,
  },
];
// public enum Ability
//     {
//         low = 1, medium, high, notRelevant
//     }

export const WorkStatusList: IListItem[] = [
  {
    label: 'עצמאי',
    value: 1,
  },
  {
    label: 'שכיר',
    value: 2,
  },
  {
    label: 'אינו עובד',
    value: 3,
  },
  {
    label: 'אינו עובד באופן סדיר',
    value: 4,
  },
  {
    label: 'גמלאי',
    value: 5,
  },
  {
    label: 'תעסוקה מוגנת',
    value: 6,
  },
];

// public enum WorkStatus
//     {
//         selfEmployed = 1, salaried, unImployed, notRegularyImployed, pensioner, ShelteredWorkshop
//     }

export interface docOpenBug {
  description: string;
  fileType: number;
  config?: inputConfig;
  filesList?: FileConfig[];
}

export interface RequestCauseDetails {
  muniCode: number;
  mainRequestId: number;
  treatmentArea: number;
  requestCause: string;
}

export interface getExistFileOpeningRes {
  existAllowance: number;
  familyMembers: Person[];
  files: FileConfig[];
  lineId: number;
  mainRequestId: number;
  profession: any;
  requestCause: string;
  treatmentArea: number;
  workStatus: number;
}

export interface SaveFilesDetailsBody {
  muniCode: number;
  mainRequestId: number;
  files: number[];
}

export interface saveFamilyDetailsBody {
  familyMembers: familyMember[];
  mainRequestId: number;
  muniCode: number;
}

export interface familyMember {
  panelOpen?: boolean;
  muniCode: number;
  pasport: string;
  firstName: string;
  lastName: string;
  phone: string;
  landlinePhone: string;
  email: string;
  pasportFileID: number;
  formType: number;
  address: {
    lineID: number;
    cityID: number;
    streetID: number;
    houseNum: number;
    apartment: number;
    entrance: string;
    postalCode: number;
    floor: number;
  };
  familyStatus: number;
  gender: number;
  dateOfBirth: Date;
  relationship: number;
  birthCountry: number;
  aliaCountry: number;
  aliaDate: Date;
  fatherName: string;
  populationGroup: number;
  workStatus: number;
  existAllowance: number;
  education: number;
  colloquialLanguage: number;
  additionalLanguage: number;
  readAbility: number;
  speakAbility: number;
  isEmergency: boolean;
}
export interface deleteMemberBody {
  familyMemberID : string;
  mainRequestId : number;
  muniCode: number;
}