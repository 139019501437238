<div class="flex">
  <div class="required box">
    <span *ngIf="config?.required && !withoutLine">*</span>
  </div>
  <div class="main">
    <div class="fleHeader">
      <div class="titlediv">
        <span>{{ config?.label }}</span>
      </div>
      <div *ngIf="config?.tooltip" class="tooltip">
        <div class="tooltipIcon">?</div>
        <span class="tooltiptext">{{ config?.tooltip }}</span>
      </div>
    </div>
    <div
      class="input"
      [ngClass]="{
        withoutBorder: withoutLine,
      }"
    >
      <!-- azureBack: withoutLine && file && file.fileID !== 0 -->

      <div class="rowInput" *ngFor="let file of filesList; let iFile = index">
        <div
          class="mini"
          [ngClass]="{
            azureBack: withoutLine && file && file.fileID !== 0
          }"
        >
          <span
            #btnFile
            id="btnFile"
            [ngClass]="{
              addPadding: withoutLine
            }"
            *ngIf="!file || file.fileID == 0"
            (click)="fileUploadToPerson.click()"
          >
            {{ text.file }}
          </span>
          <span
            *ngIf="file && file.fileID !== 0"
            [ngClass]="{
              withoutBack: withoutLine
            }"
            class="underLine"
          >
            <!-- (click)="inputfile.click()" -->
            {{ file.fileName | slice : 0 : 15 }}
            {{ file.fileName.length > 15 ? "..." : "" }}
          </span>

          <input
            multiple
            [formControl]="control"
            type="file"
            class="file-input"
            accept="application/pdf, image/png, image/jpeg"
            [disabled]="disabledFiles(iFile)"
            (change)="onFileSelected($event, iFile)"
            #fileUploadToPerson
            id="fileUploadToPerson"
          />
          <div class="deleteDiv" *ngIf="file && file.fileID != 0">
            <img
              class="down"
              [ngClass]="{
                white: withoutLine
              }"
              (click)="downloadFile(iFile)"
              src="../../../../assets/svg/eye.svg"
            />
            <img
              [ngClass]="{
                white: withoutLine
              }"
              (click)="deleteFile(iFile)"
              class="delete"
              src="../../../../assets/svg/garbage.svg"
            />
          </div>
        </div>

        <span
          (click)="addLine()"
          class="addInput"
          *ngIf="file && file.fileID != 0 && iFile == filesList.length - 1"
          >+</span
        >
      </div>
    </div>

    <div class="errorInput">
      <span *ngIf="control.hasError('required') || errorRes == 'required'">
        {{ config.label }} {{ error.is }} <strong>{{ error.must }}</strong>
      </span>
      <span *ngIf="errorRes == 'another'"> {{ errorAnother }} </span>
    </div>
  </div>
</div>
