import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-discount-welcome',
  templateUrl: './discount-welcome.component.html',
  styleUrls: ['./discount-welcome.component.scss'],
})
export class DiscountWelcomeComponent implements OnInit {

  text = {
    title: 'ברוכים הבאים להגשת בקשה מקוונת להנחה בארנונה',
  };
  newReq = {
    text: 'לפתיחת בקשה חדשה',
    url : '/newDiscount'
  };
  oldReq = {
    text: 'לחזור לבקשה קיימת',
    url : '/existDiscount'
  };
  calc = {
    text : 'כמה הנחה מגיע לי?',
    url:'/claculatorDiscount'
  }
  constructor(private router: Router){}
  ngOnInit(): void {
  }
  nav(url: string){
    this.router.navigate([url]);
  }
}
