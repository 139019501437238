import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  IMunicipality,
  MuniCodeReq,
  MuniServiceCard,
  MuniServiceCommonAction,
  MuniTitle,
} from '../common/models/muni.model';
import { MatDialog } from '@angular/material/dialog';
import { PopupGetMuniComponent } from '../common/popup-get-muni/popup-get-muni.component';
import { muniParams } from '../forms/elementForm/payment-page/paymentPage.model';

@Injectable({
  providedIn: 'root',
})
export class MuniService {
  muniServices$ = new BehaviorSubject<MuniServiceCard[]>([]);
  muniTitles$ = new BehaviorSubject<MuniTitle[]>([]);
  muniLogo$ = new BehaviorSubject<string>('');
  commonActions$ = new BehaviorSubject<MuniServiceCommonAction[]>([]);
  changeToggleFromOut$ = new BehaviorSubject<1 | 2 | -1>(-1);
  public MuniParams$ = new BehaviorSubject<Array<muniParams> | null>(null);
  updateMuniCode$ = new Subject<void>();
  constructor(private app: AppService, private dialog: MatDialog) {}
  public getMunicipalities(): Observable<IMunicipality[]> {
    return this.app.get('Muni/GetMunicipalities');
  }
  public getMuniLogo(muniCodeReq: MuniCodeReq): Observable<string> {
    return this.app.post('Muni/GetMuniLogo', muniCodeReq);
  }
  public GetMuniServices(muniCodeReq: MuniCodeReq): Observable<any> {
    return this.app.post('Muni/GetMuniServices', muniCodeReq);
  }
  public GetTitles(): Observable<any> {
    return this.app.get('Muni/GetTitles');
  }
  public GetCommonActions(muniCodeReq: MuniCodeReq): Observable<any> {
    return this.app.post('Muni/GetCommonActions', muniCodeReq);
  }
  public GetWords(word: string): Observable<any> {
    return this.app.get('Muni/GetWords', { word: word });
  }
  get getLogo(): string {
    let logo = localStorage.getItem('muniLogo');
    if (logo && logo != '') {
      this.muniLogo$.next(logo);
    }
    return logo;
  }
  getMuniData(muniCode: number, muniName: string) {
    let muniCodeReq: MuniCodeReq = {
      muniCode: muniCode,
    };
    localStorage.setItem('muniCode', muniCode.toString());
    if (muniName != '') {
      localStorage.setItem('muniName', muniName);
    }
    this.updateMuniCode$.next();
    this.getMuniLogo(muniCodeReq).subscribe(
      (res) => {
        this.muniLogo$.next(res);
        localStorage.setItem('muniLogo', res);
      },
      (error) => {
        if (error.status == 200) {
          this.muniLogo$.next(error.error.text);
          localStorage.setItem('muniLogo', error.error.text);
        }
      }
    );
    this.GetMuniServices(muniCodeReq).subscribe(
      (res: any) => {
        if (res && res.body) this.muniServices$.next(res.body);
      },
      (error) => {}
    );
    this.GetTitles().subscribe(
      (res) => {
        if (res) this.muniTitles$.next(res);
      },
      (error) => {}
    );
    this.GetCommonActions(muniCodeReq).subscribe((res) => {
      if (res && res.body) {
        this.commonActions$.next(res.body);
      }
    });
  }
  public GetMuniParams() {
    // const MuniParams = JSON.parse(localStorage.getItem('MuniParams'));
    // if (MuniParams && MuniParams.length > 0) this.MuniParams$.next(MuniParams);
    // else {
    let muniCode = this.getMuniCode;
    let url = 'App/MuniParams/';
    this.app.post(url, { muniCode: muniCode }).subscribe(
      (res) => {
        if (res) {
          this.MuniParams$.next(res.body.muniParamsList);
          localStorage.setItem(
            'MuniParams',
            JSON.stringify(res.body.muniParamsList.valueOf())
          );
        }
      },
      (error) => {}
    );
    // }
  }
  get getMuniCode(): number {
    let muni = localStorage.getItem('muniCode');
    return muni ? +muni : 0;
  }
}
