import { Injectable } from '@angular/core';
import { AppService } from '../services/app.service';
import { MuniService } from '../services/muni.service';
import {
  CasualService,
  CasualServicesCategories,
  GuestResponse,
  paymentGuestDetails,
  selectedService,
} from './random.model';
import { BehaviorSubject } from 'rxjs';
import {
  basket,
  casualPaymentAnswer,
} from '../forms/elementForm/payment-page/paymentPage.model';
import { PaymentService } from '../forms/elementForm/payment-page/payment.service';
const CONTROLLER = 'FinancialData';

@Injectable({
  providedIn: 'root',
})
export class RandomService {
  Guest = new BehaviorSubject<GuestResponse>(null);
  public services$ = new BehaviorSubject<CasualService[]>([]);
  public categories$ = new BehaviorSubject<CasualServicesCategories[]>([]);
  public selectedServices$ = new BehaviorSubject<selectedService[]>([]);
  public level$ = new BehaviorSubject<number>(1);
  constructor(
    private appService: AppService,
    private muni: MuniService,
    private paymentService: PaymentService
  ) {}
  public async GuestDetailsForPay(paymentGuestDetails: paymentGuestDetails) {
    let url = '/GuestDetails/';
    let body = {
      guestID: 0,
      lastName: paymentGuestDetails.lastName,
      firstName: paymentGuestDetails.firstName,
      ID: paymentGuestDetails.id,
      email: paymentGuestDetails.email,
      phone: paymentGuestDetails.phone.toString(),
      address: paymentGuestDetails.address,
      note: paymentGuestDetails.note,
      muniCode: paymentGuestDetails.muniCode,
    };
    let data = await this.appService.post(CONTROLLER + url, body).toPromise();
    if (data) {
      if (data.body.guestID != 0) {
        this.Guest.next(data.body);
        // this.voucherService.mainIdPaymentOneVoucher = 0;
      } else {
        console.log('Error in log the guest details');
        // const dialogRef = this.dialog.open(PopupComponent, {
        //   data: this.dataForPopup,
        //   disableClose: true,
        // });
        // dialogRef.afterClosed().subscribe((result) => {
        //   this.route.navigate(['/main']);
        // });
      }
    }
    return data;
  }
  public GetCasualServices() {
    let url = '/GetCasualServices/';
    let body = {
      group: 0,
      specificService: 0,
      muniCode: this.muni.getMuniCode,
    };
    this.appService.post('FinancialData' + url, body).subscribe((res) => {
      if (res.body.casualServicesCategories.length != 0) {
        let arr = res.body.casualServicesCategories.sort((a, b) => {
          if (a.categoryName < b.categoryName) return -1;
          else return 1;
        });
        this.categories$.next(arr);
      } else {
        this.categories$.next([])
      }
      if (res.body.casualServices.length != 0) {
        let arr = res.body.casualServices.sort((a, b) => {
          if (a.serviceName < b.serviceName) return -1;
          else return 1;
        });
        this.services$.next(arr);
      }
      else {
        this.services$.next([])
      }
    });
  }
  public setBasketItemsRandom() {
    let basket: basket[] = [];
    let sum = 0;
    this.selectedServices$.value.map((serviceDetails) => {
      let dynamic: casualPaymentAnswer[] = [];
      if (serviceDetails?.service?.dynamicFields?.length > 0) {
        serviceDetails.service.dynamicFields.map((field) => {
          const el = {
            answerValue: field.answerValue,
            questionID: field.questionID,
          };
          dynamic.push(el);
        });
      }
      basket.push({
        title: serviceDetails.service.serviceName,
        NumOfOrderedItems: serviceDetails.amount,
        id: serviceDetails.service.serviceCode.toString(),
        price: serviceDetails.service.price,
        DynamicField: dynamic.length > 0 ? dynamic : [],
        numOfPayments: serviceDetails.service.numberOfPayments? serviceDetails.service.numberOfPayments: null,
      });
      sum = serviceDetails.service.price * serviceDetails.amount;
      this.paymentService.basketItems$.next(basket);
      this.paymentService.sum$.next(sum);
    });
  }
}
