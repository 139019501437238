<div class="wrapper">
  <span class="title">{{ text.title }}</span>
  <my-big-input
    class="inputWidth"
    [config]="searchConfig"
    [menuList]="searchMenuList"
    (submitInput)="search($event)"
    (chooseOneMenu)="chooseSearchOne($event)"
  ></my-big-input>

  <div id="toggle" class="toggle">
    <div class="wrapToggle" (click)="toggleChange(1)">
      <div
        id="right"
        class="oneToggle"
        [ngClass]="{
          oneChoose: choose == 1,
        }"
      ></div>
      <span
        [ngClass]="{
          one: choose == 1
        }"
        class="ectionText"
        >{{ text.actions }}
      </span>
    </div>
    <div class="wrapToggle" (click)="toggleChange(2)">
      <div
        id="left"
        class="oneToggle"
        id="twoChoose"
        [ngClass]="{
          twoChoose: choose == 2,
        }"
      ></div>

      <span
        [ngClass]="{
          one: choose == 2
        }"
        class="ectionText"
      >
        {{ text.forms }}
      </span>
    </div>
  </div> 
  <app-cards-data
    class="w90"
    [cards]="services"
    [type]="choose"
  ></app-cards-data>
</div>
