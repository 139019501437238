import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IPerson } from '../../personal.model';
import { IListItem } from 'src/app/forms/elementForm/form-input/form-input.model';

@Component({
  selector: 'app-family-members',
  templateUrl: './family-members.component.html',
  styleUrls: ['./family-members.component.scss'],
})
export class FamilyMembersComponent implements OnInit, OnDestroy {
  @Input() members: IPerson[];
  text = {
    title: 'פרטי בני המשפחה',
  };
  displayedColumns = [
    '',
    'שם',
    'ת.ז',
    'תאריך לידה',
    'מין',
    'טלפון',
    'מייל',
    '',
  ];
  mobile = window.screen.width <= 850 ? true : false;
  listMobile: IListItem[] = [];
  constructor() {}
  ngOnInit(): void {
    if (this.members && this.members.length > 0) {
      this.members.map(m=>{
        m.openedPanel = false;
      })
    }
  }
  ngOnDestroy(): void {}
  addLine() {}
  open(i: number) {
    this.members[i].openedPanel = true;
  }
  close(i: number) {
    this.members[i].openedPanel = false;
  }
}
