<div>
  <my-select
    [config]="assetSelectConfig"
    [selected]="assetSelected"
    (selectionChange)="select($event)"
  ></my-select>
  <div class="details">
    <div>
      <span class="bold"> {{ text.asset }}: </span> 12312
    </div>
    <div>
      <span class="bold">{{ text.address }}:</span> הלילך 32 ירושלים
    </div>
    <div>
      <span class="bold">{{ text.type }}:</span> מגורים
    </div>
    <div>
      <span class="bold">{{ text.exist }}: </span> 50 חוז על בסיס מבחן הכנסה
    </div>
  </div>
  <div class="btnChoose" *ngFor="let btn of btnControls; let i = index">
    <div class="row">
      <my-button
        [config]="btn?.config"
        [toggleConfig]="btn?.toggleConfig"
        [value]="btn?.value"
        (buttonSelected)="chooseBtn($event, i)"
      >
      </my-button>
      <div
        *ngIf="
          (i == 0 && hisApartment == false) ||
          (i == 1 && haveAnother == true) ||
          (i == 2 && haveCar == true)
        "
      >
        <div *ngFor="let input of btn.childControls">
          <form-input
            [fullControl]="input.inputControl"
            (submitFunction)="submitInput($event)"
          ></form-input>
          <my-file
            *ngIf="input.inputControl.config.type == 'file'"
            [config]="input.inputControl.config"
            [file]="input.inputControl.config.file"
            [errorRes]="input.inputControl.config.errors"
            [errorAnother]="input.inputControl.config.anotherError"
            (submitFunction)="submitInput($event)"
          ></my-file>
        </div>
      </div>
    </div>
  </div>
</div>
