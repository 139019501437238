import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { docOpenBug } from '../openBug.model';
import { FileOpeningService } from '../file-opening.service';
import { FormsFunctionsService } from '../../forms-functions.service';
import { MuniService } from 'src/app/services/muni.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-open-bug-documents',
  templateUrl: './open-bug-documents.component.html',
  styleUrls: ['./open-bug-documents.component.scss'],
})
export class OpenBugDocumentsComponent implements OnInit {
  @Output() next = new EventEmitter();
  destroy$ = new Subject();
  displayedColumns = ['#', 'המסמך הנדרש', 'צירוף קובץ'];
  data: docOpenBug[] = [
    {
      description: 'צילום תז ראש המשפחה',
      fileType: 10,
      filesList: [],
      config: {
        formControlName: 'fileId',
        icon: null,
        lable: 'בחר קובץ',
        required: false,
        type: 'input',
        value: [],
      },
    },
    {
      description: 'צילום תז בן הזוג ',
      fileType: 10,
      filesList: [],

      config: {
        formControlName: 'fileId',
        icon: null,
        lable: 'בחר קובץ',
        required: false,
        type: 'input',
        value: [],
      },
    },
    {
      description: '	אישורי הכנסה',
      fileType: 60,
      filesList: [],

      config: {
        formControlName: 'fileId',
        icon: null,
        lable: 'בחר קובץ',
        required: false,
        type: 'input',
        value: [],
      },
    },
    {
      description: '	אישורי קצבה',
      fileType: 80,
      filesList: [],

      config: {
        formControlName: 'fileId',
        icon: null,
        lable: 'בחר קובץ',
        required: false,
        type: 'input',
        value: [],
      },
    },
    {
      description: '	תדפיס עוש ל3 חודשים אחרונים',
      fileType: 50,
      filesList: [],
      config: {
        formControlName: 'fileId',
        icon: null,
        lable: 'בחר קובץ',
        required: false,
        type: 'input',
        value: [],
      },
    },
  ];
  files: number[] = [];
  mobile = window.screen.width <= 850 ? true : false;
  constructor(
    private fileopening: FileOpeningService,
    private formsFunctions: FormsFunctionsService,
    private muni: MuniService
  ) {}
  ngOnInit(): void {
    this.fileopening.fileOpeningFiles$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && res.length > 0) {
          res.map((file) => {
            let i = this.data.findIndex((f) => f.fileType == file.fileType);
            if (i != -1) {
              this.data[i].filesList.push({
                fileID: file.fileID,
                fileName: file.fileName,
              });
            }
          });
        }
      });
  }
  submitInput(fileEvent, i: number) {
    this.files.push(fileEvent.value);
  }
  deleteChild(event, i) {}
  nextEmit() {
    this.fileopening
      .SaveFilesDetails({
        files: this.files,
        mainRequestId:
          this.formsFunctions.existRequestData$.value.requestData.lineID,
        muniCode: this.muni.getMuniCode,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.next.emit();
        },
        (error) => {}
      );
  }
}
