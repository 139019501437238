export function chackFormValid(controls: any) {
    const validator = [];
    let note = '';
    for (const name in controls) {
      if (controls[name].invalid) {
        validator.push(name);
      }
    }
    if (validator.length > 0) {
      if (validator.length > 1) {
        note = 'יש למלא ערכים תקינים';
      } else if (validator[0] == 'email') {
        note = 'יש למלא כתובת אימייל תקינה';
      } else {
        note = 'יש למלא ערכים תקינים בכל השדות';
      }
    }
    return note;
  }