<div id="bussines">
  <mat-expansion-panel
  *ngIf="!mobile"
    [expanded]="panelOpen"
    #panel
    hideToggle
    class="row margin-b"
    disabled
  >
    <mat-expansion-panel-header>
      <div class="wrapper">
        <div class="muniLineText">
          <span class="title">{{ text.title }}</span>
          <div *ngIf="panelOpen" class="muniLine"></div>
        </div>
        <div class="details">
          <span
            >אין לנו פה עדיין נתונים על העסק שלך.. אבל אפשר להזדהות בפורטל
            הספקים שלנו</span
          >
          <button class="btn" (click)="nav()">להתחברות לפורטל הספקים</button>
        </div>
      </div>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
  <div *ngIf="mobile">
    <div class="details">
      <span
        >אין לנו פה עדיין נתונים על העסק שלך.. אבל אפשר להזדהות בפורטל
        הספקים שלנו</span
      >
      <button class="btn" (click)="nav()">להתחברות לפורטל הספקים</button>
  </div>
</div>
