<div class="main">
  <div class="wrapper">
    <div class="title">
      <span>{{ text.title }}</span>
      <span>{{ text.num }} {{ numberReq }}</span>
    </div>

    <img src="../../../../assets/gif/send.gif" />
    <button (click)="go()">{{ text.btn }}</button>
  </div>
</div>
