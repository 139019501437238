import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'proximity',
})
export class ProximityPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      // {lineID: 1, desc: 'ראש המשפחה'}
      // {lineID: 2, desc: 'בן/בת זוג'}
      // {lineID: 3, desc: 'בן/בת'}
      // {lineID: 0, desc: 'נלווה-לא חלק ממשק הבית'}
      // {lineID: 6, desc: 'בן/בת חורגים לראש משפחה'}
      // {lineID: 7, desc: 'אח/אחות של ראש המשפחה'}
      case 1:
        return 'ראש המשפחה';
      case 2:
        return 'בן/בת זוג';
      case 3:
        return 'בן/בת ';
      case 0:
        return 'נלווה-לא חלק ממשק הבית';
      case 6:
        return 'בן/בת חורגים לראש משפחה';
      case 7:
        return 'אח/אחות של ראש המשפחה';
      default:
        return '';
    }
  }
}
