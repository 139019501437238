import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageDialogComponent } from 'src/app/forms/popups/message-dialog/message-dialog.component';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-add-new-user',
  templateUrl: './add-new-user.component.html',
  styleUrls: ['./add-new-user.component.scss'],
})
export class AddNewUserComponent {
  destroy$ = new Subject();
  text = {
    sisma: 'יצירת סיסמא לאתר',
    place1: 'סיסמא חדשה',
    place2: 'אימות סיסמא',
    pass: 'סיסמא',
    clalim1: 'על הסיסמא להיות באורך 8 תווים לפחות.',
    clalim2: 'על הסיסמא להכיל אותיות קטנות וגדולות.',
    after: 'לאחר האישור תועברו לאתר בית לביצוע כניסה מחדש למערכת',
    btn: 'שמירת סיסמא',
  };
  hide: boolean = true;
  hideNew: boolean = true;
  hideConfirm: boolean = true;
  form: FormGroup;
  errorPass = '';
  errprNew = '';
  errorSecond = '';
  note = '';
  isCorrectCurrent = false;
  isCorrectNew = false;
  isCorrectSecond = false;
  constructor(
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private dialog: MatDialog,
    private router: Router
  ) {}
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  ngOnInit(): void {
    this.form = this.fb.group({
      newPass: ['', Validators.required],
      confirmPass: ['', Validators.required],
    });
  }
  checkNewPass(newPassword: string) {
    if (newPassword != '') {
      const regex =
        /^(?=[^A-Z\n]*[A-Z])(?=[^a-z\n]*[a-z])(?=[^0-9\n]*[0-9])\S{7,}[A-Za-z0-9-]$/;
      const hebrew = /[\u0590-\u05FF]/;
      if (!regex.test(newPassword) || hebrew.test(newPassword)) {
        this.errprNew = 'סיסמא לא תקינה';
        this.isCorrectNew = false;
      } else {
        this.errprNew = 'הסיסמא תקינה';
        this.isCorrectNew = true;
      }
    }
  }
  checkSecondPass(SecondPass: string, firstPassword: any) {
    if (SecondPass != '' && firstPassword != '') {
      if (SecondPass != firstPassword) {
        this.errorSecond = 'הסיסמאות אינן תואמות';
        this.isCorrectSecond = false;
      } else {
        this.errorSecond = '';
        this.isCorrectSecond = true;
      }
    }
  }
  disabled(): boolean {
    if (
      this.form.valid &&
      this.isCorrectSecond &&
      this.isCorrectNew
    )
      return false;
    else return true;
  }
  approval() {
    if (this.form.valid) {
      const newPass = this.form.get('newPass').value;
      const currentPassword = this.form.get('pass').value;
      this.accountService
        .newPassword(currentPassword, newPass)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.body.rc == 0) {
            // !
            // מעכשיו תוכל להתחבר לאתר עם הסיסמא שבחרת
            let ref = this.dialog.open(MessageDialogComponent, {
              data: {
                title: 'הסיסמא התקבלה בהצלחה!',
                body: ['מעכשיו תוכל להתחבר לאתר עם הסיסמא שבחרת'],
                btn: 'תודה!',
              },
            });
            ref
              .afterClosed()
              .pipe(takeUntil(this.destroy$))
              .subscribe((res) => {
                this.router.navigate(['']);
              });
          } else {
            //באג
            this.note = 'אירעה שגיאה. נסה שוב';
          }
        });
    } else {
      this.note = 'יש למלא ערכים בכל השדות';
    }
  }
}
