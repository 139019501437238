import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { FileConfig, UpdateFile, UploadFile, fileDownload } from './file.model';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  constructor(private app: AppService, private loading: LoadingService) {}
  maxSize = 2097152;
  fileError: string;

  validFileSize(event: any): boolean {
    if (event.target.files[0].size > this.maxSize) {
      this.fileError = 'גודל הקובץ חייב להיות עד 2 MB';
      return false;
    }
    this.fileError = '';
    return true;
  }
  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) =>
      result.next(btoa(event.target.result.toString()));
    return result;
  }
  public UploadSimpleFile(fileData: UploadFile): Observable<any> {
    return this.app.postF('Forms/Files/UploadFile', fileData);
  }
  public DownloadFile(fileData: fileDownload): Observable<any> {
    return this.app.postF('Forms/Files/DownloadFile', fileData);
  }
  public UpdateFile(fileData: UpdateFile): Observable<any> {
    return this.app.postF('Forms/Files/UpdateFile', fileData);
  }
  public openFile(fileContent, fileName: string) {
    if (fileName.includes('.pdf')) {
      const byteArray = new Uint8Array(
        atob(fileContent)
          .split('')
          .map((char) => char.charCodeAt(0))
      );
      let fileURL = document.createElement('a');
      const filename =
        new Date()
          .toLocaleString()
          .substr(0, 11)
          .replace(',', '')
          .replace(' ', '') + '.pdf';
      const file = new Blob([byteArray], { type: 'application/pdf' });
      fileURL.href = URL.createObjectURL(file);
      if (filename) {
        fileURL.setAttribute('download', filename);
      }
      document.body.appendChild(fileURL);
      window.open(fileURL.href);
    } else if (
      fileName.includes('.png') ||
      fileName.includes('.jpg') ||
      fileName.includes('.jpeg')
    ) {
      const base64ImageData = 'data:image/png;base64,' + fileContent;
      const contentType = 'image/png';
      const byteCharacters = atob(
        base64ImageData.substr(`data:${contentType};base64,`.length)
      );
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }
    this.loading.hide();
  }
}
