import { Component, Input, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { PaymentService } from 'src/app/forms/elementForm/payment-page/payment.service';
import { myPayment } from '../paymentSite.model';

@Component({
  selector: 'app-payment-line-mobile',
  templateUrl: './payment-line-mobile.component.html',
  styleUrls: ['./payment-line-mobile.component.scss'],
})
export class PaymentLineMobileComponent implements OnInit {
 @Input() line: myPayment;
  constructor(
    
  ) {}
  ngOnInit(): void {
   
  }
}
