import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChangePaymentsService } from './change-payments.service';
import {
  SendWaterInfo,
  WaterDataForms,
  changeData,
  exsistRequestChange,
  saveDetailsData,
} from './changePayments.model';
import { formDeme } from '../../elementForm/controlsDeme';
import { saveDataLevel } from '../../elementForm/control.model';
import { AuthService } from 'src/app/services/auth.service';
import { FormsFunctionsService } from '../forms-functions.service';
import {
  CollectionAsset,
  FormPersonReq,
  FormPropertyReq,
  FormsAddress,
  FormsExistData,
  GetAssetsByFamilyMainIDReq,
  Person,
} from '../functions.model';
import { MuniService } from 'src/app/services/muni.service';
import { MatStepper } from '@angular/material/stepper';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { FormsDataService } from '../../forms-data.service';
import { MyDateService } from '../../elementForm/my-date/my-date.service';
import { Subject, takeUntil } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-change-payments',
  templateUrl: './change-payments.component.html',
  styleUrls: ['./change-payments.component.scss'],
})
export class ChangePaymentsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  level = 1;
  text = {
    title: 'פתיחת בקשה להחלפת משלמים',
    choose: 'בחר את',
    siba: 'סיבת ההחלפה',
    btn: 'בואו נמשיך',
    rent: 'התחלה / סיום חוזה שכירות',
    home: 'קניה / מכירה של הנכס',
    cavod: 'עם מי יש לנו הכבוד?',
    moher: 'אני המוכר',
    buy: 'אני הקונה',
    soher: 'אני השוכר (נכנס)',
    soher2: 'אני השוכר (יוצא) ',
    soher3: 'או בעל הנכס',
    prev : 'חזור לבחירת סיבת ההחלפה'
  };
  iLevel1 = -1;
  iLevel2 = -1;
  iLevel3 = -1;
  section = 1;
  changeData: changeData = {
    typeForm: null,
    buyOrSellPerson: null,
    rentPerson: null,
  };
  changePaymentsForm: formDeme;
  haveDataFromMaintz = false;
  constructor(
    private changeService: ChangePaymentsService,
    private formsFunctions: FormsFunctionsService,
    private muni: MuniService,
    private authService: AuthService,
    private loading: LoadingService,
    private formsData: FormsDataService,
    private dateService: MyDateService
  ) {}
  ngOnInit(): void {
    this.formsFunctions.existRequestData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: FormsExistData) => {
        if (res && res?.requestData?.id != '') {
          this.setLevel1Data(res);
        }
      });
    this.formsFunctions.collectionAsset$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: CollectionAsset[]) => {
        if (res && res.length > 0) {
          this.setLevel2Data(res);
        }
      });
    this.changeService.exsistRequestChangeData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.setLevel3Data(res);
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  getAddressObjects() {
    this.formsData.citiesListSelect$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!res || res.length == 0) {
          this.formsData.getCities();
        } else {
          this.changePaymentsForm.levels[2].controls[6].inputControl.selectconfig.list =
            res;
          this.changePaymentsForm.levels[2].controls[6].inputControl.selectconfig.copyList =
            res;
        }
      });
    this.formsData.streetsListSelect$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && res.length > 0) {
          this.changePaymentsForm.levels[2].controls[7].inputControl.selectconfig.list =
            res;
          this.changePaymentsForm.levels[2].controls[7].inputControl.selectconfig.copyList =
            res;
        }
      });
  }
  setLevel1Data(res: FormsExistData) {
    if (res.requestData && res.requestData.applicantDetails) {
      this.changePaymentsForm.levels[0].controls[1].inputControl.config.value =
        res.requestData.applicantDetails.lastName;
      this.changePaymentsForm.levels[0].controls[2].inputControl.config.value =
        res.requestData.applicantDetails.firstName;
      this.changePaymentsForm.levels[0].controls[3].inputControl.config.value =
        res.requestData.applicantDetails.email;
      this.changePaymentsForm.levels[0].controls[4].inputControl.config.value =
        res.requestData.applicantDetails.phone;
      this.changePaymentsForm.levels[0].controls[5].inputControl.config.file = {
        fileID: res.requestData.applicantDetails.pasportFileID,
        fileName: res.requestData.applicantDetails.pasportFile.fileName,
      };
    }
    if (res.requestData.familyMainID && res.requestData.familyMainID != '') {
      this.getDataForLavel2WithMaintz(res.requestData.familyMainID);
      this.haveDataFromMaintz = true;
    }
  }
  setLevel2Data(res: CollectionAsset[]) {
    if (
      this.formsFunctions.existRequestData$.value.requestData.familyMainID != ''
    ) {
      this.changePaymentsForm.levels[1].controls[0].inputControl.config.value =
        this.formsFunctions.existRequestData$.value.requestData.familyMainID;
    }
    this.changePaymentsForm.levels[1].controls[1].inputControl.config.value =
      res[0].assetNum;
    this.changePaymentsForm.levels[1].controls[1].inputControl.selectconfig.list =
      this.formsFunctions.assetNumberList$.value;
    this.changePaymentsForm.levels[1].controls[1].inputControl.selectconfig.copyList =
      this.formsFunctions.assetNumberList$.value;
    this.changePaymentsForm.levels[1].controls[1].inputControl.selectconfig.selected =
      this.formsFunctions.assetNumberList$.value[0].value;
    this.changePaymentsForm.levels[1].controls[3].inputControl.config.value =
      res[0].address;
    this.changePaymentsForm.levels[1].controls[2].inputControl.config.value =
      res[0].assetType;
  }
  setLevel3Data(res: exsistRequestChange) {
    this.changePaymentsForm.levels[2].controls[0].inputControl.config.value =
      res.tenantPerson.pasport;
    this.changePaymentsForm.levels[2].controls[1].inputControl.config.value =
      res.tenantPerson.lastName;
    this.changePaymentsForm.levels[2].controls[2].inputControl.config.value =
      res.tenantPerson.firstName;
    this.changePaymentsForm.levels[2].controls[4].inputControl.config.value =
      res.tenantPerson.email;
    this.changePaymentsForm.levels[2].controls[3].inputControl.config.value =
      res.tenantPerson.phone;
    if (res.tenantPerson.address) this.setAddress(res.tenantPerson.address);
    if (res?.proofFileID) {
      this.changePaymentsForm.levels[2].controls[9].inputControl.config.file = {
        fileID: res?.proofFileID,
        fileName: res?.proofFile?.fileName,
      };
    }
    //in level 2
    if (res.exchangeDate) {
      let stringDate = this.dateService.getStrinsOfDate(
        res.exchangeDate.toString()
      );
      this.changePaymentsForm.levels[1].controls[4].inputControl.config.day =
        stringDate.day;
      this.changePaymentsForm.levels[1].controls[4].inputControl.config.month =
        stringDate.month;
      this.changePaymentsForm.levels[1].controls[4].inputControl.config.year =
        stringDate.year;
    }
  }
  setLevel4Data() {
    let water: WaterDataForms =
      this.changeService.exsistRequestChangeData$.value.waterData;
    if (water) {
      this.changePaymentsForm.levels[3].controls[0].inputControl.config.value =
        water.waterCardNumber;
      this.changePaymentsForm.levels[3].controls[1].inputControl.config.value =
        water.meterReading;
      if (water.meterReadingDate) {
        let stringDate = this.dateService.getStrinsOfDate(
          water.meterReadingDate.toString()
        );
        this.changePaymentsForm.levels[3].controls[2].inputControl.config.day =
          stringDate.day;
        this.changePaymentsForm.levels[3].controls[2].inputControl.config.month =
          stringDate.month;
        this.changePaymentsForm.levels[3].controls[2].inputControl.config.year =
          stringDate.year;
      }
      this.changePaymentsForm.levels[3].controls[3].inputControl.config.file = {
        fileID: water.meterReadingFile.fileID,
        fileName: water.meterReadingFile.fileName,
      };
      this.changePaymentsForm.levels[3].controls[4].inputControl.config.value =
        water.tenantsCount;
      // res.tenantPerson.pasport;
    }
  }
  setAddress(res: FormsAddress) {
    this.changePaymentsForm.levels[2].controls[6].inputControl.selectconfig.selected =
      res.cityID;
    this.formsData.getStreets(res.cityID);
    this.changePaymentsForm.levels[2].controls[7].inputControl.selectconfig.selected =
      res.streetID;
    this.changePaymentsForm.levels[2].controls[8].inputControl.doubleConfig[0].value =
      res.houseNum;
    this.changePaymentsForm.levels[2].controls[8].inputControl.doubleConfig[1].value =
      res.apartment;
  }
  prev(){
    this.section=1;
    this.iLevel1 = -1;
    this.iLevel2 = -1;
    this.iLevel3= -1;
    this.changeData={
      typeForm: null,
      buyOrSellPerson: null,
      rentPerson: null,
    };
    this.level=1;
  }
  choose(i: number, level: number) {
    switch (level) {
      case 1:
        this.iLevel1 = i;
        this.changeData.typeForm = i == 1 ? 'rent' : 'buyOrSell';
        this.go(1);
        break;
      case 2:
        this.iLevel2 = i;
        this.changeData.buyOrSellPerson = i == 1 ? 'seller' : 'buyer';
        this.go(2);
        break;
      case 3:
        this.iLevel3 = i;
        this.changeData.rentPerson = i == 1 ? 'enter' : 'go';
        this.go(2);
        break;

      default:
        break;
    }
  }
  back() {
    this.level = 1;
  }
  go(index: number) {
    switch (index) {
      case 1:
        if (this.iLevel1 == 1) this.level = 3;
        else if (this.iLevel1 == 2) this.level = 2;
        break;
      case 2:
        this.section = 2;
        this.changePaymentsForm = this.changeService.createForm(
          this.changeData
        );
        this.setAddressList();
        break;
      default:
        break;
    }
  }
  setAddressList() {
    this.formsData.citiesListSelect$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!res || res.length == 0) {
          this.formsData.getCities();
        } else {
          this.changePaymentsForm.levels[1].controls[3].inputControl.selectconfig.list =
            res;
          this.changePaymentsForm.levels[1].controls[3].inputControl.selectconfig.copyList =
            res;
        }
      });
    this.formsData.streetsListSelect$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && res.length > 0) {
          this.changePaymentsForm.levels[1].controls[4].inputControl.selectconfig.list =
            res;
          this.changePaymentsForm.levels[1].controls[4].inputControl.selectconfig.copyList =
            res;
        }
      });
  }
  getDataForLavel2WithMaintz(maintz: string) {
    // let body: GetAssetsByFamilyMainIDReq = {
    //   id: maintz,
    //   muniCode: this.muni.getMuniCode,
    // };
    // this.formsFunctions.GetAssetsByFamilyMainID(body);
    this.changeService.getExistRequestHoldersExchange();
  }
  saveDataLevel(event: saveDataLevel) {
    let level = this.changePaymentsForm.levels.find(
      (l) => l.id == event.levelID
    );
    if (level) {
      switch (level.id) {
        case 1:
          const body1: FormPersonReq = {
            email: event.data.email,
            firstName: event.data.firstName,
            lastName: event.data.lastName,
            muniCode: this.muni.getMuniCode,
            pasport: event.data.id,
            pasportFileID: event.data.fileSefach,
            phone: event.data.phone,
            formType: this.changePaymentsForm.id,
            address: null,
          };
          this.formsFunctions
            .SaveApplicantRequestDetails(body1)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res) => {
                if (!this.haveDataFromMaintz && this.authService.isLoggedIn()) {
                  this.authService
                    .getUser()
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((res) => {
                      this.getDataForLavel2WithMaintz(res.maintz.toString());
                    });
                }
                this.getAddressObjects();
                this.next(event.stepper);
              },
              (error) => {
                this.loading.hide();
              }
            );
          break;
        case 2:
          const body2: FormPropertyReq = {
            AssetID: event.data.assetNumber,
            muniCode: this.muni.getMuniCode,
            RequestId:
              this.formsFunctions.existRequestData$.value.requestData.lineID,
          };
          this.changeService.changeDate = event.data.dateChange;
          this.formsFunctions
            .SavePropertyDetails(body2)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res) => {
                this.next(event.stepper);
              },
              (error) => {
                this.loading.hide();
              }
            );
          break;
        case 3:
          const person: FormPersonReq = {
            address: {
              apartment: event.data.apartment != '' ? +event.data.apartment : 0,
              cityID: event.data.city,
              entrance: '',
              houseNum:
                event.data.houseNumber != '' ? +event.data.houseNumber : 0,
              lineID: 0,
              postalCode: 0,
              streetID: event.data.street,
            },
            email: event.data.email,
            firstName: event.data.firstName,
            lastName: event.data.lastName,
            pasport: event.data.id,
            phone: event.data.phone,
            formType: this.changePaymentsForm.id,
            muniCode: this.muni.getMuniCode,
          };
          const body3: saveDetailsData = {
            muniCode: this.muni.getMuniCode,
            mainRequestId:
              this.formsFunctions.existRequestData$.value.requestData.lineID,
            personDetails: person,
            proofFileID: event.data.proofFile,
            type: +this.changeService.HoldersExchangeType,
            exchangeDate: moment
              .utc(
                this.dateService.getDefultStrinsOfDate(
                  this.changeService.changeDate
                ),
                'MM/DD/YYYY'
              )
              .toDate(),
            // new Date(this.changeService.changeDate),
          };
          this.changeService
            .saveDetailsHoldersExchange(body3)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res) => {
                this.setLevel4Data();
                this.next(event.stepper);
              },
              (error) => {
                this.loading.hide();
              }
            );
          break;
        case 4:
          let body: SendWaterInfo = {
            mainRequestId:
              this.formsFunctions.existRequestData$.value.requestData.lineID,
            muniCode: this.muni.getMuniCode,
            waterCardNumber: event.data.numCardWater,
            meterReading: event.data.endMone,
            meterReadingDate: moment
              .utc(
                this.dateService.getDefultStrinsOfDate(event.data.dateReadMone),
                'MM/DD/YYYY'
              )
              .toDate(),
            // new Date(event.data.dateReadMone),
            meterReadingFileId: event.data.fileWaterMone,
            fileIDs: [],
            tenantsCount: event.data.numLivers,
          };
          this.changeService
            .SendWaterInfo(body)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res) => {
                this.next(event.stepper);
              },
              (error) => {
                this.loading.hide();
              }
            );
          break;
        default:
          break;
      }
    }
  }
  next(mystepper: MatStepper) {
    this.loading.hide();
    setTimeout(() => {
      mystepper.selected.completed = true;
      mystepper.next();
    }, 1);
  }
}
