<div class="welcome" #welcome>
  <span class="title">{{ text.title }}</span>
  <div class="cards">
    <div class="card newReq" (click)="nav(newReq.url)">
      <div class="circle">
        <img src="../../../assets/discount/surface1.svg">
      </div>
      <span>{{ newReq.text }}</span>
    </div>
    <div class="card oldReq" (click)="nav(oldReq.url)">
      <div class="circle">
        <img src="../../../assets/discount/file.svg">
      </div>
      <span>{{ oldReq.text }}</span>
    </div>
    <div class="card calc" (click)="nav(calc.url)">
      <div class="circle">
        <img src="../../../assets/discount/balance-sheet.svg">
      </div>
      <span>{{ calc.text }}</span>
    </div>
  </div>
</div>
