import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileConfig } from '../my-file/file.model';

export interface InputConfig {
  type:
    | 'input'
    | 'select'
    | 'file'
    | 'multy-files'
    | 'date'
    | 'textarea'
    | 'password'
    | 'anyThing';
  inputType: string;
  formControlName: string;
  required: boolean;
  name: string;
  label: string;
  validation?:
    | 'id'
    | 'email'
    | 'emailPhone'
    | 'name'
    | 'phone'
    | 'none'
    | 'plusNumbers'
    | 'dateAfterToday';
  list?: IListItem[];
  appearance?: 'legacy' | 'outline' | 'standard' | 'none' | 'fill';
  miniInput?: boolean;
  disabled?: boolean;
  upLable?: boolean;
  value?: any;
  day?: number;
  month?: number;
  year?: number;
  file?: FileConfig;
  fileList?: FileConfig[];
  icon?: string;
  errors?:
    | 'id'
    | 'email'
    | 'emailPhone'
    | 'name'
    | 'phone'
    | 'plusNumbers'
    | 'dateAfterToday'
    | 'required'
    | 'another';
  anotherError?: string;
  titleTextarea?: string;
  tooltip?: string;
}
export interface IListItem {
  value: any;
  label: string;
  icon?: string;
  fileType?: number;
}
export interface IListItemFile {
  value: any;
  label: string;
  fileType: number;
}
export interface ICityItem {
  cityID: any;
  cityName: string;
}
export interface IStreetItem {
  streetID: any;
  streetName: string;
}
export function getInputConfig(
  type: 'input' | 'select' | 'file' | 'date',
  inputType: string,
  formControlName: string,
  name: string,
  validation:
    | 'id'
    | 'email'
    | 'name'
    | 'phone'
    | 'none'
    | 'plusNumbers'
    | 'dateAfterToday',
  required?: boolean,
  label?: string,
  miniInput?: boolean,
  disabled?: boolean,
  list?: IListItem[],
  appearance?: 'legacy' | 'outline' | 'standard' | 'none' | 'fill',
  upLable?: boolean
): InputConfig {
  return {
    type,
    inputType,
    formControlName,
    name,
    required: !!required,
    label,
    validation,
    miniInput: !!miniInput,
    disabled: !!disabled,
    list,
    appearance,
    upLable,
  };
}

export function generateForm(inputs: InputConfig[]): FormGroup {
  const formGroup = new FormGroup({});
  inputs.forEach((input) => {
    if (input.formControlName)
      formGroup.addControl(
        input.formControlName,
        new FormControl(
          '',
          input.required ? Validators.required : Validators.nullValidator
        )
      );
    else {
      formGroup.addControl(
        input.name,
        new FormControl(
          '',
          input.required ? Validators.required : Validators.nullValidator
        )
      );
    }
  });
  return formGroup;
}
export function generateFormArray(
  controlsArray: Array<InputConfig[]>
): FormGroup {
  const formGroup = new FormGroup({});
  controlsArray.forEach((control) => {
    control.forEach((input) => {
      formGroup.addControl(
        input.formControlName,
        new FormControl(
          '',
          input.required ? Validators.required : Validators.nullValidator
        )
      );
    });
  });

  return formGroup;
}

export function addControlToForm(
  formGroup: FormGroup,
  input: InputConfig
): FormGroup {
  formGroup.addControl(
    input.formControlName,
    new FormControl(
      '',
      input.required ? Validators.required : Validators.nullValidator
    )
  );
  return formGroup;
}

export function addControlToExistFormGroup(
  formGroup: FormGroup,
  inputs: InputConfig[]
): FormGroup {
  inputs.forEach((input) => {
    formGroup.addControl(
      input.formControlName,
      new FormControl(
        '',
        input.required ? Validators.required : Validators.nullValidator
      )
    );
  });
  return formGroup;
}
