import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { LoaderComponent } from './loader.component';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  public message = new BehaviorSubject<string>('');
  private overlayRef: OverlayRef | null = null;
  constructor(private overlay: Overlay) {}
  public show(messagea = '') {
    // Returns an OverlayRef (which is a PortalHost)
    if (!this.overlayRef) {
      this.message.next(messagea);
      this.overlayRef = this.overlay.create();
    }
    // Create ComponentPortal that can be attached to a PortalHost
    const spinnerOverlayPortal = new ComponentPortal(LoaderComponent);
    const component = this.overlayRef.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
  }

  public hide() {
    if (!!this.overlayRef) {
      this.message = null;
      this.overlayRef.detach();
    }
  }
}
