<div *ngIf="formsTypes != '1'" class="imageDashboard">
  <div
    class="wrapper"
    [ngClass]="{
      mobile: mobile
    }"
  >
    <div id="dashboardContent" class="dashboardContent">
      <app-header></app-header>
      <app-muni-choose class="marginTablet"></app-muni-choose>
      <app-actions-line></app-actions-line>
    </div>
    <div *ngIf="!mobile" class="backDown" id="backDown" (click)="scroll()">
      <img class="btndown" src="../../assets/png/btndown.gif" />
    </div>
    <div class="howCanHelp">
      <app-how-can-help id="howCanHelp" class="w100"></app-how-can-help>
    </div>
    <div class="peopleImg">
      <app-people-enter class="w100"></app-people-enter>
    </div>
    <app-footer></app-footer>
  </div>
</div>

<div *ngIf="formsTypes == '1'" class="imageForms">
  <div
    class="wrapper"
    [ngClass]="{
      mobile: mobile
    }"
  >
    <div id="dashboardContent" class="formsContent">
      <app-header></app-header>
      <app-forms-type></app-forms-type>
    </div>
  </div>
</div>
