import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { formDeme, voucherNumberConfig } from '../../elementForm/controlsDeme';
import { idconfig } from '../../elementForm/controlsDeme';
import { level } from '../../elementForm/controlsDeme';
import { fullControl } from '../../elementForm/control.model';
import { VoucherService } from 'src/app/services/voucher.service';
import { BehaviorSubject, Subject, map, skipWhile, takeUntil } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from '../../elementForm/payment-page/payment.service';
import { InputConfig } from '../../elementForm/form-input/form-input.model';
import { inputConfig } from 'src/app/common/elements/my-big-input/my-big-input.model';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-pay-voucher',
  templateUrl: './pay-voucher.component.html',
  styleUrls: ['./pay-voucher.component.scss'],
})
export class PayVoucherComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') private myStepper: MatStepper;
  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    if (
      event.target.id != 'voucherImg' &&
      event.target.id != 'openSpan' &&
      event.target.id != 'openImg' &&
      this.haveVoucher
    ) {
      this.close();
      event.stopPropagation();
    }
  }
  destroy$ = new Subject();
  text = {
    where: 'איפה הנתונים האלה נמצאים?',
  };
  clearControl = new BehaviorSubject<string>('');
  paymentNumberInput: InputConfig = {
    formControlName: 'paymentNumber',
    inputType: 'number',
    label: 'מספר משלם',
    name: 'paymentNumber',
    required: true,
    type: 'input',
    icon: 'man.svg',
    validation: 'plusNumbers',
  };
  paymentNumberconfig: fullControl = {
    controlType: 'input',
    inputControl: {
      config: this.paymentNumberInput,
    },
  };
  paymentName = '';
  payment: fullControl[] = [this.paymentNumberconfig, voucherNumberConfig];
  details: fullControl[] = [idconfig, this.paymentNumberconfig];
  identification: fullControl[] = [
    voucherNumberConfig,
    // this.paymentNumberconfig,
  ];
  payVoucherLevels: level[] = [
    {
      controls: this.identification,
      id: 0,
      name: 'זיהוי',
      btnText: 'המשך לתשלום',
      type: 'form',
      lable: '',
    },
    {
      controls: this.details,
      id: 2,
      name: 'פרטים',
      lable: '',
      type: 'table',
    },
    {
      controls: this.payment,
      id: 3,
      name: 'תשלום',
      type: 'form',
      lable: '',
    },
  ];
  formdeme: formDeme = {
    id: 30,
    levels: this.payVoucherLevels,
    name: 'תשלום שובר',
  };
  haveVoucher = false;
  isLevel1 = true;
  voucherNumber = '';
  paymentNumber = 0;
  paymentCodeConfig: inputConfig = {
    formControlName: 'paymentCode',
    icon: 'man.svg',
    lable: 'מספר משלם',
    required: true,
    type: 'number',
  };
  voucherNumberConfig: inputConfig = {
    formControlName: 'voucher',
    icon: 'voucher.svg',
    lable: 'מספר שובר/מסלקה',
    required: true,
    type: 'number',
  };
  isLoggdeIn = false;
  mobile = false;
  sum = 0;
  voucherSrc = '';
  constructor(
    private voucherService: VoucherService,
    private loadingService: LoadingService,
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (window.screen.width <= 950) {
      this.mobile = true;
    }
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        if (params.voucher && params.payment) {
          this.voucherNumber = params.voucher;
          this.paymentNumber = params.payment;
          if (params.tab == 'pay') {
            this.searchVoucher(true);
          } else if (!params.tab) {
            this.searchVoucher();
          }
        }
      });
    this.isLoggdeIn = this.authService.isLoggedIn();
    if (!this.isLoggdeIn) {
      this.identification.push(this.paymentNumberconfig);
    }
    this.voucherService
      .getVoucherImg()
      .pipe(skipWhile((x) => !x))
      .subscribe((res) => {
        if (res && res.body.voucherImg) {
          this.voucherSrc = res.body.voucherImg;
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  openVoucher() {
    this.haveVoucher = !this.haveVoucher;
    if (window.screen.width <= 850) {
      let el = document.getElementById('voucherImg');
      if (el) {
        el.style.display = 'block';
        el.style.transition = 'all 1s ease';
        el.style.transform = 'translate(0, 600px)';
      }
    } else {
      if (this.haveVoucher == false) {
        this.close();
      } else {
        let el = document.getElementById('voucherImg');
        el.style.left = '200px';
        if (el) {
          el.style.transition = 'all 1s ease';
          el.style.transform = 'translate(-500px, 0)';
        }
      }
    }
  }
  close() {
    if (window.screen.width <= 850) {
      let el = document.getElementById('voucherImg');
      if (el) {
        el.style.transition = 'all 0.5s ease';
        el.style.transform = 'translate(0, -600px)';
      }
    } else {
      let el = document.getElementById('voucherImg');
      el.style.left = '200px';
      if (el) {
        el.style.transition = 'all 0.5s ease';
        el.style.transform = 'translate(500px, 0)';
      }
    }
  }
  saveDataLevel(event: any) {
    this.voucherNumber = event.value.voucherNumber;
    this.paymentNumber = event.value.paymentNumber;
  }
  searchVoucher(isPayTab?: boolean) {
    this.loadingService.show('כבר מוצאים את השובר שלך');
    let paymentID = this.isLoggdeIn ? 0 : this.paymentNumber;
    this.voucherService
      .getOneVoucher(this.voucherNumber, paymentID)
      .pipe(
        skipWhile((x) => !x),
        map((x) => x),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (res) => {
          if (
            !res?.body ||
            res.body.rc == 99 ||
            (res.body.rc == 0 && !res.body.voucher)
          ) {
            this.loadingService.hide();
            //באג
            this.formdeme.levels[0].controls[0].inputControl.config.errors =
              'another';
            this.formdeme.levels[0].controls[0].inputControl.config.anotherError =
              'אירעה שגיאה. נסה שנית';
          } else if (res.body.rc == 2) {
            this.loadingService.hide();
            this.formdeme.levels[0].controls[0].inputControl.config.errors =
              'another';
            this.formdeme.levels[0].controls[0].inputControl.config.anotherError =
              'מספר שובר לא קיים למשלם זה';
            this.voucherNumber = '';
            this.formdeme.levels[0].controls[0].inputControl.config.value = '';
            this.clearControl.next('voucherNumber');
          } else if (res.body.rc == 1) {
            this.loadingService.hide();
            this.formdeme.levels[0].controls[1].inputControl.config.errors =
              'another';
            this.formdeme.levels[0].controls[1].inputControl.config.anotherError =
              'קוד משלם לא קיים במערכת';
            this.formdeme.levels[0].controls[1].inputControl.config.value = '';
            this.paymentNumber = 0;
            this.clearControl.next('paymentNumber');
          } else if (res.body.voucher?.payed) {
            this.loadingService.hide();
            this.formdeme.levels[0].controls[0].inputControl.config.errors =
              'another';
            this.formdeme.levels[0].controls[0].inputControl.config.anotherError =
              'שובר זה כבר שולם בעבר';
            this.voucherNumber = '';
            this.formdeme.levels[0].controls[0].inputControl.config.value = '';
          } else if (res.body.voucher?.isAnnualDatePassed === true) {
            this.loadingService.hide();
            this.formdeme.levels[0].controls[0].inputControl.config.errors =
              'another';
            this.formdeme.levels[0].controls[0].inputControl.config.anotherError =
              'תאריך אחרון לתשלום שובר שנתי עבר';
            this.voucherNumber = '';
            this.formdeme.levels[0].controls[0].inputControl.config.value = '';
          } else if (res.body.voucher?.valid == false) {
            this.loadingService.hide();
            this.formdeme.levels[0].controls[0].inputControl.config.errors =
              'another';
            this.formdeme.levels[0].controls[0].inputControl.config.anotherError =
              'תאריך התשלום של שובר זה עבר. צור קשר עם הרשות';
            this.voucherNumber = '';
            this.formdeme.levels[0].controls[0].inputControl.config.value = '';
          } else {
            this.isLevel1 = false;
            this.formdeme.name =
              'תשלום שובר מספר ' + res.body.voucher.voucherId;
            this.voucherService.numVocher = +this.voucherNumber;
            this.paymentName = res.body.voucher.fullName;
            this.voucherService.oneVocher$.next(res.body.voucher);
            this.voucherService.mainIdPaymentOneVoucher = this.paymentNumber;
            this.paymentService.sum$.next(res.body.voucher.sum);
            this.sum = res.body.voucher.sum;
            this.router.navigate([], {
              queryParams: {
                voucher: this.voucherNumber,
                payment: this.paymentNumber,
                tab: 'details',
              },
              queryParamsHandling: 'merge',
            });
            // this.appService.Guest.next({ email: '', guestID: 0 });
            if (isPayTab) {
              setTimeout(() => {
                this.myStepper.selectedIndex = 2;
              });
            } else {
              this.next(this.myStepper);
            }
          }
        },
        (error) => {
          //bug
          this.formdeme.levels[0].controls[0].inputControl.config.errors =
            'another';
          this.formdeme.levels[0].controls[0].inputControl.config.anotherError =
            'אירעה שגיאה. נסה שנית';
        }
      );
  }
  prev(mystepper: MatStepper) {
    setTimeout(() => {
      mystepper.previous();
      if (mystepper.selectedIndex == 0) {
        this.isLevel1 = true;
      }
    }, 1);
  }
  next(mystepper: MatStepper) {
    setTimeout(() => {
      mystepper.next();
    }, 1);
  }
}
