import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  FormControl,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { emitObj, inputControl } from '../control.model';
import { InputConfig } from './form-input.model';
import { selectConfig } from 'src/app/common/elements/my-select/my-select.model';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { FormsDataService } from '../../forms-data.service';
import isIsraeliIdValid from 'israeli-id-validator';
@Component({
  selector: 'form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputComponent),
      multi: true,
    },
  ],
})
export class FormInputComponent implements OnDestroy, AfterViewInit, OnDestroy {
  @Input() fullControl: inputControl;
  @Input() errorRes: string;
  @Input() errorAnother: string;
  @Input() enterNext: boolean;
  @Input() selectconfig: selectConfig;
  @Input() selected: any;
  @Input() clearControl = new BehaviorSubject<string>('');
  @Input() focusControl = new BehaviorSubject<string>('');
  @Output() submitFunction = new EventEmitter<any>();
  @Output() nextEnterGo = new EventEmitter<void>();
  destroy$ = new Subject();
  config: InputConfig;
  doubleConfig: InputConfig[];
  control = new FormControl();
  control2 = new FormControl();
  timeout: any = null;
  gender = -1;
  error = {
    error: '',
    is: 'הוא',
    must: 'חובה',
  };
  constructor(private formsData: FormsDataService) {}
  ngOnInit(): void {
    this.GetDataFromObjectFull();
    this.clearControl.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res != '' && res == this.config.formControlName) {
        this.control.reset();
      }
    });
    this.focusControl.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res != '' && res == this.config.formControlName) {
        this.control.reset();
        document
          .getElementById('fieldinput' + this.config.formControlName)
          .focus();
      }
    });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  ngAfterViewInit(): void {
    if (this.config.value) {
      this.control.setValue(this.config.value);
    }
    if (this.selectconfig?.selected) {
      this.selected = this.selectconfig.selected;
    }
  }
  onSelect(event: any) {
    this.selected = event;
    this.submitFunction.emit({
      value: event,
      name: this.config.formControlName,
    });
    if (this.config.formControlName == 'city' && this.config.type == 'select') {
      this.formsData.getStreets(event);
    }
  }
  getValue(is2?: boolean): boolean {
    if (is2) return this.control2.value ? true : false;
    else return this.control.value ? true : false;
  }
  onKeySearch(event: any, name: string) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        if ($this.config?.validation != 'none') {
          $this.validations(event.target.value, $this.config.validation);
        }
        if (!$this.control.errors) {
          let obj: emitObj = {
            name: name,
            value: event.target.value,
            errors: $this.control.errors,
          };
          $this.submitFunction.emit(obj);
        }
      }
    }, 250);
  }

  enter() {
    // if (this.enterNext) {
    this.nextEnterGo.emit();
    // }
  }
  saveGender(event) {
    this.gender = event.target.value;
    this.config.value = this.gender;
    this.submitFunction.emit({
      value: event.target.value,
      name: 'gender',
    });
  }
  validations(value, typeValidation) {
    this.errorRes = '';
    switch (typeValidation) {
      case 'id':
        if (!isIsraeliIdValid(value) || value.toString().length < 9) {
          this.control.setErrors({ id: true });
        }
        break;
      case 'email':
        var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!pattern.test(value)) {
          this.control.setErrors({ email: true });
        }

        break;
      case 'name':
        if (value.length <= 1) {
          this.control.setErrors({ name: true });
        }
        break;
      case 'phone':
        var patt = /^(?:\+972|05)[-\d]*\d{7}$/;
        if (!patt.test(value)) {
          this.control.setErrors({ phone: true });
        }
        break;
      case 'plusNumbers':
        if (value < 0) {
          this.control.setErrors({ plusNumbers: true });
        }
        break;
      case 'dateAfterToday':
        if (value < new Date()) {
          this.control.setErrors({ dateAfterToday: true });
        }
        break;
      case 'emailPhone':
        var patternEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        var pattPhone = /^(?:\+972|05)[-\d]*\d{7}$/;
        if (!patternEmail.test(value) && !pattPhone.test(value)) {
          this.control.setErrors({ emailPhone: true });
        }
        break;
    }
  }
  private GetDataFromObjectFull() {
    if (this.fullControl) {
      let inputControl = this.fullControl;
      this.config = inputControl.config;
      this.doubleConfig = inputControl?.doubleConfig;
    }
  }
}
