<div class="wrapper">
  <span class="title">{{ text.title }}</span>
  <div class="paddingMobile" *ngIf="level == 1">
    <div class="filters" *ngIf="!mobile">
      <my-select
        [config]="assetSelectConfig"
        [selected]="assetSelected"
        (selectionChange)="select($event, 'asset')"
      ></my-select>
      <my-select
        [config]="typeSelectConfig"
        [selected]="typeSelected"
        (selectionChange)="select($event, 'type')"
      ></my-select>
      <my-select
        [config]="yearSelectConfig"
        [selected]="yearSelected"
        (selectionChange)="select($event, 'year')"
      ></my-select>
    </div>
    <app-debt-table *ngIf="!mobile" [data]="chargesList"></app-debt-table>
    <app-debts-balance-mobile
      [assetSelectConfig]="assetSelectConfig"
      [typeSelectConfig]="typeSelectConfig"
      [yearSelectConfig]="yearSelectConfig"
      [yearSelected]="yearSelected"
      [typeSelected]="typeSelected"
      [assetSelected]="assetSelected"
      (filter)="select($event, 'mobile')"
      *ngIf="mobile"
      [data]="chargesList"
    ></app-debts-balance-mobile>
  </div>

  <div class="payPage" *ngIf="level == 2">
    <app-payment-page (goPrev)="goPrev()"></app-payment-page>
  </div>
</div>
