<div class="titlediv">
  <span *ngIf="(selected || selected == 0) && !config?.bigSelect && !config.icon">{{
    config?.lable
  }}</span>
</div>
<img
  *ngIf="config?.icon"
  class="icon"
  src="../../../../assets/svg/{{ config.icon }}"
/>
<div
  id="myselect"
  [ngClass]="{
    iconSelect: config?.icon
  }"
>
  <div class="flexForNotBig">
    <!-- shadow: config?.shadow, -->
    <mat-form-field
      [ngClass]="{
        bigSelect: config?.bigSelect,
        border: config?.normal || config?.shadow,
        normal: !config?.bigSelect && !config?.shadow
      }"
      appearance="outline"
      floatlLabel="never"
    >
      <!-- <mat-label
      class="selectLable"
      *ngIf="!selected && !select.value && !focusInput"
      >{{ config.lable }}</mat-label
    > -->
      <mat-select
        placeholder="{{ config.lable }}"
        [formControl]="control"
        [value]="selected || config?.selected"
        (selectionChange)="selectionChangeEmit($event)"
        #bigselect
        (opened)="opened()"
        (closed)="closed()"
        [ngClass]="{
          selectedColor: selected && config?.textBlue
        }"
      >
        <div class="search-container" *ngIf="config.list.length >= 10 || config.list.length == 0 || iAfterFilter">
          <input
            mat-input
            placeholder="סנן"
            class="search"
            (keyup)="filter($event)"
          />
        </div>
        <mat-option *ngFor="let item of config.list" [value]="item.value">{{
          item.label
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
