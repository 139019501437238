<div class="header">
  <div class="rightContent">
    <div class="mini">
      <img
      class="menuHome"
        *ngIf="mobile"
        src="../../../assets/svg/menuHome.svg"
        (click)="openMobileSidebar()"
      />
      <img
        class="munilogo"
        (click)="main()"
        src="../../../assets/svg/muniLogo.svg"
      />
      <div
        *ngIf="!mobile"
        class="digitalActions"
        class="open-menu-button"
        (click)="toggleMenu()"
      >
        <span id="openMenu">{{ text.digitalActions }}</span>
        <img id="openMenu" src="../../../assets/png/arrowDown.png" />
      </div>
      <div class="menu-container">
        <div class="top-menu"[class.open]="isMenuOpen">
          <!-- Your menu items go here -->
          <div class="list">
            <span class="title">{{ text.title3 }}</span>
            <span
              (click)="nav(line.url)"
              *ngFor="let line of menuPayments; let i = index"
              >{{ line.name }}</span
            >
          </div>
          <div class="list">
            <span class="title">{{ text.title5 }}</span>
            <span
              (click)="nav(line.url)"
              *ngFor="let line of menuConfirms; let i = index"
              >{{ line.name }}</span
            >
          </div>
          <div class="list">
            <span class="title">{{ text.title6 }}</span>
            <span *ngFor="let line of menuForms" (click)="nav(line.url)">{{
              line.name
            }}</span>
          </div>
          <div class="list">
            <span class="title">{{ text.title4 }}</span>
            <span *ngFor="let line of menuPersonal" (click)="nav(line.url)">{{
              line.name
            }}</span>
          </div>
        </div>
      </div>
      <div>
        <span (click)="scroll()" class="digitalActions" >{{
          text.aboutUs
        }}</span>
      </div>
    </div>

    <div *ngIf="!mobile" >
      <span
        *ngIf="isLoggedIn"
        id="loginClick"
        class="login"
        (mouseenter)="openDialogLogin('0ms', '0ms')"
        >{{ name }}</span
      >
      
      <button class="loginbtn" *ngIf="!isLoggedIn" (click)="openDialogLogin('0ms', '0ms')">
        <img src="../../../assets/svg/userLogin.svg" alt="" />
        {{ name }}
      </button>
    </div>
  </div>
  <div class="leftContent">
    <div id="logoDiv" class="logoDiv" (click)="main()">
      <img src="{{ logo }}" />
    </div>
  </div>
</div>
<!-- <app-digital-menu (click)="$event.stopPropagation()"></app-digital-menu> -->
