<div class="wrapperP">
  <form [formGroup]="form" class="">
    <span class="title">{{ tabs[0].label }}</span>
    <app-basic-form
      [controlsList]="firstControls"
      [hideNext]="2"
      [hidePrev]="true"
      [miniForm]="false"
      [autoSave]="true"
      [enterNext]="true"
      [btnText]=""
      (getDataInput)="getDataInput($event)"
      (submitFunction)="save($event, 0)"
    ></app-basic-form>
  </form>
  <form [formGroup]="form2" class="">
    <span class="title">{{ tabs[1].label }}</span>
    <app-basic-form
      [controlsList]="secondControls"
      [hideNext]="2"
      [hidePrev]="true"
      [miniForm]="false"
      [autoSave]="true"
      [enterNext]="true"
      [btnText]=""
      (getDataInput)="getDataInput($event)"
      (submitFunction)="save($event, 1)"
    ></app-basic-form>
  </form>
  <div class="alignBtn">
    <my-button
      [config]="addEmergency?.buttonControl?.config"
      [value]="addEmergency?.buttonControl?.value"
      [toggleConfig]="addEmergency?.buttonControl?.toggleConfig"
      (buttonSelected)="buttonSelected($event)"
    ></my-button>
    <!-- [focusControl]="focusControl" -->
  </div>

  <div class="nextdiv">
    <button class="deletePerson" mat-button (click)="deletePerson()">
      <img src="../../../../../../assets/svg/garbageAzure.svg" alt="" />
      מחיקת בן משפחה
    </button>

    <button
      [disabled]="disabledfunc()"
      class="next"
      mat-button
      (click)="saveData()"
    >
      שמירה
    </button>
  </div>
</div>
