<div class="wrapper">
  <span class="title">{{ text.title }}</span>
  <div *ngIf="!mobile" class="content">
    <div class="headerImg">
      <div class="header">
        <div class="line">
          <span>{{ text.what }}</span>
        </div>
        <div class="icons">
          <div
            [ngClass]="{
              soon: icon.panelOpened == false
            }"
            (click)="scroll(icon)"
            class="back"
            *ngFor="let icon of options"
          >
            <img src="../../assets/svg/personal/{{ icon.icon }}" />
            <span>{{ icon.title }}</span>
          </div>
        </div>
      </div>
      <img class="img" src="../../assets/svg/personal/man.png" />
    </div>
    <app-personal id="personal" class="app"></app-personal>
    <app-asset id="asset" class="app"></app-asset>
    <!-- <app-my-forms id="forms" class="app"></app-my-forms> -->
    <!-- <app-notifications id="notifications" class="app"></app-notifications> -->
    <app-bussines id="bussines" class="app"></app-bussines>
  </div>
  <div *ngIf="mobile" class="content">
    <div class="header">
      <div class="line">
        <span>{{ text.what }}</span>
      </div>
      <div class="data">
        <mat-expansion-panel
          id="panel{{ i }}"
          *ngFor="let icon of options; let i = index"
          hideToggle
          [expanded]="!icon.panelOpened"
          [ngClass]="{
            lastchild: i == options.length-1,
          }"
        >
          <mat-expansion-panel-header (click)="openClose(i)">
            <img src="../../assets/svg/personal/{{ icon.icon }}" />
            <span>{{ icon.title }}</span>
            <img
              class="arrow"
              *ngIf="!icon.panelOpened"
              src="../../assets/svg/arrow-right.svg"
            />
          </mat-expansion-panel-header>
          <app-personal
            *ngIf="i == 0"
            (closeParent)="openClose(i)"
          ></app-personal>
          <app-asset (closeParent)="close(i)" *ngIf="i == 1"></app-asset>
          <!-- <app-my-forms
            *ngIf="i == 2"
            (closeParent)="openClose(i)"
          ></app-my-forms> -->
          <!-- <app-notifications *ngIf="i == 3"></app-notifications> -->
          <app-bussines [mobile]="mobile" *ngIf="i == 2"></app-bussines>
        </mat-expansion-panel>
      </div>
    </div>
  </div>
</div>
