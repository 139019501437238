<div class="doc">
  <span class="title">בשלב זה יש לצרף מסמכים, ועדיף כמה שיותר :)</span>
  <table class="tableDivDoc">
    <div class="panelFlex th">
      <th *ngFor="let title of displayedColumns">{{ title }}</th>
    </div>
    <div class="tableBack">
      <div class="panelFlex row" *ngFor="let element of data; let i = index">
        <td *ngIf="!mobile">{{ i + 1 }}.</td>
        <td class="flexrow">
          <span *ngIf="mobile">{{ i + 1 }}.</span>
          {{ element.description }}
        </td>
        <td>
          <multy-files
            [fileType]="element.fileType"
            [config]="element.config"
            [filesList]="element.filesList"
            [withoutLine]="true"
            (submitFunction)="submitInput($event, i)"
            (deletedFile)="deleteFile($event, i)"
          ></multy-files>
        </td>
      </div>
    </div>
  </table>
</div>
<div class="nextdiv">
  <button class="prev" mat-button matStepperPrevious>
    <img src="../../../../../../assets/svg/arrow-right.svg" />
    הקודם
  </button>
  <button class="next" mat-button (click)="nextEmit()">
    הבא
    <img src="../../../../../../assets/png/leftPay.png" />
  </button>
</div>
