<div class="wrapper">
  <div class="titleDiv">
    <span *ngIf="config">{{ config.label }}</span>
  </div>
  <div class="mydatemain">
    <div class="required">
      <span *ngIf="config?.required">*</span>
    </div>
    <div class="myInputDate">
      <input
        placeholder="yyyy"
        [(ngModel)]="year"
        id="year{{ config.name }}"
        (focus)="select(3)"
        (keydown.tab)="onTabClick(3)"
        (keyup)="onKeySearch($event, 3)"
        class="year"
        type="number"
      />/
      <input
        placeholder="mm"
        [(ngModel)]="month"
        id="month{{ config.name }}"
        (focus)="select(2)"
        (keydown.tab)="onTabClick(2)"
        (keyup)="onKeySearch($event, 2)"
        class="month"
        type="number"
      />/
      <input
        placeholder="dd"
        [(ngModel)]="day"
        id="day{{ config.name }}"
        (focus)="select(1)"
        (keydown.tab)="onTabClick(1)"
        (keyup)="onKeySearch($event, 1)"
        class="day"
        type="number"
      />
    </div>
    <input
      [formControl]="control"
      class="dateinput"
      #frominput
      matInput
      [matDatepicker]="fromPicker"
      [placeholder]="text.fromDate"
      (dateChange)="setDate(frominput.value, true)"
    />
    <mat-datepicker-toggle matIconSuffix [for]="fromPicker">
      <mat-icon matDatepickerToggleIcon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 96 960 960"
          width="24"
        >
          <path
            d="M316 653.385q-14.385 0-25.577-11.193-11.192-11.192-11.192-25.577 0-14.384 11.192-25.577 11.192-11.192 25.577-11.192 14.385 0 25.577 11.192 11.192 11.193 11.192 25.577 0 14.385-11.192 25.577-11.192 11.193-25.577 11.193Zm164 0q-14.385 0-25.577-11.193-11.192-11.192-11.192-25.577 0-14.384 11.192-25.577 11.192-11.192 25.577-11.192 14.385 0 25.577 11.192 11.192 11.193 11.192 25.577 0 14.385-11.192 25.577-11.192 11.193-25.577 11.193Zm164 0q-14.385 0-25.577-11.193-11.192-11.192-11.192-25.577 0-14.384 11.192-25.577 11.192-11.192 25.577-11.192 14.385 0 25.577 11.192 11.192 11.193 11.192 25.577 0 14.385-11.192 25.577-11.192 11.193-25.577 11.193ZM218.615 966q-38.34 0-64.478-26.137Q128 913.725 128 875.385v-522.77q0-38.34 26.137-64.478Q180.275 262 218.615 262h38.769v-85.231h69.077V262h310.154v-85.231h66.001V262h38.769q38.34 0 64.478 26.137Q832 314.275 832 352.615v522.77q0 38.34-26.137 64.478Q779.725 966 741.385 966h-522.77Zm0-66h522.77q9.23 0 16.923-7.692Q766 884.615 766 875.385v-374.77H194v374.77q0 9.23 7.692 16.923Q209.385 900 218.615 900ZM194 434.615h572v-82q0-9.23-7.692-16.923Q750.615 328 741.385 328h-522.77q-9.23 0-16.923 7.692Q194 343.385 194 352.615v82Zm0 0V328v106.615Z"
          />
        </svg>
      </mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #fromPicker></mat-datepicker>
  </div>

  <div class="errorInput">
    <span *ngIf="control.hasError('required') || errorRes == 'required'">
      {{ config.label }} {{ error.is }} <strong>{{ error.must }}</strong>
    </span>
    <span
      *ngIf="control.hasError('dateAfterToday') || errorRes == 'dateAfterToday'"
    >
      תאריך לא תקין
    </span>
    <span *ngIf="errorRes == 'another'"> {{ errorAnother }} </span>
  </div>
</div>
