<div class="wrapper">
  <div class="text">
    <span class="all">{{ text.all }}</span>
    <div class="miniWrapper">
      <span class="mini">{{ text.home }}</span>
      <span class="mini">{{ text.place }}</span>
    </div>
    <button class="btn" (click)="login()">{{ text.btn }}</button>
  </div>
  <img class="desktop people" src="../../../assets/png/peopleGroup.png" />
  <img class="mobile tablet people" src="../../../assets/png/people.png" />
</div>
<div class="aboutUsWrapper" id="aboutUsWrapper">
  <div class="muniLineText">
    <span class="aboutUs">{{ text.aboutUs }}</span>
    <div class="muniLine"></div>
  </div>
  <div class="wrapperPart">
    <div class="part">
      <span>{{ text.part1 }}</span>
      <span>{{ text.part2 }}</span>
      <span>{{ text.part3 }}</span>
      <span>{{ text.part4 }}</span>
      <a class="enter" (click)="go()"
        >{{ text.enter }}
        <img src="../../../assets/svg/arrowLeft.svg" />
    </a>
    </div>
  </div>
</div>
