<div class="wrapper">
  <div class="titleDiv">{{ text.text }} ({{ amount }})</div>
  <div class="lines" *ngFor="let service of services">
    <div class="line">
      <span>{{ service.service.serviceName }}</span>
      <span>{{ service.amount }}x</span>
    </div>
  </div>
  <div>
    <mat-divider></mat-divider>
    <div class="sum">
      <span>{{ text.sum }}</span>
      <span class="bold">{{ sum | currency : "ILS" }} </span>
    </div>
  </div>
</div>
