import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { UserData } from 'src/app/common/models/auth.model';
import { AccountService } from 'src/app/services/account.service';
import { AuthService } from 'src/app/services/auth.service';
import { IFamilyDetails, IPerson } from '../personal.model';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss'],
})
export class PersonalComponent implements OnInit, OnDestroy {
  @Output() closeParent = new EventEmitter()
  destroy$ = new Subject();
  familyMembers: IPerson[] = [];
  FamilyDetails: IFamilyDetails;
  user: UserData;
  text = {
    title: 'פרטים אישיים',
  };
  details = [];
  panelOpen = false;
  mobile = false;
  members = false
  constructor(private accountService: AccountService) {}
  ngOnInit(): void {
    if (window.screen.width <= 850) {
      this.mobile = true;
    }
    this.accountService.FamilyDetails$.pipe(takeUntil(this.destroy$)).subscribe(
      (res) => {
        this.FamilyDetails = res;
        this.familyMembers = res.familyMembers;
        if (res.paymentId && res.paymentId != '') this.generateList(res);
      }
    );
  }
  ngOnDestroy(): void {}
  generateList(res: IFamilyDetails) {
    this.details = [];
    this.details.push({ label: 'שם משלם', value: res.paymentName });
    this.details.push({ label: 'מספר משלם', value: res.paymentId });
    this.details.push({ label: 'נייד', value: res.cellphone });
    this.details.push({ label: 'כתובת המשלם', value: res.paymentAddress });
    this.details.push({ label: 'יישוב', value: res.paymentCity });
    this.details.push({ label: 'אימייל', value: res.email });
  }
  close(){
    this.closeParent.emit();
  }

}
