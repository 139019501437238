import { Component, OnDestroy, OnInit } from '@angular/core';
import { fullControl } from '../../elementForm/control.model';
import {
  birthDateConfig,
  firstNameconfig,
  idconfig,
  lastNameconfig,
} from '../../elementForm/controlsDeme';
import { MatDialog } from '@angular/material/dialog';
import { ResidentPopupComponent } from './resident-popup/resident-popup.component';
import { FormGroup } from '@angular/forms';
import { checkSimpleResident, getSimpleMail } from './resident.model';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { MuniService } from 'src/app/services/muni.service';
import { ResisentConfirmService } from './resisent-confirm.service';
import {
  InputConfig,
  generateForm,
} from '../../elementForm/form-input/form-input.model';
import { MyDateService } from '../../elementForm/my-date/my-date.service';
import { FormsDataService } from '../../forms-data.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-resident-confirm-simple',
  templateUrl: './resident-confirm-simple.component.html',
  styleUrls: ['./resident-confirm-simple.component.scss'],
})
export class ResidentConfirmSimpleComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  focusControl = new BehaviorSubject<string>('');
  controls: fullControl[] = [
    idconfig,
    lastNameconfig,
    firstNameconfig,
    birthDateConfig,
    {
      controlType: 'input',
      inputControl: {
        config: {
          formControlName: 'device',
          inputType: 'text',
          label: 'כתובת מייל / נייד לאימות',
          name: 'device',
          required: true,
          type: 'input',
          validation: 'emailPhone',
        },
      },
    },
  ];
  text = {
    title: 'אישור תושב',
    your: 'האישור שלך מוכן!',
    what: 'מה תבחרו לעשות עכשיו?',
    print: 'הדפסת האישור',
    email: 'שליחת האישור למייל',
    send: 'האישור שלך אצלך במייל',
    sendto: 'האישור הופק ונשלח למייל',
    addnote: 'תהליך ההזדהות נעשה על פי הנתונים המעודכנים במערכת הגביה של הרשות המקומית'
  };
  error = '';
  form: FormGroup;
  haveEntitlement = false;
  disabled = false;
  emailNote = '';
  isSending = false;
  email = '';
  mobile = false;
  constructor(
    private dialog: MatDialog,
    private loading: LoadingService,
    private muni: MuniService,
    private residentService: ResisentConfirmService,
    private dateService: MyDateService,
    private formsDataService: FormsDataService,
    private router: Router
  ) {}
  ngOnInit(): void {
    if (window.screen.width <= 850) {
      this.text.email = 'שליחה במייל';
      this.mobile = true;
    }
    this.generateForm();
    this.formsDataService.currentFormType = 70;
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  saveDataLevel(event: any) {
    // this.form = event;
    this.email = this.form.value.device;
  }
  disabledfunc(): boolean {
    return false;
  }
  checkFormValidation() :boolean{
    let controls = this.form.controls;
    const validator = [];
    let note = '';
    for (const name in controls) {
      if (controls[name].invalid) {
        validator.push(name);
      }
    }
    if (validator.length > 0) {
      if (validator.length > 1) {
        note = 'יש להזין ערכים תקינים בכל השדות';
      }
      validator.map((vald) => {
        let find = this.controls.findIndex(
          (c) => c.inputControl.config.formControlName == vald
        );
        switch (vald) {
          case 'id':
            this.controls[find].inputControl.config.errors = 'id';
            break;
          case 'firstName':
            this.controls[find].inputControl.config.errors = 'required';
            break;
          case 'lastName':
            this.controls[find].inputControl.config.errors = 'required';
            break;
          case 'device':
            this.controls[find].inputControl.config.errors = 'emailPhone';
            break;
          case 'birthDate':
            this.controls[find].inputControl.config.errors = 'dateAfterToday';
            break;
          default:
            break;
        }
      });
      return true
    }
    return false;
  }
  getDataInput(event: any) {
    if (event.type == 'resident') {
      this.form.controls[event.name].setValue(event.value);
    }
  }
  getCode() {
    if (this.checkFormValidation() == false) {
      let detils = this.form.value;
      this.loading.show();
      let body: checkSimpleResident = {
        ID: +detils.id,
        firstName: detils.firstName.toString(),
        lastName: detils.lastName.toString(),
        dateOfBirth: 
        moment.utc(this.dateService.getDefultStrinsOfDate(detils.birthDate), 'MM/DD/YYYY').toDate(),
        cellPhone: detils.device.includes('@') ? '' : detils.device,
        email: detils.device.includes('@') ? detils.device : '',
        muniCode: this.muni.getMuniCode,
      };
      this.residentService
        .SimpleResidentConfirmCheck(body)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res: any) => {
            this.loading.hide();
            this.residentService.TempTableLineID = res.tempTableLineID;
            res.errorType.forEach((errorType) => {
              switch (errorType) {
                case 1:
                  const dialogRef = this.dialog.open(ResidentPopupComponent, {
                    data: {},
                    disableClose: true,
                  });
                  dialogRef
                    .afterClosed()
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((result) => {
                      if (
                        this.residentService.fileFromRes?.fileContents &&
                        this.residentService.fileFromRes?.fileContents != ''
                      ) {
                        this.haveEntitlement = true;
                        this.disabled = true;
                        if (!detils.device.includes('@')) {
                          this.controls[4].inputControl.config.errors =
                            'another';
                          this.controls[4].inputControl.config.anotherError =
                            'יש להזין כתובת מייל לשליחת האישור';
                          this.controls[4].inputControl.config.label =
                            'כתובת מייל לשליחת האישור';
                          this.controls[4].inputControl.config.value = '';
                          this.form.controls['device'].setValue('');
                          this.focusControl.next('device');
                          if (this.mobile) {
                            let doc = document.getElementById('formID');
                            if (doc) {
                              doc.style.paddingBottom = '300px';
                              doc.scrollIntoView({block: 'end'})
                            }
                          }
                        } else {
                          this.emailNote = '';
                        }
                      } else {
                        this.haveEntitlement = false;
                        this.disabled = false;
                        this.emailNote = '';
                        this.error = '';
                      }
                    });
                  break;
                case 2:
                  this.haveEntitlement = false;
                  // this.error = 'ת"ז זו לא קיימת במאגר משרד הפנים של הרשות';
                  const i = this.controls.findIndex(
                    (c) => c.inputControl.config.formControlName == 'id'
                  );
                  this.controls[i].inputControl.config.errors = 'another';
                  this.controls[i].inputControl.config.anotherError =
                    'ת"ז זו לא קיימת במאגר משרד הפנים של הרשות';
                  break;
                case 7:
                  this.haveEntitlement = false;
                  // this.error = 'תז לא קיימת במאגר הנתונים של מערכת הגביה הרשותית';
                  const id = this.controls.findIndex(
                    (c) => c.inputControl.config.formControlName == 'id'
                  );
                  this.controls[i].inputControl.config.errors = 'another';
                  this.controls[i].inputControl.config.anotherError =
                    'תז לא קיימת במאגר הנתונים של מערכת הגביה הרשותית';
                  break;
                case 3:
                  this.haveEntitlement = false;
                  // this.error = 'שם פרטי לא קיים';
                  const name = this.controls.findIndex(
                    (c) => c.inputControl.config.formControlName == 'firstName'
                  );
                  this.controls[name].inputControl.config.errors = 'another';
                  this.controls[name].inputControl.config.anotherError =
                    'שם פרטי לא קיים';
                  break;
                case 4:
                  this.haveEntitlement = false;
                  // this.error = 'שם משפחה לא קיים';
                  const ii = this.controls.findIndex(
                    (c) => c.inputControl.config.formControlName == 'lastName'
                  );
                  this.controls[ii].inputControl.config.errors = 'another';
                  this.controls[ii].inputControl.config.anotherError =
                    'שם משפחה לא קיים';
                  break;
                case 5:
                  this.haveEntitlement = false;
                  // this.error = 'תאריך לידה לא קיים';
                  const t = this.controls.findIndex(
                    (c) => c.inputControl.config.formControlName == 'birthDate'
                  );
                  this.controls[t].inputControl.config.errors = 'another';
                  this.controls[t].inputControl.config.anotherError =
                    'תאריך לידה לא קיים';
                  break;
                case 6:
                  this.haveEntitlement = false;
                  this.error = 'פרטים לא קיימים';
                  break;
              }
            });
          },
          (error) => {
            this.disabled = false;
            //  bugg
          }
        );
    }
  }
  print() {
    this.loading.show();
    if (this.residentService.fileFromRes) {
      this.openFile(
        this.residentService.fileFromRes.fileContents,
        this.residentService.fileFromRes.contentType
      );
    } else {
      //bug
    }
  }
  public openFile(fileContent, fileType: string) {
    const byteArray = new Uint8Array(
      atob(fileContent)
        .split('')
        .map((char) => char.charCodeAt(0))
    );
    var typeToConvert: string;
    typeToConvert = fileType;
    const file = new Blob([byteArray], { type: typeToConvert });
    var url = window.URL.createObjectURL(file);
    let anchor = document.createElement('a');
    anchor.href = url;
    anchor.target = '_blank';
    anchor.click();
    this.loading.hide();
  }
  sendEmail() {
    this.loading.show();
    let body: getSimpleMail = {
      pdfContent: this.residentService.fileFromRes.fileContents,
      email: this.form.controls['device'].value,
      familyID: +this.form.controls['id'].value,
      muniName: 'בדיקות',
      personName: this.form.controls['firstName'].value.toString(),
    };
    this.residentService
      .SendPdfInMail(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.isSending = true;
          this.loading.hide();
        },
        (error) => {
          this.loading.hide();
        }
      );
  }
  goHome(){
    this.router.navigate(['/'])
  }
  private generateForm() {
    if (this.controls?.length > 0) {
      let configs: InputConfig[] = [];
      this.controls.map((ctrl) => {
        if (!ctrl.inputControl.doubleConfig)
          configs.push(ctrl.inputControl?.config);
        else {
          ctrl.inputControl.doubleConfig.map((c) => {
            configs.push(c);
          });
        }
      });
      this.form = generateForm(configs);
    }
  }
}
