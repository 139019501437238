import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { stringDate } from '../../allForms/functions.model';

@Injectable({
  providedIn: 'root',
})
export class MyDateService {
  clear$ = new Subject<any>();
  constructor() {}
  public getStrinsOfDate(date: string): stringDate {
    let stringDate: stringDate;
    let arr = date.split('T')[0].split('-');
    let fullDate = new Date(+arr[0], +arr[1], +arr[2]);
    stringDate = {
      day: fullDate.getDate()!=31 ? fullDate.getDate() : 1,
      month: fullDate.getMonth()!=0 ? fullDate.getMonth() : 12,
      year: fullDate.getFullYear(),
    };
    return stringDate;
  }
    public getArrDateOfString(date: Date): stringDate {
    let back = this.getStringFronDateObject(date);
    let stringDate: stringDate;
    let arr = back.split('/');
    stringDate = {
      day: +arr[0],
      month: +arr[1]+1,
      year: +arr[2],
    };
    return stringDate;
  }
  public getStringFronDateObject(date: Date): string {
    let stringDate = '';
    stringDate =
      date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear();
    return stringDate;
  }
  public getIsraeliStrinsOfDate(strongeDate: string): string {
    let arr = strongeDate.split('/');
    let fullDate = arr[0] + '/' + (+arr[1] + 1) + '/' + arr[2];
    return fullDate;
  }
  public getDefultStrinsOfDate(strongeDate: string): string {
    let arr = strongeDate.split('/');
    let fullDate = +arr[1] + '/' + ( +arr[0]  ) + '/' + arr[2];
    // let fullDate = +arr[1] + '/' + (+arr[0]!=31? +arr[0] + 1 : 31) + '/' + arr[2];
    return fullDate;
  }
}
