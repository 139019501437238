import { Injectable } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { MuniService } from 'src/app/services/muni.service';
import {
  CollectionAsset,
  CollectionPerson,
  ExistRequestReq,
  FormPersonReq,
  FormPropertyReq,
  FormsExistData,
  GetAssetsByFamilyMainIDReq,
  SelfParticipationSpouseDetailsReq,
  generalSave,
  getExistReq,
  personDetailsReq,
  saveDeductible,
  sendRequestReq,
  stringDate,
  updateNumPersons,
} from './functions.model';
import { IListItem } from '../elementForm/form-input/form-input.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ChangePaymentsService } from './change-payments/change-payments.service';

@Injectable({
  providedIn: 'root',
})
export class FormsFunctionsService {
  public existRequestData$ = new BehaviorSubject<FormsExistData | any>(null);
  public collectionAsset$ = new BehaviorSubject<CollectionAsset[] | any>([]);
  public assetNumberList$ = new BehaviorSubject<IListItem[] | any>([]);
  public personGviaData$ = new BehaviorSubject<CollectionPerson>(null);
  public personID$ = new BehaviorSubject<string>(null);
  constructor(
    private app: AppService,
    private muni: MuniService,
    private authService: AuthService,
  ) {}
  public getExistRequest(formId: number, maintz: string) {
    let body: ExistRequestReq = {
      formType: formId,
      id: maintz,
      muniCode: this.muni.getMuniCode,
    };
    this.GetExistRequestSS(body).subscribe(
      (res) => {
        this.existRequestData$.next(res.body);
      },
      (error) => {}
    );
  }
  public GetExistRequestSS(body: ExistRequestReq): Observable<any> {
    return this.app.postF('Forms/MainForms/GetExistRequest', body);
  }
  public SaveApplicantRequestDetails(body: FormPersonReq): Observable<any> {
    return this.app.postF('Forms/MainForms/SaveApplicantRequestDetails', body);
  }
  public SavePropertyDetails(body: FormPropertyReq): Observable<any> {
    return this.app.postF('Forms/MainForms/SavePropertyDetails', body);
  }
  public GetAssetsByFamilyMainID(body: GetAssetsByFamilyMainIDReq) {
    this.app
      .post('Forms/CollectionData/GetAssetsByFamilyMainID', body)
      .subscribe(
        (res) => {
          if (res && res.body) {
            this.assetNumberList$.next(
              this.getListSelectFromAssetList(res.body)
            );
            this.collectionAsset$.next(res.body);
          }
        },
        (error) => {
        }
      );
  }
  public PersonsNumSaveDetails(body: updateNumPersons): Observable<any> {
    return this.app.postF('Forms/PersonsNumUpdate/SaveDetails', body);
  }
  public PersonsNumGetExist(body: getExistReq): Observable<any> {
    return this.app.postF('Forms/PersonsNumUpdate/GetExistRequest', body);
  }
  public deductibleSaveDetails(body: saveDeductible): Observable<any> {
    return this.app.postF('Forms/SelfParticipation/SaveDetails', body);
  }
  public deductibleSaveSpouseDetails(body: SelfParticipationSpouseDetailsReq): Observable<any> {
    return this.app.postF('Forms/SelfParticipation/SaveSpouseDetails', body);
  }
  public deductibleGetExist(body: getExistReq): Observable<any> {
    return this.app.postF('Forms/SelfParticipation/GetExistRequest', body);
  }
  public generalRequestGetExist(body: getExistReq): Observable<any> {
    return this.app.postF('Forms/GeneralRequest/GetExistRequest', body);
  }
  public generalRequestSaveDetails(body: generalSave): Observable<any> {
    return this.app.postF('Forms/GeneralRequest/SaveDetails', body);
  }


  public sendRequest(body: sendRequestReq): Observable<any> {
    return this.app.postF('Forms/MainForms/SendRequest', body);
  }
  public getPersonDetailsGvia(id: string) {
    let body: personDetailsReq = {
      id: id,
      muniCode: this.muni.getMuniCode,
    };
    this.getPersonDetails(body).subscribe((res) => {
      this.personGviaData$.next(res.body);
    });
  }
  public getPersonDetails(body: personDetailsReq) {
    return this.app.postF('Forms/CollectionData/GetPersonDetails', body);
  }
  public sendReportToMail() {
    let body = {
      muniCode: this.muni.getMuniCode,
      mainRequestID: this.existRequestData$.value.requestData.lineID,
    };
    return this.app.postF('Forms/MainForms/sendReportToMail', body);
  }
  private getListSelectFromAssetList(list: CollectionAsset[]): IListItem[] {
    let newList: IListItem[] = [];

    list.map((l) => {
      newList.push({ label: l.assetNum, value: l.assetNum });
    });

    return newList;
  }
}
