import { Component, OnDestroy, OnInit } from '@angular/core';
import { RandomService } from './random.service';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import {
  CasualService,
  CasualServicesCategories,
  randomPanel,
} from './random.model';
import { AuthService } from '../services/auth.service';
import { GuestDetailsComponent } from './guest-details/guest-details.component';
import { MatDialog } from '@angular/material/dialog';
import { PaymentService } from '../forms/elementForm/payment-page/payment.service';

@Component({
  selector: 'app-random-payments',
  templateUrl: './random-payments.component.html',
  styleUrls: ['./random-payments.component.scss'],
})
export class RandomPaymentsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  text = {
    title: 'תשלומים שונים',
  };
  categories: CasualServicesCategories[] = [];
  servicesByCategories: CasualService[] = [];
  appropriateServices: Array<CasualService> = this.servicesByCategories;
  appropriateServicesData: Array<CasualService> = this.servicesByCategories;
  panels: randomPanel[] = [
    {
      index: 0,
      accordionOpened: true,
      haveService: false,
      headerVisible: false,
      isEdit: false,
    },
  ];
  mobile = false;
  level = 1;
  count = 0;
  sum = 0;
  constructor(
    private randomService: RandomService,
    private authService: AuthService,
    private dialog: MatDialog,
    private paymentService: PaymentService
  ) {}
  ngOnDestroy(): void {
    this.destroy$.complete();
    this.level = 1;
    this.randomService.level$.next(1);
    this.randomService.selectedServices$.next([]);
    this.paymentService.basketItems$.next([]);
    this.paymentService.sum$.next(0);
  }
  ngOnInit(): void {
    if (window.screen.width <= 950) {
      this.mobile = true;
    }

    this.randomService.GetCasualServices();
    this.randomService.level$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.level = res;
      });
    this.randomService.selectedServices$.subscribe((res) => {
      if (res && res.length > 0) {
        this.count = res.length;
        this.sum = 0;
        res.map((r) => {
          this.sum += r.service.price * r.amount;
        });
        if (res.length >= 1) {
          let el = document.getElementById('footerM');
          if (el) {
            el.style.transition = 'all 0.5s ease';
            el.style.transform = 'translate(0, -130px)';
          }
        }
      } else {
        this.sum = 0;
        this.count = 0;
      }
    });
  }
  deletePanel(serviceCode: number) {
    let index = this.randomService.selectedServices$.value.findIndex(
      (s) => s.service.serviceCode == serviceCode
    );
    if (index != -1) {
      this.panels.splice(index, 1);
      let prev = this.randomService.selectedServices$.value;
      prev.splice(index, 1);
      this.randomService.selectedServices$.next(prev);
    } else {
      this.panels.splice(this.panels.length - 1, 1);
      if (this.panels.length == 0) {
        this.plus();
      }
    }
  }
  plus() {
    this.panels.push({
      index: 0,
      accordionOpened: true,
      haveService: false,
      headerVisible: false,
      isEdit: false,
    });
  }
  pay() {
    if (!this.authService.isLoggedIn()) {
      if (this.mobile) {
        this.randomService.level$.next(3);
      } else {
        const dialogRef = this.dialog.open(GuestDetailsComponent, {});
        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.destroy$))
          .subscribe((result) => {
            if (this.randomService.Guest?.value?.guestID) {
              this.randomService.setBasketItemsRandom();
              this.randomService.level$.next(2);
            }
          });
      }
    } else if (this.randomService.selectedServices$.value.length != 0) {
      this.randomService.setBasketItemsRandom();
      this.randomService.level$.next(2);
    }
  }
  goPrev(isGuest: boolean) {
    this.level = 1;
    if (!isGuest) this.randomService.selectedServices$.next([]);
  }
}
