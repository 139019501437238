import { Component, OnDestroy, OnInit } from '@angular/core';
import { MuniService } from 'src/app/services/muni.service';
import { FormsFunctionsService } from '../forms-functions.service';
import {
  emailconfig,
  firstNameconfig,
  formDeme,
  idconfig,
  lastNameconfig,
  phoneconfig,
} from '../../elementForm/controlsDeme';
import { saveDataLevel } from '../../elementForm/control.model';
import {
  FormPersonReq,
  FormsExistData,
} from '../functions.model';
import { MatStepper } from '@angular/material/stepper';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/common/elements/loader/loading.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-confidentiality-waiver',
  templateUrl: './confidentiality-waiver.component.html',
  styleUrls: ['./confidentiality-waiver.component.scss'],
})
export class ConfidentialityWaiverComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  ConfidentialityWaiverForm: formDeme = {
    id: 60,
    levels: [
      {
        id: 1,
        lable: '',
        name: 'פרטי מגיש הבקשה',
        type: 'form',
        controls: [
          idconfig,
          lastNameconfig,
          firstNameconfig,
          emailconfig,
          phoneconfig,
        ],
      },
      {
        id: 2,
        lable: '',
        name: 'הצהרה',
        type: 'sign',
        signBody: {
          buttons: [
            {
              btnName : 'check1',
              config : {
                name: 'check1',
                text : 'אני מאשר/ת למחלקה לשירותים חברתיים של הרשות למסור או לקבל פרטים ומסמכים הקשורים במצבי ובטיפול שניתן לי ולמשפחתי. וכל אינפורמציה שתידרש מאת המחלקה לשירותים חברתיים ובזה אני מוותר/ת על סודיות',
                type : 'checkbox',
              }
            },
            {
              btnName : 'check1',
              config : {
                name: 'check1',
                text : 'בשליחת טופס זה הריני מצהיר/ה בזה כי כל הפרטים הרשומים בבקשתי זו הם נכונים, מדויקים ומלאים. וכי לא העלמתי פרט כלשהו מהפרטים שנתבקשתי למלא וכי אני מסכים לתנאי השימוש באתר והצהרת הפרטיות כפי שמופעים באתר. משלוח טופס זה מהווה הסכמה לכך שהרשות המקומית תיצור איתי קשר באמצעות הטלפון בשיחה ו/או מסרון ובאמצעות הדוא"ל לצורך טיפול בפנייה ובנושאים אחרים בכל עת .ידוע לי כי דיווח חלקי ו/או כוזב בבקשתי זו הינו עבירה על החוק, וכי לרשות המקומית יש זכות לבדוק את נכונות הפרטים שמסרתי בבקשתי זו בכל האמצעים העומדים לרשותה בחוק .זהו שמי וזו תעודת הזהות שלי, ותוכן הצהרה זו - אמת',
                type : 'checkbox',
              }
            },
          ],
          sign: [
            {
              name: 'חתימת הפונה',
            },
          ],
          needEnd: false,
        },
      },
      {
        id: 3,
        lable: '',
        name: 'סיום',
        type: 'sendSucsses',
      },
    ],
    name: 'מחלקה לשירותים חברתיים- ויתור סודיות',
  };
  constructor(
    private muni: MuniService,
    private formsFunctions: FormsFunctionsService,
    private authService: AuthService,
    private loading: LoadingService
  ) {}

  ngOnInit(): void {
    this.formsFunctions.existRequestData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: FormsExistData) => {
        if (res && res?.requestData?.id != '') {
          this.setLevel1Data(res);
        }
      }, error=>{
      });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  setLevel1Data(res: FormsExistData) {
    if (res.requestData && res.requestData.applicantDetails) {
      this.ConfidentialityWaiverForm.levels[0].controls[1].inputControl.config.value =
        res.requestData.applicantDetails.lastName;
      this.ConfidentialityWaiverForm.levels[0].controls[2].inputControl.config.value =
        res.requestData.applicantDetails.firstName;
      this.ConfidentialityWaiverForm.levels[0].controls[3].inputControl.config.value =
        res.requestData.applicantDetails.email;
      this.ConfidentialityWaiverForm.levels[0].controls[4].inputControl.config.value =
        res.requestData.applicantDetails.phone;
    }
  }
  saveDataLevel(event: saveDataLevel) {
    let level = this.ConfidentialityWaiverForm.levels.find(
      (l) => l.id == event.levelID
    );
    if (level) {
      switch (level.id) {
        case 1:
          const body1: FormPersonReq = {
            email: event.data.email,
            firstName: event.data.firstName,
            lastName: event.data.lastName,
            muniCode: this.muni.getMuniCode,
            pasport: event.data.id,
            phone: event.data.phone,
            formType: this.ConfidentialityWaiverForm.id,
            address: null,
          };
          this.formsFunctions
            .SaveApplicantRequestDetails(body1)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res) => {
                this.next(event.stepper);
              },
              (error) => {}
            );
          break;
      }
    }
  }
  next(mystepper: MatStepper) {
    this.loading.hide();
    setTimeout(() => {
      mystepper.selected.completed = true;
      mystepper.next();
    }, 1);
  }
}
