import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'familyStatus',
})
export class FamilyStatusPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): string {
    switch (value) {
      case 1:
        return 'נשוי/אה';
      case 2:
        return 'רווק/ה';
      case 3:
        return 'גרוש/ה';
      case 4:
        return 'אלמן/ה';
      case 5:
        return 'פרוד/ה';
      default:
        return '';
    }
  }
}
