<div class="flex">
  <div class="required box">
    <span *ngIf="config?.required && !withoutLine">*</span>
  </div>
  <div class="main">
    <div class="fleHeader">
      <div class="titlediv">
        <span>{{ config?.label }}</span>
      </div>
      <div *ngIf="config?.tooltip" class="tooltip">
        <div class="tooltipIcon">?</div>
        <span class="tooltiptext">{{ config?.tooltip }}</span>
      </div>
    </div>

    <div class="input"
    [ngClass]="{
      withoutBorder: withoutLine,
      azureBack: withoutLine && file && file.fileID !== 0
    }"
    >
      <div class="mini">
        <span
        [ngClass]="{
          addPadding: withoutLine
        }"
          *ngIf="!(file && file.fileID !== 0)"
          (click)="fileUploadToPerson.click()"
        >
          {{ text.file }}
        </span>
        <span
          *ngIf="file && file.fileID !== 0"
          class="underLine"
          [ngClass]="{
            withoutBack: withoutLine
          }"
          (click)="inputfile.click()"
        >
          {{ file.fileName }}
        </span>
        <input
          #inputfile
          [formControl]="control"
          type="file"
          class="file-input"
          accept="application/pdf, image/png, image/jpeg"
          [disabled]="disabledFiles()"
          (change)="onFileSelected($event)"
          #fileUploadToPerson
        />
      </div>
      <div class="deleteDiv" *ngIf="file && file.fileID!=0">
        <img
          class="down"
          (click)="downloadFile()"
          src="../../../../assets/svg/eye.svg"
        />
        <img
          (click)="deleteFile()"
          class="delete"
          src="../../../../assets/svg/garbage.svg"
        />
      </div>
    </div>
    <div class="errorInput">
      <span *ngIf="control.hasError('required') || errorRes == 'required'">
        {{ config.label }} {{ error.is }} <strong>{{ error.must }}</strong>
      </span>
      <span *ngIf="errorRes == 'another'"> {{ errorAnother }} </span>
    </div>
  </div>
</div>
