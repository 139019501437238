import { Injectable } from '@angular/core';

import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';
import { AuthService } from '../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { LayoutService } from '../layout/layout.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  mobile = window.screen.width <= 850 ? true : false;
  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const needLogin = route.data[0].needLogin;
    const currentUser = this.authService.isLoggedIn();
    if (currentUser) {
      return true;
    }
    if (needLogin) {
      this.authService.prevUrl = route.url[0].path;
      if (!this.mobile)
        this.dialog.open(LoginComponent, {
          width: '490px',
          height: '550px',
        });
      else {
        this.layoutService.openMobileLogin$.next(true);
        this.router.navigate(['dashboard']);
      }
      return false;
    }
    return true;
  }
}
